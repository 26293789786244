/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PersonListInfoDTOAllOf { 
    /**
     * Person practice name
     */
    practiceName?: string;
    /**
     * Address
     */
    address?: string;
    /**
     * Person status
     */
    status?: string;
    deleted?: boolean;
    appointmentsNumber?: number;
    employeeNumber?: number;
    treatmentsNumber?: number;
}

