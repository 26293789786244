/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Sort Direction Enum
 */
export type SortDirectionEnumDTO = 'ASC' | 'DESC';

export const SortDirectionEnumDTO = {
    ASC: 'ASC' as SortDirectionEnumDTO,
    DESC: 'DESC' as SortDirectionEnumDTO
};

