import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { MessageService } from './message.service';
import { ToastContainerModule, ToastrModule, ToastrService } from 'ngx-toastr';
import { ToastPositionEnum } from '@gp-angular/shared/schema';

@NgModule({
	declarations: [
		ToastComponent
	],
	imports: [
		CommonModule,

		ToastContainerModule,
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: ToastPositionEnum.SMALL_BOTTOM,
			preventDuplicates: true
		})
	],
	providers: [
		MessageService,
		ToastrService,
	]
})
export class ServiceMessageModule {
	static forRoot(): ModuleWithProviders<ServiceMessageModule> {
		return {
			ngModule: ServiceMessageModule,
			providers: [MessageService, ToastrService]
		};
	}
}
