/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Result item to show in calendar dropdown
 */
export interface CalendarSearchItemDTO { 
    /**
     * Appointment Id
     */
    appointmentId?: number;
    /**
     * patientName
     */
    patientName?: string;
    /**
     * Date of the appointment
     */
    date?: string;
    /**
     * Start and end time
     */
    startAndEndTime?: string;
}

