/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Send email response Data Transfer Object
 */
export interface SendEmailResponseDTO { 
    /**
     * Send email detailed message.
     */
    message?: string;
}

