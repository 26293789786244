import { InsuranceTypeEnumDTO } from '@noventi/gp-platform/online-appointments';

export interface AppointmentTokenSchema {
	insuranceType: InsuranceTypeEnumDTO;
	startTime: Date;
	endTime: Date;
	widgetToken: string;
	selectedTreatment: WidgetObjectSchema;
	selectedResource?: WidgetObjectSchema;
	/** Request only: 1 */
	req?: number;
	/** Request only: Time interval start */
	ts?: string;
	/** Request only: Time interval end */
	te?: string;
}

export interface WidgetSchema {
	insurance?: InsuranceTypeEnumDTO;
	start?: Date;
	end?: Date;
	token?: string;
	treatment?: WidgetObjectSchema;
	resource?: WidgetObjectSchema;
}

export interface WidgetObjectSchema {
	id: number;
	name?: string;
}

export type BusinessHourAction = 'CREATE' | 'DELETE';

export const BusinessHourAction = {
	CREATE: 'CREATE' as BusinessHourAction,
	DELETE: 'DELETE' as BusinessHourAction
};


export type AppointmentAction = 'CREATE' | 'DELETE' | 'CANCEL' | 'TIME_UPDATED';

export const AppointmentAction = {
	CREATE: 'CREATE' as AppointmentAction,
	DELETE: 'DELETE' as AppointmentAction,
	CANCEL: 'CANCEL' as AppointmentAction,
	TIME_UPDATED: 'TIME_UPDATED' as AppointmentAction
};

export const SEARCH_MIN_LENGTH = 2;

export const CountryPrefixList: string[] = ['+49'];

