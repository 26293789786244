import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'gp-angular-loader',
	template: '<mat-progress-spinner mode="indeterminate"></mat-progress-spinner>',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class LoaderComponent {
}
