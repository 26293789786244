import deLocale from '@fullcalendar/core/locales/de';
import { CalendarDisplayModeEnumDTO } from '@noventi/gp-platform/users';

const DEFAULT_DURATION = '00:05:00';
const MIN_TIME = '00:00:00';
const MAX_TIME = '24:00:00';
export const MIN_SCROLL_HOUR = 6;
export const calendarOptions = {
	schedulerLicenseKey: '',
	weekNumberCalculation: 'ISO',
	resources: [],
	datesAboveResources: true,
	selectable: true,
	duration: DEFAULT_DURATION,
	headerToolbar: false,
	titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
	locales: [deLocale],
	locale: 'de',
	lazyFetching: true,
	firstDay: 1,
	// TODO: update flow needed!!! Backend does not support drag&drop or resize
	editable: false,
	droppable: false,
	eventDurationEditable: false,
	// editable: true,
	// droppable: true,
	// eventDurationEditable: true,
	resourceOrder: 'type,priority',
	slotLabelInterval: DEFAULT_DURATION,
	slotDuration: DEFAULT_DURATION,
	slotMinTime: MIN_TIME,
	slotMaxTime: MAX_TIME,
	stickyHeaderDates: true,
	height: 'parent',
	views: {
		month: {
			allDaySlot: true,
			datesAboveResources: true,
			resourceAreaWidth: 200,
			eventOverlap: false,
			firstDay: 1,
			handleWindowResize: true,
			titleFormat: {year: 'numeric', month: 'long'},
			slotEventOverlap: false,
			slotLabelFormat: [
				{weekday: 'short'},
				{day: '2-digit'}
			],
			slotDuration: {hours: 24},
			slotLabelInterval: {hours: 24},
			dayMinWidth: 20,
			//resourceGroupField: 'type'
		},
		week: {
			allDaySlot: false,
			datesAboveResources: true,
			eventOverlap: false,
			firstDay: 1,
			handleWindowResize: true,
			titleFormat: {year: 'numeric', month: 'short', day: 'numeric'},
			slotEventOverlap: false,
			weekNumbers: false
		},
		day: {
			allDaySlot: false,
			datesAboveResources: true,
			eventOverlap: false,
			firstDay: 1,
			handleWindowResize: true,
			titleFormat: {year: 'numeric', month: 'long', day: 'numeric'},
			slotEventOverlap: false
		}
	}
};

export enum CalendarViewType {
	DAILY = 'resourceTimeGridDay',
	WEEKLY = 'resourceTimeGridWeek',
	MONTHLY = 'resourceTimelineMonth'
}

export enum CalendarResourceHourType {
	BOOKING = 'heal-booking',
	ONLINE_BOOKING = 'heal-online-booking',
	ABSENT = 'heal-absent',
}

export const CALENDAR_DISPLAY_MAP = new Map([
	[CalendarViewType.DAILY, CalendarDisplayModeEnumDTO.DAILY],
	[CalendarViewType.WEEKLY, CalendarDisplayModeEnumDTO.WEEKLY],
	[CalendarViewType.MONTHLY, CalendarDisplayModeEnumDTO.MONTHLY],
])

