import { createReducer, on, Action } from '@ngrx/store';

import * as AccountActions from './account.actions';
import { AccountData, AccountManage } from './account.models';

export const ACCOUNT_FEATURE_KEY = 'account';

export interface State {
	/** UserDTO: name, email */
	data: AccountData;
	/** Manage resource ids */
	manage: AccountManage;
	/** Permission in app */
	permission: Array<string>;
	currentPid: number;
}

export interface AccountPartialState {
	readonly [ACCOUNT_FEATURE_KEY]: State;
}

export const initialState: State = {
	data: undefined,
	manage: undefined,
	permission: [],
	currentPid: undefined
};

const accountReducer = createReducer(
	initialState,
	on(AccountActions.Init, (state) => ({...state})),
	on(AccountActions.SetData, (state, {data}) => ({...state, data})),
	on(AccountActions.SetManageIds, (state, {manage}) => ({...state, manage})),
	on(AccountActions.SetPermission, (state, {permission}) => ({...state, permission})),
	on(AccountActions.SetCurrentPid, (state, {currentPid}) => ({...state, currentPid})),
	on(AccountActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return accountReducer(state, action);
}
