import { createAction, props } from '@ngrx/store';
import { AccountData, AccountManage } from './account.models';

export const Init = createAction('[Account] Init');

export const SetData = createAction(
	'[Account] Set Data',
	props<{ data: AccountData }>()
);

export const SetManageIds = createAction(
	'[Account] Set Manage Ids',
	props<{ manage: AccountManage }>()
);

export const SetPermission = createAction(
	'[Account] Set Permission',
	props<{ permission: Array<string> }>()
);

export const SetCurrentPid = createAction(
	'[Account] Set Current Pid',
	props<{ currentPid: number }>()
);

export const ClearState = createAction('[Account] Clear State');
