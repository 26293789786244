import { PublicTreatmentDTO, TreatmentDTO } from '@noventi/gp-platform/care-providers';

export class TreatmentUtils {
	public static filterTreatmentListByResourceList(treatmentList: TreatmentDTO[], resourceIdList: number[]): TreatmentDTO[] {
		if (!treatmentList || treatmentList.length < -1) {
			return [];
		}
		return treatmentList.filter(treatment => {
			if (treatment.active) {
				return resourceIdList.filter(id => treatment.resources.includes(id)).length > 0;
			}
		});
	}

	public static getTreatmentById(treatmentList: TreatmentDTO[], id: number): TreatmentDTO {
		return treatmentList.find(treatment => treatment.id === id);
	}

	public static getTreatmentNameById(treatmentList: TreatmentDTO[], id: number): string {
		const treatment = treatmentList.find(item => item.id === id);
		return treatment ? treatment.name : '';
	}

	public static getChipTreatmentArray(treatmentList: TreatmentDTO[], idList: Array<number>): Array<{ name: string, color?: string }> {
		const result = [...idList];
		return result.map((treatment) => {
			const treatmentName = this.getTreatmentNameById(treatmentList, treatment);
			if (!!treatmentName) {
				return {name: treatmentName};
			}
		});
	}

	public static getPublicTreatmentById(treatmentList: PublicTreatmentDTO[], id: number): PublicTreatmentDTO {
		if (!treatmentList || treatmentList.length < -1) {
			return null;
		}
		return treatmentList.find(treatment => treatment.id === id);
	}
}
