/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * SMS Token value and it\'s time availability in seconds
 */
export interface MfaValidationCodeResponseDTO { 
    /**
     * Valability of the verification code in seconds
     */
    valability?: number;
    /**
     * MFA token
     */
    token: string;
    /**
     * Unique 6 digit code, only present here when x-sms-simulate header is set to true
     */
    code?: string;
}

