import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AbsentHoursService } from './api/absentHours.service';
import { BookingHoursService } from './api/bookingHours.service';
import { CPInternalService } from './api/cPInternal.service';
import { ExpertiseAreaService } from './api/expertiseArea.service';
import { KeyValuePairService } from './api/keyValuePair.service';
import { KpiService } from './api/kpi.service';
import { LanguageService } from './api/language.service';
import { MedicalServiceService } from './api/medicalService.service';
import { MessageTemplateService } from './api/messageTemplate.service';
import { OnlineBookingHoursService } from './api/onlineBookingHours.service';
import { PeriodicalDeletionService } from './api/periodicalDeletion.service';
import { PersonService } from './api/person.service';
import { PortalService } from './api/portal.service';
import { ProfessionService } from './api/profession.service';
import { ResourceService } from './api/resource.service';
import { ResourceOverviewService } from './api/resourceOverview.service';
import { SettingsService } from './api/settings.service';
import { TreatmentsService } from './api/treatments.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
