import { Directive, Input, OnChanges, OnDestroy, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[appHealPhoneFormatter]',
})
export class PhoneFormatterDirective implements OnChanges, OnDestroy {
	private _ngControlSubscription: ISubscription;

	@Input() formControlName;

	constructor(@Self() private ngControl: NgControl) {
	}

	public ngOnChanges(): void {
		if (this._ngControlSubscription && !this._ngControlSubscription.closed) {
			this._ngControlSubscription.unsubscribe();
		}
		this._ngControlSubscription = this.ngControl.valueChanges
			.pipe(startWith(''), pairwise())
			.subscribe(([oldValue, newValue]: [any, any]) => {
				if (newValue && oldValue) {
					if (
						(newValue === 0 || String(newValue).startsWith('0')) &&
						!String(newValue).startsWith('0)')
					) {
						this.ngControl.control.setValue('(0)' + newValue.slice(1));
						newValue = '(0)' + newValue.slice(1);
					}

					// if (!newValue.startsWith('(0)') && oldValue.startsWith('(0)')) {
					// 	this.ngControl.control.setValue(newValue.slice(2));
					// }
				}
			});
	}

	public ngOnDestroy(): void {
		if (this._ngControlSubscription && !this._ngControlSubscription.closed) {
			this._ngControlSubscription.unsubscribe();
		}
	}
}
