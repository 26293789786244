import { createReducer, on, Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import * as ProviderActions from './provider.actions';
import { AsyncStoreObject, isNullOrEmpty } from '@gp-angular/shared/utils';
import { MessageDTO } from '@noventi/gp-platform/online-appointments';
import {
	CalendarSettingsDTO, MessageTemplateDTO, PersonDTO, ResourceCategoryWrapperDTO, ResourceDTO, SettingsDTO
} from '@noventi/gp-platform/care-providers';

export const PROVIDER_FEATURE_KEY = 'provider';

export interface State {
	data: PersonDTO;
	calendarResources: Array<ResourceDTO>;
	messageCount: number;
	messageList: AsyncStoreObject<Array<MessageDTO>>;
	systemTemplates: AsyncStoreObject<Array<MessageTemplateDTO>>;
	customTemplates: AsyncStoreObject<Array<MessageTemplateDTO>>;
	resources: Array<ResourceCategoryWrapperDTO>;
	settings?: {
		appointment?: SettingsDTO,
		calendar?: CalendarSettingsDTO
	};
	loading: boolean;
	error?: HttpErrorResponse | null;
}

export interface ProviderPartialState {
	readonly [PROVIDER_FEATURE_KEY]: State;
}

export const initialState: State = {
	// set initial required properties
	data: undefined,
	calendarResources: undefined,
	messageCount: 0,
	messageList: new AsyncStoreObject([]),
	systemTemplates: new AsyncStoreObject([]),
	customTemplates: new AsyncStoreObject([]),
	resources: [],
	settings: {
		appointment: undefined,
		calendar: undefined
	},
	loading: false,
};

const providerReducer = createReducer(
	initialState,

	on(ProviderActions.LoadProvider, ProviderActions.SaveProviderInformation, ProviderActions.ClearReinitializedProvider, (state) => ({
		...state, loading: true, error: null
	})),
	on(ProviderActions.LoadProviderComplete, ProviderActions.SaveProviderInformationComplete, (state, {data}) => ({
		...state, data, loading: false, error: null
	})),
	//on(ProviderActions.LoadProviderFailed, ProviderActions.SaveProviderInformationFailed, (state, {error}) => ({...state, error})),

	on(ProviderActions.CountUnreadMessageComplete, (state, {payload}) => ({...state, messageCount: payload})),
	on(ProviderActions.UpdateCountUnreadMessage, (state, {payload}) => ({...state, messageCount: state.messageCount - payload})),
	on(ProviderActions.LoadSystemTemplatesComplete, (state, {payload}) => ({
		...state, systemTemplates: new AsyncStoreObject(payload, false, state.systemTemplates.saving)
	})),
	on(ProviderActions.LoadCustomTemplatesComplete, (state, {payload}) => ({
		...state, customTemplates: new AsyncStoreObject(payload, false, state.customTemplates.saving)
	})),
	on(ProviderActions.SaveCustomTemplateComplete, (state, {payload}) => {
		const messageTemplate: MessageTemplateDTO = payload;
		const oldMessageTemplateList: MessageTemplateDTO[] = !isNullOrEmpty(state.customTemplates.value) ?
			state.customTemplates.value : [];
		const index = oldMessageTemplateList.findIndex(message => message.id === messageTemplate.id);
		const newMessageTemplateList = index < 0 ? [...oldMessageTemplateList, messageTemplate] :
			[...oldMessageTemplateList.slice(0, index), messageTemplate, ...oldMessageTemplateList.slice(index + 1)];
		return {
			...state,
			customTemplates: new AsyncStoreObject(newMessageTemplateList, false, state.customTemplates.saving)
		};
	}),
	on(ProviderActions.CreateResourceComplete, ProviderActions.UpdateResourceComplete, (state, {resources}) => ({...state, resources})),
	on(ProviderActions.LoadSettingsComplete, ProviderActions.LoadCalendarSettingsComplete,
		(state, {settings}) => ({...state, settings, loading: false, error: null})),
	on(ProviderActions.UpdateSettingsComplete, ProviderActions.UpdateCalendarSettingsComplete,
		(state, {settings}) => ({...state, settings, loading: false, error: null})),

	on(ProviderActions.DeleteResourceComplete, (state, {payload: {resources, calendarResources}}) => ({
		...state, resources, calendarResources
	})),
	on(ProviderActions.LoadResourceGroup, (state) => ({...state})),
	on(ProviderActions.LoadResourceGroupComplete, (state, {resources}) => ({...state, resources})),
	on(ProviderActions.SetCalendarResources, (state, {calendarResources}) => ({...state, calendarResources})),
	on(ProviderActions.ReorderResourcesComplete, (state, {payload: {calendarResources, resources}}) => ({
		...state, calendarResources, resources
	})),
	on(ProviderActions.ReorderResourcesFailed, (state) => ({...state})),

	on(ProviderActions.Error, (state, {error}) => ({...state, error})),

	on(ProviderActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return providerReducer(state, action);
}
