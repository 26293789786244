/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines one attribute with all needed values
 */
export interface MessageTemplateAttributeDTO { 
    /**
     * message template attribute id
     */
    id?: number;
    /**
     * name of attribute
     */
    name?: string;
    /**
     * showing label (name in German)
     */
    label?: string;
    /**
     * value to show in preview
     */
    previewValue?: string;
    /**
     * whether this field is mandatory
     */
    mandatory?: boolean;
}

