/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Requested registration status enum data transfer object
 */
export type RegisterStatusEnumDTO = 'EMAIL_NOT_VERIFIED' | 'EMAIL_VERIFIED' | 'LOCKED' | 'ACTIVATED';

export const RegisterStatusEnumDTO = {
    EMAILNOTVERIFIED: 'EMAIL_NOT_VERIFIED' as RegisterStatusEnumDTO,
    EMAILVERIFIED: 'EMAIL_VERIFIED' as RegisterStatusEnumDTO,
    LOCKED: 'LOCKED' as RegisterStatusEnumDTO,
    ACTIVATED: 'ACTIVATED' as RegisterStatusEnumDTO
};

