import { PageEvent } from '@angular/material/paginator';

export const PageEventPagination: PageEvent = {
	previousPageIndex: 0,
	pageIndex: 0,
	pageSize: 50,
	length: 0
};

export type AppNameType = 'ADMIN' | 'BOOKING' | 'PORTAL';

export const AppNameType = {
	ADMIN: 'ADMIN' as AppNameType,
	BOOKING: 'BOOKING' as AppNameType,
	PORTAL: 'PORTAL' as AppNameType
};

export type cmsContentList = 'AGB' | 'FAQ' | 'Datenschutz' | 'Impressum' | 'Kontakt';

export const cmsContentList = {
	AGB: 'AGB' as cmsContentList,
	FAQ: 'FAQ' as cmsContentList,
	DATENSCHUTZ: 'Datenschutz' as cmsContentList,
	IMPRESSUM: 'Impressum' as cmsContentList,
	KONTAKT: 'Kontakt' as cmsContentList,
};

export const SEARCH_DEBOUNCE_DEFAULT = 500;

export const AcceptedImagesType: Array<string> = ['image/jpg', 'image/jpeg', 'image/bmp', 'image/png'];

export const FIRST_DAY_OF_THE_WEEK = 1;

export const MAX_FILE_SIZE = 25000000;
