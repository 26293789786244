import { FormArray } from '@angular/forms';
import { PersonDTO } from '@noventi/gp-platform/care-providers';

export class ProviderUtils {
	private static getName(provider: PersonDTO): string {
		return `${provider.firstname ? provider.firstname : ''} ${provider.lastname ? provider.lastname : ''}`;
	}

	public static displayName(provider: PersonDTO, short = false): string {
		if (!provider) {
			return undefined;
		}
		if (short) {
			return this.getName(provider);
		}
		return `${provider.salutation ? provider.salutation : ''}
					${provider.academicalDegree ? provider.academicalDegree : ''}
					${this.getName(provider)}`;
	}

	public static getOrderedArrayValues(form, formArrayName: string): Array<any> {
		const orderedItems: Array<any> = [];

		const controls = form.get(formArrayName) as FormArray;
		Object.keys(controls.controls).forEach(control => {
			const resource = Object.assign({}, controls.controls[control].value, {index: control});
			orderedItems.push(resource);
		});
		return orderedItems;
	}
}
