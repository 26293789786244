/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * AbsentHour Pageable Request Data Transfer Object
 */
export interface PagedAbsentHourRequestDTO { 
    /**
     * Id of the resource
     */
    resourceId: number;
    /**
     * Start date
     */
    startDate: string;
    /**
     * End date
     */
    endDate: string;
    /**
     * Start time
     */
    startTime?: string;
    /**
     * End time
     */
    endTime?: string;
    /**
     * Page number
     */
    page?: number;
    /**
     * Page size
     */
    size?: number;
}

