import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from './environment.service';

@NgModule({
  imports: [CommonModule]
})

export class ServiceEnvironmentModule {
	static forRoot(): ModuleWithProviders<ServiceEnvironmentModule> {
		return {
			ngModule: ServiceEnvironmentModule,
			providers: [EnvironmentService]
		};
	}
}
