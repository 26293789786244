/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter Criteria Enum Data Transfer Object
 */
export type FilterCriteriaEnumDTO = 'EQUALS' | 'CONTAINS' | 'STARTS_WITH' | 'GREATER_THAN' | 'GREATER_THAN_OR_EQUALS' | 'LESS_THAN' | 'LESS_THAN_OR_EQUALS';

export const FilterCriteriaEnumDTO = {
    EQUALS: 'EQUALS' as FilterCriteriaEnumDTO,
    CONTAINS: 'CONTAINS' as FilterCriteriaEnumDTO,
    STARTSWITH: 'STARTS_WITH' as FilterCriteriaEnumDTO,
    GREATERTHAN: 'GREATER_THAN' as FilterCriteriaEnumDTO,
    GREATERTHANOREQUALS: 'GREATER_THAN_OR_EQUALS' as FilterCriteriaEnumDTO,
    LESSTHAN: 'LESS_THAN' as FilterCriteriaEnumDTO,
    LESSTHANOREQUALS: 'LESS_THAN_OR_EQUALS' as FilterCriteriaEnumDTO
};

