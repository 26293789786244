/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceHoursItemDTOAllOf { 
    daysOfWeek?: Array<number>;
    /**
     * the item\'s date
     */
    itemDate?: string;
    /**
     * Start time
     */
    startTime?: string;
    /**
     * End time
     */
    endTime?: string;
    bookingHours?: boolean;
    onlineBookingHours?: boolean;
    absentHours?: boolean;
}

