import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as AdminActions from './admin.actions';
import { AdminUtils } from '../admin.utils';
import { PageableRequestDTO } from '@noventi/gp-platform/care-providers';
import { PageableUsersRequestDTO } from '@noventi/gp-platform/users';

export const ADMIN_FEATURE_KEY = 'admin';

export interface State extends EntityState<{}> {
	providerListFilter: PageableRequestDTO;
	userListFilter: PageableUsersRequestDTO;
	loading: boolean;
	error?: HttpErrorResponse | null;
}

export interface AdminPartialState {
	readonly [ADMIN_FEATURE_KEY]: State;
}

export const adminAdapter: EntityAdapter<State> = createEntityAdapter<State>();

export const initialState: State = adminAdapter.getInitialState({
	providerListFilter: AdminUtils.defaultPageableRequest('PageableRequestDTO'),
	userListFilter: AdminUtils.defaultPageableRequest('PageableUsersRequestDTO'),
	loading: false,
	error: undefined
});

const adminReducer = createReducer(
	initialState,
	on(AdminActions.SetProviderListFilter, (state, {providerListFilter}) => ({...state, providerListFilter})),
	on(AdminActions.ClearProviderListFilter, (state, {providerListFilter}) => ({...state, providerListFilter})),

	on(AdminActions.SetUserListFilter, (state, {userListFilter}) => ({...state, userListFilter})),
	on(AdminActions.ClearUserListFilter, (state, {userListFilter}) => ({...state, userListFilter})),

	on(AdminActions.Error, (state, {error}) => ({...state, error})),

	on(AdminActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return adminReducer(state, action);
}
