<button mat-button color="primary" class="close" mat-dialog-close *ngIf="data && !data.closeIcon">
    <mat-icon class="icon-close" color="primary"></mat-icon>
</button>
<h1 mat-dialog-title>{{'Modal.Provider.Widget.Title' | translate}}</h1>

<mat-dialog-content>
    <p>{{'Modal.Provider.Widget.Content_Block_Button' | translate}}:</p>
    <div class="html-code margin-bottom">
        <span class="comment">// {{'Modal.Provider.Widget.Content_Floating_Button' | translate}}:</span>
        <div class="flex relative margin-top-S margin-bottom-L">
            <div [innerHTML]="displayFloatingButtonCode()"></div>
            <button mat-icon-button color="primary" matTooltipPosition="before"
                    [matTooltip]="'App.General.Copy_Floating_Button' | translate | uppercase"
                    (click)="copy('floating')">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
        <span class="comment">// {{'Modal.Provider.Widget.Content_Sticky_Button' | translate}}:</span>
        <div class="flex relative margin-top-S">
            <div [innerHTML]="displayStickyButtonCode()"></div>
            <button mat-icon-button color="primary" matTooltipPosition="before"
                    [matTooltip]="'App.General.Copy_Sticky_Button' | translate | uppercase"
                    (click)="copy('sticky')">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>

    <p class="padding-top">{{'Modal.Provider.Widget.Content_Block_Script' | translate}}:</p>
    <div class="html-code flex relative margin-bottom">
        <mat-button-toggle-group>
            <mat-button-toggle color="primary" [checked]="widget" (click)="widget = true">
                <mat-icon>widgets</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle color="primary" [checked]="!widget" (click)="widget = false">
                <mat-icon>code</mat-icon>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div [innerHTML]="displayWidgetCode(widget)"></div>
        <button mat-icon-button color="primary" matTooltipPosition="before"
                [matTooltip]="'App.General.Copy_Script' | translate | uppercase"
                (click)="copy(widget ? 'widget' : 'iframe')">
            <mat-icon>content_copy</mat-icon>
        </button>
    </div>

    <p class="padding-top">{{'Modal.Provider.Widget.Content_Link' | translate}}:</p>
    <div class="relative">
        <div class="html-code flex">
            <a href="{{data.profileLink}}" target="_blank" contenteditable="false" class="no-code margin-right">
                {{data.profileLink}}
            </a>
            <button mat-icon-button color="primary" matTooltipPosition="before"
                    [matTooltip]="'App.General.Copy_Url' | translate | uppercase"
                    (click)="copy('link')">
                <mat-icon>content_copy</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'right'">
    <button mat-button (click)="close()" color="primary">{{'App.General.Cancel' | translate | uppercase}} </button>
</mat-dialog-actions>
