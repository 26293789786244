export const environment = {
	production: false,
	name: 'admin-ui',
	app: 'booking',
	env: undefined,
	version: undefined,
	endpoints: undefined,
	websocket: undefined,
	analytics: undefined
};
