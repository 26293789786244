/**
 * Gesundheitsportal - Patients REST API
 * Gesundheitsportal Patients Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Job status enum
 */
export type JobStatusEnumDTO = 'SUCCESS' | 'ERROR';

export const JobStatusEnumDTO = {
    SUCCESS: 'SUCCESS' as JobStatusEnumDTO,
    ERROR: 'ERROR' as JobStatusEnumDTO
};

