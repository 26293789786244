<div class="margin-bottom">
    <mat-slide-toggle class="text-wrap margin-right-S" [checked]="fc.value" (change)="checkboxToggle($event, fc)">{{label}}</mat-slide-toggle>
</div>
<mat-form-field appearance="outline" class="time">
    <mat-select #select [formControl]="fc" (valueChange)="emit(fc)" [placeholder]="placeholderText()" >
        <mat-option *ngFor="let item of list" [value]="item">
            {{item}}{{valueText}}
        </mat-option>
    </mat-select>
    <mat-error>{{messageService.error(fc, label)}}</mat-error>
</mat-form-field>
