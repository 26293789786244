<mat-form-field appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-select #select [formControl]="fc" (valueChange)="emit(fc)">
        <mat-option *ngFor="let time of [5,10,15,20,25,30,35,40,45,50,55,60]" [value]="time">
            {{time}} {{ 'App.General.Minutes' | translate }}
        </mat-option>
    </mat-select>
    <mat-checkbox matPrefix [checked]="fc.enabled"></mat-checkbox>
    <mat-error>{{messageService.error(fc, label)}}</mat-error>
</mat-form-field>
<mat-checkbox class="over" [checked]="fc.value" (change)="checkboxToggle($event, fc)"></mat-checkbox>
