/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of resource
 */
export type ResourceTypeEnumDTO = 'EMPLOYEE' | 'ROOM' | 'DEVICE';

export const ResourceTypeEnumDTO = {
    EMPLOYEE: 'EMPLOYEE' as ResourceTypeEnumDTO,
    ROOM: 'ROOM' as ResourceTypeEnumDTO,
    DEVICE: 'DEVICE' as ResourceTypeEnumDTO
};

