import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { asyncScheduler, Observable, of, scheduled, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';

import * as AdminActions from './+state/admin.actions';
import { AdminFacade } from './+state/admin.facade';
import { AdminUtils } from './admin.utils';
import {
	CreateRegistrationRequestBodyDTO, ExpertiseAreaDTO, HistoryTypeEnumDTO, MedicalServiceDTO, PageablePersonHistoryResponseDTO,
	PageablePersonsResponseDTO, PageableRequestDTO, ProfessionDTO, RegistrationRequestDTO, UsagesDTO
} from '@noventi/gp-platform/care-providers';
import { PageableUsersRequestDTO, PageableUsersResponseDTO, UserDTO } from '@noventi/gp-platform/users';

@Injectable()
export class AdminService {

	constructor(
		private actionsSubject$: ActionsSubject,
		private adminFacade: AdminFacade
	) {
	}

	public loadProviderList() {
		this.adminFacade.dispatch(AdminActions.LoadProviderList());
	}

	public setProviderListFilter(filter: PageableRequestDTO) {
		this.adminFacade.dispatch(AdminActions.SetProviderListFilter({providerListFilter: filter}));
	}

	public getProviderListFilter$(): Observable<PageableRequestDTO> {
		return this.adminFacade.providerListFilter$;
		//	.pipe(
		//	takeUntil(this.actionsSubject$.pipe(ofType(AdminActions.ClearState))
		//)
	}

	public getProviderListPageEvent(): PageEvent {
		let PAGE_EVENT;
		this.adminFacade.providerListFilter$
			.subscribe((next) => PAGE_EVENT = AdminUtils.getPageEvent(next));
		return PAGE_EVENT;
	}

	public getProviderList$(): Observable<PageablePersonsResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadProviderListComplete, AdminActions.LoadProviderListFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadProviderListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadProviderRequestList(personId: number) {
		this.adminFacade.dispatch(AdminActions.LoadProviderRequestList({payload: personId}));
	}

	public getProviderRequestList$(): Observable<Array<RegistrationRequestDTO>> {
		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadProviderRequestListComplete, AdminActions.LoadProviderRequestListFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadProviderRequestListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public sendProviderRegistrationRequest$(payload: { id: number, request: CreateRegistrationRequestBodyDTO }): Observable<any> {
		this.adminFacade.dispatch(AdminActions.SendProviderRequest({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.SendProviderRequestComplete, AdminActions.SendProviderRequestFailed),
			switchMap((action) => {
				if (action.type === AdminActions.SendProviderRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public updateRequestStatus$(registrationRequest: RegistrationRequestDTO): Observable<RegistrationRequestDTO> {
		this.adminFacade.dispatch(AdminActions.UpdateRegistrationStatus({
			payload: {
				registrationRequest: registrationRequest,
				id: registrationRequest.id
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.UpdateRegistrationStatusComplete, AdminActions.UpdateRegistrationStatusFailed),
			take(1),
			switchMap((action) => {
				if (action.type === AdminActions.UpdateRegistrationStatusFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteProviderRequest$(id: number): Observable<any> {
		this.adminFacade.dispatch(AdminActions.DeleteProviderRequest({payload: id}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.DeleteProviderRequestComplete, AdminActions.DeleteProviderRequestFailed),
			take(1),
			switchMap((action) => {
				if (action.type === AdminActions.DeleteProviderRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	//TODO: HERE
	public loadProviderHistoryList(providerId: number, historyType: HistoryTypeEnumDTO, pageEvent: PageEvent) {
		this.adminFacade.dispatch(AdminActions.LoadProviderHistory({
			payload: {
				providerId: providerId,
				historyType: historyType,
				pageableRequestDTO: {
					page: pageEvent.pageIndex,
					size: pageEvent.pageSize
				} as PageableRequestDTO
			}
		}));
	}

	public getProviderHistoryList$(): Observable<PageablePersonHistoryResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadProviderHistoryComplete, AdminActions.LoadProviderHistoryFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadProviderHistoryFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadUserList() {
		this.adminFacade.dispatch(AdminActions.LoadUserList());
	}

	public setUserListFilter(filter: PageableUsersRequestDTO) {
		this.adminFacade.dispatch(AdminActions.SetUserListFilter({userListFilter: filter}));
	}

	public getUserListFilter$(): Observable<PageableUsersRequestDTO> {
		return this.adminFacade.userListFilter$;
	}

	public getUserListPageEvent$(): Observable<PageEvent> {
		return this.adminFacade.pageEventForUserList$;
	}

	public getUserList$(): Observable<PageableUsersResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadUserListComplete, AdminActions.LoadUserListFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadUserListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadUser(username: string) {
		this.adminFacade.dispatch(AdminActions.LoadUser({payload: username}));
	}

	public getUser$(): Observable<UserDTO> {
		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadUserComplete, AdminActions.LoadUserFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createUser$(data: UserDTO): Observable<UserDTO> {
		this.adminFacade.dispatch(AdminActions.CreateUser({payload: data}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.CreateUserComplete, AdminActions.CreateUserFailed),
			switchMap((action) => {
				if (action.type === AdminActions.CreateUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public updateUser$(username: string, data: UserDTO): Observable<UserDTO> {
		this.adminFacade.dispatch(AdminActions.UpdateUser({payload: {username: username, data: data}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.UpdateUserComplete, AdminActions.UpdateUserFailed),
			switchMap((action) => {
				if (action.type === AdminActions.UpdateUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getGeneralDataServicesList$(): Observable<Array<MedicalServiceDTO>> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataServices());

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataServicesComplete, AdminActions.LoadGeneralDataServicesFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataServicesFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteGeneralDataService$(serviceId: number): Observable<any> {
		this.adminFacade.dispatch(AdminActions.DeleteGeneralDataService({payload: serviceId}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.DeleteGeneralDataServiceComplete, AdminActions.DeleteGeneralDataServiceFailed),
			switchMap((action) => {
				if (action.type === AdminActions.DeleteGeneralDataServiceFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public createGeneralDataService$(medicalService: MedicalServiceDTO): Observable<MedicalServiceDTO> {
		this.adminFacade.dispatch(AdminActions.CreateGeneralDataService({payload: medicalService}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.CreateGeneralDataServiceComplete, AdminActions.CreateGeneralDataServiceFailed),
			switchMap((action) => {
				if (action.type === AdminActions.CreateGeneralDataServiceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public editGeneralDataService$(serviceId: number, data): Observable<MedicalServiceDTO> {
		this.adminFacade.dispatch(AdminActions.EditGeneralDataService({payload: {serviceId: serviceId, data: data}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.EditGeneralDataServiceComplete, AdminActions.EditGeneralDataServiceFailed),
			switchMap((action) => {
				if (action.type === AdminActions.EditGeneralDataServiceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}


	public getGeneralDataProfessionList$(): Observable<Array<ProfessionDTO>> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataProfessions());

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataProfessionsComplete, AdminActions.LoadGeneralDataProfessionsFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataProfessionsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteGeneralDataProfession$(professionId: number): Observable<any> {
		this.adminFacade.dispatch(AdminActions.DeleteGeneralDataProfession({payload: professionId}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.DeleteGeneralDataProfessionComplete, AdminActions.DeleteGeneralDataProfessionFailed),
			switchMap((action) => {
				if (action.type === AdminActions.DeleteGeneralDataProfessionFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public createGeneralDataProfession$(profession: ProfessionDTO): Observable<ProfessionDTO> {
		this.adminFacade.dispatch(AdminActions.CreateGeneralDataProfession({payload: profession}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.CreateGeneralDataProfessionComplete, AdminActions.CreateGeneralDataProfessionFailed),
			switchMap((action) => {
				if (action.type === AdminActions.CreateGeneralDataProfessionFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public editGeneralDataProfession$(professionId: number, data): Observable<ProfessionDTO> {
		this.adminFacade.dispatch(AdminActions.EditGeneralDataProfession({payload: {professionId: professionId, data: data}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.EditGeneralDataProfessionComplete, AdminActions.EditGeneralDataProfessionFailed),
			switchMap((action) => {
				if (action.type === AdminActions.EditGeneralDataProfessionFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getGeneralDataExpertiseAreaList$(): Observable<Array<ExpertiseAreaDTO>> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataExpertiseAreas());

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataExpertiseAreasComplete, AdminActions.LoadGeneralDataExpertiseAreasFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataExpertiseAreasFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteGeneralDataExpertiseArea$(expertiseAreaId: number): Observable<any> {
		this.adminFacade.dispatch(AdminActions.DeleteGeneralDataExpertiseArea({payload: expertiseAreaId}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.DeleteGeneralDataExpertiseAreaComplete, AdminActions.DeleteGeneralDataExpertiseAreaFailed),
			switchMap((action) => {
				if (action.type === AdminActions.DeleteGeneralDataExpertiseAreaFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public createGeneralDataExpertiseArea$(expertiseArea: ExpertiseAreaDTO): Observable<ExpertiseAreaDTO> {
		this.adminFacade.dispatch(AdminActions.CreateGeneralDataExpertiseArea({payload: expertiseArea}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.CreateGeneralDataExpertiseAreaComplete, AdminActions.CreateGeneralDataExpertiseAreaFailed),
			switchMap((action) => {
				if (action.type === AdminActions.CreateGeneralDataExpertiseAreaFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public editGeneralDataExpertiseArea$(expertiseAreaId: number, data): Observable<ExpertiseAreaDTO> {
		this.adminFacade.dispatch(AdminActions.EditGeneralDataExpertiseArea({payload: {expertiseAreaId: expertiseAreaId, data: data}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.EditGeneralDataExpertiseAreaComplete, AdminActions.EditGeneralDataExpertiseAreaFailed),
			switchMap((action) => {
				if (action.type === AdminActions.EditGeneralDataExpertiseAreaFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getServiceUsagesNumber$(id: number): Observable<UsagesDTO> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataServiceUsageNumber({payload: {serviceId: id}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataServicesUsageNumberComplete, AdminActions.LoadGeneralDataServicesUsageNumberFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataServicesUsageNumberFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getExpertiseUsagesNumber$(id: number): Observable<UsagesDTO> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataExpertiseAreaUsageNumber({payload: {serviceId: id}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataExpertiseAreaUsageNumberComplete, AdminActions.LoadGeneralDataExpertiseAreaUsageNumberFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataExpertiseAreaUsageNumberFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getProfessionUsagesNumber$(id: number): Observable<UsagesDTO> {
		this.adminFacade.dispatch(AdminActions.LoadGeneralDataProfessionUsageNumber({payload: {serviceId: id}}));

		return this.actionsSubject$.pipe(
			ofType(AdminActions.LoadGeneralDataProfessionUsageNumberComplete, AdminActions.LoadGeneralDataProfessionUsageNumberFailed),
			switchMap((action) => {
				if (action.type === AdminActions.LoadGeneralDataProfessionUsageNumberFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public clearProviderListFilter(): void {
		this.adminFacade.dispatch(AdminActions.ClearProviderListFilter);
	}

	public clearUserListFilter(): void {
		this.adminFacade.dispatch(AdminActions.ClearUserListFilter);
	}

	public clearState$(): Observable<boolean> {
		this.adminFacade.dispatch(AdminActions.ClearState());
		return scheduled([true], asyncScheduler);
	}

	public error$(): Observable<any> {
		return this.adminFacade.error$;
	}
}
