import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { PersonDTO } from '@noventi/gp-platform/care-providers';

@Component({
	selector: 'gp-provider-profile-status',
	templateUrl: './provider-profile-status.component.html',
	styles: [`
        gp-provider-profile-status {
            display: flex;
        }

        gp-provider-profile-status .mat-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
	`],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderProfileStatusComponent {

	@Input() public provider: PersonDTO;

	constructor() {
	}
}
