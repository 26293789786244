/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MFA update Request Data Transfer Object
 */
export interface MfaUpdateRequestDTO { 
    /**
     * MFA phone number
     */
    phoneNumber?: string;
    /**
     * MFA activation flag
     */
    mfaActive: boolean;
    /**
     * MFA token
     */
    token?: string;
    /**
     * Unique 6 digit code, only present here when x-sms-simulate header is set to true
     */
    code?: string;
    /**
     * Save as trusted device
     */
    trusted?: boolean;
    /**
     * Current password
     */
    password?: string;
}

