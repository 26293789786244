<svg width="130px" height="30px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 756.85 127.56" style="enable-background:new 0 0 756.85 127.56;" xml:space="preserve">
    <polygon points="1.91,120.12 22.68,120.12 22.68,40.28 70.18,120.12 91.97,120.12 91.97,6.57 71.37,6.57 71.37,86.58 25.06,6.57 1.91,6.57 "/>
    <path class="o" d="M137.14,102.77c11.29,13.78,28.43,22.58,47.64,22.58c19.2,0,36.35-8.8,47.64-22.58H137.14z M244.35,79.09
        c1.26-4.9,2-10.02,2-15.31c0-34-27.56-61.57-61.57-61.57c-34,0-61.57,27.57-61.57,61.57c0,5.3,0.74,10.41,2,15.31h23.88
        c-2.02-4.7-3.15-9.87-3.15-15.31c0-21.45,17.39-38.84,38.84-38.84c21.45,0,38.84,17.39,38.84,38.84c0,5.44-1.13,10.61-3.15,15.31
        H244.35z"/>
    <polygon points="304.33,120.15 327.14,120.15 366.47,6.59 343.48,6.59 316.24,95.29 288.83,6.59 265.51,6.59 "/>
    <path d="M417.03,25.32h49.54V6.59h-71.33v113.55h73.21v-18.56h-51.41V70.61h45.12V52.05h-45.12V25.32z"/>
    <polygon points="501.49,120.12 522.26,120.12 522.26,40.28 569.76,120.12 591.55,120.12 591.55,6.57 570.95,6.57 570.95,86.58 524.64,6.57 501.49,6.57 "/>
    <polygon points="620.66,25.3 650.45,25.3 650.45,120.12 672.42,120.12 672.42,25.3 702.38,25.3 702.38,6.57 620.66,6.57 "/>
    <path d="M753.28,6.57h-21.79v113.55h21.79V6.57z"/>
</svg>
