<ng-container [ngTemplateOutlet]="!disabled ? select : selectDisable"></ng-container>

<ng-template #select>
    <span matPrefix class="color-picker" [matMenuTriggerFor]="colorMenu">
        <ng-container [ngTemplateOutlet]="selectData"></ng-container>
    </span>

    <mat-menu #colorMenu="matMenu" class="color-picker" [overlapTrigger]="false">
        <button mat-icon-button (click)="colorUpdate(paint)" *ngFor="let paint of calendarColors" [ngStyle]="{'background': paint}">
            <mat-icon *ngIf="paint === color" [ngClass]="'selected'">check</mat-icon>
        </button>
    </mat-menu>
</ng-template>

<ng-template #selectDisable>
    <span matPrefix class="color-picker disabled">
        <ng-container [ngTemplateOutlet]="selectData"></ng-container>
    </span>
</ng-template>

<ng-template #selectData>
    {{'App.General.Color' | translate}}
    <mat-icon class="circle  margin-left-S" [ngStyle]="{'background': color}"></mat-icon>
    <mat-icon class="arrow-down">arrow_drop_down</mat-icon>
</ng-template>
