import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApplication from './+state/application.reducer';
import { ApplicationEffects } from './+state/application.effects';
import { ApplicationFacade } from './+state/application.facade';
import { ApplicationService } from './application.service';
import { ApplicationDateAdapterService } from './application-date-adapter.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromApplication.APPLICATION_FEATURE_KEY, fromApplication.reducer),
		EffectsModule.forFeature([ApplicationEffects]),
	],
	providers: [ApplicationFacade],
})
export class ServiceApplicationModule {
	static forRoot(): ModuleWithProviders<ServiceApplicationModule> {
		return {
			ngModule: ServiceApplicationModule,
			providers: [ApplicationService, ApplicationDateAdapterService]
		};
	}
}
