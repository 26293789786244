import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule]
})
//export class ServiceTranslateModule {}
export class ServiceTranslateModule implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    // TODO maybe do more?
    console.warn(`%cMissing translation key: ${params.key}`, 'color: Red; font-weight: bold');
    // return `NO TRANSLATION -> ${params.key}`;
    return '';
  }
}
