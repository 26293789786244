import { FormGroup } from '@angular/forms';
import { isNullOrUndefined } from './helpers';
import { DayOfWeekEnumDTO, OpenHoursItemDTO, OpenHoursTypeEnumDTO } from '@noventi/gp-platform/care-providers';

export class FormUtils {
	public static getValueOrEmpty(value: string | number | boolean): string | number | boolean {
		return value ? value : '';
	}

	public static getDateOrEmpty(value: Date | string): Date {
		return !value ? undefined : typeof value === 'string' ? new Date(value) : value;
	}

	public static getDirtyControlsAsKeyValue(formGroup: FormGroup): any {
		const form = formGroup;
		const result = {};
		Object.keys(form.controls).forEach((item) => {
			if (form.controls[item].dirty === true) {
				result[item] = form.controls[item].value;
			}
		});
		return result;
	}

	//public static setPrestineControlsAsKeyValue(formGroup: FormGroup): FormGroup {
	//	const form = formGroup;
	//	Object.keys(form.controls).forEach((item) => {
	//		if (form.controls[item].dirty === true) {
	//			form.controls[item].markAsPristine();
	//		}
	//	});
	//	form.markAsPristine();
	//	return form;
	//}

	public static getControlsAsPredefinedEmailVariables(formGroup: FormGroup, onlyDirty = false) {
		const form = formGroup;
		const result = [];
		Object.keys(form.controls).forEach((item) => {
			if (!onlyDirty || form.controls[item].dirty === true) {
				const iControl = {};
				iControl['name'] = item;
				iControl['value'] = form.controls[item].value;
				result.push(iControl);
			}
		});
		return result;
	}

	public static setOpenHoursItemDTO(formGroup: FormGroup): Array<OpenHoursItemDTO> {
		const form = formGroup;
		const result: Array<OpenHoursItemDTO> = [];

		const openHours = form.controls['openHours'] as FormGroup;
		Object.keys(openHours.controls).forEach((day) => {

			const dayPeriod = openHours.controls[day] as FormGroup;
			Object.keys(dayPeriod.controls).forEach((period) => {

				const dayPeriodTime = dayPeriod.controls[period] as FormGroup;
				if (period !== 'day' && !isNullOrUndefined(dayPeriodTime.value.from)) {
					result.push({
						day: DayOfWeekEnumDTO[day],
						type: OpenHoursTypeEnumDTO[period],
						from:dayPeriodTime.controls.from.value,
						to: dayPeriodTime.controls.to.value
					});
				}
			});
		});

		return result;
	}
}
