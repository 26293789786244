import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AbstractComponent } from '@gp-angular/shared/abstract';
import { FormValidators, pattern } from '@gp-angular/shared/utils';
//import { MessageService } from '@gp-angular/service/message';
import { DialogDeleteDataViewEnum } from '../dialog.enum';
import { DialogDeleteDataModel } from '../dialog.model';

@Component({
	selector: 'gp-angular-delete-data',
	templateUrl: './delete-data.component.html',
	styleUrls: ['./delete-data.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDeleteDataComponent extends AbstractComponent implements OnInit {

	public readonly DialogDeleteDataViewEnum = DialogDeleteDataViewEnum;

	public currentView = this.DialogDeleteDataViewEnum.FORM;

	public form: FormGroup;

	public isProduction: boolean = this._environment.production;

	public xSmsSimulate: boolean;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		@Inject(MAT_DIALOG_DATA) public data: DialogDeleteDataModel,
		public dialogRef: MatDialogRef<DialogDeleteDataComponent>,
		private fb: FormBuilder,
		//public messageService: MessageService,
		public translateService: TranslateService
	) {
		super();

		const VALIDATORS = [Validators.required, FormValidators.noWhitespace];

		this.form = this.fb.group({
			emailValidation: false,
			downloadPdf: false,
			email: [
				{value: undefined, disabled: true},
				[...VALIDATORS, Validators.maxLength(200), Validators.pattern(pattern('email'))]
			],
			confirmation: [
				{value: undefined, disabled: !this.data.confirmation},
				[...VALIDATORS, FormValidators.matchString(data.pattern)]
			]
		});
	}

	public ngOnInit(): void {
		super.addSubscription(this.form.get('emailValidation').valueChanges
			.pipe(
				distinctUntilChanged()
			)
			.subscribe((next) => {
				const email = this.form.get('email');
				if (next) {
					email.enable();
				} else {
					email.disable();
				}
			}));

		if (this.data.hide) {
			this.form.get('emailValidation').disable();
			this.form.get('downloadPdf').disable();
		}
	}

	public back(): void {
		this.currentView = DialogDeleteDataViewEnum.FORM;
		this.form.reset();
	}

	public delete(): void {
		const result: any = { download: this.form.get('downloadPdf').value };

		if (this.form.get('emailValidation').value) {
			result.email = this.form.get('email').value;
		}

		if(!!this.xSmsSimulate) {
			result.xSmsSimulate = this.xSmsSimulate;
		}

		this.dialogRef.close(result);
	}
}
