import { CareProviderFilterEnumDTO } from '@noventi/gp-platform/care-providers';


export type UserStatusEnum = null | true | false;

export const UserStatusEnum = {
	ACTIVE: true as UserStatusEnum,
	INACTIVE: false as UserStatusEnum
};

export class DefaultSearchPackage {
	constructor(
		public filter: Array<string> = [],
		public search: string = ''
	) {
	}
}

export class ProviderSearchPackage {
	constructor(
		public filter: Array<CareProviderFilterEnumDTO> = [],
		public search: string = ''
	) {
	}
}
