import { Injectable } from '@angular/core';
//import { createEffect, Actions, ofType } from '@ngrx/effects';

//import * as AccountFeature from './account.reducer';
//import * as AccountActions from './account.actions';

@Injectable()
export class AccountEffects {
	constructor() {
	}
}
