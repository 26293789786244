import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocorrectTimeDirective } from './autocorrect-time.directive';
import { FormMandatoryStarDirective } from './form-mandatory-star.directive';
import { PhoneFormatterDirective } from './phone-formatter.directive';

@NgModule({
	declarations: [
		AutocorrectTimeDirective,
		FormMandatoryStarDirective,
		PhoneFormatterDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		AutocorrectTimeDirective,
		FormMandatoryStarDirective,
		PhoneFormatterDirective
	],
})
export class SharedDirectiveModule {
}
