import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { AnalyticsConstants, AnalyticsService } from '@gp-angular/service/analytics';
import { AppointmentStatusEnumDTO } from '@noventi/gp-platform/online-appointments';

@Component({
	selector: 'gp-angular-contact-short',
	templateUrl: './contact-short.component.html',
	styleUrls: ['./contact-short.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactShortComponent {

	@Input() telephone;
	@Input() email;
	@Input() appointmentStatus;

	constructor(
		private analyticsService: AnalyticsService) {
	}

	public showTracker(input: string) {
		switch (input) {
			case 'telephone':
				if (this.appointmentStatus === AppointmentStatusEnumDTO.CONFIRMED) {
					this.analyticsService.emitEvent(AnalyticsConstants.APPOINTMENT_BOOKING,
						AnalyticsConstants.ACTION_CLICK_DIAL_PHONE,
						AnalyticsConstants.LABEL_APPOINTMENT_CONFIRMATION);
				} else {
					this.analyticsService.emitEvent(AnalyticsConstants.APPOINTMENT_BOOKING,
						AnalyticsConstants.ACTION_CLICK_DIAL_PHONE,
						AnalyticsConstants.LABEL_APPOINTMENT_CANCELATION_CONFIRMATION);
				}
				break;
			case 'email':
				if (this.appointmentStatus === AppointmentStatusEnumDTO.CONFIRMED) {
					this.analyticsService.emitEvent(AnalyticsConstants.APPOINTMENT_BOOKING,
						AnalyticsConstants.ACTION_CLICK_SEND_EMAIL,
						AnalyticsConstants.LABEL_APPOINTMENT_CONFIRMATION);
				} else {
					this.analyticsService.emitEvent(AnalyticsConstants.APPOINTMENT_BOOKING,
						AnalyticsConstants.ACTION_CLICK_SEND_EMAIL,
						AnalyticsConstants.LABEL_APPOINTMENT_CANCELATION_CONFIRMATION);
				}
				break;
			default:
				break;
		}
	}

}
