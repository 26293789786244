/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LanguageDTOAllOf { 
    /**
     * Language code
     */
    code?: string;
    /**
     * Language name
     */
    name?: string;
    /**
     * Language name for portal
     */
    nativeName?: string;
    /**
     * Prefered order ID
     */
    index?: number;
}

