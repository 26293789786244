import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	APPOINTMENT_FEATURE_KEY,
	State,
	AppointmentPartialState,
} from './appointment.reducer';

// Lookup the 'Appointment' feature state managed by NgRx
export const getAppointmentState = createFeatureSelector<AppointmentPartialState,
	State>(APPOINTMENT_FEATURE_KEY);

export const getDetail = createSelector(
	getAppointmentState,
	(state: State) => state.detail
);

export const getProvider = createSelector(
	getAppointmentState,
	(state: State) => state.provider
);

export const getPatient = createSelector(
	getAppointmentState,
	(state: State) => state.patient
);

export const getLoading = createSelector(
	getAppointmentState,
	(state: State) => state.loading
);

export const getError = createSelector(
	getAppointmentState,
	(state: State) => state.error
);
