/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Allowed buffer times Enum Data Transfer Object
 */
export type AllowedBufferTimesEnumDTO = 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60;

export const AllowedBufferTimesEnumDTO = {
    NUMBER_5: 5 as AllowedBufferTimesEnumDTO,
    NUMBER_10: 10 as AllowedBufferTimesEnumDTO,
    NUMBER_15: 15 as AllowedBufferTimesEnumDTO,
    NUMBER_20: 20 as AllowedBufferTimesEnumDTO,
    NUMBER_25: 25 as AllowedBufferTimesEnumDTO,
    NUMBER_30: 30 as AllowedBufferTimesEnumDTO,
    NUMBER_35: 35 as AllowedBufferTimesEnumDTO,
    NUMBER_40: 40 as AllowedBufferTimesEnumDTO,
    NUMBER_45: 45 as AllowedBufferTimesEnumDTO,
    NUMBER_50: 50 as AllowedBufferTimesEnumDTO,
    NUMBER_55: 55 as AllowedBufferTimesEnumDTO,
    NUMBER_60: 60 as AllowedBufferTimesEnumDTO
};

