import { AfterViewChecked, Directive, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, FormControl, NgControl } from '@angular/forms';

@Directive({
	//eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[appMandatoryStar], [formControl], [formControlName]',
})
export class FormMandatoryStarDirective implements OnInit, AfterViewChecked {
	private static emptyControl = new FormControl();

	@Input() formControl;

	@Input() formControlName;

	@Input() ngModel;

	@Input() noMandatoryStar = false;

	@Input() autocomplete = 'off';

	constructor(
		private el: ElementRef,
		@Self() @Optional() private currentControl: NgControl
	) {
	}

	private _start;

	public ngOnInit(): void {
		this._init();
	}

	ngAfterViewChecked(): void {
		if (!this._start) {
			this._start = new Date().getTime() + 500;
		} else if (this._start - new Date().getTime() < 0) {
			return;
		}
		this._init();
	}

	private _init() {
		if (
			!this.noMandatoryStar &&
			this.currentControl &&
			this.currentControl.control
		) {
			const control: AbstractControl = this.currentControl.control;

			control.setAsyncValidators = (...args) => {
				const result = control.setValidators(...args);
				this.addStarsToMandatoryInputs(control);
				return result;
			};

			this.addStarsToMandatoryInputs(control);
		}
	}

	private addStarsToMandatoryInputs(control) {
		const placeholderList = [];
		const validator = control.validator;
		const isRequired =
			validator && validator(FormMandatoryStarDirective.emptyControl);
		if (isRequired) {
			if (control instanceof FormControl) {
				this.currentControl.control['placeholderList'] = placeholderList;
				const nativeElement: HTMLElement = this.el.nativeElement;
				if (nativeElement) {
					// Add autocomplete="off" to each input
					nativeElement.setAttribute('autocomplete', this.autocomplete);

					placeholderList.push(
						new PlaceholderManipulator(
							nativeElement,
							(element, placeholder) => {
								element['placeholder'] = placeholder;
							},
							(element) => {
								return element['placeholder'];
							}
						)
					);

					const parentElement = nativeElement.parentElement;
					if (parentElement) {
						const labelList = parentElement.getElementsByClassName(
							'mat-form-field-label'
						);
						const length = labelList.length;
						for (let i = 0; i < length; i++) {
							const label = labelList.item(i);
							placeholderList.push(
								new PlaceholderManipulator(
									label,
									(element, placeholder) => {
										element[
											'innerHTML'
											] = `<mat-label>${placeholder}</mat-label>`;
									},
									(element) => {
										return element['innerText'];
									}
								)
							);
						}
					}
				}

				for (const element of placeholderList) {
					const placeholder: string = element['placeholder'];

					if (!!placeholder && !placeholder.endsWith('*')) {
						element['placeholder'] = placeholder + '\u00A0*';
					}
				}
			}
		}
	}
}

class PlaceholderManipulator {
	constructor(
		private element,
		private howToSet: Function,
		private howToGet: Function
	) {
	}

	public get placeholder() {
		return this.howToGet(this.element);
	}

	public set placeholder(placeholder: string) {
		this.howToSet(this.element, placeholder);
	}
}
