import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AdminPageTypeArray, BookingPageTypeArray, CmsPageTypeUrl, PAGE } from '@gp-angular/shared/schema';
import { ContentManagementService } from '@gp-angular/service/content-management';
import { CmsPageTypeEnumDTO } from '@noventi/gp-platform/users';

@Component({
	selector: 'gp-angular-content-display',
	templateUrl: './content-display.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	//providers: [ContentManagementService]
})
export class ContentDisplayComponent extends AbstractComponent implements OnInit {

	public title: string;

	public loading: boolean;

	public content: SafeHtml;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private contentManagementService: ContentManagementService,
		private route: ActivatedRoute,
		private translateService: TranslateService,
		private titleService: Title,
		private sanitizer: DomSanitizer
	) {
		super();
	}

	private static _fixTableScroll(value: string) {
		if (!!value) {
			return value
				.replace(/<table/g, '<div style="overflow:auto"><table')
				.replace(/<\/table>/g, '</table></div>');
		}
		return '';
	}

	public ngOnInit(): void {
		this._scroll();
		super.addSubscription(this.route.fragment
			.subscribe((fragment) => this._scroll(fragment))
		);

		super.addSubscription(this.route.params
			.pipe(
				filter(params => !!params && params.content),
				distinctUntilChanged(),
				tap(() => {
					this.title = undefined;
					this.content = undefined;
					this._loading(true);
				})
			).subscribe(
				(params) => {
					let content;
					const INDEX = (Object.values(CmsPageTypeUrl) as Array<string>).indexOf(params.content);
					if (INDEX > -1) {
						const PAGE_TYPE = (this.route.snapshot.data.type === 'ADMIN') ? AdminPageTypeArray : BookingPageTypeArray;
						const PAGE_TYPE_INDEX = CmsPageTypeEnumDTO[Object.keys(CmsPageTypeEnumDTO)[INDEX]];
						content = Object.values(PAGE_TYPE).indexOf(PAGE_TYPE_INDEX) > -1 ? PAGE_TYPE_INDEX : undefined
					}
					const TYPE = !!this.route.snapshot && !!this.route.snapshot.data && !!this.route.snapshot.data.type ?
						this.route.snapshot.data.type : undefined;
					if (!!content && !!TYPE) {
						this.contentManagementService.loadContent(TYPE, content);
					}
				})
		);

		super.addSubscription(this.contentManagementService.getContent$()
			.pipe(
				filter(next => !!next)
			)
			.subscribe(
				(next) => {
					this.title = next.title;
					this.content = this.sanitizer.bypassSecurityTrustHtml(ContentDisplayComponent._fixTableScroll(next.content));

					const TITLE = this.translateService.instant(PAGE.PROVIDER.title);
					this.titleService.setTitle(
						this.titleService.getTitle().replace(TITLE, `${TITLE} - ${this.title}`)
					);
					this._loading();
				},

				() => this._loading()
			)
		);
	}

	private _scroll(fragment?: string) {
		if (!fragment && this.route.snapshot && this.route.snapshot.fragment) {
			setTimeout(() => this._scroll(this.route.snapshot.fragment), 500);
			return;
		}
		try {
			document.querySelector('a[class="' + fragment + '"]').scrollIntoView({behavior: 'smooth', block: 'center'});
		} catch (e) {
		}
	}

	private _loading(value: boolean = false) {
		this.loading = value;
		this.changeDetectorRef.markForCheck();
	}
}
