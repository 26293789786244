import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestOptions } from './request-options';


@Injectable()
export class HttpInterceptor {

	constructor(
	) {
	}

	public interceptRequestOptions(options?): RequestOptions {
		if (options === null || options === undefined) {
			options = new RequestOptions();
		}
		if (options.headers === null || options.headers === undefined) {
			options.headers = new HttpHeaders();
		}

		options.withCredentials = true;

		return options;
	}

	public interceptRequestUrl(url: string | HttpRequest<any>): any {
		return url;
	}

	public interceptResponse($observable: Observable<any>) {
		return $observable;
	}

	public interceptRequestBody(body: any) {
		return body;
	}

	public refreshTokenIfNeeded$() {
		return of(false);
	}
}
