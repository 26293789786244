import { Inject, Injectable } from '@angular/core';
import { MatomoAnalyticsService } from './matomo/matomo-analytics.service';
import { GoogleAnalyticsService } from './google/google-analytics.service';
import { Analytics } from './analytics.schema';

@Injectable()
export class AnalyticsService {

	private _isInitialized = false;

	private _analytics: Analytics;

	private _analyticsServiceImpl;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any
	) {
	}

	public isReady(): boolean {
		return this._analyticsServiceImpl && this._analyticsServiceImpl.isReady;
	}

	public init(): void {
		if (!this._isInitialized) {
			this._isInitialized = true;

			this._analytics = {...this._environment.analytics, ...{ appName: this._environment.name, appVersion: this._environment.version }} as Analytics;

			if (this._analytics.provider === 'google') {
				this._analyticsServiceImpl = new GoogleAnalyticsService();
			} else if (this._analytics.provider === 'matomo') {
				this._analyticsServiceImpl = new MatomoAnalyticsService();
			} else {
				return;
			}
			this._analyticsServiceImpl.init(this._analytics);
		}
	}

	public config(title?: string, path?: string): void {
		if (this.isReady()) {
			this._analyticsServiceImpl.config(this._analytics, title, path);
		}
	}

	public emitScreen(screen: string): void {
		if (this.isReady()) {
			this._analyticsServiceImpl.emitScreen(this._analytics, screen);
			this._consoleScreen(screen);
		}
	}

	public emitEvent(eventCategory: string, eventAction?: string, eventLabel?: string, eventValue?: number): void {
		if (this.isReady()) {
			this._analyticsServiceImpl.emitEvent(this._analytics, eventCategory, eventAction, eventLabel, eventValue);
			this._consoleEvent(eventCategory, eventAction, eventLabel, eventValue);
		}
	}

	private _consoleScreen(screen: string) {
		if (!!this._environment && !this._environment.production) {
			console.warn('*** Tracked analytics screen ***\n' +
								'* SCREEN: ', screen, '\n' +
							   '********************************');
		}
	}

	private _consoleEvent(eventCategory: string, eventAction: string, eventLabel: string, eventValue: number) {
		if (!!this._environment && !this._environment.production) {
			console.warn('*** Tracked analytics event ***\n' +
						 		'* Category:   ', eventCategory, '\n' +
								'* Action:     ', eventAction, '\n' +
								'* Label/Name: ', eventLabel, '\n' +
								'* Value:      ', eventValue, '\n' +
								'*******************************');
		}
	}
}





