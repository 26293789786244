/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PLZ-ORT Mapping Data Transfer Object
 */
export interface PostalCodeCityDTO { 
    /**
     * Postal Code
     */
    postalCode?: string;
    /**
     * City
     */
    city?: string;
}

