import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateGuard } from '@gp-angular/shared/guard';
import { BrowserModule } from '@angular/platform-browser';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('@gp-angular/api/booking').then(m => m.ApiBookingModule)
	}
];

@NgModule({
	imports: [
		BrowserModule,
		RouterModule.forRoot(routes, {initialNavigation: 'enabled', useHash: false, relativeLinkResolution: 'legacy'})
	],
	providers: [CanDeactivateGuard],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
