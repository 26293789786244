import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from '@gp-angular/service/environment';
import { ServiceAuthModule as AuthApiModule, BASE_PATH as AuthBasePath } from '@gp-angular/service/auth';
import { ServiceHttpModule } from './service-http.module';

import { ApiModule as AppointmentApiModule, BASE_PATH as AppointmentBasePath } from '@noventi/gp-platform/online-appointments';
import { ApiModule as CMSApiModule, BASE_PATH as CmsBasePath } from '@noventi/gp-platform/users';
import { ApiModule as EmailingApiModule, BASE_PATH as EmailingBasePath } from '@noventi/gp-platform/emailing';
import { ApiModule as CareProviderApiModule, BASE_PATH as PersonBasePath } from '@noventi/gp-platform/care-providers';
import { ApiModule as UsersApiModule, BASE_PATH as UserBasePath } from '@noventi/gp-platform/users';
import { ApiModule as PatientApiModule, BASE_PATH as PatientBasePath } from '@noventi/gp-platform/patients';


@NgModule({
	imports: [
		CommonModule,
		ServiceHttpModule
	],
	providers: [
		{
			provide: AuthBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/auth'
		},
		AuthApiModule,

		{
			provide: AppointmentBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/online-appointments'
		},
		AppointmentApiModule,

		{
			provide: CmsBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api']
		},
		CMSApiModule,

		{
			provide: EmailingBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/emailing'
		},
		EmailingApiModule,

		{
			provide: PersonBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/care-providers'
		},
		CareProviderApiModule,

		{
			provide: UserBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/users'
		},
		UsersApiModule,

		{
			provide: PatientBasePath,
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().endpoints['api'] + '/patients'
		},
		PatientApiModule,

		{
			provide: 'stompConfig',
			deps: [EnvironmentService],
			useFactory: (e: EnvironmentService) => e.getEnvironment().websocket
		}
	]
})
export class HttpEndpointsModule {
}
