import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPLICATION_FEATURE_KEY, State, ApplicationPartialState } from './application.reducer';

// Lookup the 'Application' feature state managed by NgRx
export const getApplicationState = createFeatureSelector<ApplicationPartialState, State>(APPLICATION_FEATURE_KEY);

export const getVersionUpdate = createSelector(
	getApplicationState,
	(state: State) => state.versionUpdate
);

export const getVersion = createSelector(
	getApplicationState,
	(state: State) => state.version
);

export const getLanguage = createSelector(
	getApplicationState,
	(state: State) => state.appLanguage
);

export const getMediaQuerySize = createSelector(
	getApplicationState,
	(state: State) => state.mediaQuerySize
);

export const getSidenav = createSelector(
	getApplicationState,
	(state: State) => state.sidenav
);
