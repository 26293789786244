import { ModuleWithProviders, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';
//import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { HttpEndpointsModule, ServiceHttpModule } from '@gp-angular/service/http';
import { SharedDirectiveModule } from '@gp-angular/shared/directive';
import { PermissionGuard } from '@gp-angular/shared/guard';

//const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//	suppressScrollX: true
//};

@NgModule({
	imports: [
		CommonModule,
		//PerfectScrollbarModule,

		SharedDirectiveModule,

		MatAutocompleteModule
	],
	exports: [
		ServiceHttpModule
	],
	providers: [
		{provide: APP_BASE_HREF, useValue: '/'},
		{provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
		//{provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},

		ServiceHttpModule,

		DatePipe,
		PermissionGuard,

		{
			provide: 'externalSafeUrl',
			useValue: (route: ActivatedRouteSnapshot) => {
				window.location.href = route.data.externalUrl;
			}
		}
	]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [ServiceHttpModule, HttpEndpointsModule]
		};
	}
}
