import { createAction, props } from '@ngrx/store';
import { EnvironmentUpdateEnum } from '@gp-angular/shared/environment';
import { MediaQuerySizeType } from '@gp-angular/service/application';

export const VersionAction = createAction(
	'[Application] Version',
	props<{ payload: { version: string, versionUpdate: EnvironmentUpdateEnum } }>()
);

export const MediaQuerySizeChanged = createAction(
	'[Application] Media Query Size Changed',
	props<{ mediaQuerySize: MediaQuerySizeType }>()
);

export const AppLanguage = createAction(
	'[Application] App Language',
	props<{ language: string }>()
);

export const AppSidenav = createAction(
	'[Application] App Sidenav',
	props<{ sidenav: string }>()
);

export const ClearState = createAction(
	'[Application] Clear State'
);
