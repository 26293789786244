/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * KPI type Enum Data Transfer Object
 */
export type KpiTypeEnumDTO = 'ONLINE_APPOINTMENT_CREATED' | 'OFFLINE_APPOINTMENT_CREATED' | 'ONLINE_APPOINTMENT_CANCELED_BY_PROVIDER' | 'ONLINE_APPOINTMENT_CANCELED_BY_PATIENT' | 'OFFLINE_APPOINTMENT_CANCELED' | 'APPOINTMENT_COMPLETED';

export const KpiTypeEnumDTO = {
    ONLINEAPPOINTMENTCREATED: 'ONLINE_APPOINTMENT_CREATED' as KpiTypeEnumDTO,
    OFFLINEAPPOINTMENTCREATED: 'OFFLINE_APPOINTMENT_CREATED' as KpiTypeEnumDTO,
    ONLINEAPPOINTMENTCANCELEDBYPROVIDER: 'ONLINE_APPOINTMENT_CANCELED_BY_PROVIDER' as KpiTypeEnumDTO,
    ONLINEAPPOINTMENTCANCELEDBYPATIENT: 'ONLINE_APPOINTMENT_CANCELED_BY_PATIENT' as KpiTypeEnumDTO,
    OFFLINEAPPOINTMENTCANCELED: 'OFFLINE_APPOINTMENT_CANCELED' as KpiTypeEnumDTO,
    APPOINTMENTCOMPLETED: 'APPOINTMENT_COMPLETED' as KpiTypeEnumDTO
};

