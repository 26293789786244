/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Gender
 */
export type GenderEnumDTO = 'MALE' | 'FEMALE' | 'NEUTRAL';

export const GenderEnumDTO = {
    MALE: 'MALE' as GenderEnumDTO,
    FEMALE: 'FEMALE' as GenderEnumDTO,
    NEUTRAL: 'NEUTRAL' as GenderEnumDTO
};

