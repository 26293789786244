import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PAGE } from '@gp-angular/shared/schema';
import { UserService } from '@gp-angular/service/user';

@Injectable()
export class PermissionGuard implements CanActivate {

	constructor(
		private router: Router,
		private userService: UserService
	) {
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const IS_LOGGED_IN: boolean = this.userService.isLoggedIn();
		const ALLOW: boolean = this.userService.isRoleAllowedOn(route.data.allowedRoles);

		if (!IS_LOGGED_IN || !ALLOW) {
			this.router.navigate([IS_LOGGED_IN ? PAGE.ERROR.path : PAGE.LOGIN.path]).then();
		}

		return ALLOW;
	}
}
