/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Key Value Type Enum Data Transfer Object
 */
export interface KeyValueDTO { 
    /**
     * Key
     */
    key?: string;
    /**
     * Text
     */
    text?: string;
    /**
     * Text vairant in case of woman (optional)
     */
    text_w?: string;
}

