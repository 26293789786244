import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, distinctUntilChanged, exhaustMap, filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { from, of } from 'rxjs';


import * as AccountAction from './account.actions';
import { AccountFacade } from './account.facade';
import * as UserAction from './user.actions';
import { UserFacade } from './user.facade';
import { InternalUtils } from '../internal.utils';
import { UserUtils } from '@gp-angular/shared/utils';
import { AuthService } from '@gp-angular/service/auth';
import {
	MfaDTO, MfaValidationCodeResponseDTO, NotificationDTO, NotificationService, PasswordResetResponseDTO, StateSettingsService, UserDTO,
	UserOnboardingDTO,
	UserOnboardingService,
	UserService
} from '@noventi/gp-platform/users';
import { EmailingService, SendEmailResponseDTO } from '@noventi/gp-platform/emailing';

@Injectable()
export class UserEffects {

	login$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.Login),
		exhaustMap(({payload: {username, password}}) => {
			return this.authServiceApi.login({username, password}).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: any) =>
					of(UserAction.LoginComplete({payload: InternalUtils.tokenExtract(result)}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.LoginFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	loginMfa$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.LoginMFA),
		exhaustMap(({payload}) => {
			return this.authServiceApi.loginMfa(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result) =>
					of(UserAction.LoginMFAComplete({payload: InternalUtils.tokenExtract(result)}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.LoginMFAFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	mfaGet$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.GetMfaData),
		withLatestFrom(this.userFacade.username$),
		exhaustMap(([action, username]) => {
			return this.userServiceApi.getMfa(username).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: MfaDTO) =>
					from([
						UserAction.GetMfaDataComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.GetMfaDataFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	mfaPasswordVerification$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.ValidatePaswordMfa),
		switchMap(({payload}) => {
			return this.userServiceApi.mfaPasswordVerification(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: any) =>
					from([
						UserAction.ValidatePaswordMfaComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.ValidatePaswordMfaFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	removeSavedDevices$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.RemoveSavedDevices),
		withLatestFrom(this.userFacade.username$),
		exhaustMap(([action, username]) => {
			return this.userServiceApi.untrustUserDevices(username).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: any) =>
					from([
						UserAction.RemoveSavedDevicesComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.RemoveSavedDevicesFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	refreshToken$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.RefreshToken),
		exhaustMap(() => {
			return this.authServiceApi.refreshAuthToken().pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: any) =>
					from([
						UserAction.RefreshTokenComplete({payload: InternalUtils.tokenExtract(result)})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.RefreshTokenFailed({error})
					])
				)
			);
		})
		)
	);

	getOnboarding$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.GetOnboarding),
		withLatestFrom(this.userFacade.username$),
		filter(([action, username]) => !!username),
		exhaustMap(([action, username]) => {
			return this.userOnboardingApi.getUserOnboardingStep(username).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: UserOnboardingDTO) =>
					of(UserAction.GetOnboardingComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.GetOnboardingFailed({error})
					])
				)
			);
		})
		)
	);

	setOnboarding$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.SetOnboarding),
		withLatestFrom(this.userFacade.username$),
		filter(([action, username]) => !!username),
		exhaustMap(([action, username]) => {
			return this.userOnboardingApi.updateUserOnboardingStep(username, action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: UserOnboardingDTO) =>
					of(UserAction.GetOnboardingComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.GetOnboardingFailed({error})
					])
				)
			);
		})
		)
	);


	emailSending$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.ResetEmailPassword),
		exhaustMap(({payload: {email}}) => {
			return this.userServiceApi.requestPasswordReset({email: email}).pipe(
				switchMap((result: PasswordResetResponseDTO) =>
					of(UserAction.ResetEmailPasswordComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.ResetEmailPasswordFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	resetPasswordValidationToken$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.ResetPasswordValidationToken),
		exhaustMap(({payload: {token}}) => {
			return this.userServiceApi.verifyPasswordResetToken({token: token}).pipe(
				switchMap((result: PasswordResetResponseDTO) =>
					of(UserAction.ResetPasswordValidationTokenComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.ResetPasswordValidationTokenFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	passwordReset$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.ResetPassword),
		switchMap(({payload}) => {
			return this.userServiceApi.passwordReset(payload).pipe(
				switchMap((result: PasswordResetResponseDTO) =>
					of(UserAction.ResetPasswordComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.ResetPasswordFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	loadUser$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.LoadUser),
		withLatestFrom(this.userFacade.username$, this.accountFacade.currentPid$),
		exhaustMap(([action, username, currentPid]) => {
			return this.userServiceApi.readUser(username).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: UserDTO) => {
					const ROLE = result.personRoles.find(item => item.personId === (!currentPid ? result.defaultPid : currentPid)).role;

					if(!currentPid) {
						return from([
							UserAction.LoadUserComplete({payload: result}),
							// TODO: move it from here
							AccountAction.SetData({data: {name: UserUtils.getName(result), email: result.email}}),
							AccountAction.SetManageIds({manage: {employee: result.resourceIds ? result.resourceIds.map(String) : undefined}}),
							AccountAction.SetPermission({permission: InternalUtils.permissionByRole(ROLE)}),
							AccountAction.SetCurrentPid({currentPid: result.defaultPid})
						])
					} else {

						return from([
							UserAction.LoadUserComplete({payload: result}),
							// TODO: move it from here
							AccountAction.SetData({data: {name: UserUtils.getName(result), email: result.email}}),
							AccountAction.SetManageIds({manage: {employee: result.resourceIds ? result.resourceIds.map(String) : undefined}}),
							AccountAction.SetPermission({permission: InternalUtils.permissionByRole(ROLE)})
						])
					}
				}),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.LoadUserFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	loadUserComplete$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.LoadUserComplete),
		withLatestFrom(this.userFacade.defaultPid$),
		filter(([action, defaultPid]) => !defaultPid),
		exhaustMap(([action, defaultPid]) => {
			return of(UserAction.SetDefaultPid({defaultPid: action.payload.defaultPid}))
		})),
		{useEffectsErrorHandler: false}
	);

	contact$ = createEffect(() =>
			this.actions$.pipe(
				ofType(UserAction.Contact),
				exhaustMap(({payload}) => {
					return this.emailingServiceApi.sendPredefinedEmail(payload).pipe(
						switchMap((response: SendEmailResponseDTO) =>
							from([
								UserAction.ContactComplete({payload: response})
							])
						),
						catchError((error) =>
							from([
								UserAction.Error({error}),
								UserAction.ContactFailed({error})
							])
						)
					);
				})
			),
		{useEffectsErrorHandler: false}
	);

	mfaSendActivationCode$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.MultiFactorAuthSendActivationCode),
		exhaustMap(({payload: {mfaValidationCodeRequest, xSmsSimulate}}) => {
			return this.userServiceApi.sendValidationCode(mfaValidationCodeRequest, xSmsSimulate).pipe(
				switchMap((result: MfaValidationCodeResponseDTO) =>
					from([
						UserAction.MultiFactorAuthSendActivationCodeComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.MultiFactorAuthSendActivationCodeFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	mfaUpdate$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.MultiFactorAuthUpdate),
		withLatestFrom(this.userFacade.username$),
		exhaustMap(([action, username]) => {
			return this.userServiceApi.updateMfa(username, action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: UserDTO) =>
					from([
						UserAction.MultiFactorAuthUpdateComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.MultiFactorAuthUpdateFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	LogoutAllDevices$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.LogoutAllDevices),
		withLatestFrom(this.userFacade.username$),
		exhaustMap(([action, user]) => {
			return this.userServiceApi.logOutUserDevices(user).pipe(
				switchMap((result: any) =>
					of(UserAction.LogoutAllDevicesComplete())
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.LogoutAllDevicesFailed({error})
					])
				)
			);
		})
		)
	);

	getUserAppSettings$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.GetUserAppSettings),
		withLatestFrom(this.userFacade.username$, this.accountFacade.currentPid$),
		exhaustMap(([,user, pid]) => {
			return this.stateSettingsService.readStateSettings(user, pid).pipe(
				switchMap((result: any) =>
					of(UserAction.GetUserAppSettingsComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.GetUserAppSettingsFailed({error})
					])
				)
			);
		})
		)
	);

	updateUserAppSettings$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.UpdateUserAppSettings),
		distinctUntilChanged(),
		withLatestFrom(this.userFacade.username$, this.accountFacade.currentPid$),
		switchMap(([action, user, pid]) => {
			return this.stateSettingsService.patchStateSettings(user, pid, action.payload).pipe(
				switchMap((result: any) =>
					of(UserAction.UpdateUserAppSettingsComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.UpdateUserAppSettingsFailed({error})
					])
				)
			);
		})
		)
	);

	searchUserFromConnectedInstitutions$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.SearchUserFromConnectedInstitutions),
		exhaustMap(({payload: {username, personId, q}}) => {
			return this.userServiceApi.searchFromConnectedPersons(username, personId, q).pipe(
				switchMap((result: Array<UserDTO>) =>
					of(UserAction.SearchUserFromConnectedInstitutionsComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.SearchUserFromConnectedInstitutionsFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	addUserToInstitution$ = createEffect(() => this.actions$.pipe(
			ofType(UserAction.AddUserToInstitution),
			exhaustMap(({payload: {username, personRoleResourcesDTO}}) => {
				return this.userServiceApi.addPersonRole(username, personRoleResourcesDTO).pipe(
					switchMap((result: any) =>
						of(UserAction.AddUserToInstitutionComplete({payload: result}))
					),
					catchError((error) =>
						from([
							UserAction.Error({error}),
							UserAction.AddUserToInstitutionFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	getNotificationList$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.LoadNotificationList),
		withLatestFrom(this.userFacade.username$),
		filter(([action, user]) => !!user),
		exhaustMap(([action, user]) => {
			return this.notificationService.getUnreadNotifications(user).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: Array<NotificationDTO>) =>
					of(UserAction.LoadNotificationListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.LoadNotificationListFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	markNotificationAsRead$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.MarkNotificationAsRead),
		exhaustMap((action) => {
			return this.notificationService.readNotification(action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(UserAction.Logout) || ofType(UserAction.ClearState))),
				switchMap((result: NotificationDTO) =>
					from([
						UserAction.MarkNotificationAsReadComplete({payload: result}),
						UserAction.LoadNotificationList()
					])
				),
				catchError((error) =>
					from([
						UserAction.Error({error}),
						UserAction.MarkNotificationAsReadFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	logout$ = createEffect(() => this.actions$.pipe(
		ofType(UserAction.Logout),
		exhaustMap(({payload}) => {
			if (payload) {
				return this.authServiceApi.logout().pipe(
					switchMap(() => from([UserAction.ClearState(), AccountAction.ClearState()])),
					catchError((error) => of(UserAction.Error({error})))
				);
			} else {
				return from([UserAction.ClearState(), AccountAction.ClearState()])
			}
		})
		)
	);

	constructor(
		private actions$: Actions,
		private userFacade: UserFacade,
		private accountFacade: AccountFacade,
		private authServiceApi: AuthService,
		private userServiceApi: UserService,
		private userOnboardingApi: UserOnboardingService,
		private emailingServiceApi: EmailingService,
		private stateSettingsService: StateSettingsService,
		private notificationService: NotificationService
	) {
	}
}
