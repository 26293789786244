import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromApplication from './application.reducer';
import * as ApplicationSelectors from './application.selectors';

@Injectable()
export class ApplicationFacade {
	version$ = this.store.pipe(select(ApplicationSelectors.getVersion));
	update$ = this.store.pipe(select(ApplicationSelectors.getVersionUpdate));
	language$ = this.store.pipe(select(ApplicationSelectors.getLanguage));
	screen$ = this.store.pipe(select(ApplicationSelectors.getMediaQuerySize));
	sideNav$ = this.store.pipe(select(ApplicationSelectors.getSidenav));

	constructor(private store: Store<fromApplication.ApplicationPartialState>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
