/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExternalResourceDTOAllOf { 
    /**
     * Name of the resource
     */
    name?: string;
    /**
     * Type of the resource
     */
    type?: string;
    /**
     * First name of resource (only if employee)
     */
    firstName?: string;
    /**
     * Last name of resource (only if employee)
     */
    lastName?: string;
    /**
     * Description of the resouce
     */
    description?: string;
    /**
     * Id of the care provider which is managing this resource
     */
    personId?: number;
}

