/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Allowed appointment time limits Enum Data Transfer Object
 */
export type AllowedAppointmentTimeLimitsEnumDTO = 12 | 24 | 36 | 48 | 60 | 72;

export const AllowedAppointmentTimeLimitsEnumDTO = {
    NUMBER_12: 12 as AllowedAppointmentTimeLimitsEnumDTO,
    NUMBER_24: 24 as AllowedAppointmentTimeLimitsEnumDTO,
    NUMBER_36: 36 as AllowedAppointmentTimeLimitsEnumDTO,
    NUMBER_48: 48 as AllowedAppointmentTimeLimitsEnumDTO,
    NUMBER_60: 60 as AllowedAppointmentTimeLimitsEnumDTO,
    NUMBER_72: 72 as AllowedAppointmentTimeLimitsEnumDTO
};

