import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
	CreateRegistrationRequestBodyDTO, ExpertiseAreaDTO, HistoryTypeEnumDTO, MedicalServiceDTO, PageablePersonHistoryResponseDTO,
	PageablePersonsResponseDTO, PageableRequestDTO, ProfessionDTO, RegistrationRequestDTO, UsagesDTO
} from '@noventi/gp-platform/care-providers';
import { PageableUsersRequestDTO, PageableUsersResponseDTO, UserDTO } from '@noventi/gp-platform/users';

export const LoadProviderList = createAction(
	'[Admin] Load Provider List',
);

export const LoadProviderListComplete = createAction(
	'[Admin] Load Provider List Complete',
	props<{ payload: PageablePersonsResponseDTO }>()
);

export const LoadProviderListFailed = createAction(
	'[Admin] Load Provider List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetProviderListFilter = createAction(
	'[Admin] Set Provider List Filter',
	props<{ providerListFilter: PageableRequestDTO }>()
);

export const ClearProviderListFilter = createAction(
	'[Admin] Clear Provider List Filter',
	props<{ providerListFilter: PageableRequestDTO }>()
);

export const LoadProviderRequestList = createAction(
	'[Admin] Load Provider Request List',
	props<{ payload: number }>()
);

export const LoadProviderRequestListComplete = createAction(
	'[Admin] Load Provider Request List Complete',
	props<{ payload: Array<RegistrationRequestDTO> }>()
);

export const LoadProviderRequestListFailed = createAction(
	'[Admin] Load Provider Request List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateRegistrationStatus = createAction(
	'[Admin] Update Registration Status',
	props<{ payload: { registrationRequest: RegistrationRequestDTO, id: number } }>()
);

export const UpdateRegistrationStatusComplete = createAction(
	'[Admin] Update Registration Status Complete',
	props<{ payload: RegistrationRequestDTO }>()
);

export const UpdateRegistrationStatusFailed = createAction(
	'[Admin] Update Registration Status Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteProviderRequest = createAction(
	'[Admin] Delete Registration Request',
	props<{ payload: number }>()
);

export const DeleteProviderRequestComplete = createAction(
	'[Admin] Delete Registration Request Complete',
	props<{ payload: any }>()
);

export const DeleteProviderRequestFailed = createAction(
	'[Admin] Delete Registration Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SendProviderRequest = createAction(
	'[Admin] Send Provider Request',
	props<{ payload: { id: number, request: CreateRegistrationRequestBodyDTO } }>()
);

export const SendProviderRequestComplete = createAction(
	'[Admin] Send Provider Request Complete',
	props<{ payload: any }>()
);

export const SendProviderRequestFailed = createAction(
	'[Admin] Send Provider Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadProviderHistory = createAction(
	'[Admin] Load Provider History',
	props<{ payload: { providerId: number, historyType: HistoryTypeEnumDTO, pageableRequestDTO: PageableRequestDTO } }>()
);

export const LoadProviderHistoryComplete = createAction(
	'[Admin] Load Provider History Complete',
	props<{ payload: PageablePersonHistoryResponseDTO }>()
);

export const LoadProviderHistoryFailed = createAction(
	'[Admin] Load Provider History Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadUserList = createAction(
	'[Admin] Load User List'
);

export const LoadUserListComplete = createAction(
	'[Admin] Load User List Complete',
	props<{ payload: PageableUsersResponseDTO }>()
);

export const LoadUserListFailed = createAction(
	'[Admin] Load User List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetUserListFilter = createAction(
	'[Admin] Set User List Filter',
	props<{ userListFilter: PageableUsersRequestDTO }>()
);

export const ClearUserListFilter = createAction(
	'[Admin] Clear User List Filter',
	props<{ userListFilter: PageableUsersRequestDTO }>()
);

export const LoadUser = createAction(
	'[Admin] Load User',
	props<{ payload: string }>()
);

export const LoadUserComplete = createAction(
	'[Admin] Load User Complete',
	props<{ payload: UserDTO }>()
);

export const LoadUserFailed = createAction(
	'[Admin] Load User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateUser = createAction(
	'[Admin] Create User',
	props<{ payload: UserDTO }>()
);

export const CreateUserComplete = createAction(
	'[Admin] Create User Complete',
	props<{ payload: UserDTO }>()
);

export const CreateUserFailed = createAction(
	'[Admin] Create User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateUser = createAction(
	'[Admin] Update User',
	props<{ payload: { username: string, data: UserDTO } }>()
);

export const UpdateUserComplete = createAction(
	'[Admin] Update User Complete',
	props<{ payload: UserDTO }>()
);

export const UpdateUserFailed = createAction(
	'[Admin] Update User Failed',
	props<{ error: HttpErrorResponse }>()
);

/** ============================================= General data ====================================================== */

export const LoadGeneralDataServices = createAction(
	'[Admin] Load General Data Services',
);

export const LoadGeneralDataServicesComplete = createAction(
	'[Admin] Load General Data Services Complete',
	props<{ payload: Array<MedicalServiceDTO> }>()
);

export const LoadGeneralDataServicesFailed = createAction(
	'[Admin] Load General Data Services Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteGeneralDataService = createAction(
	'[Provider] Delete General Data Service',
	props<{ payload: number }>()
);

export const DeleteGeneralDataServiceComplete = createAction(
	'[Provider] Delete General Data Service Complete'
);

export const DeleteGeneralDataServiceFailed = createAction(
	'[Provider] Delete General Data Service Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateGeneralDataService = createAction(
	'[Provider] Create General Data Service',
	props<{ payload: MedicalServiceDTO }>()
);

export const CreateGeneralDataServiceComplete = createAction(
	'[Provider] Create General Data Service Complete',
	props<{ payload: MedicalServiceDTO }>()
);

export const CreateGeneralDataServiceFailed = createAction(
	'[Provider] Create General Data Service Failed',
	props<{ error: HttpErrorResponse }>()
);

export const EditGeneralDataService = createAction(
	'[Provider] Edit General Data Service',
	props<{ payload: { serviceId: number, data: UserDTO } }>()
);

export const EditGeneralDataServiceComplete = createAction(
	'[Provider] Edit General Data Service Complete',
	props<{ payload: MedicalServiceDTO }>()
);

export const EditGeneralDataServiceFailed = createAction(
	'[Provider] Edit General Data Service Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadGeneralDataServiceUsageNumber = createAction(
	'[Admin] Load General Data Service Usage Number',
	props<{ payload: { serviceId: number } }>()
);

export const LoadGeneralDataServicesUsageNumberComplete = createAction(
	'[Admin] Load General Data Service Usage Number Complete',
	props<{ payload: UsagesDTO }>()
);

export const LoadGeneralDataServicesUsageNumberFailed = createAction(
	'[Admin] Load General Data Service Usage Number Failed',
	props<{ error: HttpErrorResponse }>()
);

/** ==== General data profession ======= */

export const LoadGeneralDataProfessions = createAction(
	'[Admin] Load General Data Professions',
);

export const LoadGeneralDataProfessionsComplete = createAction(
	'[Admin] Load General Data Professions Complete',
	props<{ payload: Array<ProfessionDTO> }>()
);

export const LoadGeneralDataProfessionsFailed = createAction(
	'[Admin] Load General Data Professions Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteGeneralDataProfession = createAction(
	'[Provider] Delete General Data Profession',
	props<{ payload: number }>()
);

export const DeleteGeneralDataProfessionComplete = createAction(
	'[Provider] Delete General Data Profession Complete'
);

export const DeleteGeneralDataProfessionFailed = createAction(
	'[Provider] Delete General Data Profession Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateGeneralDataProfession = createAction(
	'[Provider] Create General Data Profession',
	props<{ payload: ProfessionDTO }>()
);

export const CreateGeneralDataProfessionComplete = createAction(
	'[Provider] Create General Data Profession Complete',
	props<{ payload: ProfessionDTO }>()
);

export const CreateGeneralDataProfessionFailed = createAction(
	'[Provider] Create General Data Profession Failed',
	props<{ error: HttpErrorResponse }>()
);

export const EditGeneralDataProfession = createAction(
	'[Provider] Edit General Data Profession',
	props<{ payload: { professionId: number, data: UserDTO } }>()
);

export const EditGeneralDataProfessionComplete = createAction(
	'[Provider] Edit General Data Profession Complete',
	props<{ payload: ProfessionDTO }>()
);

export const EditGeneralDataProfessionFailed = createAction(
	'[Provider] Edit General Data Profession Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadGeneralDataProfessionUsageNumber = createAction(
	'[Admin] Load General Data Profession Usage Number',
	props<{ payload: { serviceId: number } }>()
);

export const LoadGeneralDataProfessionUsageNumberComplete = createAction(
	'[Admin] Load General Data Profession Usage Number Complete',
	props<{ payload: UsagesDTO }>()
);

export const LoadGeneralDataProfessionUsageNumberFailed = createAction(
	'[Admin] Load General Data Profession Usage Number Failed',
	props<{ error: HttpErrorResponse }>()
);

/** ==== General data expertise area ======= */

export const LoadGeneralDataExpertiseAreas = createAction(
	'[Admin] Load General Data Expertise Areas',
);

export const LoadGeneralDataExpertiseAreasComplete = createAction(
	'[Admin] Load General Data Expertise Areas Complete',
	props<{ payload: Array<ExpertiseAreaDTO> }>()
);

export const LoadGeneralDataExpertiseAreasFailed = createAction(
	'[Admin] Load General Data Expertise Areas Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteGeneralDataExpertiseArea = createAction(
	'[Provider] Delete General Data Expertise Area',
	props<{ payload: number }>()
);

export const DeleteGeneralDataExpertiseAreaComplete = createAction(
	'[Provider] Delete General Data Expertise Area Complete'
);

export const DeleteGeneralDataExpertiseAreaFailed = createAction(
	'[Provider] Delete General Data Expertise Area Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateGeneralDataExpertiseArea = createAction(
	'[Provider] Create General Data Expertise Area',
	props<{ payload: ExpertiseAreaDTO }>()
);

export const CreateGeneralDataExpertiseAreaComplete = createAction(
	'[Provider] Create General Data Expertise Area Complete',
	props<{ payload: ExpertiseAreaDTO }>()
);

export const CreateGeneralDataExpertiseAreaFailed = createAction(
	'[Provider] Create General Data Expertise Area Failed',
	props<{ error: HttpErrorResponse }>()
);

export const EditGeneralDataExpertiseArea = createAction(
	'[Provider] Edit General Data Expertise Area',
	props<{ payload: { expertiseAreaId: number, data: UserDTO } }>()
);

export const EditGeneralDataExpertiseAreaComplete = createAction(
	'[Provider] Edit General Data Expertise Area Complete',
	props<{ payload: ExpertiseAreaDTO }>()
);

export const EditGeneralDataExpertiseAreaFailed = createAction(
	'[Provider] Edit General Data Expertise Area Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadGeneralDataExpertiseAreaUsageNumber = createAction(
	'[Admin] Load General Data Expertise Area Usage Number',
	props<{ payload: { serviceId: number } }>()
);

export const LoadGeneralDataExpertiseAreaUsageNumberComplete = createAction(
	'[Admin] Load General Data Expertise Area Usage Number Complete',
	props<{ payload: UsagesDTO }>()
);

export const LoadGeneralDataExpertiseAreaUsageNumberFailed = createAction(
	'[Admin] Load General Data Expertise Area Usage Number Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ClearState = createAction(
	'[Admin] Clear State'
);

export const Error = createAction(
	'[Admin] Error',
	props<{ error: HttpErrorResponse }>()
);
