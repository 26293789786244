/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Base Pageable Request Data Transfer Object
 */
export interface PageableResponseDTO { 
    /**
     * The total amount of elements
     */
    totalCount?: number;
    /**
     * The number of total pages
     */
    totalPages?: number;
    /**
     * Current page list objects (Created as object instead of array during swagger codegen limitations)
     */
    data?: any;
}

