import { Analytics } from '../analytics.schema';

declare var gtag: any;

export class GoogleAnalyticsService {

	public isReady = false;

	constructor() {
	}

	public init(analytics: Analytics): void {
		const googleAnalyticsJS = document.createElement('script');
		googleAnalyticsJS.type = 'text/javascript';
		googleAnalyticsJS.async = true;
		googleAnalyticsJS.src = analytics.url;

		(window as any).dataLayer = (window as any).dataLayer || [];

		(window as any).gtag = function() {
			(window as any).dataLayer.push(arguments);
		};
		(window as any).gtag('js', new Date());

		googleAnalyticsJS.onload = () => {
			this.isReady = true;
		};

		document.head.appendChild(googleAnalyticsJS);
	}

	public config(analytics: Analytics, pageTitle?: string, pagePath?: string): void {
		if ((window as any).gtag) {
			if (pageTitle && pagePath) {
				gtag('config', analytics.appId, {
					app_name: analytics.appName,
					app_version: analytics.appVersion,
					page_title: pageTitle,
					page_path: pagePath
				});
			} else {
				gtag('config', analytics.appId, {
					app_name: analytics.appName,
					app_version: analytics.appVersion
				});
			}
		}
	}

	public emitScreen(analytics: Analytics, screen: string): void {
		if ((window as any).gtag) {
			gtag('event', 'screen_view', {
				app_name: analytics.appName,
				app_version: analytics.appVersion,
				screen_name: screen
			});
		}
	}

	public emitEvent( analytics: Analytics, eventCategory: string, eventAction?: string, eventLabel?: string, eventValue?: number): void {
		if ((window as any).gtag) {
			gtag('event', eventCategory, {
				event_category: eventCategory,
				event_action: eventAction,
				event_label: eventLabel,
				value: eventValue
			});
		}
	}
}
