/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Used not to confuse similar profiles
 */
export type ExternalSourceEnumDTO = 'AXIOM' | 'USER_REGISTRATION' | 'ADMIN_REGISTRATION';

export const ExternalSourceEnumDTO = {
    AXIOM: 'AXIOM' as ExternalSourceEnumDTO,
    USERREGISTRATION: 'USER_REGISTRATION' as ExternalSourceEnumDTO,
    ADMINREGISTRATION: 'ADMIN_REGISTRATION' as ExternalSourceEnumDTO
};

