import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector       : 'gp-toast',
	templateUrl    : 'toast.component.html',
	encapsulation  : ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations     : [
		trigger('flyInOut', [
			state('inactive', style({display: 'none', opacity: 0})),
			state('active', style({})),
			state('removed', style({opacity: 0})),

			state('swipe-right', style({display: 'none', opacity: 0, left: '150%'})),
			state('swipe-left', style({display: 'none', opacity: 0, right: '150%'})),

			transition('inactive => active',
				animate('{{ easeTime }}ms {{ easing }}')
			),
			transition('active => removed',
				animate('{{ easeTime }}ms {{ easing }}')
			),
			transition('* => swipe-right', [
				animate('500ms ease-out', keyframes([
					style({opacity: 1, left: '0%', offset: 0}),
					style({opacity: .5, left: '50%', offset: 0.3}),
					style({opacity: 0, left: '150%', offset: 1})
				]))
			]),
			transition('* => swipe-left', [
				animate('500ms ease-out', keyframes([
					style({opacity: 1, right: '0%', offset: 0}),
					style({opacity: .5, right: '50%', offset: 0.3}),
					style({opacity: 0, right: '150%', offset: 1})
				]))
			])
		])
	]
})
export class ToastComponent extends Toast {

	@HostBinding('@flyInOut') public stateExt;

	public transition = '';

	constructor(
		public toastPackage: ToastPackage,
		public toastrService: ToastrService
	) {
		super(toastrService, toastPackage);
	}

	public action() {
		this.toastPackage.triggerAction(this.toastPackage.title);
		this.toastrService.remove(this.toastPackage.toastId);
	}

	public swipeEvent(event) {
		this.stateExt = event.deltaX > 0 ? 'swipe-right' : 'swipe-left';
		setTimeout(() => {
			this.toastrService.remove(this.toastPackage.toastId);
		}, 500);
	}
}
