import { CmsPageTypeEnumDTO } from '@noventi/gp-platform/users';

export const CmsPageTypeUrl = {
	FAQ: 'faq' as CmsPageTypeEnumDTO,
	TERMS_AND_CONDITIONS: 'nutzungsbedingungen' as CmsPageTypeEnumDTO,
	PRIVACY: 'datenschutz' as CmsPageTypeEnumDTO,
	IMPRESSIONS: 'impressum' as CmsPageTypeEnumDTO,
	CONTACT: 'kontakt' as CmsPageTypeEnumDTO,
	LICENSES: 'licenses' as CmsPageTypeEnumDTO,
	RELEASENOTES: 'neuigkeiten' as CmsPageTypeEnumDTO
};

export const BookingPageTypeArray: Array<CmsPageTypeEnumDTO> = [
	CmsPageTypeEnumDTO.PRIVACY,
	CmsPageTypeEnumDTO.TERMSANDCONDITIONS,
	CmsPageTypeEnumDTO.IMPRESSIONS,
	CmsPageTypeEnumDTO.CONTACT,
	CmsPageTypeEnumDTO.LICENSES
];

export const AdminPageTypeArray: Array<CmsPageTypeEnumDTO> = [
	CmsPageTypeEnumDTO.PRIVACY,
	CmsPageTypeEnumDTO.TERMSANDCONDITIONS,
	CmsPageTypeEnumDTO.IMPRESSIONS,
	CmsPageTypeEnumDTO.FAQ,
	CmsPageTypeEnumDTO.LICENSES,
	CmsPageTypeEnumDTO.RELEASENOTES
];
