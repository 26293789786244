import { Component, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MessageService } from '@gp-angular/service/message';

@Component({
	selector: 'gp-toggle-select',
	templateUrl: './toggle-select.component.html',
	styleUrls: ['./toggle-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleSelectComponent {

	@Output() public changed: EventEmitter<FormControl> = new EventEmitter<FormControl>();

	@ViewChild('select', {static: true}) public select: MatSelect;

	@Input() public label: string;

	@Input() public fc: FormControl;

	@Input() public list: any[];

	@Input() public defaultValue: any;

	@Input() public valueText: string;

	constructor(
		public messageService: MessageService
	) {
	}

	public checkboxToggle(event, control: FormControl): void {
		control.markAsDirty();
		if (event.checked) {
			control.setValue(this.defaultValue);
			control.enable();
		} else {
			control.setValue(null);
			control.disable();
		}
		this.emit(control);
	}

	public preventDefault(): void {
		setTimeout(() => {
			this.select.close();
		},1);
	}

	public emit(control): void {
		this.changed.emit(control);
	}

	public placeholderText(): string {
		const DEFAULT_VALUE = this.defaultValue ? this.defaultValue : '';
		const VALUE_TEXT = this.valueText ? this.valueText : '';
		return !this.fc.value ? `${DEFAULT_VALUE}${VALUE_TEXT}` : '';
	}

}
