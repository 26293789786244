export enum ColorResource {
	'#AD1557',
	'#D81C60',
	'#D51700',
	'#E67C73',
	'#F4511D',
	'#EF6C02',
	'#F09301',
	'#F6BF26',
	'#E4C441',
	'#C0CA33',
	'#7CB342',
	'#33B679',
	'#148043',
	'#159688',
	'#039BE5',
	'#4385F4',
	'#4050B5',
	'#7986CB',
	'#B39DDB',
	'#9E69AF',
	'#8E24AA',
	'#795547',
	'#616161',
	'#A79B8E',
	'DEFAULT_ROOM',
	'DEFAULT_DEVICE',
	'NONE'
}

export enum ColorCalendar {
	'#ad15574d',
	'#f96da04d',
	'#ffc9c44d',
	'#f4511d4d',
	'#ffaa654d',
	'#f093014d',
	'#ffe18c4d',
	'#e8ee9d4d',
	'#d2e8ba4d',
	'#1480434d',
	'#86e0b64d',
	'#4050b54d',
	'#95bcff4d',
	'#66cdff4d',
	'#c4caec4d',
	'#a5958e4d',
	'#8d8c8c4d',
	'#bcbcbc4d',
	'#d7cec44d',
	'#cbb0d34d',
	'#8e24aa4d',
	'#c073d44d'
}
