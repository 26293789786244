import {
	Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter, ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MessageService } from '@gp-angular/service/message';
import { MatSelect } from '@angular/material/select';
import { FormValidators, pattern } from '@gp-angular/shared/utils';

@Component({
	selector: 'gp-buffer-time',
	styleUrls: ['buffer-time.component.scss'],
	templateUrl: './buffer-time.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BufferTimeComponent {

	@Output() public changed: EventEmitter<FormControl> = new EventEmitter<FormControl>();

	@ViewChild('select', {static: true}) public select: MatSelect;

	@Input() public label: string;

	@Input() public fc: FormControl;

	constructor(
		public messageService: MessageService
	) {
	}

	public checkboxToggle(event, control: FormControl): void {
		const VALIDATORS = [FormValidators.noWhitespace, Validators.pattern(pattern('integer'))];

		control.markAsDirty();
		control.markAsTouched();
		if (event.checked) {
			control.enable();
			control.setValidators([...VALIDATORS, Validators.required]);
		} else {
			control.setValidators(VALIDATORS);
			control.setValue(null);
			control.disable();
		}
		control.updateValueAndValidity();
		this.emit(control);
	}

	public preventDefault(): void {
		setTimeout(() => {
			this.select.close();
		},1);
	}

	public emit(control): void {
		this.changed.emit(control);
	}
}
