export class PatientUtils {
	private static getName(patient: any): string {
		return `${patient.lastname ? patient.lastname : ''} ${patient.firstname ? patient.firstname : ''}`;
	}

	public static displayName(patient: any, gender = true): string {
		if (!patient) {
			return undefined;
		}
		return `${gender ? (patient.gender ? patient.gender : '') : ''}
					${this.getName(patient)}`;
	}

	public static displayPhone(patient: any): string {
		if (!patient) {
			return undefined;
		}
		return `${patient.telephone ? patient.telephone : ''}`;
	}
}
