import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROVIDER_FEATURE_KEY, State, ProviderPartialState, } from './provider.reducer';
import { ResourceTypeEnumDTO } from '@noventi/gp-platform/care-providers';

export const getProviderState = createFeatureSelector<ProviderPartialState, State>(PROVIDER_FEATURE_KEY);

export const getProviderLoading = createSelector(
	getProviderState,
	(state: State) => state.loading
);

export const getProviderError = createSelector(
	getProviderState,
	(state: State) => state.error
);

export const getData = createSelector(
	getProviderState,
	(state: State) => state.data
);

export const getCalendarResources = createSelector(
	getProviderState,
	(state: State) => state.calendarResources
);

export const getCountUnreadMessage = createSelector(
	getProviderState,
	(state: State) => state.messageCount
);

export const getSystemTemplates = createSelector(
	getProviderState,
	(state: State) => state.systemTemplates
);

export const getCustomTemplates = createSelector(
	getProviderState,
	(state: State) => state.customTemplates
);

export const getResources = createSelector(
	getProviderState,
	(state: State) => state.resources
);

export const getEmployeeResources = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.EMPLOYEE)[0];
		return RESOURCES && RESOURCES['items'] && RESOURCES['items'].length > 0 ? RESOURCES['items'] : [];
	}
);

export const getEmployeeResourcesObj = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.EMPLOYEE)[0];
		return RESOURCES ? RESOURCES : {};
	}
);

export const getRoomResources = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.ROOM)[0];
		return RESOURCES && RESOURCES['items'] && RESOURCES['items'].length > 0 ? RESOURCES['items'] : [];
	}
);

export const getRoomResourcesObj = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.ROOM)[0];
		return RESOURCES ? RESOURCES : {};
	}
);

export const getDeviceResources = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.DEVICE)[0];
		return RESOURCES && RESOURCES['items'] && RESOURCES['items'].length > 0 ? RESOURCES['items'] : [];
	}
);

export const getDeviceResourcesObj = createSelector(
	getProviderState,
	(state: State) => {
		const RESOURCES = state.resources.filter((group) => group.type === ResourceTypeEnumDTO.DEVICE)[0];
		return RESOURCES ? RESOURCES : {};
	}
);

export const getSettings = createSelector(
	getProviderState,
	(state: State) => state.settings.appointment
);

export const getSettingsCalendar = createSelector(
	getProviderState,
	(state: State) => state.settings.calendar
);
