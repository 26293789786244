import { Injectable } from '@angular/core';
import { ProviderFacade } from './+state/provider.facade';
import * as ProviderActions from './+state/provider.actions';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { asyncScheduler, Observable, of, scheduled, throwError } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { AsyncStoreObject, ResourceUtils } from '@gp-angular/shared/utils';
import {
	AbsentHourDTO, AttachmentsResponseDTO, BookingHoursDTO, CalendarSettingsDTO, CareProviderDataEnumDTO, CreateRegistrationRequestBodyDTO,
	ExpertiseAreaService,
	ExpertiseDTO, ExtraInfoDTO,
	KeyValueDTO, LanguageService, MedicalServiceDTO, MedicalServiceService, MessageTemplateDTO, OnlineBookingHourDTO, OpenHoursItemDTO,
	PersonDTO, PersonListInfoDTO, PersonRequestBodyDTO, PostalCodeCityDTO, ProfessionService, QuerySearchByExistingIdsDTO,
	RegisterProfileConfirmDTO,
	RegisterStatusEnumDTO, RegistrationRequestDTO, ResourceCategoryWrapperDTO, ResourceDTO, ResourceHoursResponseDTO, ResourceReorderDTO,
	ResourceTypeEnumDTO, SettingsDTO, TimeSlotsDTO, TreatmentDTO
} from '@noventi/gp-platform/care-providers';
import {
	AppointmentDTO, AppointmentHeatmapDTO, CalendarEventDTO, InsuranceTypeEnumDTO, MessageDTO,
	PageableAppointmentInfosResponseDTO, PageableMessagesRequestDTO, PageableResponseDTO, PageableWaitingListsRequestDTO,
	PageableWaitingListsResponseDTO,
	PublicAppointmentDTO, ResourceGdprDTO, WaitingListBookingStatusResponseDTO
} from '@noventi/gp-platform/online-appointments';
import {
	GetPatientsResponseDTO, PageablePatientsResponseDTO, PatientDTO, PatientGdprInfoDTO, SortFieldWithDirectionDTO
} from '@noventi/gp-platform/patients';
import {
	CmsAppTypeEnumDTO, CmsPageTypeEnumDTO, PageableUsersRequestDTO, PageableUsersResponseDTO, PasswordResetRequestDTO,
	PasswordResetResponseDTO, UserDTO, UserRoleEnumDTO
} from '@noventi/gp-platform/users';
import { InternalUtils } from './internal.utils';

@Injectable()
export class ProviderService {

	private keysOfAcademicalDegreeCached: any;

	private keysOfSalutationCached: any;

	constructor(
		private actionsSubject$: ActionsSubject,
		private providerFacade: ProviderFacade,
		private medicalServicesService: MedicalServiceService,
		private languageServiceGenerated: LanguageService,
		private professionService: ProfessionService,
		private expertiseAreaService: ExpertiseAreaService
	) {
	}

	public getPostalCodeCity$(value: PostalCodeCityDTO): Observable<PostalCodeCityDTO[]> {
		this.providerFacade.dispatch(ProviderActions.AutocompletePostalCodeCity({payload: value}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.AutocompletePostalCodeCityComplete, ProviderActions.AutocompletePostalCodeCityFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.AutocompletePostalCodeCityFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getKeysOfSalutation$(): Observable<KeyValueDTO[]> {
		if (!!this.keysOfSalutationCached) {
			return of(this.keysOfSalutationCached);
		} else {
			this.providerFacade.dispatch(ProviderActions.LoadProviderKeySalutation());
			return this.actionsSubject$.pipe(
				ofType(ProviderActions.LoadProviderKeySalutationComplete, ProviderActions.LoadProviderKeySalutationFailed),
				take(1),
				switchMap((action) => {
					if (action.type === ProviderActions.LoadProviderKeySalutationFailed.type) {
						return throwError(action.error);
					}
					this.keysOfSalutationCached = action.payload;
					return of(action.payload);
				})
			);
		}
	}

	public getKeysOfAcademicalDegree$(): Observable<KeyValueDTO[]> {
		if (!!this.keysOfAcademicalDegreeCached) {
			return of(this.keysOfAcademicalDegreeCached);
		} else {
			this.providerFacade.dispatch(ProviderActions.LoadProviderKeyAcademicalDegree());

			return this.actionsSubject$.pipe(
				ofType(ProviderActions.LoadProviderKeyAcademicalDegreeComplete, ProviderActions.LoadProviderKeyAcademicalDegreeFailed),
				take(1),
				switchMap((action) => {
					if (action.type === ProviderActions.LoadProviderKeyAcademicalDegreeFailed.type) {
						return throwError(action.error);
					}
					this.keysOfAcademicalDegreeCached = action.payload;
					return of(action.payload);
				})
			);
		}
	}

	public loading$(): Observable<boolean> {
		return this.providerFacade.loading$;
	}

	public loadProviderList(username: string): void {
		this.providerFacade.dispatch(ProviderActions.LoadProviderList({payload: username}));
	}

	public getProviderList$(): Observable<Array<PersonListInfoDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadProviderListComplete, ProviderActions.LoadProviderListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadProviderListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadProvider(providerId: number): void {
		this.providerFacade.dispatch(ProviderActions.LoadProvider({payload: providerId}));
	}

	public loadProvider$(providerId: number): Observable<PersonDTO> {
		this.providerFacade.dispatch(ProviderActions.LoadProvider({payload: providerId}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadProviderComplete, ProviderActions.LoadProviderFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.data);
			})
		);
	}

	public readProvider$(): Observable<PersonDTO> {
		return this.providerFacade.data$;
	}

	public createProvider$(personRequest: PersonRequestBodyDTO) {
		this.providerFacade.dispatch(ProviderActions.CreateProvider({payload: personRequest}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateProviderComplete, ProviderActions.CreateProviderFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public confirmRegistrationRequest$(registerProfileConfirmDTO: RegisterProfileConfirmDTO, token: string): Observable<RegisterProfileConfirmDTO> {
		this.providerFacade.dispatch(ProviderActions.ConfirmRegistrationRequest({
			payload: {
				registerProfileConfirm: registerProfileConfirmDTO, token: token
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ConfirmRegistrationRequestComplete, ProviderActions.ConfirmRegistrationRequestFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.ConfirmRegistrationRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public saveProviderInformation$(providerId: number, data: any): Observable<PersonDTO> {
		this.providerFacade.dispatch(ProviderActions.SaveProviderInformation({
			payload: {
				id: providerId, body: data
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveProviderInformationComplete, ProviderActions.SaveProviderInformationFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveProviderInformationFailed.type) {
					return throwError(action.error);
				}
				return of(action.data);
			})
		);
	}

	public saveProviderImage$(images: Array<any>): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.SaveProviderImage({payload: images}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveProviderImageComplete, ProviderActions.SaveProviderImageFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveProviderImageFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteProviderImage$(payload: any): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteProviderImage(payload));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteProviderImageComplete, ProviderActions.DeleteProviderImageFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteProviderImageFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public saveProviderSchedule$(providerId: number, body: Array<OpenHoursItemDTO>): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.SaveProviderSchedule({
			payload: {
				id: providerId, body: body
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveProviderScheduleComplete, ProviderActions.SaveProviderScheduleFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveProviderScheduleFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteProvider$(payload: { providerId: number, email?: string, download?: boolean, xSmsSimulate?: boolean }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteProvider({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteProviderComplete, ProviderActions.DeleteProviderFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public archiveProvider$(providerId: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.ArchiveProvider({payload: providerId}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ArchiveProviderComplete, ProviderActions.ArchiveProviderFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.ArchiveProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public reactivateProvider$(providerId: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.ReactivateProvider({payload: providerId}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ReactivateProviderComplete, ProviderActions.ReactivateProviderFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.ReactivateProviderFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public copyProviderData$(payload: { idFrom: number, idTo: number, data: Array<CareProviderDataEnumDTO> }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.CopyProviderData({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CopyProviderDataComplete, ProviderActions.CopyProviderDataFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CopyProviderDataFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== Business hours ========================================================== */

	public loadResourceHourList(payload: {
		date: Date, interval: number, resourceId?: number, bookingHours?: boolean,
		onlineBookingHours?: boolean, absentHours?: boolean
	}) {
		this.providerFacade.dispatch(ProviderActions.loadResourceHourList({payload}));
	}

	public getResourceHourList$(): Observable<Array<ResourceHoursResponseDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.loadResourceHourListComplete, ProviderActions.loadResourceHourListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.loadResourceHourListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/**
	 * Appointments
	 */
	public loadAppointments(date: Date, interval: number) {
		this.providerFacade.dispatch(ProviderActions.LoadAppointmentList({
			payload: {
				date: date, interval: interval
			}
		}));
	}

	public getAppointments$(): Observable<Array<CalendarEventDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadAppointmentListComplete, ProviderActions.LoadAppointmentListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadAppointmentListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadAppointmentById$(appointmentId: number): Observable<AppointmentDTO> {
		this.providerFacade.dispatch(ProviderActions.LoadAppointment({payload: appointmentId}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadAppointmentComplete, ProviderActions.LoadAppointmentFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadAppointmentHeatmap(date: Date, interval: number, resourceList: Array<ResourceDTO>) {
		this.providerFacade.dispatch(ProviderActions.LoadAppointmentHeatmap({
			payload: {
				date: date, interval: interval, resourceIds: ResourceUtils.getEmployeeIdList(resourceList)
			}
		}));
	}

	public getAppointmentHeatmap$(): Observable<Array<AppointmentHeatmapDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadAppointmentHeatmapComplete, ProviderActions.LoadAppointmentHeatmapFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadAppointmentHeatmapFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public checkAppointmentInterval$(overlap: boolean, payload: { resourceId: number, treatmentId?: number, appointmentId?: number, startTime: string, endTime: string }): Observable<InsuranceTypeEnumDTO[]> {
		this.providerFacade.dispatch(ProviderActions.CheckAppointmentInterval({overlap: overlap, payload: payload}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CheckAppointmentIntervalComplete, ProviderActions.CheckAppointmentIntervalFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CheckAppointmentIntervalFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadPublicAppointmentList(payload: number[]): void {
		this.providerFacade.dispatch(ProviderActions.LoadPublicAppointmentList({payload: payload}));
	}

	public getPublicAppointmentList$(): Observable<PublicAppointmentDTO[]> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadPublicAppointmentListComplete, ProviderActions.LoadPublicAppointmentListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadPublicAppointmentListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createMultipleAppointments$(appointments: Array<AppointmentDTO>, bookingLink: string): Observable<Array<AppointmentDTO>> {
		this.providerFacade.dispatch(ProviderActions.CreateMultipleAppointments({payload: {appointments: [...appointments], bookingLink}}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateMultipleAppointmentsComplete, ProviderActions.CreateMultipleAppointmentsFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateMultipleAppointmentsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createAppointment$(appointment: AppointmentDTO, bookingLink: string, waitingListId?: number): Observable<AppointmentDTO> {
		const PAYLOAD = {appointment: appointment, bookingLink: bookingLink};
		if (waitingListId) {
			PAYLOAD['waitingListId'] = waitingListId;
		}
		this.providerFacade.dispatch(ProviderActions.CreateAppointment({payload: PAYLOAD}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateAppointmentComplete, ProviderActions.CreateAppointmentFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public updateAppointment$(appointmentId: number, sendSms: boolean = false, data: any): Observable<AppointmentDTO> {
		this.providerFacade.dispatch(ProviderActions.UpdateAppointment({
			payload: {
				id: appointmentId,
				sendSms: sendSms,
				body: data
			}
		}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateAppointmentComplete, ProviderActions.UpdateAppointmentFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteAppointment$(appointmentId: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteAppointment({payload: appointmentId}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteAppointmentComplete, ProviderActions.DeleteAppointmentFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	/** ============================================== Provider services ========================================================== */
	public loadProviderServices(id: number): void {
		this.providerFacade.dispatch(ProviderActions.LoadProviderServices({payload: id}));
	}

	public getProviderServices$(): Observable<Array<MedicalServiceDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadProviderServicesComplete, ProviderActions.LoadProviderServicesFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadProviderServicesFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	// TODO: make this an effect ?
	public searchServices(query: QuerySearchByExistingIdsDTO, minInputForSearch: number) {
		if (!!query && query.q.trim().length >= minInputForSearch) {
			return this.medicalServicesService.searchMedicalServices(query);
		}
	}

	public saveProviderServices$(medicalServices): Observable<MedicalServiceDTO[]> {
		this.providerFacade.dispatch(ProviderActions.SaveProviderServices({payload: medicalServices}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveProviderServicesComplete, ProviderActions.SaveProviderServicesFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveProviderServicesFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	/** ============================================== Provider Extra Info ========================================================== */

	public loadExtraInfo(): void {
		this.providerFacade.dispatch(ProviderActions.LoadExtraInfo());
	}

	public getExtraInfo$(): Observable<ExtraInfoDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadExtraInfoComplete, ProviderActions.LoadExtraInfoFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadExtraInfoFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	// TODO: make this an effect ?
	public searchLanguages(query: QuerySearchByExistingIdsDTO, minInputForSearch: number) {
		if (!!query && query.q.trim().length >= minInputForSearch) {
			return this.languageServiceGenerated.searchLanguages(query);
		}
	}

	public saveExtraInfo$(extraInfo: ExtraInfoDTO): Observable<ExtraInfoDTO> {
		this.providerFacade.dispatch(ProviderActions.SaveExtraInfo({payload: extraInfo}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveExtraInfoComplete, ProviderActions.SaveExtraInfoFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveExtraInfoFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	/** ============================================== Expertise ========================================================== */
	public loadExpertise(): void {
		this.providerFacade.dispatch(ProviderActions.LoadExpertise());
	}

	public getExpertise$(): Observable<ExpertiseDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadExpertiseComplete, ProviderActions.LoadExpertiseFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadExpertiseFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	// TODO: make this an effect ?
	public searchProfessions(query: QuerySearchByExistingIdsDTO, minInputForSearch: number = 3) {
		if (!!query && query.q.trim().length >= minInputForSearch) {
			return this.professionService.searchProfessions(query);
		}
		return [];
	}

	// TODO: make this an effect ?
	public searchExpertiseAreas(query: QuerySearchByExistingIdsDTO, minInputForSearch: number = 3) {
		if (!!query && query.q.trim().length >= minInputForSearch) {
			return this.expertiseAreaService.searchExpertiseAreas(query);
		}
	}

	public saveExpertise$(expertise: ExpertiseDTO): Observable<ExpertiseDTO> {
		this.providerFacade.dispatch(ProviderActions.SaveExpertise({payload: expertise}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveExpertiseComplete, ProviderActions.SaveExpertiseFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveExpertiseFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	/** ============================================== Messages ========================================================== */
	public countUnreadMessage$(): Observable<number> {
		this.providerFacade.dispatch(ProviderActions.CountUnreadMessage());

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CountUnreadMessageComplete, ProviderActions.CountUnreadMessageFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CountUnreadMessageFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public readCountUnreadMessage$(): Observable<number> {
		return this.providerFacade.messageCount$;
	}

	public decreaseCountUnreadMessage(): void {
		this.providerFacade.dispatch(ProviderActions.UpdateCountUnreadMessage({payload: 1}));
	}

	public increaseCountUnreadMessage(): void {
		this.providerFacade.dispatch(ProviderActions.UpdateCountUnreadMessage({payload: -1}));
	}

	public loadMessageList(pageEvent: PageEvent, pid: number): void {
		const PAYLOAD: PageableMessagesRequestDTO = {
			page: pageEvent.pageIndex,
			size: pageEvent.pageSize,
			personId: pid
		};
		this.providerFacade.dispatch(ProviderActions.LoadMessageList({payload: PAYLOAD}));
	}

	//public loadLastMessage$(pageEvent: PageEvent): Observable<PageableResponseDTO> {
	//	this.providerFacade.dispatch(ProviderActions.LoadLastMessage({payload: pageEvent}));
	//
	//	return this.actionsSubject$.pipe(
	//		ofType(ProviderActions.LoadLastMessageComplete, ProviderActions.LoadLastMessageFailed),
	//		switchMap((action) => {
	//			if (action.type === ProviderActions.LoadLastMessageFailed.type) {
	//				return throwError(action.error);
	//			}
	//			return of(action.payload);
	//		})
	//	);
	//}

	public getMessageList$(): Observable<PageableResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadMessageListComplete, ProviderActions.LoadMessageListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadMessageListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadMessageById(id: number) {
		this.providerFacade.dispatch(ProviderActions.LoadMessage({payload: id}));
	}

	public getMessageById$(): Observable<MessageDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadMessageComplete, ProviderActions.LoadMessageFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadMessageFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== Text templates ========================================================== */
	public loadSystemTemplates(): void {
		this.providerFacade.dispatch(ProviderActions.LoadSystemTemplates());
	}

	public getSystemTemplates$(): Observable<AsyncStoreObject<Array<MessageTemplateDTO>>> {
		return this.providerFacade.systemTemplates$;
	}

	public loadCustomTemplates(): void {
		this.providerFacade.dispatch(ProviderActions.LoadCustomTemplates());
	}

	public getCustomTemplates$(): Observable<AsyncStoreObject<Array<MessageTemplateDTO>>> {
		return this.providerFacade.customTemplates$;
	}

	public saveCustomTemplate$(template: MessageTemplateDTO): Observable<MessageTemplateDTO> {
		this.providerFacade.dispatch(ProviderActions.SaveCustomTemplate({payload: template}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.SaveCustomTemplateComplete, ProviderActions.SaveCustomTemplateFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.SaveCustomTemplateFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	public uploadAttachment$(blob: Blob): Observable<AttachmentsResponseDTO> {
		this.providerFacade.dispatch(ProviderActions.UploadAttachment({payload: blob}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UploadAttachmentComplete, ProviderActions.UploadAttachmentFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.UploadAttachmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	public getAttachment$(id: number, uuid: string): Observable<Blob> {
		this.providerFacade.dispatch(ProviderActions.GetAttachment({payload: {id: id, uuid: uuid}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.GetAttachmentComplete, ProviderActions.GetAttachmentFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.GetAttachmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	/** ============================================== Resources ========================================================== */

	public loadResourceId$(id: number): Observable<ResourceDTO> {
		this.providerFacade.dispatch(ProviderActions.LoadResource({payload: id}));

		return this.getResourceId$();
	}

	public getResourceId$(): Observable<ResourceDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadResourceComplete, ProviderActions.LoadResourceFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadResourceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadResources(): void {
		this.providerFacade.dispatch(ProviderActions.LoadResourceGroup());
	}

	//public getResources$(): Observable<Array<ResourceCategoryWrapperDTO>> {
	//	return this.actionsSubject$.pipe(
	//		ofType(ProviderActions.LoadResourceGroupComplete, ProviderActions.LoadResourceGroupFailed),
	//		switchMap((action) => {
	//			if (action.type === ProviderActions.LoadResourceGroupFailed.type) {
	//				return throwError(action.error);
	//			}
	//			return of(action.resources);
	//		})
	//	);
	//}

	public readResources$(): Observable<Array<ResourceCategoryWrapperDTO>> {
		return this.providerFacade.resourceList$;
	}

	public readResourceType$(type: ResourceTypeEnumDTO): Observable<ResourceDTO[]> {
		switch (type) {
			case ResourceTypeEnumDTO.EMPLOYEE:
				return this.providerFacade.employeeList$;
			case ResourceTypeEnumDTO.ROOM:
				return this.providerFacade.roomList$;
			case ResourceTypeEnumDTO.DEVICE:
				return this.providerFacade.deviceList$;
			default:
				return this.providerFacade.employeeList$;

		}
	}

	public deleteResource$(payload: { resource: ResourceDTO, email?: string, download?: boolean, xSmsSimulate?: boolean }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteResource({payload: payload}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteResourceComplete, ProviderActions.DeleteResourceFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteResourceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload.pdf);
			})
		);
	}

	public createResource$(resource: ResourceDTO): Observable<ResourceDTO> {
		this.providerFacade.dispatch(ProviderActions.CreateResource({payload: resource}));

		return this.getCreateResource$();
	}

	public getCreateResource$(): Observable<ResourceDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateResourceComplete, ProviderActions.CreateResourceFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateResourceFailed.type) {
					return throwError(action.error);
				}
				return of(action.result);
			}));
	}

	public updateResource$(resourceId: number, data: any): Observable<boolean> {
		this.providerFacade.dispatch(ProviderActions.UpdateResource({
			payload: {
				id: resourceId, body: data
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateResourceComplete, ProviderActions.UpdateResourceFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateResourceFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			}));
	}

	public manuallyUpdateResource(resource: ResourceDTO, resourceState: Array<ResourceCategoryWrapperDTO>): void {
		this.providerFacade.dispatch(
			ProviderActions.UpdateResourceComplete({resources: InternalUtils.getModifiedResourceState(resource, resourceState)})
		)
	}

	public loadNotAssignedResourceList(userId?: number) {
		const payload = !!userId ? {userId: userId} : {};
		this.providerFacade.dispatch(ProviderActions.LoadNotAssignedResourceList({payload: payload}));
	}

	public getNotAssignedResourceList$(): Observable<ResourceDTO[]> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadNotAssignedResourceListComplete, ProviderActions.LoadNotAssignedResourceListFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadNotAssignedResourceListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	public reorderResource$(resourceReorder: ResourceReorderDTO): Observable<boolean> {
		this.providerFacade.dispatch(ProviderActions.ReorderResources({payload: resourceReorder}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ReorderResourcesComplete, ProviderActions.ReorderResourcesFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.ReorderResourcesFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			}));
	}

	public setCalendarResources(resources: Array<ResourceDTO>): void {
		this.providerFacade.dispatch(ProviderActions.SetCalendarResources({calendarResources: resources}));
	}

	public resetCalendarResources(): void {
		this.providerFacade.dispatch(ProviderActions.SetCalendarResources({calendarResources: undefined}));
	}

	public readCalendarResources$(): Observable<Array<ResourceDTO>> {
		return this.providerFacade.calendarResources$;
	}

	public getResourceGDPR$(resourceId: number): Observable<ResourceGdprDTO> {
		this.providerFacade.dispatch(ProviderActions.LoadResourceGdpr({payload: resourceId}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadResourceGdprComplete, ProviderActions.LoadResourceGdprFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadResourceGdprFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== Settings (provide, calendar) ==================================================== */

	public loadSettings(): void {
		this.providerFacade.dispatch(ProviderActions.LoadSettings());
	}

	public readSettings$(): Observable<SettingsDTO> {
		return this.providerFacade.settings$;
	}

	public updateSettings$(data: any): Observable<SettingsDTO> {
		this.providerFacade.dispatch(ProviderActions.UpdateSettings({
			payload: {
				body: data
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateSettingsComplete, ProviderActions.UpdateSettingsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateSettingsFailed.type) {
					return throwError(action.error);
				}
				return of(action.settings.appointment);
			})
		);
	}

	public loadCalendarSettings(username?: string): void {
		this.providerFacade.dispatch(ProviderActions.LoadCalendarSettings({payload: username}));
	}

	public readCalendarSettings$(): Observable<CalendarSettingsDTO> {
		return this.providerFacade.settingsCalendar$;
	}

	public updateCalendarSettings$(data: any, username?: string): Observable<CalendarSettingsDTO> {
		const PAYLOAD = {body: data};
		if (username) {
			PAYLOAD['username'] = username;
		}
		this.providerFacade.dispatch(ProviderActions.UpdateCalendarSettings({payload: PAYLOAD}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateCalendarSettingsComplete, ProviderActions.UpdateCalendarSettingsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateCalendarSettingsFailed.type) {
					return throwError(action.error);
				}
				return of(action.settings.appointment);
			})
		);
	}

	public loadCalendarDefaultSettings(): void {
		this.providerFacade.dispatch(ProviderActions.LoadCalendarDefaultSettings());
	}

	public getCalendarDefaultSettings$(): Observable<SettingsDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadCalendarDefaultSettingsComplete, ProviderActions.LoadCalendarDefaultSettingsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadCalendarDefaultSettingsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public downloadPrintableCalendar$(payload: { startDate: Date, interval: number, resourceIds: Array<ResourceDTO> }): Observable<Blob> {
		this.providerFacade.dispatch(ProviderActions.DownloadPrintableCalendar({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DownloadPrintableCalendarComplete, ProviderActions.DownloadPrintableCalendarFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.DownloadPrintableCalendarFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getDefaultTimeSlots$(): Observable<TimeSlotsDTO> {
		this.providerFacade.dispatch(ProviderActions.LoadDefaultTimeSlots());

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadDefaultTimeSlotsComplete, ProviderActions.LoadDefaultTimeSlotsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadDefaultTimeSlotsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public downloadPeriodicalDeletionReport$(): Observable<Blob> {
		this.providerFacade.dispatch(ProviderActions.DownloadPeriodicalDeletionReport());

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DownloadPeriodicalDeletionReportComplete, ProviderActions.DownloadPeriodicalDeletionReportFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.DownloadPeriodicalDeletionReportFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== RESOURCE rules ================================================= */

	public loadResourceOfficeHours$(id: number): Observable<Array<BookingHoursDTO>> {
		this.providerFacade.dispatch(ProviderActions.LoadResourceOfficeHours({payload: {resourceId: id}}));

		return this.readResourceOfficeHours$();
	}

	public readResourceOfficeHours$(): Observable<Array<BookingHoursDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadResourceOfficeHoursComplete, ProviderActions.LoadResourceOfficeHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadResourceOfficeHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createResourceOfficeHours$(id: number, bookingHours: BookingHoursDTO): Observable<BookingHoursDTO> {
		this.providerFacade.dispatch(ProviderActions.CreateResourceOfficeHours({payload: {resourceId: id, bookingHours: bookingHours}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateResourceOfficeHoursComplete, ProviderActions.CreateResourceOfficeHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateResourceOfficeHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public patchResourceOfficeHours$(id: number, bookingHours: BookingHoursDTO): Observable<BookingHoursDTO> {
		this.providerFacade.dispatch(ProviderActions.PatchResourceOfficeHours({payload: {id: id, bookingHours: bookingHours}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.PatchResourceOfficeHoursComplete, ProviderActions.PatchResourceOfficeHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.PatchResourceOfficeHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteResourceOfficeHours$(id: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteResourceOfficeHours({payload: id}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteResourceOfficeHoursComplete, ProviderActions.DeleteResourceOfficeHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteResourceOfficeHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadResourceAbsences$(id: number): Observable<Array<AbsentHourDTO>> {
		this.providerFacade.dispatch(ProviderActions.LoadResourceAbsences({payload: {resourceId: id}}));

		return this.readResourceAbsences$();
	}

	public readResourceAbsences$(): Observable<Array<AbsentHourDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadResourceAbsencesComplete, ProviderActions.LoadResourceAbsencesFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadResourceAbsencesFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createResourceAbsence$(id: number, absenceHours: AbsentHourDTO): Observable<AbsentHourDTO> {
		this.providerFacade.dispatch(ProviderActions.CreateResourceAbsence({payload: {resourceId: id, absenceHours: absenceHours}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateResourceAbsenceComplete, ProviderActions.CreateResourceAbsenceFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateResourceAbsenceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public patchResourceAbsence$(id: number, absenceHours: AbsentHourDTO): Observable<AbsentHourDTO> {
		this.providerFacade.dispatch(ProviderActions.PatchResourceAbsence({payload: {id: id, absenceHours: absenceHours}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.PatchResourceAbsenceComplete, ProviderActions.PatchResourceAbsenceFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.PatchResourceAbsenceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteResourceAbsence$(id: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteResourceAbsence({payload: id}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteResourceAbsenceComplete, ProviderActions.DeleteResourceAbsenceFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteResourceAbsenceFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadResourceOnlineHours$(id: number): Observable<Array<BookingHoursDTO>> {
		this.providerFacade.dispatch(ProviderActions.LoadResourceOnlineHours({payload: {resourceId: id}}));

		return this.readResourceOnlineHours$();
	}

	public readResourceOnlineHours$(): Observable<Array<BookingHoursDTO>> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadResourceOnlineHoursComplete, ProviderActions.LoadResourceOnlineHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadResourceOnlineHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createResourceOnlineHours$(id: number, onlineBookingHours: OnlineBookingHourDTO): Observable<OnlineBookingHourDTO> {
		this.providerFacade.dispatch(ProviderActions.CreateResourceOnlineHours({
			payload: {
				resourceId: id, onlineBookingHours: onlineBookingHours
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateResourceOnlineHoursComplete, ProviderActions.CreateResourceOnlineHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateResourceOnlineHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public patchResourceOnlineHours$(id: number, onlineBookingHours: OnlineBookingHourDTO): Observable<OnlineBookingHourDTO> {
		this.providerFacade.dispatch(ProviderActions.PatchResourceOnlineHours({payload: {id: id, onlineBookingHours: onlineBookingHours}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.PatchResourceOnlineHoursComplete, ProviderActions.PatchResourceOnlineHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.PatchResourceOnlineHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}


	public deleteResourceOnlineHours$(id: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteResourceOnlineHours({payload: id}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteResourceOnlineHoursComplete, ProviderActions.DeleteResourceOnlineHoursFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteResourceOnlineHoursFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== CMS ========================================================== */

	public loadContentCMS(type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO): void {
		this.providerFacade.dispatch(ProviderActions.LoadContent({
			payload: {type: type, pageType: pageType}
		}));
	}

	//public getContentCMS$(): Observable<any> {
	//	return this.actionsSubject$.pipe(
	//		ofType(ProviderActions.LoadContentComplete, ProviderActions.LoadContentFailed),
	//		switchMap((action) => {
	//			if (action.type === ProviderActions.LoadContentFailed.type) {
	//				return throwError(action.error);
	//			}
	//			return of(action.payload);
	//		})
	//	);
	//}

	/** ============================================== Treatment ========================================================== */

	public loadTreatmentList$(): Observable<TreatmentDTO[]> {
		this.providerFacade.dispatch(ProviderActions.LoadTreatmentList());

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadTreatmentListComplete, ProviderActions.LoadTreatmentListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadTreatmentListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createTreatment$(treatment: TreatmentDTO) {
		this.providerFacade.dispatch(ProviderActions.CreateTreatment({payload: treatment}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateTreatmentComplete, ProviderActions.CreateTreatmentFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateTreatmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	public updateTreatment$(treatmentId: number, data: any): Observable<TreatmentDTO> {
		this.providerFacade.dispatch(ProviderActions.UpdateTreatment({
			payload: {id: treatmentId, body: data}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateTreatmentComplete, ProviderActions.UpdateTreatmentFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateTreatmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			}));
	}

	public deleteTreatment$(treatment: TreatmentDTO): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteTreatment({payload: treatment}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteTreatmentComplete, ProviderActions.DeleteTreatmentFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteTreatmentFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	// TODO: make this an effect ? Do we need this?
	//public searchTreatments(id: number, query: QuerySearchByExistingIdsDTO, minInputForSearch: number = 3): Observable<any> {
	//	if (!!query && query.q.trim().length >= minInputForSearch) {
	//		return this.treatmentsService.searchTreatments(id, query);
	//	}
	//}

	/** ============================================== Patient ========================================================== */

	public getPatientList$(payload?: { query?: string, page?: number, size?: number, sortFields?: Array<SortFieldWithDirectionDTO> }): Observable<PageablePatientsResponseDTO> {
		this.providerFacade.dispatch(ProviderActions.GetPatientList({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.GetPatientListComplete, ProviderActions.GetPatientListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.GetPatientListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadPatientDetails(id: number): void {
		this.providerFacade.dispatch(ProviderActions.LoadPatientDetails({payload: id}));
	}

	public getPatient$(): Observable<PatientDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadPatientDetailsComplete, ProviderActions.LoadPatientDetailsFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadPatientDetailsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadPatientAppointmentList(payload: { patientId: number, page: number, size: number }): void {
		this.providerFacade.dispatch(ProviderActions.LoadPatientAppointmentList({payload: payload}));
	}

	public readPatientAppointmentList$(): Observable<PageableAppointmentInfosResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadPatientAppointmentListComplete, ProviderActions.LoadPatientAppointmentListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadPatientAppointmentListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createPatient$(payload: PatientDTO): Observable<PatientDTO> {
		this.providerFacade.dispatch(ProviderActions.CreatePatient({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreatePatientComplete, ProviderActions.CreatePatientFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.CreatePatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public updatePatient$(payload: { id: number, data: any }): Observable<PatientDTO> {
		this.providerFacade.dispatch(ProviderActions.UpdatePatient({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdatePatientComplete, ProviderActions.UpdatePatientFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdatePatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getPatientGDPR$(patientList: number[]): Observable<PatientGdprInfoDTO> {
		this.providerFacade.dispatch(ProviderActions.GetPatientGdpr({payload: patientList}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.GetPatientGdprComplete, ProviderActions.GetPatientGdprFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === ProviderActions.GetPatientGdprFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deletePatient$(payload: {
		patientName: string, patientList: number[], email?: string,
		download?: boolean, xSmsSimulate?: boolean
	}): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeletePatient({payload: payload}));
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeletePatientComplete, ProviderActions.DeletePatientFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.DeletePatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public blockPatient$(payload: { id: number, block: boolean }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.BlockPatient({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.BlockPatientComplete, ProviderActions.BlockPatientFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.BlockPatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getMergePatientList$(patientList: number[]): Observable<GetPatientsResponseDTO> {
		this.providerFacade.dispatch(ProviderActions.GetMergePatientList({payload: patientList}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.GetMergePatientListComplete, ProviderActions.GetMergePatientListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.GetMergePatientListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public mergePatient$(payload: { newPatient: PatientDTO, oldPatientIds: number[] }): Observable<PatientDTO> {
		this.providerFacade.dispatch(ProviderActions.MergePatient({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.MergePatientComplete, ProviderActions.MergePatientFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.MergePatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== User Management ========================================================== */

	public loadUserRequestList(): void {
		this.providerFacade.dispatch(ProviderActions.LoadUserRequestList());
	}

	public getUserRequestList$(): Observable<RegistrationRequestDTO[]> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadUserRequestListComplete, ProviderActions.LoadUserRequestListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadUserRequestListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadUserList(request: PageableUsersRequestDTO, roles: Array<UserRoleEnumDTO>): void {
		this.providerFacade.dispatch(ProviderActions.LoadUserList({payload: {...request, roles: roles}}));
	}

	public getUserList$(): Observable<PageableUsersResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadUserListComplete, ProviderActions.LoadUserListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadUserListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public createRegistrationRequest$(payload: CreateRegistrationRequestBodyDTO): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.CreateRegistrationRequest({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.CreateRegistrationRequestComplete, ProviderActions.CreateRegistrationRequestFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.CreateRegistrationRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resendMailForRequest$(registrationRequest: RegistrationRequestDTO): Observable<RegistrationRequestDTO> {
		this.providerFacade.dispatch(ProviderActions.ResendVerificationMail({
			payload: {
				...registrationRequest, status: RegisterStatusEnumDTO.EMAILNOTVERIFIED
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ResendVerificationMailComplete, ProviderActions.ResendVerificationMailFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.ResendVerificationMailFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteRegistrationRequest$(id: number): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteRegistrationRequest({payload: id}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteRegistrationRequestComplete, ProviderActions.DeleteRegistrationRequestFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteRegistrationRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteUser$(payload: { username: string, personId?: number }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.DeleteUser({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.DeleteUserComplete, ProviderActions.DeleteUserFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.DeleteUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadUser(username: string): void {
		this.providerFacade.dispatch(ProviderActions.LoadUser({payload: username}));
	}

	public responseUser(user: UserDTO): void {
		this.providerFacade.dispatch(ProviderActions.LoadUserComplete({payload: user}));
	}

	public getUser$(): Observable<UserDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadUserComplete, ProviderActions.LoadUserFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public updateUser$(payload: { username: string, data: UserDTO }): Observable<UserDTO> {
		this.providerFacade.dispatch(ProviderActions.UpdateUser({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.UpdateUserComplete, ProviderActions.UpdateUserFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.UpdateUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resetPassword$(payload: PasswordResetRequestDTO): Observable<PasswordResetResponseDTO> {
		this.providerFacade.dispatch(ProviderActions.ResetPassword({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.ResetPasswordComplete, ProviderActions.ResetPasswordFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.ResetPasswordFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** ============================================== Patient Waiting List ========================================================== */

	public loadPageableWaitingList(payload: PageableWaitingListsRequestDTO): void {
		this.providerFacade.dispatch(ProviderActions.LoadPageableWaitingList({payload: payload}));
	}

	public getPageableWaitingList$(): Observable<PageableWaitingListsResponseDTO> {
		return this.actionsSubject$.pipe(
			ofType(ProviderActions.LoadPageableWaitingListComplete, ProviderActions.LoadPageableWaitingListFailed),
			switchMap((action) => {
				if (action.type === ProviderActions.LoadPageableWaitingListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getWaitingListBookingStatus$(payload: Array<number>): Observable<Array<WaitingListBookingStatusResponseDTO>> {
		this.providerFacade.dispatch(ProviderActions.GetWaitingListBookingStatus({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.GetWaitingListBookingStatusComplete, ProviderActions.GetWaitingListBookingStatusFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.GetWaitingListBookingStatusFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public patchWaitingListRequest$(requestId: number, data: { [key: string]: any; }): Observable<any> {
		this.providerFacade.dispatch(ProviderActions.PatchWaitingListRequest({payload: {requestId: requestId, data: data}}));

		return this.actionsSubject$.pipe(
			ofType(ProviderActions.PatchWaitingListRequestComplete, ProviderActions.PatchWaitingListRequestFailed),
			take(1),
			switchMap((action) => {
				if (action.type === ProviderActions.PatchWaitingListRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public clearState(): void {
		this.providerFacade.dispatch(ProviderActions.ClearState());
	}

	public clearState$(): Observable<boolean> {
		this.providerFacade.dispatch(ProviderActions.ClearState());
		return scheduled([true], asyncScheduler);
	}

	public error$(): Observable<any> {
		return this.providerFacade.error$;
	}
}
