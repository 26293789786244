import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { AppUtils } from '@gp-angular/shared/utils';

@Component({
  selector: 'gp-angular-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogWidgetComponent extends AbstractComponent {

	@Output() public onToast: EventEmitter<any> = new EventEmitter<any>();

	private readonly styleButtonFloating: string = `position:fixed; border-radius:0 0 4px; background:#4038aa; color:#fff; padding:8px; top:0; right:16px;`;

	private readonly styleButtonSticky: string = `background:#4038aa; color:#fff; padding:8px; margin:auto`;

	private _portalLink = this._environment.endpoints.portal;

	public widget = true;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		@Inject(DOCUMENT) private document: Document,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogRef: MatDialogRef<DialogWidgetComponent>,
		private translateService: TranslateService,
		private sanitizer: DomSanitizer
	) {
		super();
	}

	public close() {
		this.dialogRef.close(true);
	}

	public displayWidgetCode(widget: boolean): any {
		const IFRAME = `<span class="script">
			&lt;<span class="name">script
				<span class="attr"> src</span>=<span class="value">"${this.getIframeScriptUrl()}"</span>
				</span>
				<span class="attr">async</span>
				<span class="attr">defer</span>&gt;&lt;<span class="name">/script</span>&gt;
			<br>
			<span class="no-code">&lt;/head&gt;</span>
			<br>
			<span class="no-code">&lt;body&gt;</span>
			<br>
			&thinsp;&lt;<span class="name">div <span class="attr">id</span>=<span class="value">"widget-booking"</span> <span class="attr">data-id</span>=<span class="value">"${this.data.widgetToken}"</span></span>&gt;&lt;<span class="name">/div</span>&gt;
			<br>
			<span class="no-code">&lt;/body&gt;</span>
		`;
		const WIDGET = `<span class="script">
			&lt;<span class="name">script
				<span class="attr"> src</span>=<span class="value">"${this.getWidgetScriptUrl()}"</span>
				</span>
				<span class="attr">id</span>=<span class="value">"noventi-widget-appointment"</span>
				<span class="attr">async</span>
				<span class="attr">defer</span>&gt;&lt;<span class="name">/script</span>&gt;
			<br>
			<span class="no-code">&lt;/head&gt;</span>
			<br>
			<span class="no-code">&lt;body&gt;</span>
			<br>
			&lt;<span class="name">noventi-widget-appointment <span class="attr">profile</span>=<span class="value">"${this.data.widgetToken}"</span></span>&gt;&lt;<span class="name">/noventi-widget-appointment</span>&gt;
			<br>
			<span class="no-code">&lt;/body&gt;</span>
			<br><br><br>
			<span class="comment">// ${this.translateService.instant('App.General.Color')}:</span>
			<br>&lt;<span class="name">noventi-widget-appointment <span class="attr">profile</span>=<span class="value">"${this.data.widgetToken}"</span>
			<span class="attr">primaryColor</span>=<span class="value">"#5a4ee6"</span>
			<span class="attr">secondaryColor</span>=<span class="value">"#00d2a5"</span><!--
		--></span>&gt;&lt;<span class="name">/noventi-widget-appointment</span>&gt;
		`;
		return this.sanitizer.bypassSecurityTrustHtml(widget ? WIDGET : IFRAME);
	}

	public displayFloatingButtonCode(): any {
		return this.sanitizer.bypassSecurityTrustHtml(`<span class="script">
		&lt;<span class="name">a</span> <span class="attr"> href</span>=<span class="value">"${this.data.profileLink}"</span>
		<span class="attr">style=<span class="value">"${this.styleButtonFloating}"</span></span>&gt;<!--
		-->${this.translateService.instant('Modal.Provider.Widget.Content_Button_Text')}<!--
		-->&lt;<span class="name">/a</span>&gt;
		`);
	}

	public displayStickyButtonCode(): any {
		return this.sanitizer.bypassSecurityTrustHtml(`<span class="script">
		&lt;<span class="name">a</span> <span class="attr"> href</span>=<span class="value">"${this.data.profileLink}"</span>
		<span class="attr">style=<span class="value">"${this.styleButtonSticky}"</span></span>&gt;<!--
		-->${this.translateService.instant('Modal.Provider.Widget.Content_Button_Text')}<!--
		-->&lt;<span class="name">/a</span>&gt;
		`);
	}

	public copy(type: string) {
		switch (type) {
			case 'iframe':
				AppUtils.copy(this.document, this._htmlIframeCode()).then((next) => {
					this.toast(next ? 'Toast.Script_Copy_Success' : 'Toast.Script_Copy_Failed',
						next ? 'success' : 'error');
				});
				break;
			case 'widget':
				AppUtils.copy(this.document, this._htmlWidgetCode()).then((next) => {
					this.toast(next ? 'Toast.Script_Copy_Success' : 'Toast.Script_Copy_Failed',
						next ? 'success' : 'error');
				});
				break;
			case 'sticky':
				AppUtils.copy(this.document, this.htmlButtonCode('sticky')).then((next) => {
					this.toast(next ? 'Toast.Button_Copy_Success' : 'Toast.Button_Copy_Failed',
						next ? 'success' : 'error');
				});
				break;
			case 'floating':
				AppUtils.copy(this.document, this.htmlButtonCode('floating')).then((next) => {
					this.toast(next ? 'Toast.Button_Copy_Success' : 'Toast.Button_Copy_Failed',
						next ? 'success' : 'error');
				});
				break;
			default:
				AppUtils.copy(this.document, this.data.profileLink).then((next) => {
					this.toast(next ? 'Toast.Link_Copy_Success' : 'Toast.Link_Copy_Failed',
						next ? 'success' : 'error');
				});
				break;
		}
	}

	private _htmlIframeCode(): string {
		return '<script src="' + this.getIframeScriptUrl() + '" async defer></script>'
			   + '<div id="widget-booking" data-id="' + this.data.widgetToken + '"></div>';
	}

	private _htmlWidgetCode(): string {
		return '<script id="noventi-widget-appointment" src="' + this.getWidgetScriptUrl() + '" async defer></script>'
			   + '<noventi-widget-appointment profile="' + this.data.widgetToken + '"></noventi-widget-appointment>';
	}

	private htmlButtonCode(type: string): string {
		const code = !!type && type === 'floating' ? this.styleButtonFloating : this.styleButtonSticky;
		return `<a href= "${this.data.profileLink}" style="${code}">${this.translateService.instant('Modal.Provider.Widget.Content_Button_Text')}</a>`;
	}

	private getIframeScriptUrl(): string {
		return `${this._portalLink}/booking-widget.js`;
	}

	private getWidgetScriptUrl(): string {
		return `${this._portalLink.replace('://', '://widget.')}/noventi-widget-appointment.js`;
	}

	public toast(content: string, type: string) {
		this.onToast.emit({content: content, type: type})
	}
}
