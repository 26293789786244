/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Type of entity
 */
export type EntityTypeEnumDTO = 'CARE_PROVIDER' | 'PATIENT';

export const EntityTypeEnumDTO = {
    CAREPROVIDER: 'CARE_PROVIDER' as EntityTypeEnumDTO,
    PATIENT: 'PATIENT' as EntityTypeEnumDTO
};

