import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { PAGE } from '@gp-angular/shared/schema';
import { FormUtils, FormValidators, ModalUtils, pattern } from '@gp-angular/shared/utils';
import { DialogContentComponent, DialogService } from '@gp-angular/service/dialog';
import { MessageService } from '@gp-angular/service/message';
import { UserService } from '@gp-angular/service/user';
import { ProviderService } from '@gp-angular/service/provider';
import { KeyValueDTO, PersonDTO } from '@noventi/gp-platform/care-providers';
import { ContactCategoryEnumDTO } from '@noventi/gp-platform/emailing';
import { UserDTO } from '@noventi/gp-platform/users';
import { ContactEnum } from './contact.enum';

@Component({
	selector: 'gp-angular-contact',
	templateUrl: './contact.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class ContactComponent extends AbstractComponent implements OnInit{

	@ViewChild('contactForm') private _form: NgForm;

	public readonly PAGE = PAGE;

	public readonly ContactCategoryEnumDTO = ContactCategoryEnumDTO;

	public form: FormGroup;

	public loading: boolean;

	public listKeySalutation$: Observable<KeyValueDTO[]> = this.providerService.getKeysOfSalutation$();

	public ncnClass: string;

	private _isLoggedIn: boolean = this.userService.isLoggedIn();

	private _user: UserDTO;

	private _provider: PersonDTO;

	constructor(
		public messageService: MessageService,
		private fb: FormBuilder,
		private dialogService: DialogService,
		private userService: UserService,
		private providerService: ProviderService,
		private translateService: TranslateService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super();
		this.loading = false;

		const VALIDATORS = [Validators.required, FormValidators.noWhitespace];

		this.form = this.fb.group({
			category: [undefined, [...VALIDATORS]],
			subject: [undefined, [...VALIDATORS, Validators.maxLength(200)]],
			message: [undefined, [...VALIDATORS, Validators.maxLength(3000)]],
			gender: [undefined, [...VALIDATORS]],
			lastName: [undefined, [...VALIDATORS, Validators.maxLength(60)]],
			firstName: [undefined, [...VALIDATORS, Validators.maxLength(60)]],
			email: [undefined, [...VALIDATORS, Validators.maxLength(200), Validators.pattern(pattern('email'))]],
			telephone: [undefined, [...VALIDATORS, Validators.maxLength(21), Validators.pattern(pattern('phoneWithoutPrefix'))]],
			practiceName: [undefined, [...VALIDATORS, Validators.maxLength(45)]],
			noventiCustomerNumber: [undefined, [...VALIDATORS, Validators.maxLength(45)]],
		});
	}

	public ngOnInit(): void {
		if (this._isLoggedIn) {
			super.addSubscription(combineLatest([this.providerService.readProvider$(), this.userService.loadUser$()])
				.pipe(
					filter(([provider, user]) => !!provider && !!user)
				)
				.subscribe(([provider, user]) => {
					this._user = user;
					this._provider = provider;

					this.form.get('lastName').disable();
					this.form.get('firstName').disable();
					this.form.get('email').disable();
					this.form.get('telephone').disable();
					this.form.get('practiceName').disable();
					this.form.get('noventiCustomerNumber').disable();

					this.ncnClass = ' mat-form-field-disabled read-only';

					this._patchForm();
					this._loading();
				}));
		}
	}

	private canDeactivate(): Observable<boolean> {
		return this.messageService.showFormGuard(this.form && this.form.dirty);
	}

	public sendDisabled() {
		this.dialogService.showInvalidControls(this.form, ContactEnum);
	}

	public send() {
		this._loading(true);

		super.addSubscription(this.userService.contact({type: 'CONTACT', variables: FormUtils.getControlsAsPredefinedEmailVariables(this.form)})
			.pipe(
				finalize(() => this._loading())
			)
			.subscribe(
				() => {
					const CONFIG: MatDialogConfig = {
						...ModalUtils.config(),
						closeOnNavigation: false,
						data: {
							title: {
								default: this.translateService.instant('Modal.Contact.Title.Default'),
								s: this.translateService.instant('Modal.Contact.Title.Small'),
							},
							content: this.translateService.instant('Modal.Contact.Text'),
							actions: [{
								title: this.translateService.instant('App.General.Ok'),
								closeButton: true
							}]
						}
					};
					this.dialogService.openDialog(DialogContentComponent, CONFIG);

					this._form.resetForm();
					this._patchForm();
				},
				() => this.messageService.toastError(this.translateService.instant('Toast.Changes_Not_Saved'))));
	}

	private _loading(value: boolean = false) {
		this.loading = value;
		this.changeDetectorRef.markForCheck();
	}

	private _patchForm(): void {
		if (this._isLoggedIn) {
			this.form.patchValue({
				firstName: this._user.firstName,
				lastName: this._user.lastName,
				email: this._user.email,
				telephone: this._user.phone,
				practiceName: this._provider.practiceName,
				noventiCustomerNumber: this._provider.noventiCustomerNumber,
			});
		}
	}
}
