import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GpHttpUrlEncodingCodec } from './encoding';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { CustomHttpParameterCodec } from '../encoder';

export class RequestOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?: HttpParams;
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	protected basePath = 'http://localhost:8512';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	public defaultHttpEncoder = new GpHttpUrlEncodingCodec();

	constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	/**
	 * @param consumes string[] mime-types
	 * @return true: consumes contains 'multipart/form-data', false: otherwise
	 */
	// private canConsumeForm(consumes: string[]): boolean {
	// 	const form = 'multipart/form-data';
	// 	for (const consume of consumes) {
	// 		if (form === consume) {
	// 			return true;
	// 		}
	// 	}
	// 	return false;
	// }

	/**
	 * Login method
	 * @param user: Object of username and password
	 */
	public login(user: { username: string, password: string, device?: any }): Observable<any> {

		if (user === null || user === undefined) {
			throw new Error('No user sent.');
		}

		const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa('admin-ui:'));

		let body = new HttpParams({encoder: this.defaultHttpEncoder});
		body = body.set('username', user.username);
		body = body.set('password', user.password);
		body = body.set('grant_type', 'password');

		return this.httpClient.post(`${this.configuration.basePath}/oauth/token`,
			body,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers
			}
		);
	}

	/**
	 * Login MFA method
	 * @param payload: Object of mfa_token, mfa_code and token
	 */
	public loginMfa(payload: { mfa_token: string, mfa_code: string, token: string, trusted: boolean }): Observable<any> {

		if (payload === null || payload === undefined) {
			throw new Error('No user sent.');
		}

		const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa('admin-ui:'));

		let body = new HttpParams({encoder: this.defaultHttpEncoder});
		body = body.set('mfa_token', payload.mfa_token);
		body = body.set('mfa_code', payload.mfa_code);
		body = body.set('token', payload.token);
		body = body.set('grant_type', 'mfa');
		body = body.set('trusted', String(payload.trusted));

		return this.httpClient.post(`${this.configuration.basePath}/oauth/token`,
			body,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers
			}
		);
	}

	/**
	 * Refresh token method
	 * @param refreshToken
	 */
	public refreshAuthToken(refreshToken?: string): Observable<any> {

		//if (refreshToken === null || refreshToken === undefined) {
		//	throw new Error('Refresh Token not sent.');
		//}

		const headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa('admin-ui:'));

		let body = new HttpParams({encoder: this.defaultHttpEncoder});
		body = body.set('grant_type', 'refresh_token');

		return this.httpClient.post(`${this.configuration.basePath}/oauth/token`,
			body,
			{
				withCredentials: this.configuration.withCredentials,
				headers: headers
			}
		);
	}

	/**
	 * Logout method
	 */
	public logout(): Observable<any> {
		const body = new HttpParams({encoder: this.defaultHttpEncoder});
		return this.httpClient.post(`${this.configuration.basePath}/oauth/revoke`,
			body,
		);
	}
}
