/**
 * Gesundheitsportal - Patients REST API
 * Gesundheitsportal Patients Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Parent class for all data transfer objects
 */
export interface BaseDTO { 
    /**
     * Id of the entity
     */
    id?: number;
}

