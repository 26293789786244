import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { select, Store, Action } from '@ngrx/store';

import * as fromAdmin from './admin.reducer';
import * as AdminSelectors from './admin.selectors';

@Injectable()
export class AdminFacade {
	providerListFilter$ = this.store.pipe(select(AdminSelectors.getProviderListFilter));
	userListFilter$ = this.store.pipe(select(AdminSelectors.getUserListFilter));
	pageEventForUserList$ = this.store.pipe(select(AdminSelectors.getPageEventForUserList));
	loading$ = this.store.pipe(select(AdminSelectors.getAdminLoading));
	error$ = this.store.pipe(select(AdminSelectors.getAdminError));

	constructor(private store: Store<fromAdmin.AdminPartialState>, private actions$: Actions) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
