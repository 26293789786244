/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * MFA validation code Request Data Transfer Object
 */
export interface MfaValidationCodeRequestDTO { 
    /**
     * MFA token
     */
    token?: string;
    /**
     * MFA phone number
     */
    phoneNumber?: string;
}

