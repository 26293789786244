/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Opening Hours Type Enum Data Transfer Object
 */
export type OpenHoursTypeEnumDTO = 'MORNING' | 'AFTERNOON' | 'CUSTOM';

export const OpenHoursTypeEnumDTO = {
    MORNING: 'MORNING' as OpenHoursTypeEnumDTO,
    AFTERNOON: 'AFTERNOON' as OpenHoursTypeEnumDTO,
    CUSTOM: 'CUSTOM' as OpenHoursTypeEnumDTO
};

