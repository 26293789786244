import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';

@NgModule({
	imports: [CommonModule]
})
export class ServiceAuthModule {
	static forRoot(): ModuleWithProviders<ServiceAuthModule> {
		return {
			ngModule: ServiceAuthModule,
			providers: [AuthService]
		};
	}
}
