import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUser from './+state/user.reducer';
import { UserEffects } from './+state/user.effects';
import { UserFacade } from './+state/user.facade';
import { UserService } from './user.service';
import * as fromAccount from './+state/account.reducer';
import { AccountEffects } from './+state/account.effects';
import { AccountFacade } from './+state/account.facade';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromUser.USER_FEATURE_KEY, fromUser.reducer),
		EffectsModule.forFeature([UserEffects]),
		StoreModule.forFeature( fromAccount.ACCOUNT_FEATURE_KEY, fromAccount.reducer),
		EffectsModule.forFeature([AccountEffects]),
	],
	providers: [UserFacade, AccountFacade],
})
export class ServiceUserModule {
	static forRoot(): ModuleWithProviders<ServiceUserModule> {
		return {
			ngModule: ServiceUserModule,
			providers: [UserService],
		};
	}
}
