/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CalendarSettingsDTOAllOf { 
    /**
     * Duration of a slot
     */
    slotDuration?: number;
    /**
     * Setting for what is the earliest hour to show in calendar
     */
    calendarStartTime?: string;
    /**
     * Setting for what is the latest hour to show in calendar
     */
    calendarEndTime?: string;
    /**
     * Setting for what color should the calendar be for online bookable hours
     */
    calendarBusinessHoursColor?: string;
    /**
     * Setting for what color should the calendar be for the office opening hours
     */
    calendarOpenHoursColor?: string;
    calendarDaysVisible?: Array<number>;
    /**
     * Setting for what time intervals should be shown in calendar
     */
    displayInterval?: number;
}

