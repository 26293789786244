import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedMatFormModule } from '@gp-angular/shared/module';
import { SharedDirectiveModule } from '@gp-angular/shared/directive';

import { AvatarComponent } from './avatar/avatar.component';
import { BufferTimeComponent } from './buffer-time/buffer-time.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ContactComponent } from './contact/contact.component';
import { ContentDisplayComponent } from './content-display/content-display.component';
import { ErrorComponent } from './error/error.component';
import { LoaderComponent } from './loader/loader.component';
import { LogoComponent } from './logo/logo.component';
import { ProviderProfileStatusComponent } from './provider-profile-status/provider-profile-status.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SearchingComponent } from './searching/searching.component';
import { CountryPrefixComponent } from './country-prefix/country-prefix.component';
import { ContactShortComponent } from './contact-short/contact-short.component';
import { ToggleSelectComponent } from './toggle-select/toggle-select.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
	declarations: [
		AvatarComponent,
		BufferTimeComponent,
		ColorPickerComponent,
		ContactComponent,
		ContactShortComponent,
		ContentDisplayComponent,
		CountryPrefixComponent,
		ErrorComponent,
		LoaderComponent,
		LogoComponent,
		ProviderProfileStatusComponent,
		RedirectComponent,
		SearchingComponent,
		RedirectComponent,
		ToggleSelectComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,

		SharedMatFormModule,
		SharedDirectiveModule,

		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
		MatTooltipModule,
		MatSlideToggleModule,
	],
	exports: [
		AvatarComponent,
		BufferTimeComponent,
		ColorPickerComponent,
		ContactComponent,
		ContactShortComponent,
		CountryPrefixComponent,
		LoaderComponent,
		LogoComponent,
		ProviderProfileStatusComponent,
		RedirectComponent,
		SearchingComponent,
		ToggleSelectComponent,

		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatMenuModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
	]
})
export class SharedComponentModule {
	static forRoot(): ModuleWithProviders<SharedComponentModule> {
		return {
			ngModule: SharedComponentModule
		};
	}
}
