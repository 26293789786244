/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Allowed time slots Enum Data Transfer Object
 */
export type AllowedTimeSlotsEnumDTO = 10 | 15 | 20 | 25 | 30 | 45 | 50 | 60 | 75 | 90 | 100;

export const AllowedTimeSlotsEnumDTO = {
    NUMBER_10: 10 as AllowedTimeSlotsEnumDTO,
    NUMBER_15: 15 as AllowedTimeSlotsEnumDTO,
    NUMBER_20: 20 as AllowedTimeSlotsEnumDTO,
    NUMBER_25: 25 as AllowedTimeSlotsEnumDTO,
    NUMBER_30: 30 as AllowedTimeSlotsEnumDTO,
    NUMBER_45: 45 as AllowedTimeSlotsEnumDTO,
    NUMBER_50: 50 as AllowedTimeSlotsEnumDTO,
    NUMBER_60: 60 as AllowedTimeSlotsEnumDTO,
    NUMBER_75: 75 as AllowedTimeSlotsEnumDTO,
    NUMBER_90: 90 as AllowedTimeSlotsEnumDTO,
    NUMBER_100: 100 as AllowedTimeSlotsEnumDTO
};

