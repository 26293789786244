import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { map, retryWhen, shareReplay, take } from 'rxjs/operators';
import { IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { PAGE, ToastPositionEnum, ToastSchema } from '@gp-angular/shared/schema';
import { TranslateService } from '@ngx-translate/core';
import { AppUtils, pattern } from '@gp-angular/shared/utils';
import { MessageStatusEnumDTO } from '@noventi/gp-platform/online-appointments';
import { ToastComponent } from './toast/toast.component';

@Injectable()
export class MessageService {

	private _toastPositionClass: string = ToastPositionEnum.SMALL_BOTTOM;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private toastrService: ToastrService
	) {}

	private static toastTitle(option: ToastSchema): string {
		return !!option && !!option.title ? option.title : undefined;
	}

	public error(control: AbstractControl, placeholder?: string) {
		let label = !!placeholder ? placeholder : this.translateService.instant('App.General.Data');
		let error = '';

		if (control.errors) {

			if (control.errors.textError) {
				error = control.errors.textError;
			}

			if (control.errors.required || control.errors.whitespace) {
				return this.translateService.instant('Error.Required', { label });
			}
			if (control.errors.minlength) {
				error = this.translateService.instant('Error.Pattern_Minlength',
					{ label, number: control.errors.minlength.requiredLength });
			}
			if (control.errors.maxlength) {
				error = this.translateService.instant('Error.Pattern_Maxlength',
					{ label, number: control.errors.maxlength.requiredLength });
			}

			if (control.errors.pattern) {
				const type: string = control.errors.pattern.requiredPattern;

				switch (type) {
					case 'email':
					case '^' + pattern('email'):
						label = !!placeholder ? placeholder : this.translateService.instant('App.General.Email');
						error = this.translateService.instant('Error.Pattern_Not_Correct', {label});
						break;
					case pattern('url'):
						error = this.translateService.instant('Error.Pattern_Invalid_URL');
						break;
					case pattern('username'):
						error = this.translateService.instant('Error.Username_Pattern');
						break;
					case '^' + pattern('phoneDE') + '$':
						error = this.translateService.instant('Error.Pattern_SMS_International_Code');
						break;
					case '^' + pattern('' +
						 'phoneDEV') + '$':
						error = this.translateService.instant('Error.Pattern_SMS_International_Code');
						break;
					case '^' + pattern('' +
						 'phoneWithoutPrefix') + '$':
						error = this.translateService.instant('Error.Pattern_SMS_International_Code');
						break;
					default:
						error = this.translateService.instant('Error.No_Valid', {label});
						break;
				}
			}

			if (control.errors.invalid) {
				error = this.translateService.instant('Error.Invalid', { label });
			}

			if (control.errors.appointmentConflict) {
				error = this.translateService.instant('Error.Calendar_Appointment_Overlap');
			}

			if (control.errors.invalidPassword) {
				error = this.translateService.instant('Error.Password_Invalid');
			}

			if (control.errors.usernameExists) {
				error = this.translateService.instant('Error.Username_Exists');
			}

			if (control.errors.emailExists) {
				error = this.translateService.instant('Error.Email_Exists');
			}

			if (control.errors.notSamePasswords) {
				error = this.translateService.instant('Error.Not_Same_Passwords');
			}

			if (control.errors.expertiseProfessionsLimit) {
				error = this.translateService.instant('Pages.Provider.Expertise.Profession_Search_Error');
			}

			if (control.errors.matchZipCityObject) {
				error = this.translateService.instant('Error.Match_No_Valid') + ' ' +
						this.translateService.instant('App.General.Postcode') + ' oder ' +
						this.translateService.instant('App.General.City') + '.';
			}

			// Show error only on last control
			if (control.errors.login_failed || control.errors.match_fields_error) {
				const controls = control.parent.controls;
				const keys = Object.keys(controls);
				if (controls[keys[keys.length - 1]] === control) {

					if (control.errors.login_failed) {
						error = this.translateService.instant('Error.Credential_Failed');
					} else if (control.errors.match_fields_error) {
						error = this.translateService.instant('Error.Not_Same_Passwords');
					}

				}
			}

		}

		return error;
	}

	/** Show successful toast */
	public toastSuccess(message: string, option?: ToastSchema) {
		return this.toastrService.success(message, MessageService.toastTitle(option), this.toastConfigCustom(option));
	}

	/** Show error toast */
	public toastError(message: string, option?: ToastSchema) {
		return this.toastrService.error(message, MessageService.toastTitle(option), this.toastConfigCustom(option));
	}

	/** Show warning toast */
	public toastWarning(message: string, option?: ToastSchema) {
		return this.toastrService.warning(message, MessageService.toastTitle(option), this.toastConfigCustom(option));
	}

	/** Show info toast */
	public toastInfo(message: string, option?: ToastSchema) {
		return this.toastrService.info(message, MessageService.toastTitle(option), this.toastConfigCustom(option));
	}

	public showToast(message: any, option?: ToastSchema) {
		return this.toastrService.show(message, MessageService.toastTitle(option), this.toastConfigCustom(option));
	}

	public clearToast() {
		try {
			this.toastrService.clear();
		} catch {
		}
	}

	public showFormGuard(status: boolean, message?: string): Observable<boolean> {
		if (status) {
			const confirmation = window.confirm(message || 'Änderungen verwerfen und beenden?');
			return of(confirmation);
		}
		return of(true);
	}

	public toastConfigContainer(overlayContainer: ToastContainerDirective) {
		try {
			this.toastrService.overlayContainer = overlayContainer;
			this._toastPositionClass = !overlayContainer ? ToastPositionEnum.SMALL_BOTTOM : ToastPositionEnum.LARGE_TOP;
		} catch {
		}
	}

	private toastConfigCustom(option: ToastSchema): Partial<IndividualConfig> {
		const closeButton = !!option && !!option.closeButton ? option.closeButton : false;
		const enableHtml = !!option && !!option.enableHtml ? option.enableHtml : false;
		const tapToDismiss = !!option && !!option.tapToDismiss ? option.tapToDismiss : true;
		const disableTimeOut = !!option && !!option.disableTimeOut ? option.disableTimeOut : false;

		let config = {
			closeButton,
			enableHtml,
			tapToDismiss,
			disableTimeOut
		};

		if (!!option && !!option.closeButton) {
			config = Object.assign({}, config, { tapToDismiss: true, disableTimeOut: true });
		}

		if (!!option && !!option.positionClass) {
			config = Object.assign({}, config, { positionClass: option.positionClass });
		} else {
			config = Object.assign({}, config, { positionClass: this._toastPositionClass });
		}

		if (!!option && !!option.duration) {
			config = Object.assign({}, config, { timeOut: option.duration });
		}

		if (!!option && !!option.toastClass) {
			config = Object.assign({}, config, { toastClass: option.toastClass });
		}

		if (!!option && !!option.toastComponent) {
			config = Object.assign({}, config, { toastComponent: option.toastComponent });
		}

		return config;
	}

	/** Notification */
	public toastNewProviderMessage$(status: MessageStatusEnumDTO, name: string, pid: number): Observable<{clicked: boolean, pid: number}> {
		const OPTION: ToastSchema = {
			enableHtml: true,
			tapToDismiss: false,
			disableTimeOut: true,
			positionClass: 'md-toast-bottom-center',
			toastClass: 'ngx-toastr flex-row',
			toastComponent: ToastComponent
		};

		const MESSAGE = `${this.translateService.instant('Toast.Provider.Message.Notification')}:
				${this.translateService.instant('Api.ProviderNotification.Appointment_Status.' + status)} (${name})`;

		if (window.location.pathname.indexOf(`${PAGE.PROVIDER.path}/${PAGE.PROVIDER_MESSAGE.path}`) < 0) {
			OPTION['title'] = this.translateService.instant('Toast.Provider.Message.Button_Open_Message');
			return this.toastrService.show(MESSAGE, MessageService.toastTitle(OPTION), this.toastConfigCustom(OPTION))
				.onAction
				.pipe(
					take(1),
					retryWhen(() => EMPTY),
					shareReplay(1),
					map((action) => ({clicked: action === OPTION.title, pid: pid}))
				);
		} else {
			this.toastrService.show(MESSAGE, MessageService.toastTitle(OPTION), this.toastConfigCustom(OPTION));
			return of({clicked: false, pid: pid});
		}
	}

	/** Password Expiration Date */
	public toastPED(days: number) {
		const option: ToastSchema = {
			enableHtml: true,
			tapToDismiss: false,
			disableTimeOut: true,
			positionClass: 'md-toast-bottom-center',
			toastClass: 'ngx-toastr flex-row no-icon',
			toastComponent: ToastComponent
		};
		option['title'] = this.translateService.instant('App.General.Ok');
		this.toastrService.error(
			this.translateService.instant('Toast.Password_Expiration_Date', {days: days}),
			MessageService.toastTitle(option),
			this.toastConfigCustom(option)
		);
	}

	public toastSmsSimulate(xSmsSimulate: boolean, code: string): void {
		if (xSmsSimulate) {
			this.toastInfo(
				`Der Validierungscode lautet ${code}.`,
				{closeButton: true}
			);
			AppUtils.copy(this.document, code).then();
		}
	}
}
