/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Filter Data Transfer Object
 */
export interface RevisionDTO { 
    /**
     * Revision Id
     */
    revisionId?: number;
    /**
     * Revision Type (create = 0, edited = 1, deleted = 2)
     */
    revisionType?: number;
    /**
     * Date of the create/update/delete operation
     */
    revisionDate?: string;
    /**
     * name of the user who edited the entry
     */
    modifiedBy?: string;
}

