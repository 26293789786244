import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { UserEntity } from './user.models';
import {
	MfaDTO, MfaPasswordVerificationDTO, MfaUpdateRequestDTO, MfaValidationCodeRequestDTO, MfaValidationCodeResponseDTO, NotificationDTO,
	PasswordResetDTO, PasswordResetRequestDTO, PasswordResetResponseDTO, PersonRoleResourcesDTO, SearchNotAssignedUserRequestDTO,
	StateSettingsDTO, TokenDTO, UserDTO, UserOnboardingDTO
} from '@noventi/gp-platform/users';
import { SendEmailResponseDTO, SendPredefinedEmailRequestDTO } from '@noventi/gp-platform/emailing';


export const Loading = createAction(
	'[User] Loading',
	props<{ loading: boolean }>()
);

export const Login = createAction(
	'[User] Login',
	props<{ payload: { username: string, password: string } }>()
);

export const LoginComplete = createAction(
	'[User] Login Complete',
	props<{ payload: UserEntity }>()
);

export const LoginFailed = createAction(
	'[User] Login Failed',
	props<{ error: HttpErrorResponse }>()
);

export const RefreshToken = createAction(
	'[User] Refresh Token'
);

export const RefreshTokenComplete = createAction(
	'[User] Refresh Token Complete',
	props<{ payload: UserEntity }>()
);

export const RefreshTokenFailed = createAction(
	'[User] Refresh Token Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetOnboarding = createAction(
	'[User] Set Onboarding',
	props<{ payload: UserOnboardingDTO }>()
);

export const GetOnboarding = createAction(
	'[User] Get Onboarding'
);

export const GetOnboardingComplete = createAction(
	'[User] Get Onboarding Complete',
	props<{ payload: UserOnboardingDTO }>()
);

export const GetOnboardingFailed = createAction(
	'[User] Get Onboarding Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResetEmailPassword = createAction(
	'[User] Reset Email Password',
	props<{ payload: PasswordResetRequestDTO }>()
);

export const ResetEmailPasswordComplete = createAction(
	'[User] Reset Email Password Complete',
	props<{ payload: PasswordResetResponseDTO }>()
);

export const ResetEmailPasswordFailed = createAction(
	'[User] Reset Email Password Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResetPasswordValidationToken = createAction(
	'[User] Reset Password Validation Token',
	props<{ payload: TokenDTO }>()
);

export const ResetPasswordValidationTokenComplete = createAction(
	'[User] Reset Password Validation Token Complete',
	props<{ payload: PasswordResetResponseDTO }>()
);

export const ResetPasswordValidationTokenFailed = createAction(
	'[User] Reset Password Validation Token Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResetPassword = createAction(
	'[User] Reset Password',
	props<{ payload: PasswordResetDTO }>()
);

export const ResetPasswordComplete = createAction(
	'[User] Reset Password Complete',
	props<{ payload: PasswordResetResponseDTO }>()
);

export const ResetPasswordFailed = createAction(
	'[User] Reset Password Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadUser = createAction(
	'[User] Load User'
);

export const LoadUserComplete = createAction(
	'[User] Load User Complete',
	props<{ payload: UserDTO }>()
);

export const LoadUserFailed = createAction(
	'[User] Load User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetDefaultPid = createAction(
	'[User] Set Default Pid',
	props<{ defaultPid: number }>()
);

export const Contact = createAction(
	'[User] Contact',
	props<{ payload: SendPredefinedEmailRequestDTO }>()
);

export const ContactComplete = createAction(
	'[User] Contact Complete',
	props<{ payload: SendEmailResponseDTO }>()
);

export const ContactFailed = createAction(
	'[User] Contact Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoginMFA = createAction(
	'[User] Login MFA',
	props<{ payload: { mfa_token: string, mfa_code: string, token: string, trusted: boolean } }>()
);

export const LoginMFAComplete = createAction(
	'[User] Login MFA Complete',
	props<{ payload: UserEntity }>()
);

export const LoginMFAFailed = createAction(
	'[User] Login MFA Failed',
	props<{ error: HttpErrorResponse }>()
);

export const MultiFactorAuthSendActivationCode = createAction(
	'[User] Multi Factor Auth Send Activation Code',
	props<{ payload: { mfaValidationCodeRequest: MfaValidationCodeRequestDTO, xSmsSimulate?: boolean } }>()
);

export const MultiFactorAuthSendActivationCodeComplete = createAction(
	'[User] Multi Factor Auth Send Activation Code Complete',
	props<{ payload: MfaValidationCodeResponseDTO }>()
);

export const MultiFactorAuthSendActivationCodeFailed = createAction(
	'[User] Multi Factor Auth Send Activation Code Failed',
	props<{ error: HttpErrorResponse }>()
);

export const MultiFactorAuthUpdate = createAction(
	'[User] Multi Factor Auth Update',
	props<{ payload: MfaUpdateRequestDTO }>()
);

export const MultiFactorAuthUpdateComplete = createAction(
	'[User] Multi Factor Auth Update Complete',
	props<{ payload: UserDTO }>()
);

export const MultiFactorAuthUpdateFailed = createAction(
	'[User] Multi Factor Auth Update Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetMfaData = createAction(
	'[User] Get MFA Data'
);

export const GetMfaDataComplete = createAction(
	'[User] Get MFA Data Complete',
	props<{ payload: MfaDTO }>()
);

export const GetMfaDataFailed = createAction(
	'[User] Get MFA Data Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ValidatePaswordMfa = createAction(
	'[User] Validate Password MFA',
	props<{ payload: MfaPasswordVerificationDTO }>()
);

export const ValidatePaswordMfaComplete = createAction(
	'[User] Validate Password MFA Complete',
	props<{ payload: any }>()
);

export const ValidatePaswordMfaFailed = createAction(
	'[User] Validate Password MFA Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LogoutAllDevices = createAction(
	'[User] Logout All Devices'
);

export const LogoutAllDevicesComplete = createAction(
	'[User] Logout All Devices Complete'
);

export const LogoutAllDevicesFailed = createAction(
	'[User] Logout All Devices Failed',
	props<{ error: HttpErrorResponse }>()
);

export const RemoveSavedDevices = createAction(
	'[User] Remove Saved Devices'
);

export const RemoveSavedDevicesComplete = createAction(
	'[User] Remove Saved Devices Complete',
	props<{ payload: any }>()
);

export const RemoveSavedDevicesFailed = createAction(
	'[User] Remove Saved Devices Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetUserAppSettings = createAction(
	'[User] Get User App Settings'
);

export const GetUserAppSettingsComplete = createAction(
	'[User] Get User App Settings Complete',
	props<{ payload: StateSettingsDTO }>()
);

export const GetUserAppSettingsFailed = createAction(
	'[User] Get User App Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateUserAppSettings = createAction(
	'[User] Update User App Settings',
	props<{ payload: StateSettingsDTO }>()
);

export const UpdateUserAppSettingsComplete = createAction(
	'[User] Update User App Settings Complete',
	props<{ payload: StateSettingsDTO }>()
);

export const UpdateUserAppSettingsFailed = createAction(
	'[User] Update User App Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadNotificationList = createAction(
	'[User] Load Notification List'
);

export const LoadNotificationListComplete = createAction(
	'[User] Load Notification List Complete',
	props<{ payload: Array<NotificationDTO> }>()
);

export const LoadNotificationListFailed = createAction(
	'[User] Load Notification List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const MarkNotificationAsRead = createAction(
	'[User] Mark Notification As Read',
	props<{ payload: number }>()
);

export const MarkNotificationAsReadComplete = createAction(
	'[User] Mark Notification As Read Complete',
	props<{ payload: NotificationDTO }>()
);

export const MarkNotificationAsReadFailed = createAction(
	'[User] Mark Notification As Read Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SearchUserFromConnectedInstitutions = createAction(
	'[User] Search User From Connected Institutions',
	props<{ payload: { username: string, personId: number, q: SearchNotAssignedUserRequestDTO } }>()
);

export const SearchUserFromConnectedInstitutionsComplete = createAction(
	'[User] Search User From Connected Institutions Complete',
	props<{ payload: Array<UserDTO> }>()
);

export const SearchUserFromConnectedInstitutionsFailed = createAction(
	'[User] Search User From Connected Institutions Failed',
	props<{ error: HttpErrorResponse }>()
);

export const AddUserToInstitution = createAction(
	'[User] Add User To Institution',
	props<{ payload: { username: string, personRoleResourcesDTO: PersonRoleResourcesDTO } }>()
);

export const AddUserToInstitutionComplete = createAction(
	'[User] Add User To Institution Complete',
	props<{ payload: any }>()
);

export const AddUserToInstitutionFailed = createAction(
	'[User] Add User To Institution Failed',
	props<{ error: HttpErrorResponse }>()
);

export const Logout = createAction(
	'[User] Logout',
	props<{ payload: boolean }>()
);

export const ClearState = createAction(
	'[User] Clear State'
);

export const Error = createAction(
	'[User] Error',
	props<{ error: HttpErrorResponse }>()
);
