import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

//import * as fromAdmin from './admin.reducer';
import * as AdminActions from './admin.actions';
import { AdminFacade } from './admin.facade';
import { AdminUtils } from '../admin.utils';
import { ignoreResultOnActions } from '@gp-angular/shared/utils';
import {
	ExpertiseAreaDTO, ExpertiseAreaService, MedicalServiceDTO, MedicalServiceService, PageablePersonHistoryResponseDTO,
	PageablePersonsResponseDTO, PageableRequestDTO, PersonService, ProfessionDTO, ProfessionService, RegistrationRequestDTO, UsagesDTO
} from '@noventi/gp-platform/care-providers';
import { PageableUsersRequestDTO, PageableUsersResponseDTO, UserDTO, UserService } from '@noventi/gp-platform/users';

@Injectable()
export class AdminEffects {

	loadProviderList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadProviderList),
		withLatestFrom(this.adminFacade.providerListFilter$),
		switchMap(([action, filter]: [any, PageableRequestDTO]) => {
			return this.personServiceApi.searchPersonsPaginated(filter).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: PageablePersonsResponseDTO) =>
					from([
						AdminActions.LoadProviderListComplete({payload: response}),
						AdminActions.ClearUserListFilter({userListFilter: AdminUtils.defaultPageableRequest('PageableUserRequestDTO')})
					])
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadProviderListFailed({error: error.error.message}),
						AdminActions.ClearUserListFilter({userListFilter: AdminUtils.defaultPageableRequest('PageableUserRequestDTO')})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	loadProviderRequestList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadProviderRequestList),
		switchMap(({payload}) => {
			return this.personServiceApi.findAllRequestsForCareProvider(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: Array<RegistrationRequestDTO>) =>
					of(AdminActions.LoadProviderRequestListComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadProviderRequestListFailed({error: error.error.message})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	updateRequestStatus$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.UpdateRegistrationStatus),
		switchMap(({payload: {registrationRequest, id}}) => {
			return this.personServiceApi.updateRegistrationStatus(id, registrationRequest).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((result) =>
					of(AdminActions.UpdateRegistrationStatusComplete({payload: result}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.UpdateRegistrationStatusFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	deleteProviderRequest$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.DeleteProviderRequest),
		switchMap(({payload}) => {
			return this.personServiceApi.deleteRegistrationRequest(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((result) =>
					of(AdminActions.DeleteProviderRequestComplete({payload: result}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.DeleteProviderRequestFailed({error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	sendProviderRegistrationRequest = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.SendProviderRequest),
		switchMap(({payload}) => {
			return this.personServiceApi.createRegistrationRequest(payload.id, payload.request).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: any) =>
					of(AdminActions.SendProviderRequestComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.SendProviderRequestFailed({error: error.error}),
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	loadProviderHistoryList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadProviderHistory),
		switchMap((action) => {
			return this.personServiceApi.searchPersonHistoryPaginated(action.payload.providerId, action.payload.historyType,
				action.payload.pageableRequestDTO.page, action.payload.pageableRequestDTO.size).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: PageablePersonHistoryResponseDTO) =>
					of(AdminActions.LoadProviderHistoryComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadProviderHistoryFailed({error: error.message})
					])
				)
			);
		})
		)
	);

	loadUserList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadUserList),
		withLatestFrom(this.adminFacade.userListFilter$),
		switchMap(([action, filter]: [any, PageableUsersRequestDTO]) => {
			return this.userServiceApi.searchUsersPaginated(filter).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: PageableUsersResponseDTO) =>
					from([
						AdminActions.LoadUserListComplete({payload: response}),
						AdminActions.ClearProviderListFilter({providerListFilter: AdminUtils.defaultPageableRequest('PageableRequestDTO')})
					])
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadUserListFailed({error: error.message}),
						AdminActions.ClearProviderListFilter({providerListFilter: AdminUtils.defaultPageableRequest('PageableRequestDTO')})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	loadUser$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadUser),
		switchMap(({payload}) => {
			return this.userServiceApi.readUser(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UserDTO) =>
					of(AdminActions.LoadUserComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadUserFailed({error: error.message})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	createUser$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.CreateUser),
		switchMap(({payload}) => {
			return this.userServiceApi.createUser(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UserDTO) =>
					of(AdminActions.CreateUserComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.CreateUserFailed({error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	updateUser$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.UpdateUser),
		switchMap(({payload: {username, data}}) => {
			return this.userServiceApi.patchUser(username, data).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UserDTO) =>
					of(AdminActions.UpdateUserComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.UpdateUserFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	/**
	 * Effects for: General data
	 */
	loadGeneralDataServiceList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataServices),
		switchMap(() => {
			return this.medicalServicesService.findAllMedicalServices().pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: Array<MedicalServiceDTO>) =>
					of(AdminActions.LoadGeneralDataServicesComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataServicesFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	deleteGeneralDataService$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.DeleteGeneralDataService),
		switchMap((action) => {
			return this.medicalServicesService.deleteMedicalService(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap(() =>
					of(AdminActions.DeleteGeneralDataServiceComplete())
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.DeleteGeneralDataServiceFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	createGeneralDataService$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.CreateGeneralDataService),
		switchMap((action) => {
			return this.medicalServicesService.createMedicalService(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((medicalService: MedicalServiceDTO) =>
					of(AdminActions.CreateGeneralDataServiceComplete({payload: medicalService}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.CreateGeneralDataServiceFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	editGeneralDataService$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.EditGeneralDataService),
		switchMap(({payload: {serviceId, data}}) => {
			return this.medicalServicesService.patchMedicalService(serviceId, data).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((medicalService: MedicalServiceDTO) =>
					of(AdminActions.EditGeneralDataServiceComplete({payload: medicalService}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.EditGeneralDataServiceFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	loadGeneralDataProfessionList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataProfessions),
		switchMap(() => {
			return this.professionService.findAllProfessions().pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: Array<ProfessionDTO>) => of(AdminActions.LoadGeneralDataProfessionsComplete({payload: response}))),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataProfessionsFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	deleteGeneralDataProfession$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.DeleteGeneralDataProfession),
		switchMap((action) => {
			return this.professionService.deleteProfession(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap(() => of(AdminActions.DeleteGeneralDataProfessionComplete())),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.DeleteGeneralDataProfessionFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	createGeneralDataProfession$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.CreateGeneralDataProfession),
		switchMap((action) => {
			return this.professionService.createProfession(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((profession: ProfessionDTO) =>
					of(AdminActions.CreateGeneralDataProfessionComplete({payload: profession}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.CreateGeneralDataProfessionFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	editGeneralDataProfession$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.EditGeneralDataProfession),
		switchMap(({payload: {professionId, data}}) => {
			return this.professionService.patchProfession(professionId, data).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((profession: ProfessionDTO) =>
					of(AdminActions.EditGeneralDataProfessionComplete({payload: profession}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.EditGeneralDataProfessionFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	getGeneralDataServiceUsageNumber$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataServiceUsageNumber),
		switchMap(({payload: {serviceId}}) => {
			return this.medicalServicesService.getMedicalServiceUsagesNumber(serviceId).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UsagesDTO) =>
					of(AdminActions.LoadGeneralDataServicesUsageNumberComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataServicesUsageNumberFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	getGeneralDataProfessionUsageNumber$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataProfessionUsageNumber),
		switchMap(({payload: {serviceId}}) => {
			return this.professionService.getProfessionUsagesNumber(serviceId).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UsagesDTO) =>
					of(AdminActions.LoadGeneralDataProfessionUsageNumberComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataProfessionUsageNumberFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	/**
	 * ddddewdewdewdew
	 */

	loadGeneralDataExpertiseAreaList$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataExpertiseAreas),
		switchMap(() => {
			return this.expertiseAreaService.findAllExpertiseArea().pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: Array<ExpertiseAreaDTO>) =>
					of(AdminActions.LoadGeneralDataExpertiseAreasComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataExpertiseAreasFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	deleteGeneralDataExpertiseArea$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.DeleteGeneralDataExpertiseArea),
		switchMap((action) => {
			return this.expertiseAreaService.deleteExpertiseArea(action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap(() =>
					of(AdminActions.DeleteGeneralDataExpertiseAreaComplete())
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.DeleteGeneralDataExpertiseAreaFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	createGeneralDataExpertiseArea$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AdminActions.CreateGeneralDataExpertiseArea),
			switchMap((action) => {
				return this.expertiseAreaService.createExpertiseArea(action.payload).pipe(
					takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
					switchMap((expertiseArea: ExpertiseAreaDTO) =>
						from([
							AdminActions.CreateGeneralDataExpertiseAreaComplete({payload: expertiseArea})
						])
					),
					catchError((error) =>
						from([
							AdminActions.Error({error}),
							AdminActions.CreateGeneralDataExpertiseAreaFailed({error: error})
						])
					)
				);
			})
		), {useEffectsErrorHandler: false}
	);

	editGeneralDataExpertiseArea$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.EditGeneralDataExpertiseArea),
		switchMap(({payload: {expertiseAreaId, data}}) => {
			return this.expertiseAreaService.patchExpertiseArea(expertiseAreaId, data).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((ExpertiseArea: ExpertiseAreaDTO) =>
					of(AdminActions.EditGeneralDataExpertiseAreaComplete({payload: ExpertiseArea}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.EditGeneralDataExpertiseAreaFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	getGeneralDataExpertiseAreaUsageNumber$ = createEffect(() => this.actions$.pipe(
		ofType(AdminActions.LoadGeneralDataExpertiseAreaUsageNumber),
		switchMap(({payload: {serviceId}}) => {
			return this.expertiseAreaService.getExpertiseAreaUsagesNumber(serviceId).pipe(
				takeUntil(this.actions$.pipe(ofType(AdminActions.ClearState))),
				switchMap((response: UsagesDTO) =>
					of(AdminActions.LoadGeneralDataExpertiseAreaUsageNumberComplete({payload: response}))
				),
				catchError((error) =>
					from([
						AdminActions.Error({error}),
						AdminActions.LoadGeneralDataExpertiseAreaUsageNumberFailed({error: error})
					])
				)
			);
		})
		), {useEffectsErrorHandler: false}
	);

	//endregion

	constructor(
		private actions$: Actions,
		private adminFacade: AdminFacade,
		private personServiceApi: PersonService,
		private userServiceApi: UserService,
		private medicalServicesService: MedicalServiceService,
		private professionService: ProfessionService,
		private expertiseAreaService: ExpertiseAreaService,
	) {
	}
}
