import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'gp-angular-content',
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class DialogContentComponent {

	public isProduction: boolean = this._environment.production;

	public xSmsSimulate: boolean;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	public dialogTitle(): string {
		if (this.data && !!this.data.title) {
			let title = this.data.title;
			if (typeof title === 'string') {
				return title;
			}
			const defaultTitle = title.default;
			// TODO find magic solution for  title in case of resize
			//title = title[this._mediaQuerySize];
			//return !isNullOrUndefined(title) ? title : defaultTitle;
			return defaultTitle;
		}
		return undefined;
	}

	public dialogAction(): { align: string, color: string } {
		let align = 'none';
		let color = 'none';
		if (this.data && !!this.data.actionsConfig) {
			align = this.data.actionsConfig.align ? this.data.actionsConfig.align : 'none';
			color = this.data.actionsConfig.color ? this.data.actionsConfig.color : '';
		}
		return Object.assign({}, {align, color});
	}
}
