import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as AccountActions from './account.actions';
import { AccountData } from './account.models';
import * as AccountSelectors from './account.selectors';

@Injectable()
export class AccountFacade {
	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	data$ = this.store.pipe(select(AccountSelectors.getData));
	email$ = this.store.pipe(select(AccountSelectors.getDataEmail));
	name$ = this.store.pipe(select(AccountSelectors.getDataName));
	manageEmployee$ = this.store.pipe(select(AccountSelectors.getManageEmployee));
	permission$ = this.store.pipe(select(AccountSelectors.getPermission));
	currentPid$ = this.store.pipe(select(AccountSelectors.getCurrentPid));

	constructor(private store: Store) {
	}

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	init() {
		this.store.dispatch(AccountActions.Init());
	}

	data(data: AccountData) {
		this.store.dispatch(AccountActions.SetData({data: data}));
	}

	permission(permission: Array<string>) {
		this.store.dispatch(AccountActions.SetPermission({permission: permission}));
	}

	currentPid(id: number) {
		this.store.dispatch(AccountActions.SetCurrentPid({currentPid: id}));
	}

	clear() {
		this.store.dispatch(AccountActions.ClearState());
	}
}
