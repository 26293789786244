import { Directive, OnDestroy, OnInit, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[appAutocorrectTime]',
})
export class AutocorrectTimeDirective implements OnInit, OnDestroy {
	private _ngControlSubscription: Subscription;

	constructor(@Self() private ngControl: NgControl) {
	}

	public ngOnInit(): void {
		this._ngControlSubscription = this.ngControl.valueChanges.subscribe(
			(value) => {
				if (!!value && value !== '') {
					let newValue = value.replace(/[^\d:]/g, '');
					if (value.length === 4 && value.indexOf(':') < 0) {
						newValue = `${value.substring(0, 2)}:${value.substring(2, 4)}`;
					}
					if (value !== newValue) {
						this.ngControl.control.setValue(newValue);
					}
				}
			}
		);
	}

	public ngOnDestroy(): void {
		this._ngControlSubscription.unsubscribe();
	}
}
