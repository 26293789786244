import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAppointment from './+state/appointment.reducer';
import { AppointmentEffects } from './+state/appointment.effects';
import { AppointmentFacade } from './+state/appointment.facade';
import { AppointmentService } from './appointment.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromAppointment.APPOINTMENT_FEATURE_KEY, fromAppointment.reducer),
		EffectsModule.forFeature([AppointmentEffects]),
	],
	providers: [AppointmentFacade]
})
export class ServiceAppointmentModule {
	static forRoot(): ModuleWithProviders<ServiceAppointmentModule> {
		return {
			ngModule: ServiceAppointmentModule,
			providers: [AppointmentService]
		};
	}
}
