import { createAction, props } from '@ngrx/store';
import {
	AbsentHourDTO, AttachmentsResponseDTO, BookingHoursDTO, CalendarSettingsDTO, CareProviderDataEnumDTO, CreateRegistrationRequestBodyDTO,
	ExpertiseDTO,
	ExtraInfoDTO, IndexItemDTO,
	KeyValueDTO, MedicalServiceDTO, MessageTemplateDTO, OnlineBookingHourDTO, OpenHoursItemDTO, PersonDTO, PersonListInfoDTO,
	PersonRequestBodyDTO,
	PostalCodeCityDTO, RegisterProfileConfirmDTO, RegistrationRequestDTO, ResourceCategoryWrapperDTO, ResourceDTO, ResourceHoursResponseDTO,
	ResourceReorderDTO, SettingsDTO, TimeSlotsDTO, TreatmentDTO
} from '@noventi/gp-platform/care-providers';
import { HttpErrorResponse } from '@angular/common/http';
import {
	AppointmentDTO, AppointmentHeatmapDTO, CalendarEventDTO, InsuranceTypeEnumDTO, MessageDTO,
	PageableAppointmentInfosResponseDTO, PageableMessagesRequestDTO,
	PageableResponseDTO, PageableWaitingListsRequestDTO, PageableWaitingListsResponseDTO, PublicAppointmentDTO, ResourceGdprDTO,
	WaitingListBookingStatusResponseDTO
} from '@noventi/gp-platform/online-appointments';
import { PageEvent } from '@angular/material/paginator';
import {
	CmsAppTypeEnumDTO, CmsPageTypeEnumDTO, PageableUsersRequestDTO, PageableUsersResponseDTO, PasswordResetRequestDTO,
	PasswordResetResponseDTO, UserDTO
} from '@noventi/gp-platform/users';
import {
	GetPatientsResponseDTO, PageablePatientsResponseDTO, PatientDTO, PatientGdprInfoDTO, SortFieldWithDirectionDTO
} from '@noventi/gp-platform/patients';

export const LoadSettings = createAction(
	'[Provider] Load Settings'
);

export const LoadSettingsComplete = createAction(
	'[Provider] Load Settings Complete',
	props<{ settings: { appointment: SettingsDTO, calendar: CalendarSettingsDTO } }>()
);

export const LoadSettingsFailed = createAction(
	'[Provider] Load Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateSettings = createAction(
	'[Provider] Update Settings',
	props<{ payload: { body: { [key: string]: any; } } }>()
);

export const UpdateSettingsComplete = createAction(
	'[Provider] Update Settings Complete',
	props<{ settings: { appointment: SettingsDTO, calendar: CalendarSettingsDTO } }>()
);

export const UpdateSettingsFailed = createAction(
	'[Provider] Update Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadCalendarSettings = createAction(
	'[Provider] Load Calendar Settings',
	props<{ payload?: string }>()
);

export const LoadCalendarSettingsComplete = createAction(
	'[Provider] Load Calendar Settings Complete',
	props<{ settings: { appointment: SettingsDTO, calendar: CalendarSettingsDTO } }>()
);

export const LoadCalendarSettingsFailed = createAction(
	'[Provider] Load Calendar Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateCalendarSettings = createAction(
	'[Provider] Update Calendar Settings',
	props<{ payload: { body: { [key: string]: any; }, username?: string } }>()
);

export const UpdateCalendarSettingsComplete = createAction(
	'[Provider] Update Calendar Settings Complete',
	props<{ settings: { appointment: SettingsDTO, calendar: SettingsDTO } }>()
);

export const UpdateCalendarSettingsFailed = createAction(
	'[Provider] Update Calendar Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DownloadPrintableCalendar = createAction(
	'[Provider] Download Printable Calendar',
	props<{ payload: { startDate: Date, interval: number, resourceIds: Array<ResourceDTO> } }>()
);

export const DownloadPrintableCalendarComplete = createAction(
	'[Provider] Download Printable Calendar Complete',
	props<{ payload: Blob }>()
);

export const DownloadPrintableCalendarFailed = createAction(
	'[Provider] Download Printable Calendar Failed',
	props<{ error: HttpErrorResponse }>()
);

export const AutocompletePostalCodeCity = createAction(
	'[Provider] Autocomplete Postal Code City',
	props<{ payload: PostalCodeCityDTO }>()
);

export const AutocompletePostalCodeCityComplete = createAction(
	'[Provider] Autocomplete Postal Code City Complete',
	props<{ payload: PostalCodeCityDTO[] }>()
);

export const AutocompletePostalCodeCityFailed = createAction(
	'[Provider] Autocomplete Postal Code City Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadProviderKeySalutation = createAction(
	'[Provider] Load Provider Key Salutation'
);

export const LoadProviderKeySalutationComplete = createAction(
	'[Provider] Load Provider Key Salutation Complete',
	props<{ payload: KeyValueDTO[] }>()
);

export const LoadProviderKeySalutationFailed = createAction(
	'[Provider] Load Provider Key Salutation Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadProviderKeyTitle = createAction(
	'[Provider] Load Provider Key Title'
);

export const LoadProviderKeyTitleComplete = createAction(
	'[Provider] Load Provider Key Title Complete',
	props<{ payload: KeyValueDTO[] }>()
);

export const LoadProviderKeyTitleFailed = createAction(
	'[Provider] Load Provider Key Title Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadProviderKeyAcademicalDegree = createAction(
	'[Provider] Load Provider Key Academical Degree'
);

export const LoadProviderKeyAcademicalDegreeComplete = createAction(
	'[Provider] Load Provider Key Academical Degree Complete',
	props<{ payload: KeyValueDTO[] }>()
);

export const LoadProviderKeyAcademicalDegreeFailed = createAction(
	'[Provider] Load Provider Key Academical Degree Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadProvider = createAction(
	'[Provider] Load Provider',
	props<{ payload: number }>()
);

export const LoadProviderComplete = createAction(
	'[Provider] Load Provider Complete',
	props<{ data: PersonDTO }>()
);

export const LoadProviderFailed = createAction(
	'[Provider] Load Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateProvider = createAction(
	'[Provider] Create Provider',
	props<{ payload: PersonRequestBodyDTO }>()
);

export const CreateProviderComplete = createAction(
	'[Provider] Create Provider Complete',
	props<{ payload: PersonDTO }>()
);

export const CreateProviderFailed = createAction(
	'[Provider] Create Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ConfirmRegistrationRequest = createAction(
	'[Provider] Confirm Registration Request',
	props<{ payload: { registerProfileConfirm: RegisterProfileConfirmDTO, token: string } }>()
);

export const ConfirmRegistrationRequestComplete = createAction(
	'[Provider] Confirm Registration Request Complete',
	props<{ payload: RegisterProfileConfirmDTO }>()
);

export const ConfirmRegistrationRequestFailed = createAction(
	'[Provider] Confirm Registration Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveProviderInformation = createAction(
	'[Provider] Save Provider Information',
	props<{ payload: { id: number, body: { [key: string]: any; } } }>()
);

export const SaveProviderInformationComplete = createAction(
	'[Provider] Save Provider Information Complete',
	props<{ data: PersonDTO }>()
);

export const SaveProviderInformationFailed = createAction(
	'[Provider] Save Provider Information Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveProviderImage = createAction(
	'[Provider] Save Provider Image',
	props<{ payload: Array<any> }>()
);

export const SaveProviderImageComplete = createAction(
	'[Provider] Save Provider Image Complete',
	props<{ payload: any }>()
);

export const SaveProviderImageFailed = createAction(
	'[Provider] Save Provider Image Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteProviderImage = createAction(
	'[Provider] Delete Provider Image',
	props<{ payload: any }>()
);

export const DeleteProviderImageComplete = createAction(
	'[Provider] Delete Provider Image Complete',
	props<{ payload: any }>()
);

export const DeleteProviderImageFailed = createAction(
	'[Provider] Delete Provider Image Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveProviderSchedule = createAction(
	'[Provider] Save Provider Schedule',
	props<{ payload: { id: number, body: Array<OpenHoursItemDTO> } }>()
);

export const SaveProviderScheduleComplete = createAction(
	'[Provider] Save Provider Schedule Complete',
	props<{ payload: any }>()
);

export const SaveProviderScheduleFailed = createAction(
	'[Provider] Save Provider Schedule Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteProvider = createAction(
	'[Provider] Delete Provider',
	props<{ payload: { providerId: number, email?: string, download?: boolean, xSmsSimulate?: boolean } }>()
);

export const DeleteProviderComplete = createAction(
	'[Provider] Delete Provider Complete',
	props<{ payload: any }>()
);

export const DeleteProviderFailed = createAction(
	'[Provider] Delete Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ArchiveProvider = createAction(
	'[Provider] Archive Provider',
	props<{ payload: number }>()
);

export const ArchiveProviderComplete = createAction(
	'[Provider] Archive Provider Complete',
	props<{ payload: any }>()
);

export const ArchiveProviderFailed = createAction(
	'[Provider] Archive Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ReactivateProvider = createAction(
	'[Provider] Reactivate Provider',
	props<{ payload: number }>()
);

export const ReactivateProviderComplete = createAction(
	'[Provider] Reactivate Provider Complete',
	props<{ payload: any }>()
);

export const ReactivateProviderFailed = createAction(
	'[Provider] Reactivate Provider Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CopyProviderData = createAction(
	'[Provider] Copy Provider Data',
	props<{ payload: { idFrom: number, idTo: number, data: Array<CareProviderDataEnumDTO> } }>()
);

export const CopyProviderDataComplete = createAction(
	'[Provider]  Copy Provider Data Complete',
	props<{ payload: any }>()
);

export const CopyProviderDataFailed = createAction(
	'[Provider]  Copy Provider Data Failed',
	props<{ error: HttpErrorResponse }>()
);

export const loadResourceHourList = createAction(
	'[Provider] Load Resource Hour List',
	props<{ payload: { date: Date, interval: number, resourceId?: number, bookingHours?: boolean, onlineBookingHours?: boolean, absentHours?: boolean } }>()
);

export const loadResourceHourListComplete = createAction(
	'[Provider] Load Resource Hour List Complete',
	props<{ payload: Array<ResourceHoursResponseDTO> }>()
);

export const loadResourceHourListFailed = createAction(
	'[Provider] Load Resource Hour List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadAppointmentList = createAction(
	'[Provider] Load Appointments List',
	props<{ payload: { date: Date, interval: number } }>()
);

export const LoadAppointmentListComplete = createAction(
	'[Provider] Load Appointments List Complete',
	props<{ payload: Array<CalendarEventDTO> }>()
);

export const LoadAppointmentListFailed = createAction(
	'[Provider] Load Appointments List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadAppointment = createAction(
	'[Provider] Load Appointment',
	props<{ payload: number }>()
);

export const LoadAppointmentComplete = createAction(
	'[Provider] Load Appointment Complete',
	props<{ payload: AppointmentDTO }>()
);

export const LoadAppointmentFailed = createAction(
	'[Provider] Load Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CheckAppointmentInterval = createAction(
	'[Provider] Check Appointment Interval',
	props<{ overlap: boolean, payload: { resourceId: number, treatmentId?: number, appointmentId?: number, startTime: string, endTime: string } }>()
);

export const CheckAppointmentIntervalComplete = createAction(
	'[Provider] Check Appointment Interval Complete',
	props<{ payload: InsuranceTypeEnumDTO[] }>()
);

export const CheckAppointmentIntervalFailed = createAction(
	'[Provider] Check Appointment Interval Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadPublicAppointmentList = createAction(
	'[Provider] Load Public Appointment List',
	props<{ payload: number[] }>()
);

export const LoadPublicAppointmentListComplete = createAction(
	'[Provider] Load Public Appointment List Complete',
	props<{ payload: PublicAppointmentDTO[] }>()
);

export const LoadPublicAppointmentListFailed = createAction(
	'[Provider] Load Public Appointment List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateMultipleAppointments = createAction(
	'[Provider] Create Multiple Appointments',
	props<{ payload: { appointments: Array<AppointmentDTO>, bookingLink: string } }>()
);

export const CreateMultipleAppointmentsComplete = createAction(
	'[Provider] Create Multiple Appointments Complete',
	props<{ payload: Array<AppointmentDTO> }>()
);

export const CreateMultipleAppointmentsFailed = createAction(
	'[Provider] Create Multiple Appointments Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateAppointment = createAction(
	'[Provider] Create Appointment',
	props<{ payload: { appointment: AppointmentDTO, bookingLink: string, waitingListId?: number } }>()
);

export const CreateAppointmentComplete = createAction(
	'[Provider] Create Appointment Complete',
	props<{ payload: AppointmentDTO }>()
);

export const CreateAppointmentFailed = createAction(
	'[Provider] Create Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateAppointment = createAction(
	'[Provider] Update Appointment',
	props<{ payload: { id: number, sendSms: boolean, body: { [key: string]: any; } } }>()
);

export const UpdateAppointmentComplete = createAction(
	'[Provider] Update Appointment Complete',
	props<{ payload: AppointmentDTO }>()
);

export const UpdateAppointmentFailed = createAction(
	'[Provider] Update Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteAppointment = createAction(
	'[Provider] Delete Appointment',
	props<{ payload: number }>()
);

export const DeleteAppointmentComplete = createAction(
	'[Provider] Delete Appointment Complete'
);

export const DeleteAppointmentFailed = createAction(
	'[Provider] Delete Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadSystemTemplates = createAction(
	'[Provider] Load System Templates'
);

export const LoadSystemTemplatesComplete = createAction(
	'[Provider] Load System Templates Complete',
	props<{ payload: Array<MessageTemplateDTO> }>()
);

export const LoadSystemTemplatesFailed = createAction(
	'[Provider] Load System Templates Failed',
	props<{ error: HttpErrorResponse }>()
);

/** * ================================== Services ============================================== */
	//region Services
export const LoadProviderServices = createAction(
		'[Provider] Load Provider Services',
		props<{ payload: number }>()
	);

export const LoadProviderServicesComplete = createAction(
	'[Provider] Load Provider Services Complete',
	props<{ payload: Array<MedicalServiceDTO> }>()
);

export const LoadProviderServicesFailed = createAction(
	'[Provider] Load Provider Services Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveProviderServices = createAction(
	'[Provider] Save Provider Services',
	props<{ payload: Array<IndexItemDTO> }>()
);

export const SaveProviderServicesComplete = createAction(
	'[Provider] Save Provider Services Complete',
	props<{ payload: Array<MedicalServiceDTO> }>()
);

export const SaveProviderServicesFailed = createAction(
	'[Provider] Save Provider Services Failed',
	props<{ error: HttpErrorResponse }>()
);
//endregion

/** * ================================== Languages ============================================== */
	//region Languages
export const LoadExtraInfo = createAction(
		'[Provider] Load Extra Info'
	);

export const LoadExtraInfoComplete = createAction(
	'[Provider] Load Extra Info Complete',
	props<{ payload: ExtraInfoDTO }>()
);

export const LoadExtraInfoFailed = createAction(
	'[Provider] Load Extra Info Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveExtraInfo = createAction(
	'[Provider] Save Extra Info ',
	props<{ payload: ExtraInfoDTO }>()
);

export const SaveExtraInfoComplete = createAction(
	'[Provider] Save Extra Info Complete',
	props<{ payload: ExtraInfoDTO }>()
);

export const SaveExtraInfoFailed = createAction(
	'[Provider] Save Extra Info Failed',
	props<{ error: HttpErrorResponse }>()
);
//endregion

/** ================================== Expertise ============================================== */
	//region Expertise
export const LoadExpertise = createAction(
		'[Provider] Load Expertise'
	);

export const LoadExpertiseComplete = createAction(
	'[Provider] Load Expertise Complete',
	props<{ payload: ExpertiseDTO }>()
);

export const LoadExpertiseFailed = createAction(
	'[Provider] Load Expertise Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveExpertise = createAction(
	'[Provider] Save Expertise',
	props<{ payload: ExpertiseDTO }>()
);

export const SaveExpertiseComplete = createAction(
	'[Provider] Save  Expertise Complete',
	props<{ payload: ExpertiseDTO }>()
);

export const SaveExpertiseFailed = createAction(
	'[Provider] Save  Expertise Failed',
	props<{ error: HttpErrorResponse }>()
);
// endregion

/** ================================== Message ============================================== */
	//region Message
export const CountUnreadMessage = createAction(
		'[Provider] Count Unread Message'
	);

export const CountUnreadMessageComplete = createAction(
	'[Provider] Count Unread Message Complete',
	props<{ payload: number }>()
);

export const CountUnreadMessageFailed = createAction(
	'[Provider] Count Unread Message Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateCountUnreadMessage = createAction(
	'[Provider] Update Count Unread Message',
	props<{ payload: number }>()
);

export const LoadMessageList = createAction(
	'[Provider] Load Message List',
	props<{ payload: PageableMessagesRequestDTO }>()
);

export const LoadMessageListComplete = createAction(
	'[Provider] Load Message List Complete',
	props<{ payload: PageableResponseDTO }>()
);

export const LoadMessageListFailed = createAction(
	'[Provider] Load Message List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadLastMessage = createAction(
	'[Provider] Load Last Message',
	props<{ payload: PageEvent }>()
);

export const LoadLastMessageComplete = createAction(
	'[Provider] Load Last Message Complete',
	props<{ payload: PageableResponseDTO }>()
);

export const LoadLastMessageFailed = createAction(
	'[Provider] Load Last Message Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadMessage = createAction(
	'[Provider] Load Message',
	props<{ payload: number }>()
);

export const LoadMessageComplete = createAction(
	'[Provider] Load Message Complete',
	props<{ payload: MessageDTO }>()
);

export const LoadMessageFailed = createAction(
	'[Provider] Load Message Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadCustomTemplates = createAction(
	'[Provider] Load Custom Templates'
);

export const LoadCustomTemplatesComplete = createAction(
	'[Provider] Load Custom Templates Complete',
	props<{ payload: Array<MessageTemplateDTO> }>()
);

export const LoadCustomTemplatesFailed = createAction(
	'[Provider] Load Custom Templates Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveCustomTemplate = createAction(
	'[Provider] Save Text Template',
	props<{ payload: MessageTemplateDTO }>()
);

export const SaveCustomTemplateComplete = createAction(
	'[Provider] Save Text Template Complete',
	props<{ payload: MessageTemplateDTO }>()
);

export const SaveCustomTemplateFailed = createAction(
	'[Provider] Save Text Template Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UploadAttachment = createAction(
	'[Provider] Upload Attachment',
	props<{ payload: Blob }>()
);

export const UploadAttachmentComplete = createAction(
	'[Provider] Upload Attachment Complete',
	props<{ payload: AttachmentsResponseDTO }>()
);

export const UploadAttachmentFailed = createAction(
	'[Provider] Upload Attachment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetAttachment = createAction(
	'[Provider] Get Attachment',
	props<{ payload: { id: number, uuid: string } }>()
);

export const GetAttachmentComplete = createAction(
	'[Provider] Get Attachment Complete',
	props<{ payload: Blob }>()
);

export const GetAttachmentFailed = createAction(
	'[Provider] Get Attachment Failed',
	props<{ error: HttpErrorResponse }>()
);
// endregion

/** ================================== Resources ============================================== */
	//region Resources
export const CreateResource = createAction(
		'[Provider] Create Resource',
		props<{ payload: ResourceDTO }>()
	);

export const CreateResourceComplete = createAction(
	'[Provider] Create Resource Complete',
	props<{ resources: Array<ResourceCategoryWrapperDTO>, result?: ResourceDTO }>()
);

export const CreateResourceFailed = createAction(
	'[Provider] Create Resource Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteResource = createAction(
	'[Provider] Delete Resource',
	props<{ payload: { resource: ResourceDTO, email?: string, download?: boolean, xSmsSimulate?: boolean } }>()
);

export const DeleteResourceComplete = createAction(
	'[Provider] Delete Resource Complete',
	props<{ payload: { resources: Array<ResourceCategoryWrapperDTO>, calendarResources: Array<ResourceDTO>, pdf: any } }>()
);

export const DeleteResourceFailed = createAction(
	'[Provider] Delete Resource Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateResource = createAction(
	'[Provider] Update Resource',
	props<{ payload: { id: number, body: { [key: string]: any; } } }>()
);

export const UpdateResourceComplete = createAction(
	'[Provider] Update Resource Complete',
	props<{ resources: Array<ResourceCategoryWrapperDTO> }>()
);

export const UpdateResourceFailed = createAction(
	'[Provider] Update Resource Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadNotAssignedResourceList = createAction(
	'[Provider] Load Not Assigned Resource List',
	props<{ payload: { userId?: number } }>()
);

export const LoadNotAssignedResourceListComplete = createAction(
	'[Provider] Load Not Assigned Resource List Complete',
	props<{ payload: ResourceDTO[] }>()
);

export const LoadNotAssignedResourceListFailed = createAction(
	'[Provider] Load Not Assigned Resource List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceGroup = createAction(
	'[Provider] Load Resource Group'
);

export const LoadResourceGroupComplete = createAction(
	'[Provider] Load Resource Group Complete',
	props<{ resources: Array<ResourceCategoryWrapperDTO> }>()
);

export const LoadResourceGroupFailed = createAction(
	'[Provider] Load Resource Group Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceOfficeHours = createAction(
	'[Provider] Load Resource Office Hours',
	props<{ payload: { resourceId: number } }>()
);

export const LoadResourceOfficeHoursComplete = createAction(
	'[Provider] Load Resource Office Hours Complete',
	props<{ payload: Array<BookingHoursDTO> }>()
);

export const LoadResourceOfficeHoursFailed = createAction(
	'[Provider] Load Resource Office Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateResourceOfficeHours = createAction(
	'[Provider] Create Resource Office Hours',
	props<{ payload: { resourceId: number, bookingHours: BookingHoursDTO } }>()
);

export const CreateResourceOfficeHoursComplete = createAction(
	'[Provider] Create Resource Office Hours Complete',
	props<{ payload: BookingHoursDTO }>()
);

export const CreateResourceOfficeHoursFailed = createAction(
	'[Provider] Create Resource Office Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const PatchResourceOfficeHours = createAction(
	'[Provider] Patch Resource Office Hours',
	props<{ payload: { id: number, bookingHours: BookingHoursDTO } }>()
);

export const PatchResourceOfficeHoursComplete = createAction(
	'[Provider] Patch Resource Office Hours Complete',
	props<{ payload: BookingHoursDTO }>()
);

export const PatchResourceOfficeHoursFailed = createAction(
	'[Provider] Patch Resource Office Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteResourceOfficeHours = createAction(
	'[Provider] Delete Resource Office Hours',
	props<{ payload: number }>()
);

export const DeleteResourceOfficeHoursComplete = createAction(
	'[Provider] Delete Resource Office Hours Complete',
	props<{ payload: any }>()
);

export const DeleteResourceOfficeHoursFailed = createAction(
	'[Provider] Delete Resource Office Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceAbsences = createAction(
	'[Provider] Load Resource Absences',
	props<{ payload: { resourceId: number } }>()
);

export const LoadResourceAbsencesComplete = createAction(
	'[Provider] Load Resource Absences Complete',
	props<{ payload: Array<AbsentHourDTO> }>()
);

export const LoadResourceAbsencesFailed = createAction(
	'[Provider] Load Resource Absences Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateResourceAbsence = createAction(
	'[Provider] Create Resource Absence',
	props<{ payload: { resourceId: number, absenceHours: AbsentHourDTO } }>()
);

export const CreateResourceAbsenceComplete = createAction(
	'[Provider] Create Resource Absence Complete',
	props<{ payload: AbsentHourDTO }>()
);

export const CreateResourceAbsenceFailed = createAction(
	'[Provider] Create Resource Absence Failed',
	props<{ error: HttpErrorResponse }>()
);

export const PatchResourceAbsence = createAction(
	'[Provider] Patch Resource Absence',
	props<{ payload: { id: number, absenceHours: AbsentHourDTO } }>()
);

export const PatchResourceAbsenceComplete = createAction(
	'[Provider] Patch Resource Absence Complete',
	props<{ payload: AbsentHourDTO }>()
);

export const PatchResourceAbsenceFailed = createAction(
	'[Provider] Patch Resource Absence Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteResourceAbsence = createAction(
	'[Provider] Delete Resource Absence Hours',
	props<{ payload: number }>()
);

export const DeleteResourceAbsenceComplete = createAction(
	'[Provider] Delete Resource Absence Complete',
	props<{ payload: any }>()
);

export const DeleteResourceAbsenceFailed = createAction(
	'[Provider] Delete Resource Absence Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceOnlineHours = createAction(
	'[Provider] Load Resource Online Hours',
	props<{ payload: { resourceId: number } }>()
);

export const LoadResourceOnlineHoursComplete = createAction(
	'[Provider] Load Resource Online Hours Complete',
	props<{ payload: Array<OnlineBookingHourDTO> }>()
);

export const LoadResourceOnlineHoursFailed = createAction(
	'[Provider] Load Resource Online Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateResourceOnlineHours = createAction(
	'[Provider] Create Resource Online Hours',
	props<{ payload: { resourceId: number, onlineBookingHours: OnlineBookingHourDTO } }>()
);

export const CreateResourceOnlineHoursComplete = createAction(
	'[Provider] Create Resource Online Hours Complete',
	props<{ payload: OnlineBookingHourDTO }>()
);

export const CreateResourceOnlineHoursFailed = createAction(
	'[Provider] Create Resource Online Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const PatchResourceOnlineHours = createAction(
	'[Provider] Patch Resource Online Hours',
	props<{ payload: { id: number, onlineBookingHours: OnlineBookingHourDTO } }>()
);

export const PatchResourceOnlineHoursComplete = createAction(
	'[Provider] Patch Resource Online Hours Complete',
	props<{ payload: OnlineBookingHourDTO }>()
);

export const PatchResourceOnlineHoursFailed = createAction(
	'[Provider] Patch Resource Online Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteResourceOnlineHours = createAction(
	'[Provider] Delete Resource Online Hours',
	props<{ payload: number }>()
);

export const DeleteResourceOnlineHoursComplete = createAction(
	'[Provider] Delete Resource Online Hours Complete',
	props<{ payload: any }>()
);

export const DeleteResourceOnlineHoursFailed = createAction(
	'[Provider] Delete Resource Online Hours Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetCalendarResources = createAction(
	'[Provider] Set Calendar Resources',
	props<{ calendarResources: Array<ResourceDTO> }>()
);

export const LoadAppointmentHeatmap = createAction(
	'[Provider] Load Appointment Heatmap',
	props<{ payload: any }>()
);

export const LoadAppointmentHeatmapComplete = createAction(
	'[Provider] Load Appointment Heatmap Complete',
	props<{ payload: Array<AppointmentHeatmapDTO> }>()
);

export const LoadAppointmentHeatmapFailed = createAction(
	'[Provider] Load Appointment Heatmap Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResource = createAction(
	'[Provider] Load Resource',
	props<{ payload: number }>()
);

export const LoadResourceComplete = createAction(
	'[Provider] Load Resource Complete',
	props<{ payload: ResourceDTO }>()
);

export const LoadResourceFailed = createAction(
	'[Provider] Load Resource Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ReorderResources = createAction(
	'[Provider] Reorder Resources',
	props<{ payload: ResourceReorderDTO }>()
);

export const ReorderResourcesComplete = createAction(
	'[Provider] Reorder Resources Complete',
	props<{ payload: { calendarResources: Array<ResourceDTO>, resources: Array<ResourceCategoryWrapperDTO> } }>()
);

export const ReorderResourcesFailed = createAction(
	'[Provider] Reorder Resources Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceGdpr = createAction(
	'[Provider] Load Resource GDPR',
	props<{ payload: number }>()
);

export const LoadResourceGdprComplete = createAction(
	'[Provider] Load Resource GDPR Complete',
	props<{ payload: ResourceGdprDTO }>()
);

export const LoadResourceGdprFailed = createAction(
	'[Provider] Load Resource GDPR Failed',
	props<{ error: HttpErrorResponse }>()
);
//endregion

/** =========================== Content Management System ================================ */
	//region Content Management System
export const LoadContent = createAction(
		'[Provider] Load Content',
		props<{ payload: { type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO } }>()
	);

export const LoadContentComplete = createAction(
	'[Provider] Load Content Complete',
	props<{ payload: any }>()
);

export const LoadContentFailed = createAction(
	'[Provider] Load Content Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion

/** =========================== Calendar Settings ================================ */
//region Calendar Settings

export const LoadCalendarDefaultSettings = createAction(
	'[Provider] Load Calendar Default Settings'
);

export const LoadCalendarDefaultSettingsComplete = createAction(
	'[Provider] Load Calendar Default Settings Complete',
	props<{ payload: SettingsDTO }>()
);

export const LoadCalendarDefaultSettingsFailed = createAction(
	'[Provider] Load Calendar Default Settings Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadDefaultTimeSlots = createAction(
	'[Provider] Load Default Time Slots'
);

export const LoadDefaultTimeSlotsComplete = createAction(
	'[Provider] Load Default Time Slots Complete',
	props<{ payload: TimeSlotsDTO }>()
);

export const LoadDefaultTimeSlotsFailed = createAction(
	'[Provider] Load Default Time Slots Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DownloadPeriodicalDeletionReport = createAction(
	'[Provider] Download Periodical Deletion Report'
);

export const DownloadPeriodicalDeletionReportComplete = createAction(
	'[Provider] Download Periodical Deletion Report Complete',
	props<{ payload: any }>()
);

export const DownloadPeriodicalDeletionReportFailed = createAction(
	'[Provider] Download Periodical Deletion Report Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion

/** =========================== Treatment Settings ================================ */
//region Treatment Settings

export const LoadTreatmentList = createAction(
	'[Provider] Load Treatment List'
);

export const LoadTreatmentListComplete = createAction(
	'[Provider] Load Treatment List Complete',
	props<{ payload: TreatmentDTO[] }>()
);

export const LoadTreatmentListFailed = createAction(
	'[Provider] Load Treatment List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateTreatment = createAction(
	'[Provider] Create Treatment',
	props<{ payload: TreatmentDTO }>()
);

export const CreateTreatmentComplete = createAction(
	'[Provider] Create Treatment Complete',
	props<{ payload: TreatmentDTO }>()
);

export const CreateTreatmentFailed = createAction(
	'[Provider] Create Treatment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateTreatment = createAction(
	'[Provider] Update Treatment',
	props<{ payload: { id: number, body: { [key: string]: any; } } }>()
);

export const UpdateTreatmentComplete = createAction(
	'[Provider] Update Treatment Complete',
	props<{ payload: TreatmentDTO }>()
);

export const UpdateTreatmentFailed = createAction(
	'[Provider] Update Treatment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteTreatment = createAction(
	'[Provider] Delete Treatment',
	props<{ payload: TreatmentDTO }>()
);

export const DeleteTreatmentComplete = createAction(
	'[Provider] Delete Treatment Complete',
	props<{ payload: TreatmentDTO }>()
);

export const DeleteTreatmentFailed = createAction(
	'[Provider] Delete Treatment Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion
/** =========================== Patient ================================ **/

export const GetPatientList = createAction(
	'[Provider] Get Patient List',
	props<{ payload: { query?: string, page?: number, size?: number, sortFields?: Array<SortFieldWithDirectionDTO> } }>()
);

export const GetPatientListComplete = createAction(
	'[Provider] Get Patient List Complete',
	props<{ payload: PageablePatientsResponseDTO }>()
);

export const GetPatientListFailed = createAction(
	'[Provider] Get Patient List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadPatientDetails = createAction(
	'[Provider] Load Patient Details',
	props<{ payload: number }>()
);

export const LoadPatientDetailsComplete = createAction(
	'[Provider] Load Patient Details Complete',
	props<{ payload: PatientDTO }>()
);

export const LoadPatientDetailsFailed = createAction(
	'[Provider] Load Patient Details Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadPatientAppointmentList = createAction(
	'[Provider] Load Patient Appointment List',
	props<{ payload: { patientId: number, page: number, size: number } }>()
);

export const LoadPatientAppointmentListComplete = createAction(
	'[Provider] Load Patient Appointment List Complete',
	props<{ payload: PageableAppointmentInfosResponseDTO }>()
);

export const LoadPatientAppointmentListFailed = createAction(
	'[Provider] Load Patient Appointment List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreatePatient = createAction(
	'[Provider] Create Patient',
	props<{ payload: PatientDTO }>()
);

export const CreatePatientComplete = createAction(
	'[Provider] Create Patient Complete',
	props<{ payload: PatientDTO }>()
);

export const CreatePatientFailed = createAction(
	'[Provider] Create Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdatePatient = createAction(
	'[Provider] Update Patient',
	props<{ payload: { id: number, data: any } }>()
);

export const UpdatePatientComplete = createAction(
	'[Provider] Update Patient Complete',
	props<{ payload: PatientDTO }>()
);

export const UpdatePatientFailed = createAction(
	'[Provider] Update Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetPatientGdpr = createAction(
	'[Provider] Get Patient GDPR Action',
	props<{ payload: number[] }>()
);

export const GetPatientGdprComplete = createAction(
	'[Provider] Get Patient GDPR Complete',
	props<{ payload: PatientGdprInfoDTO }>()
);

export const GetPatientGdprFailed = createAction(
	'[Provider] Get Patient GDPR Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeletePatient = createAction(
	'[Provider] Delete Patient Action',
	props<{ payload: { patientName: string, patientList: number[], email?: string, download?: boolean, xSmsSimulate?: boolean } }>()
);

export const DeletePatientComplete = createAction(
	'[Provider] Delete Patient Complete',
	props<{ payload: any }>()
);

export const DeletePatientFailed = createAction(
	'[Provider] Delete Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const BlockPatient = createAction(
	'[Provider] Block Patient',
	props<{ payload: { id: number, block: boolean } }>()
);

export const BlockPatientComplete = createAction(
	'[Provider] Block Patient Complete',
	props<{ payload: any }>()
);

export const BlockPatientFailed = createAction(
	'[Provider] Block Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetMergePatientList = createAction(
	'[Provider] Get Merge Patient List',
	props<{ payload: number[] }>()
);

export const GetMergePatientListComplete = createAction(
	'[Provider] Get Merge Patient List Complete',
	props<{ payload: GetPatientsResponseDTO }>()
);

export const GetMergePatientListFailed = createAction(
	'[Provider] Get Merge Patient List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const MergePatient = createAction(
	'[Provider] Merge Patient',
	props<{ payload: { newPatient: PatientDTO, oldPatientIds: number[] } }>()
);

export const MergePatientComplete = createAction(
	'[Provider] Merge Patient Complete',
	props<{ payload: PatientDTO }>()
);

export const MergePatientFailed = createAction(
	'[Provider] Merge Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion
/** =========================== User Management ================================ **/

export const LoadUserRequestList = createAction(
	'[Provider] Load User Request List'
);

export const LoadUserRequestListComplete = createAction(
	'[Provider] Load User Request List Complete',
	props<{ payload: RegistrationRequestDTO[] }>()
);

export const LoadUserRequestListFailed = createAction(
	'[Provider] Load User Request List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadUserList = createAction(
	'[Provider] Load User List',
	props<{ payload: PageableUsersRequestDTO }>()
);

export const LoadUserListComplete = createAction(
	'[Provider] Load User List Complete',
	props<{ payload: PageableUsersResponseDTO }>()
);

export const LoadUserListFailed = createAction(
	'[Provider] Load User List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateRegistrationRequest = createAction(
	'[Provider] Create Registration Request',
	props<{ payload: CreateRegistrationRequestBodyDTO }>()
);

export const CreateRegistrationRequestComplete = createAction(
	'[Provider] Create Registration Request Complete',
	props<{ payload: any }>()
);

export const CreateRegistrationRequestFailed = createAction(
	'[Provider] Create Registration Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResendVerificationMail = createAction(
	'[Provider] Resend Verification Mail',
	props<{ payload: RegistrationRequestDTO }>()
);

export const ResendVerificationMailComplete = createAction(
	'[Provider] Resend Verification Mail Complete',
	props<{ payload: RegistrationRequestDTO }>()
);

export const ResendVerificationMailFailed = createAction(
	'[Provider] Resend Verification Mail Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteRegistrationRequest = createAction(
	'[Provider] Delete Registration Request',
	props<{ payload: number }>()
);

export const DeleteRegistrationRequestComplete = createAction(
	'[Provider] Delete Registration Request Complete',
	props<{ payload: any }>()
);

export const DeleteRegistrationRequestFailed = createAction(
	'[Provider] Delete Registration Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteUser = createAction(
	'[Provider] Delete User',
	props<{ payload: { username: string, personId?: number } }>()
);

export const DeleteUserComplete = createAction(
	'[Provider] Delete User Complete',
	props<{ payload: any }>()
);

export const DeleteUserFailed = createAction(
	'[Provider] Delete User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadUser = createAction(
	'[Provider] Load User',
	props<{ payload: string }>()
);

export const LoadUserComplete = createAction(
	'[Provider] Load User Complete',
	props<{ payload: UserDTO }>()
);

export const LoadUserFailed = createAction(
	'[Provider] Load User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UpdateUser = createAction(
	'[Provider] Update User',
	props<{ payload: { username: string, data: UserDTO } }>()
);

export const UpdateUserComplete = createAction(
	'[Provider] Update User Complete',
	props<{ payload: UserDTO }>()
);

export const UpdateUserFailed = createAction(
	'[Provider] Update User Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResetPassword = createAction(
	'[Provider] Reset Password',
	props<{ payload: PasswordResetRequestDTO }>()
);

export const ResetPasswordComplete = createAction(
	'[Provider] Reset Password Complete',
	props<{ payload: PasswordResetResponseDTO }>()
);

export const ResetPasswordFailed = createAction(
	'[Provider] Reset Password Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion
/** =========================== Patient Waiting List ================================ **/

export const LoadPageableWaitingList = createAction(
	'[Provider] Load Pageable Waiting List',
	props<{ payload: PageableWaitingListsRequestDTO }>()
);

export const LoadPageableWaitingListComplete = createAction(
	'[Provider] Load Pageable Waiting Complete',
	props<{ payload: PageableWaitingListsResponseDTO }>()
);

export const LoadPageableWaitingListFailed = createAction(
	'[Provider] Load Pageable Waiting Failed',
	props<{ error: HttpErrorResponse }>()
);

export const GetWaitingListBookingStatus = createAction(
	'[Provider] Get Waiting List Booking Status',
	props<{ payload: Array<number> }>()
);

export const GetWaitingListBookingStatusComplete = createAction(
	'[Provider] Get Waiting List Booking Status Complete',
	props<{ payload: Array<WaitingListBookingStatusResponseDTO> }>()
);

export const GetWaitingListBookingStatusFailed = createAction(
	'[Provider] Get Waiting List Booking Status Failed',
	props<{ error: HttpErrorResponse }>()
);

export const PatchWaitingListRequest = createAction(
	'[Provider] Patch Waiting List Request',
	props<{ payload: { requestId: number, data: { [key: string]: any; } } }>()
);

export const PatchWaitingListRequestComplete = createAction(
	'[Provider] Patch Waiting List Request Complete',
	props<{ payload: any }>()
);

export const PatchWaitingListRequestFailed = createAction(
	'[Provider] Patch Waiting List Request Failed',
	props<{ error: HttpErrorResponse }>()
);

//endregion
/** =========================== Provider List ================================ **/
export const LoadProviderList = createAction(
	'[Provider] Load Provider List',
	props<{ payload: string }>()
);

export const LoadProviderListComplete = createAction(
	'[Provider] Load Provider List Complete',
	props<{ payload: Array<PersonListInfoDTO> }>()
);

export const LoadProviderListFailed = createAction(
	'[Provider] Load Provider List Failed',
	props<{ error: HttpErrorResponse }>()
);
//endregion

export const ClearReinitializedProvider = createAction(
	'[Provider] Clear Reinitialized State',
	props<{ payload: string }>()
);

export const ClearState = createAction(
	'[Provider] Clear State'
);

export const Error = createAction(
	'[Provider] Error',
	props<{ error: HttpErrorResponse }>()
);
