import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DateUtils } from './date-utils';
import { isNullOrEmpty, pattern } from './helpers';

export class FormValidators {
	private static readonly emailRegex = new RegExp(pattern('email'));

	public static noWhitespace(control: FormControl) {
		const isWhitespace = control.value && control.value.length > 0 && (control.value || '').trim().length === 0;
		return !isWhitespace ? null : {'whitespace': true};
	}

	public static passwordComposition(control: FormControl) {
		const isValidPassword = control.value && control.value.trim().length > 7 && RegExp(pattern('password')).test(control.value);
		return isValidPassword ? null : {'invalidPassword': true};
	}

	public static noSpaces(control: FormControl) {
		const isValid = (control.value || '').indexOf(' ') === -1;
		return isValid ? null : {'whitespace': true};
	}

	public static matchString(exactString: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			const isValid = control.value !== undefined && control.value === exactString;
			return isValid ? null : {'matchString': true};
		};
	}

	public static isTime(control: FormControl) {
		const timeRegex = '^(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]$';
		if (control.value) {
			return control.value.toString().match(timeRegex) ? null : {'format': true};
		}
	}

	public static isTimeRounded(minutes: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			const isValid = (!isNullOrEmpty(control.value) && (Number(control.value.slice(3, 5)) % minutes === 0));
			if (minutes !== 45 && minutes !== 90) {
				return isValid ? null : {'slotInvalid': true};
			}
			return null;
		};
	}

	public static timeGroupSchedule(minutes?: number): ValidatorFn {
		return (group: FormGroup): { [key: string]: any } => {
			const invalidStartValue = {invalidInterval: true};
			const invalidEndValue = {invalidInterval: true};
			const startTime: string = group.controls['from'].value;
			const endTime: string = group.controls['to'].value;
			const timeRegex = '^(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]$';

			if (startTime && startTime.length > 0 && !startTime.toString().match(timeRegex)) {
				group.controls['from'].setErrors(invalidStartValue);
				return invalidStartValue;
			}

			if (endTime && endTime.length > 0 && !endTime.toString().match(timeRegex)) {
				group.controls['to'].setErrors(invalidStartValue);
				return invalidEndValue;
			}

			if (startTime && endTime) {
				if (minutes) {
					/** Calculation based on slotDuration */
					const date: Date = new Date();
					const dateStart: Date = new Date(date.getFullYear(), date.getMonth(), date.getDay(),
						+startTime.split(':')[0], +startTime.split(':')[1], 0, 0);
					const dateEnd: Date = new Date(date.getFullYear(), date.getMonth(), date.getDay(),
						+endTime.split(':')[0], +endTime.split(':')[1], 0, 0);
					const difference = Math.round((dateEnd.getTime() - dateStart.getTime()) / 60000);
					if (difference % minutes !== 0) {
						group.controls['to'].setErrors(invalidEndValue);
						return invalidEndValue;
					}
					group.controls['to'].setErrors(null);
					return null;
				} else {
					// TODO: Do we need this anymore?!
					const startHours: number = +startTime.split(':')[0];
					const startMinutes: number = +startTime.split(':')[1];

					const endHours: number = +endTime.split(':')[0];
					const endMinutes: number = +endTime.split(':')[1];

					if ((endHours < startHours) || (endHours === startHours && endMinutes <= startMinutes)) {
						group.controls['to'].setErrors(invalidEndValue);
						return invalidEndValue;
					} else if (group.controls['to'].errors && group.controls['to'].errors.invalidInterval) {
						group.controls['to'].setErrors(null);
						return null;
					}
				}
			}
			return null;
		};
	}

	public static periodGroupSchedule(group: FormGroup): { [key: string]: any } {
		const invalidValue = {error: 'Invalid!'};
		const groupMorning = (group.controls['MORNING'] as FormGroup);
		const groupAfternoon = (group.controls['AFTERNOON'] as FormGroup);

		if (groupAfternoon.controls['from'].value && groupMorning.controls['to'].value
			&& groupAfternoon.controls['from'].value <= groupMorning.controls['to'].value
			&& (groupAfternoon.controls['from'].value.length > 0 && groupMorning.controls['to'].value.length > 0)) {
			groupAfternoon.controls['from'].setErrors(invalidValue);
			groupAfternoon.controls['from'].markAsDirty();
			groupAfternoon.controls['from'].markAsTouched();
			return invalidValue;
		}

		groupAfternoon.controls['from'].setErrors(null);
		return null;
	}

	public static matchFields(formGroup: FormGroup) {
		const formControls: FormControl[] = [];

		if (!formGroup.controls) {
			return undefined;
		}

		Object.keys(formGroup.controls).forEach(key => {
			formControls.push(formGroup.controls[key] as FormControl);
		});

		let firstControl: FormControl;

		if (formControls.length < 2) {
			return undefined;
		}

		formControls.forEach((control, index) => {
			if (index === 0) {
				firstControl = control;
			} else {
				if (firstControl.value !== control.value) {
					control.setErrors({match_fields_error: true});
				} else {
					control.setErrors(null);
				}
			}
		});
	}

	/**
	 * custom validator to check that two fields match
	 */
	public static mustMatch(controlName: string, matchingControlName: string) {
		return (formGroup: FormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors.notSamePasswords) {
				// return if another validator has already found an error on the matchingControl
				return;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({notSamePasswords: true});
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	public static email(control: FormControl) {
		if (!control.value || control.value.trim().length < 1) {
			return null;
		}
		return FormValidators.emailRegex.test(control.value) ? null : {'email': true};
	}

	public static isObject(control: AbstractControl) {
		const selection = control.value;
		if (!isNullOrEmpty(selection) && typeof selection !== 'object') {
			return {incorrect: true};
		}
		return null;
	}

	public static inputErrorOnArrayLength(inputControlName: string, arrayControlName: string) {
		return (formGroup: FormGroup) => {
			const inputControl = formGroup.controls[inputControlName];
			const arrayControl = formGroup.controls[arrayControlName];

			if (arrayControl.value.length && inputControl.value.length) {
				inputControl.setErrors({expertiseProfessionsLimit: true});
			}
		};
	}

	public static billingType(formGroup: FormGroup) {
		let isOneChecked = false;
		let lastControl;

		Object.keys(formGroup.controls).forEach(control => {
			isOneChecked = isOneChecked || formGroup.controls[control].value;
			lastControl = formGroup.controls[control];
		});

		if (!isOneChecked) {
			lastControl.setErrors({min_one_required: true});
			lastControl.markAsDirty();
		} else {
			lastControl.setErrors(null);
		}
	}

	public static validateSameDayInterval(): ValidatorFn {
		return (group: FormGroup): { [key: string]: any } => {
			if (!group) {
				return null;
			}
			const inputStartDate = group.get('startDate').value;
			const inputEndDate = group.get('endDate').value;
			const inputStartTime = group.get('startTime');
			const inputEndTime = group.get('endTime');
			const ignoreTime = group.get('allDay').value;

			const invalidStartTime = !inputStartTime.value || inputStartTime.value.trim().length < 1;
			const invalidEndTime = !inputEndTime.value || inputEndTime.value.trim().length < 1;

			if (ignoreTime) {
				inputStartTime.setErrors(null);
				inputEndTime.setErrors(null);
				return null;
			} else if (invalidStartTime || invalidEndTime) {
				inputStartTime.setErrors(invalidStartTime ? {invalidInterval: true} : null);
				inputEndTime.setErrors(inputEndTime ? {invalidInterval: true} : null);
				return {invalidInterval: true};
			}

			if (inputStartDate && inputEndDate && inputStartTime && inputEndTime &&
				DateUtils.getDateSafe(inputStartDate).getTime() === DateUtils.getDateSafe(inputEndDate).getTime()) {

				const startHours: number = +inputStartTime.value.split(':')[0];
				const startMinutes: number = +inputStartTime.value.split(':')[1];

				const endHours: number = +inputEndTime.value.split(':')[0];
				const endMinutes: number = +inputEndTime.value.split(':')[1];

				if (endHours < startHours || (endHours === startHours && endMinutes <= startMinutes)) {
					inputStartTime.setErrors({invalidInterval: true});
					inputEndTime.setErrors({invalidInterval: true});
					return {invalidInterval: true};
				}

				inputStartTime.setErrors(null);
				inputEndTime.setErrors(null);
				return null;
			}

			inputStartTime.setErrors(null);
			inputEndTime.setErrors(null);
			return null;
		};
	}

	public static location(group, validLocation): void {
		// TODO: maybe refactor as validator/asyncValidator
		if (group.get('postalCode').touched || group.get('city').touched) {
			let extract;
			if (validLocation) {
				extract = validLocation.find(obj => {
						return +obj.postalCode === +group.get('postalCode').value && obj.city === group.get('city').value
					}
				);
			}

			const ERROR = !extract ? {matchZipCityObject: true} : null;

			if (!!group.get('postalCode').value && !!group.get('city').value) {
				group.get('postalCode').setErrors(ERROR);
				group.get('postalCode').markAsTouched();
				group.get('city').setErrors(ERROR);
				group.get('city').markAsTouched();
			}
		}
	}
}


