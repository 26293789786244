import { Injectable } from '@angular/core';

import { Store, Action } from '@ngrx/store';

@Injectable()
export class CmsFacade {

	constructor(private store: Store<any>) {
	}

	dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
