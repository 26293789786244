/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * CMS pages type enum dto
 */
export type CmsPageTypeEnumDTO = 'FAQ' | 'TERMS_AND_CONDITIONS' | 'PRIVACY' | 'IMPRESSIONS' | 'CONTACT' | 'LICENSES' | 'RELEASE_NOTES';

export const CmsPageTypeEnumDTO = {
    FAQ: 'FAQ' as CmsPageTypeEnumDTO,
    TERMSANDCONDITIONS: 'TERMS_AND_CONDITIONS' as CmsPageTypeEnumDTO,
    PRIVACY: 'PRIVACY' as CmsPageTypeEnumDTO,
    IMPRESSIONS: 'IMPRESSIONS' as CmsPageTypeEnumDTO,
    CONTACT: 'CONTACT' as CmsPageTypeEnumDTO,
    LICENSES: 'LICENSES' as CmsPageTypeEnumDTO,
    RELEASENOTES: 'RELEASE_NOTES' as CmsPageTypeEnumDTO
};

