/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Facet names Enum Data Transfer Object
 */
export type PersonFacetFieldEnumDTO = 'LANG_CODES' | 'LANG_NAMES' | 'SPECIALIZATIONS' | 'CITIES';

export const PersonFacetFieldEnumDTO = {
    LANGCODES: 'LANG_CODES' as PersonFacetFieldEnumDTO,
    LANGNAMES: 'LANG_NAMES' as PersonFacetFieldEnumDTO,
    SPECIALIZATIONS: 'SPECIALIZATIONS' as PersonFacetFieldEnumDTO,
    CITIES: 'CITIES' as PersonFacetFieldEnumDTO
};

