/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TreatmentDTOAllOf { 
    /**
     * Treatment name
     */
    name?: string;
    /**
     * Treatment description
     */
    description?: string;
    /**
     * Flag to indicate whether the treatment is active or not
     */
    active?: boolean;
    /**
     * Flag to indicate whether the treatment is the deafult treatment or not
     */
    defaultTreatment?: boolean;
    /**
     * Treatment duration
     */
    duration?: number;
    /**
     * The resource list attached to the treatment
     */
    resources?: Array<number>;
    /**
     * Id of the care provider
     */
    careProviderId?: number;
    /**
     * Buffer time before treatment
     */
    bufferTimeBefore?: number;
    /**
     * Buffer time after treatment
     */
    bufferTimeAfter?: number;
    /**
     * Allow employee resource assignment
     */
    allowEmployee?: boolean;
    /**
     * Allow room resource assignment
     */
    allowRooms?: boolean;
}

