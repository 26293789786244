/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * User role enum
 */
export type UserRoleEnumDTO = 'ROLE_SUPER_ADMIN' | 'ROLE_ADMIN' | 'ROLE_CUSTOMER_CARE' | 'ROLE_INSTITUTION_MANAGER' | 'ROLE_OFFICE_MANAGER' | 'ROLE_EMPLOYEE';

export const UserRoleEnumDTO = {
    SUPERADMIN: 'ROLE_SUPER_ADMIN' as UserRoleEnumDTO,
    ADMIN: 'ROLE_ADMIN' as UserRoleEnumDTO,
    CUSTOMERCARE: 'ROLE_CUSTOMER_CARE' as UserRoleEnumDTO,
    INSTITUTIONMANAGER: 'ROLE_INSTITUTION_MANAGER' as UserRoleEnumDTO,
    OFFICEMANAGER: 'ROLE_OFFICE_MANAGER' as UserRoleEnumDTO,
    EMPLOYEE: 'ROLE_EMPLOYEE' as UserRoleEnumDTO
};

