import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { AppUtils } from '@gp-angular/shared/utils';
import { AdminService } from '@gp-angular/service/admin';
import { ProviderService } from '@gp-angular/service/provider';
import { UserService } from '@gp-angular/service/user';

@Component({
	selector: 'gp-angular-error',
	templateUrl: './error.component.html'
})
export class ErrorComponent extends AbstractComponent implements OnInit {

	@HostBinding('class') class = 'template';

	public isLoggedIn: boolean;

	public readonly content: string = AppUtils.footerContent(this._environment);

	constructor(
		@Inject('ENVIRONMENT') private _environment: never,
		private adminService: AdminService,
		private router: Router,
		private providerService: ProviderService,
		private userService: UserService
	) {
		super();
	}

	public ngOnInit(): void {
		this.isLoggedIn = this.userService.isLoggedIn();
	}

	public reload(): void {
		super.addSubscription(combineLatest([this.adminService.clearState$(), this.providerService.clearState$(), this.userService.clearState$()])
			.pipe(take(1))
			.subscribe(() => this.router.navigate(['/']).then())
		);
	}

	public logout(): void {
		this.userService.logout();
	}
}
