import { ResourceCategoryWrapperDTO, ResourceDTO } from '@noventi/gp-platform/care-providers';

export class InternalUtils {
	/**
	 * 1. Finds the resourceWrapper into resourceState after type
	 * 2. Add, update or delete resource from resourceWrapper.items
	 * 3. Returns the new resources state
	 *
	 * @param resource
	 * @param resourceState
	 * @param isDelete
	 */
	public static getModifiedResourceState(resource: ResourceDTO, resourceState: Array<ResourceCategoryWrapperDTO>, isDelete: boolean = false): Array<ResourceCategoryWrapperDTO> {
		return resourceState.map((group) => {
			if (group.type === resource.type) {
				const index = Object.keys(group).includes('items') ? group.items.findIndex(item => item.id === resource.id) : -1;

				if (index < 0) {
					// NEW
					return {...group, items: [...group.items, resource]};
				} else if (isDelete) {
					// DETELE
					return {...group, items: [...group.items.slice(0, index), ...group.items.slice(index + 1)]};
				} else {
					// EDIT
					return {...group, items: [...group.items.slice(0, index), resource, ...group.items.slice(index + 1)]};
				}
			}
			return group;
		});

	}

	/**
	 * Clones  resourceItemList from store, update priority from payload, sort items after priority and return new state
	 *
	 * @param payload
	 * @param resourceState
	 */
	public static getReorderedResourceState(payload, resourceState: Array<ResourceCategoryWrapperDTO>): Array<ResourceCategoryWrapperDTO> {
		return resourceState.map((group) => {
			if (group.type === payload.type) {
				return {
					...group,
					items: group.items.map(item => {
						return {...item, priority: payload.items.find(orderItem => orderItem.id === item.id).index};
					}).sort((a, b) => a.priority - b.priority),
					random: payload.random
				}
			}
			return group;
		});
	}
}
