import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CmsAppTypeEnumDTO, CmsPageContentDTO, CmsPageTypeEnumDTO } from '@noventi/gp-platform/users';

export const LoadList = createAction(
	'[CMS] Load List',
	props<{ payload: CmsAppTypeEnumDTO }>()
);

export const CancelLoadList = createAction(
	'[CMS] Cancel Load List'
);

export const LoadListComplete = createAction(
	'[CMS] Load List Complete',
	props<{ payload: Array<CmsPageContentDTO> }>()
);

export const LoadListFailed = createAction(
	'[CMS] Load List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadContent = createAction(
	'[CMS] Load Content',
	props<{ payload: { type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO } }>()
);

export const CancelLoadContent = createAction(
	'[CMS] Cancel Load Content'
);

export const LoadContentComplete = createAction(
	'[CMS] Load Content Complete',
	props<{ payload: CmsPageContentDTO }>()
);

export const LoadContentFailed = createAction(
	'[CMS] Load Content Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SaveContent = createAction(
	'[CMS] Save Content',
	props<{ payload: { type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO, requestBody: { [key: string]: any; }, newRelease?: boolean } }>()
);

export const SaveContentComplete = createAction(
	'[CMS] Save Content Complete',
	props<{ payload: CmsPageContentDTO }>()
);

export const SaveContentFailed = createAction(
	'[CMS] Save Content Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ClearState = createAction(
	'[CMS] Clear State'
);

export const Error = createAction(
	'[CMS] Error',
	props<{ error: HttpErrorResponse }>()
);
