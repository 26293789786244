import {Observable, Subject} from 'rxjs';

export function isUndefined(value: any) {
	return typeof value === 'undefined';
}

export function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}

export function isNullOrEmpty(value: Array<any> | string) {
	return value == null || value === undefined || value.length === 0;
}

export function isNumber(value: any) {
	return typeof value === 'number';
}

export function isString(value: any) {
	return typeof value === 'string';
}

export function isBoolean(value: any) {
	return typeof value === 'boolean';
}

export function isObject(value: any) {
	return value !== null && typeof value === 'object';
}

export function isEmptyObject(obj) {
	for(const key in obj) {
		if(obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

export function isArray(obj: any) {
	return Array.isArray(obj);
}

export function isNumberFinite(value: any) {
	return isNumber(value) && isFinite(value);
}

export function arraysEqual(a, b) {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}

export function applyPrecision(num: number, precision: number) {
	if (precision <= 0) {
		return Math.round(num);
	}

	const tho = 10 ** precision;
	return Math.round(num * tho) / tho;
}

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
	const keys = map.split('.');
	const key = keys.shift();

	return keys.reduce((prop: any, k: string) => {
		return !isUndefined(prop) && !isUndefined(prop[k])
			? prop[k]
			: undefined;
	}, obj[key || '']);
}

export function getKeysTwoObjects(obj: any, other: any): any {
	return [...Object.keys(obj), ...Object.keys(other)]
		.filter((key, index, array) => array.indexOf(key) === index);
}

export function isDeepEqual(obj: any, other: any): any {
	if (!isObject(obj) || !isObject(other)) {
		return obj === other;
	}

	return getKeysTwoObjects(obj, other).every((key: any): boolean => {
		if (!isObject(obj[key]) && !isObject(other[key])) {
			return obj[key] === other[key];
		}
		if (!isObject(obj[key]) || !isObject(other[key])) {
			return false;
		}
		return isDeepEqual(obj[key], other[key]);
	});
}

export function padStart(targetString: string, targetLength: number, padString?: string) {
	targetLength = targetLength >> 0; // floor if number or convert non-number to 0;
	padString = String(padString || ' ');
	if (targetString.length > targetLength) {
		return String(targetString);
	} else {
		targetLength = targetLength - targetString.length;
		if (targetLength > padString.length) {
			padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
		}
		return padString.slice(0, targetLength) + String(targetString);
	}

}

export function replaceParams(text: string, params?: { [key: string]: string }) {
	if (!params) {
		return text;
	}
	const keys = Object.keys(params);
	keys.forEach(key => {
		text = text.replace('${' + key + '}', params[key]);
	});
	return text;
}

/**
 * Parameters for daysBetween should pass through this method,
 * separated for performance.
 *
 * @param date - the date to treat as UTC
 * @returns time in milliseconds
 */
export function treatAsUTC(date) {
	const result = new Date(date);
	result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
	return result.getTime();
}

/**
 * Calculate number of days between 2 dates. To account for daylight savings
 * time and leap seconds that the engine should handle use the @treatAsUTC(date)
 * method on the parameters.
 *
 * @param startDateMilliseconds - start date in milliseconds
 * @param endDateMilliseconds - end date in milliseconds
 * @returns - number of days between the dates (signed)
 */
export function daysBetween(startDateMilliseconds, endDateMilliseconds) {
	const millisecondsPerDay = 86400000; // 24 * 60 * 60 * 1000;
	return (endDateMilliseconds - startDateMilliseconds) / millisecondsPerDay;
}

export function pattern(type: string) {
	switch (type) {
		case 'email':
			return '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
		case 'password':
			return '^(?=.*[a-z]){1,}(?=.*[A-Z]){1,}(?=.*[0-9]){1,}(?=.*[!@#$%^&\\-\\_\\(\\)*]){1,}[\\w\\d!@#$%^&\\-\\_\\(\\)*\\s]+.{6,}$';
		case 'phone':
			return '^([\\+]{0,1})?([0-9])?[(]{0,1}[0-9]{1,4}[)]{0,1}[-+\\|\\s\\.\\/0-9]*$';
		case 'phoneDEV':
			// Allow phoneDE + Romanian numbers
			return '\\(?(\\+|00)?\\(?(49|40)\\)?[ ()]?([- ()]?\\d[- ()]?){2,11}';
		case 'phoneDE':
			return '\\(?(\\+|00)?\\(?49\\)?[ ()]?([- ()]?\\d[- ()]?){2,11}';
		case 'phoneWithoutPrefix':
			return '[ ()]?([- ()]?\\d[- ()]?){3,15}';
		case 'url':
			return '^((http(s)?:\\/\\/))?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$';
		case 'urlSimplePath':
			return '[a-z0-9_-]{1,}';
		case 'time':
			return '([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}';
		case 'integer':
			return '^\\d+$';
		case 'allow-negative-number':
			return '^\\-?\\d+$';
		case 'username':
			return '^[a-zA-Z0-9\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df\u1E9E\\._-]+$';
		case 'practiceName':
			return '([a-zA-Z0-9\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df\u1E9E-]{1,}(\\s){0,1}){0,}';
		default:
			return type;
	}
}

export function round(value: number, precision: number = 0): number {
	if (precision <= 0) {
		return Math.round(value);
	}

	// Test case: const value = 1.0049999952316284; const precision = 3; result = 1.005
	return Number(Math.round(Number(value + 'e' + precision)) + 'e-' + precision);
}

const lut = [];
for (let i = 0; i < 256; i++) {
	lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
}
export function uuid() {
	const crypto = window.crypto;
	const d0 = crypto.getRandomValues(new Uint32Array(1))[0];
	const d1 = crypto.getRandomValues(new Uint32Array(1))[0];
	const d2 = crypto.getRandomValues(new Uint32Array(1))[0];
	const d3 = crypto.getRandomValues(new Uint32Array(1))[0];

	return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + '-' +
				 lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + '-' + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + '-' +
				 lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + '-' + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] +
				 lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
}

export function asISOString(date: string | Date): string {
	if (typeof date === 'string') {
		return date;
	}
	return date.toISOString();
}

export function asDate(date: string | Date) {
	const IS_SAFARI = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
						navigator.userAgent &&
						navigator.userAgent.indexOf('CriOS') === -1 &&
						navigator.userAgent.indexOf('FxiOS') === -1;
	const DATE: Date = typeof date === 'string' ? (IS_SAFARI ? new Date(formatDate(date)) : new Date(date)) : date;
	return new Date(DATE.getTime() - (DATE.getTimezoneOffset() * 60000));
}

/**
 * Workaround because Safari... It does not consider time zone offset when creates an instance with date string.
 * @param value
 */
function formatDate(value) {
	const field = value.match(/^([+-]?\d{4}(?!\d\d\b))(?:-?(?:(0[1-9]|1[0-2])(?:-?([12]\d|0[1-9]|3[01]))?)(?:[T\s](?:(?:([01]\d|2[0-3])(?::?([0-5]\d))?|24\:?00)([.,]\d+(?!:))?)?(?::?([0-5]\d)(?:[.,](\d+))?)?([zZ]|([+-](?:[01]\d|2[0-3])):?([0-5]\d)?)?)?)?$/) || [];
	const result = new Date(field[1], field[2] - 1 | 0, field[3] || 1, field[4] | 0, field[5] | 0, field[7] | 0, field[8] | 0);
	if (field[9]) {
		result.setUTCMinutes(result.getUTCMinutes() - result.getTimezoneOffset() - ((field[10] * 60 + +field[11]) || 0));
	}
	return result;
}

export function getCurrentDate(): Date {
	const NOW = new Date(Date.now());
	return new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate(), 12, 0, 0, 0);
}

export function dateDifferenceInDays(date1: Date, date2: Date) {
	const DATE_1 = date1;
	const DATE_2 = date2;
	DATE_1.setHours(0, 0, 0, 0);
	DATE_2.setHours(0, 0, 0, 1);
	return Math.ceil((DATE_1.getTime() - DATE_2.getTime()) / (1000 * 3600 * 24));
}

export function getResetDate(date?: Date): Date {
	const NOW: Date = !isNullOrUndefined(date) ? date : new Date();
	return new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate(), 12, 0, 0, 0);
}

export function getMonthStartDate(date?: Date): Date {
	const NOW: Date = !isNullOrUndefined(date) ? date : new Date();
	return new Date(NOW.getFullYear(), NOW.getMonth(), 1, 12, 0, 0, 0);
}

export function addDays(date?: Date, days?: number): Date {
	const NOW: Date = !isNullOrUndefined(date) ? date : new Date();
	const DAY: number = !isNullOrUndefined(days) ? days : 0;
	NOW.setDate(NOW.getDate() + DAY);
	return NOW;
}

export function calculateTokenExpiration(value: any): string {
	if (isNullOrUndefined(value)) {
		return undefined;
	}
	const minutes = Math.floor(value / 60);
	const seconds = value - minutes * 60;
	return padStart(minutes + '', 2, '0') + ':' +
		   padStart(seconds + '', 2, '0');
}

// TODO maybe expand this relative to our convetions about how/what the backend erros will look liek
export function remoteError(error: string): string {
	return 	error.match(/^-{0,1}\d+$/) ? `RemoteError.${error}` : error;
}

export function getBase64(event): Observable<string> {
	const [file] = event.target.files;
	const sub = new Subject<string>();
	const fr = new FileReader();

	fr.onload = () => {
		// data:image/jpeg;base64
	    const base64PreContent: string =  'data:' + file.type + ';base64,';
	    if (file.size > 0) {
			const content: string = base64PreContent + btoa(fr.result as string);
			sub.next(content);
		} else {
			sub.error(true);
		}
		sub.complete();
	};

	fr.readAsBinaryString(file);
	return sub.asObservable();
}

export function getDaysToDisplay(year, month, cDay: Array<number>) {
	let counter = 0;
	cDay.forEach((dayNo: number) => {
		let day = 1;
		let date = new Date(year, month, day);

		while (date.getMonth() === month) {
			if (date.getDay() === dayNo) { // Sun=0, Mon=1, Tue=2, etc.
				counter += 1;
			}
			day += 1;
			date = new Date(year, month, day);
		}
	})
	return counter;
}
