/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AbsentHourDTOAllOf { 
    /**
     * Hour start date
     */
    startDate?: string;
    /**
     * Hour start time
     */
    startTime?: string;
    /**
     * Hour end date
     */
    endDate?: string;
    /**
     * Hour end time
     */
    endTime?: string;
    /**
     * Reason
     */
    reason?: string;
    allDay?: boolean;
    /**
     * Id of the resource
     */
    resourceId?: number;
}

