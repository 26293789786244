import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { AppointmentFacade } from './+state/appointment.facade';
import * as AppointmentAction from './+state/appointment.actions';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { InternalUtils } from './internal.utils';
import { PersonDTO, PublicPersonDTO, PublicTreatmentDTO, QuerySearchByExistingIdsDTO } from '@noventi/gp-platform/care-providers';
import {
	AppointmentSearchResponseDTO, AvailableSlotDTO, CheckTokenDurationDTO, CheckWaitingListTokenDurationDTO, CreatePublicAppointmentDTO,
	CreatePublicWaitingListDTO, ExtCalendarAuthDTO, ExternalAuthUrlResponseDTO, FreeSlotsResponseDTO,
	OnlineAppointmentService,
	PublicAppointmentDTO, PublicWaitingListDTO, ResendTokenRequestDTO, SearchAppointmentDTO, TokenTypeResponseDTO,
	TokenValidationRequestDTO, TreatmentDTO
} from '@noventi/gp-platform/online-appointments';
import { PageablePatientsResponseDTO, SearchPatiensRequestDTO } from '@noventi/gp-platform/patients';

@Injectable({
	providedIn: 'root'
})

export class AppointmentService extends AbstractComponent {

	constructor(
		private actionsSubject$: ActionsSubject,
		private appointmentFacade: AppointmentFacade,
		private appointmentServiceApi: OnlineAppointmentService
	) {
		super();
	}

	public token$(token: string): Observable<TokenTypeResponseDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.Token({payload: token}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.TokenCompleted, AppointmentAction.TokenFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.TokenFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadProviderByWidgetToken$(token: string): Observable<PersonDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.LoadBookingProviderByToken({payload: token}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.LoadBookingProviderByTokenCompleted, AppointmentAction.LoadBookingProviderByTokenFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.LoadBookingProviderByTokenFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	//public getProvider$() {
	//	return this.actionsSubject$.pipe(
	//		ofType(AppointmentAction.LoadBookingProviderByTokenCompleted, AppointmentAction.LoadBookingProviderByTokenFailed),
	//		distinctUntilChanged(),
	//		switchMap((action) => {
	//			if (action.type === AppointmentAction.LoadBookingProviderByTokenFailed.type) {
	//				return throwError(action.error);
	//			}
	//			return of(action.payload);
	//		})
	//	);
	//}

	public readProvider$(): Observable<PublicPersonDTO> {
		return this.appointmentFacade.provider$;
	}

	public readProvider(): PublicPersonDTO | undefined {
		let provider;
		this.appointmentFacade.provider$
			.pipe(take(1))
			.subscribe((next) => provider = next);
		return provider;
	}

	public createAppointment$(appointment: { [key: string]: any }, widgetToken: string, xSmsSimulate?: boolean) {
		const PAYLOAD = {
			appointment: (InternalUtils.prepareServerData(appointment) as CreatePublicAppointmentDTO), widgetToken: widgetToken
		};
		if (!!xSmsSimulate) {
			PAYLOAD['xSmsSimulate'] = xSmsSimulate;
		}
		this.appointmentFacade.dispatch(AppointmentAction.CreateAppointment({payload: PAYLOAD}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.CreateAppointmentCompleted, AppointmentAction.CreateAppointmentFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.CreateAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}


	public createRequest$(request: { [key: string]: any }, widgetToken: string, xSmsSimulate?: boolean) {
		const PAYLOAD = {
			createPublicAppointmentDTO: (InternalUtils.prepareServerData(request, true) as CreatePublicWaitingListDTO),
			widgetToken: widgetToken
		};
		if (!!xSmsSimulate) {
			PAYLOAD['xSmsSimulate'] = xSmsSimulate;
		}
		this.appointmentFacade.dispatch(AppointmentAction.CreateRequest({payload: PAYLOAD}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.CreateRequestCompleted, AppointmentAction.CreateRequestFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.CreateRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public confirmTokenValidation$(tokenValidationRequest: TokenValidationRequestDTO, type: string, xSmsSimulate: boolean) {
		const PAYLOAD = {tokenValidationRequest: tokenValidationRequest, xSmsSimulate: xSmsSimulate};
		if (type === TokenTypeResponseDTO.WAITINGLIST) {
			this.appointmentFacade.dispatch(AppointmentAction.ConfirmTokenValidationRequest({payload: PAYLOAD}));
		} else {
			this.appointmentFacade.dispatch(AppointmentAction.ConfirmTokenValidationAppointment({payload: PAYLOAD}));
		}

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.ConfirmTokenValidationCompleted, AppointmentAction.ConfirmTokenValidationFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.ConfirmTokenValidationFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadTokenAppointmentDuration(payload: CheckTokenDurationDTO) {
		this.appointmentFacade.dispatch(AppointmentAction.CheckTokenAppointmentDuration({payload: payload}));
	}

	public loadTokenRequestDuration(payload: CheckWaitingListTokenDurationDTO) {
		this.appointmentFacade.dispatch(AppointmentAction.CheckTokenRequestDuration({payload: payload}));
	}

	public getTokenDuration$() {
		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.CheckTokenDurationCompleted, AppointmentAction.CheckTokenDurationFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.CheckTokenDurationFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resendToken$(resendTokenRequest: ResendTokenRequestDTO, type: string, xSmsSimulate?: boolean) {
		const PAYLOAD = {resendTokenRequest: resendTokenRequest};
		if (!!xSmsSimulate) {
			PAYLOAD['xSmsSimulate'] = xSmsSimulate;
		}
		if (type === TokenTypeResponseDTO.WAITINGLIST) {
			this.appointmentFacade.dispatch(AppointmentAction.ResendTokenRequest({payload: PAYLOAD}));
		} else {
			this.appointmentFacade.dispatch(AppointmentAction.ResendTokenAppointment({payload: PAYLOAD}));
		}

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.ResendTokenCompleted, AppointmentAction.ResendTokenFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.ResendTokenFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getAppointmentByToken(token: string) {
		this.appointmentFacade.dispatch(AppointmentAction.DetailByTokenAppointment({payload: token}));
	}

	public getAppointmentByToken$(): Observable<PublicAppointmentDTO> {
		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.DetailByTokenAppointmentCompleted, AppointmentAction.DetailByTokenAppointmentFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.DetailByTokenAppointmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getRequestByToken(token: string) {
		this.appointmentFacade.dispatch(AppointmentAction.DetailByTokenRequest({payload: token}));
	}

	public getRequestByToken$(): Observable<PublicWaitingListDTO> {
		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.DetailByTokenRequestCompleted, AppointmentAction.DetailByTokenRequestFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.DetailByTokenRequestFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public setAppointmentError(errorText: string, isCancellation: boolean = false) {
		this.appointmentFacade.dispatch(AppointmentAction.SetTokenError(
			{payload: {errorText: errorText, isCancellation: isCancellation}}));
	}

	public readAppointmentError$(): Observable<HttpErrorResponse | any> {
		return this.appointmentFacade.error$;
	}

	/**
	 * Appointment cancellation
	 */
	public cancelAppointmentByToken$(payload: { token: string, xSmsSimulate?: boolean }, type: string) {
		if (type === TokenTypeResponseDTO.WAITINGLIST) {
			this.appointmentFacade.dispatch(AppointmentAction.CancelByTokenRequest({payload: payload}));
		} else {
			this.appointmentFacade.dispatch(AppointmentAction.CancelByTokenAppointment({payload: payload}));
		}

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.CancelByTokenCompleted, AppointmentAction.CancelByTokenFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.CancelByTokenFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public confirmCancellation$(tokenValidationRequest: TokenValidationRequestDTO, type: string) {
		if (type === TokenTypeResponseDTO.WAITINGLIST) {
			this.appointmentFacade.dispatch(AppointmentAction.ConfirmCancellationRequest({payload: tokenValidationRequest}));
		} else {
			this.appointmentFacade.dispatch(AppointmentAction.ConfirmCancellationAppointment({payload: tokenValidationRequest}));
		}

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.ConfirmCancellationCompleted, AppointmentAction.ConfirmCancellationFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.ConfirmCancellationFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public clear() {
		this.appointmentFacade.dispatch(AppointmentAction.ClearState());
	}

	public searchPatient$(payload: SearchPatiensRequestDTO): Observable<PageablePatientsResponseDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.SearchPatient({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SearchPatientComplete, AppointmentAction.SearchPatientFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SearchPatientFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	// TODO: use action/effect
	public searchAppointmentByPatientName(careProviderId: number, query: string): Observable<AppointmentSearchResponseDTO> {
		if (query && query.length > 2) {
			const searchAppointmentDTO: SearchAppointmentDTO = {careProviderId, query};
			return this.appointmentServiceApi.searchAppointment(searchAppointmentDTO);
		}
		return EMPTY;
	}

	public searchTreatments(query: QuerySearchByExistingIdsDTO): Observable<Array<TreatmentDTO>> {
		this.appointmentFacade.dispatch(AppointmentAction.SearchTreatmentList({payload: query}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SearchTreatmentListCompleted, AppointmentAction.SearchTreatmentListFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.SearchTreatmentListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public uploadPhotos(attachments: Blob) {
		this.appointmentFacade.dispatch(AppointmentAction.UploadPhotos({payload: attachments}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.UploadPhotosCompleted, AppointmentAction.UploadPhotosFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.UploadPhotosFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getResourceAvailableSlots$(request: AvailableSlotDTO): Observable<FreeSlotsResponseDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.LoadResourceAvailableSlots({payload: request}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.LoadResourceAvailableSlotsCompleted, AppointmentAction.LoadResourceAvailableSlotsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.LoadResourceAvailableSlotsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getAppointmenAttachments(appointmentID: number, attachments: string) {
		this.appointmentFacade.dispatch(AppointmentAction.LoadAppointmentAttachments({
			payload: {appointmentID: appointmentID, attachments: attachments}
		}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.LoadAppointmentAttachmentsCompleted, AppointmentAction.LoadAppointmentAttachmentsFailed),
			distinctUntilChanged(),
			switchMap((action) => {
				if (action.type === AppointmentAction.LoadAppointmentAttachmentsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadPublicTreatment(id: number): void {
		this.appointmentFacade.dispatch(AppointmentAction.LoadPublicTreatment({payload: id}));
	}

	public getPublicTreatment$(): Observable<PublicTreatmentDTO> {
		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.LoadPublicTreatmentComplete, AppointmentAction.LoadPublicTreatmentFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.LoadPublicTreatmentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public error$(): Observable<any> {
		return this.appointmentFacade.error$;
	}

	public loadSyncAccounts(username: string): void {
		this.appointmentFacade.dispatch(AppointmentAction.SyncAccounts({payload: username}));
	}

	public getSyncAccounts$(): Observable<Array<ExtCalendarAuthDTO>> {
		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SyncAccountsComplete, AppointmentAction.SyncAccountsFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SyncAccountsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public syncGoogleAuth$(): Observable<ExternalAuthUrlResponseDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.SyncGoogleAuth());

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SyncGoogleAuthComplete, AppointmentAction.SyncGoogleAuthFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SyncGoogleAuthFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public syncGoogleCode$(username: string, code: string): Observable<ExtCalendarAuthDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.SyncGoogleCode({payload: {username: username, code: code}}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SyncGoogleCodeComplete, AppointmentAction.SyncGoogleCodeFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SyncGoogleCodeFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public syncOffice365Auth$(): Observable<ExternalAuthUrlResponseDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.SyncOffice3655Auth());

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SyncOffice365AuthComplete, AppointmentAction.SyncOffice365AuthFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SyncOffice365AuthFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public syncOffice365Code$(username: string, code: string): Observable<ExtCalendarAuthDTO> {
		this.appointmentFacade.dispatch(AppointmentAction.SyncOffice365Code({payload: {username: username, code: code}}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.SyncOffice365CodeComplete, AppointmentAction.SyncOffice365CodeFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.SyncOffice365CodeFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public deleteCalendarSync$(username: string, id: number): Observable<any> {
		this.appointmentFacade.dispatch(AppointmentAction.DeleteCalendarSync({payload: {username: username, id: id}}));

		return this.actionsSubject$.pipe(
			ofType(AppointmentAction.DeleteCalendarSyncComplete, AppointmentAction.DeleteCalendarSyncFailed),
			switchMap((action) => {
				if (action.type === AppointmentAction.DeleteCalendarSyncFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}
}
