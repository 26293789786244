import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private tokenExtractor: HttpXsrfTokenExtractor
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const headerName = 'XSRF-TOKEN';
		const respHeaderName = 'X-XSRF-TOKEN';
		const token = this.tokenExtractor.getToken();

		if (token !== null && !req.headers.has(headerName) && !!this._environment && this._environment.app !== 'widget') {
			req = req.clone({ headers: req.headers.set(respHeaderName, token) });
		}
		return next.handle(req);
	}
}
