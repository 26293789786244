import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

export class ModalUtils {
	public static config(size: string = 'default'): MatDialogConfig {
		return {
			autoFocus: false,
			disableClose: false,
			closeOnNavigation: true,
			restoreFocus: false,
			data: {
				disableClose: true,
				actionsConfig: {
					align: 'right'
				}
			},
			...this._width(size)
		} as MatDialogConfig;
	}

	private static _width(size: string): MatDialogConfig {
		const CONFIG = {};
		switch (size) {
			case 'small':
				CONFIG['maxWidth'] = '98vw';
				CONFIG['width'] = 600;
				break;
			case 'medium':
				CONFIG['maxWidth'] = '98vw';
				CONFIG['width'] = 1023;
				break;
			case 'large':
				CONFIG['maxWidth'] = '98vw';
				CONFIG['width'] = 1200;
				break;
			case 'extra':
				CONFIG['maxWidth'] = '98vw';
				CONFIG['width'] = '100%';
				break;
			default:
				break;

		}
		return CONFIG as MatDialogConfig;
	}

	public static checkFormToCloseModal(dialog: MatDialogRef<any>, status: boolean, message: string): void {
		if (status) {
			const confirmation = window.confirm(message);
			if (confirmation) {
				dialog.close(false);
			}
		} else {
			dialog.close(false);
		}
	}
}
