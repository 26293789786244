import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorUtils {

	constructor(
		private readonly translate: TranslateService
	) {
	}

	public getIntl(pageLabel: string): MatPaginatorIntl {
		const INTL = new MatPaginatorIntl();
		INTL.itemsPerPageLabel = this.translate.instant(pageLabel);
		INTL.firstPageLabel = this.translate.instant('App.Paginator.FirstPageLabel');
		INTL.nextPageLabel = this.translate.instant('App.Paginator.NextPageLabel');
		INTL.previousPageLabel = this.translate.instant('App.Paginator.PreviousPageLabel');
		INTL.lastPageLabel = this.translate.instant('App.Paginator.LastPageLabel');
		INTL.getRangeLabel = this._getRangeLabel.bind(this);
		return INTL;
	}

	private _getRangeLabel(page: number, pageSize: number, length: number): string {
		if (length === 0 || pageSize === 0) {
			return this.translate.instant('App.Paginator.RangePageLabel_1', {length});
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return this.translate.instant('App.Paginator.RangePageLabel_2', {startIndex: startIndex + 1, endIndex, length});
	}
}
