import { Component, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, Inject } from '@angular/core';
import { AppUtils } from '@gp-angular/shared/utils';

@Component({
	selector: 'gp-angular-country-prefix',
	templateUrl: './country-prefix.component.html',
	styleUrls: ['./country-prefix.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryPrefixComponent {

	public countryPrefixList: string[] = AppUtils.getCountryCodeList(this._environment);

	@Input() countryPrefix: string;

	@Input() disabled: boolean;

	@Output() prefixChanged = new EventEmitter<string>();

	constructor(@Inject('ENVIRONMENT') private _environment: never) {
	}

	public onPrefixChanges(prefix: string) {
		if (this.disabled) {
			return;
		}
		this.prefixChanged.emit(prefix);
	}
}
