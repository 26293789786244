import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from './analytics.service';

@NgModule({
	imports: [CommonModule]
})
export class ServiceAnalyticsModule {
	static forRoot(): ModuleWithProviders<ServiceAnalyticsModule> {
		return {
			ngModule: ServiceAnalyticsModule,
			providers: [AnalyticsService]
		};
	}
}
