import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as AppointmentActions from './appointment.actions';
import { PublicPersonDTO } from '@noventi/gp-platform/care-providers';

export const APPOINTMENT_FEATURE_KEY = 'appointment';

export interface State extends EntityState<{}> {
	detail: any;
	provider: PublicPersonDTO;
	patient: any;
	loading: boolean;
	error?: HttpErrorResponse | null | { errorText: string, isCancellation: boolean }
}

export interface AppointmentPartialState {
	readonly [APPOINTMENT_FEATURE_KEY]: State;
}

export const appointmentAdapter: EntityAdapter<State> = createEntityAdapter<State>();

export const initialState: State = appointmentAdapter.getInitialState({
	detail: undefined,
	provider: undefined,
	patient: undefined,
	loading: false,
	error: undefined
});

const appointmentReducer = createReducer(
	initialState,

	on(AppointmentActions.LoadPatient, state => ({...state, loading: true})),
	on(AppointmentActions.LoadPatientCompleted, (state, {payload}) => ({...state, patient: payload})),
	on(AppointmentActions.LoadPatientFailed, state => ({...state, loading: false})),

	on(AppointmentActions.LoadBookingProviderByToken, state => ({...state, loading: true})),
	on(AppointmentActions.LoadBookingProviderByTokenCompleted, (state, {payload}) => ({...state, provider: payload})),
	on(AppointmentActions.LoadBookingProviderByTokenFailed, state => ({...state, loading: false})),

	on(AppointmentActions.SetTokenError, (state, {payload}) => ({...state, error: payload})),

	on(AppointmentActions.Error, (state, {error}) => ({...state, error})),

	on(AppointmentActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return appointmentReducer(state, action);
}
