/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A public personDTO containing only the needed data for public paths
 */
export interface PortalPersonDTO { 
    /**
     * Person salutation
     */
    salutation?: string;
    /**
     * Firstname of person
     */
    firstname?: string;
    /**
     * Lastname of person
     */
    lastname?: string;
    /**
     * Academical degree
     */
    academicalDegree?: string;
    /**
     * Unique token for care provider identification in widget
     */
    widgetToken?: string;
    /**
     * City
     */
    city?: string;
    /**
     * Postal code
     */
    postalCode?: string;
    /**
     * Street
     */
    street?: string;
    /**
     * House number
     */
    houseNumber?: string;
    allowRegisterRequest?: boolean;
}

