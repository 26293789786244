/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RecipientReportDTO } from './recipientReportDTO';


/**
 * SMS Callback from Retarus
 */
export interface CallbackJobRequestDTO { 
    readonly jobId?: string;
    /**
     * Sender ID.
     */
    readonly src?: string;
    readonly encoding?: CallbackJobRequestDTO.EncodingEnum;
    readonly billcode?: string;
    /**
     * Delivery notification requested.
     */
    readonly statusRequested?: boolean;
    readonly flash?: boolean;
    /**
     * Validity of the SMS in minutes.
     */
    readonly validityMin?: number;
    /**
     * Recommended max. 64 characters.
     */
    readonly customerRef?: string;
    /**
     * Quality of Service.
     */
    readonly qos?: CallbackJobRequestDTO.QosEnum;
    /**
     * Timestamp indicating when the job was received by Retarus (iso-8601).
     */
    readonly receiptTs?: string;
    /**
     * Timestamp indicating when the SMS received its final status (iso-8601).
     */
    readonly finishedTs?: string;
    readonly recipientIds?: Array<string>;
    readonly recipients?: Array<RecipientReportDTO>;
}
export namespace CallbackJobRequestDTO {
    export type EncodingEnum = 'standard' | 'utf-16';
    export const EncodingEnum = {
        Standard: 'standard' as EncodingEnum,
        Utf16: 'utf-16' as EncodingEnum
    };
    export type QosEnum = 'EXPRESS' | 'NORMAL';
    export const QosEnum = {
        EXPRESS: 'EXPRESS' as QosEnum,
        NORMAL: 'NORMAL' as QosEnum
    };
}


