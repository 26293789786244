/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PublicAppointmentResourceDTO { 
    /**
     * Resource first name (in case of employee resources)
     */
    resourceFirstName?: string;
    /**
     * Resource last name (in case of employee resources)
     */
    resourceLastName?: string;
    /**
     * Resource name (in case of rooms)
     */
    resourceName?: string;
}

