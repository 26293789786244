import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { asyncScheduler, Observable, of, scheduled, throwError } from 'rxjs';
import { AccountFacade } from './+state/account.facade';
import { UserFacade } from './+state/user.facade';
import * as UserAction from './+state/user.actions';
import { isNullOrEmpty, UserUtils } from '@gp-angular/shared/utils';
import {
	MfaDTO, MfaUpdateRequestDTO, MfaValidationCodeRequestDTO, MfaValidationCodeResponseDTO, NotificationDTO, PasswordResetDTO,
	PasswordResetResponseDTO, PersonRoleResourcesDTO, SearchNotAssignedUserRequestDTO,
	StateSettingsDTO, UserDTO, UserOnboardingDTO, UserRoleEnumDTO
} from '@noventi/gp-platform/users';
import { SendPredefinedEmailRequestDTO } from '@noventi/gp-platform/emailing';
import { InternalUtils } from './internal.utils';

@Injectable()
export class UserService {

	constructor(
		private actionsSubject$: ActionsSubject,
		private accountFacade: AccountFacade,
		private userFacade: UserFacade
	) {
	}

	public login$(user: { username: string, password: string }) {
		this.userFacade.dispatch(UserAction.ClearState());
		this.userFacade.dispatch(UserAction.Login({payload: user}));

		return this.getLoginResult$();
	}

	public getLoginResult$(): Observable<any> {
		return this.actionsSubject$.pipe(
			ofType(UserAction.LoginComplete, UserAction.LoginFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.LoginFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public permissions$(): Observable<Array<string>> {
		return this.userFacade.permissions$;
	}

	public uan$(): Observable<string> {
		return this.userFacade.uan$;
	}

	public loading(value: boolean = true) {
		this.userFacade.dispatch(UserAction.Loading({loading: value}));
	}

	public loading$(): Observable<boolean> {
		return this.userFacade.loading$;
	}

	public loginMfa$(payload: { mfa_token: string, mfa_code: string, token: string, trusted: boolean }): Observable<any> {
		this.userFacade.dispatch(UserAction.LoginMFA({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.LoginMFAComplete, UserAction.LoginMFAFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.LoginMFAFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public logout(revoke: boolean = true) {
		this.userFacade.dispatch(UserAction.Logout({payload: revoke}));
	}

	public shouldLogout$() {
		return this.actionsSubject$.pipe(
			ofType(UserAction.Logout, UserAction.ClearState),
			switchMap(() => {
				return of(true);
			})
		);
	}

	public isLoggedIn(): boolean {
		let isLoggedIn = false;
		this.userFacade.username$
			.pipe(take(1))
			.subscribe((username) => isLoggedIn = !!username);
		return isLoggedIn;
	}

	public isRole(role: UserRoleEnumDTO): boolean {
		const permissions: Array<string> = this.getPermissions();
		return !isNullOrEmpty(permissions) && permissions.length === 1 && permissions[0] === role;
	}

	public isRoleAllowedOn(allowedRoles: Array<UserRoleEnumDTO>): boolean {
		const isLoggedIn: boolean = this.isLoggedIn();

		if (isLoggedIn) {
			const permissions: Array<string> = this.getPermissions();
			return isNullOrEmpty(allowedRoles) || isNullOrEmpty(permissions) ||
				   !!allowedRoles.find((role) => permissions.indexOf(role) >= 0);
		} else {
			return false;
		}
	}

	public getPermissions(): Array<UserRoleEnumDTO> {
		let permissions = [undefined];
		this.userFacade.permissions$
			.pipe(take(1))
			.subscribe((perms) => permissions = perms);
		return permissions;
	}

	public getUser(): string {
		let user;
		this.userFacade.username$
			.pipe(take(1))
			.subscribe((username) => user = username);
		return user;
	}

	public loadUser$(): Observable<UserDTO> {
		this.userFacade.dispatch(UserAction.LoadUser());

		return this.getUser$();
	}

	public getUser$(): Observable<UserDTO> {
		return this.actionsSubject$.pipe(
			ofType(UserAction.LoadUserComplete, UserAction.LoadUserFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.LoadUserFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public readUsername$(): Observable<string> {
		return this.userFacade.username$;
	}

	public getOnboarding() {
		this.userFacade.dispatch(UserAction.GetOnboarding());
	}

	public setOnboarding(value: number) {
		this.userFacade.dispatch(UserAction.SetOnboarding({payload: {step: value}}));
	}

	public getOnboarding$(): Observable<UserOnboardingDTO> {
		this.userFacade.dispatch(UserAction.GetOnboarding());

		return this.actionsSubject$.pipe(
			ofType(UserAction.GetOnboardingComplete, UserAction.GetOnboardingFailed),
			switchMap((action) => {
				if (action.type === UserAction.GetOnboardingFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resetEmail(email: string): Observable<PasswordResetResponseDTO> {
		this.userFacade.dispatch(UserAction.ResetEmailPassword({payload: {email: email}}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.ResetEmailPasswordComplete, UserAction.ResetEmailPasswordFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.ResetEmailPasswordFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resetPasswordValidationToken(token: string): Observable<any> {
		this.userFacade.dispatch(UserAction.ResetPasswordValidationToken({payload: {token: token}}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.ResetPasswordValidationTokenComplete, UserAction.ResetPasswordValidationTokenFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.ResetPasswordValidationTokenFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public resetPassword(passwordReset: PasswordResetDTO): Observable<any> {
		this.userFacade.dispatch(UserAction.ResetPassword({payload: passwordReset}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.ResetPasswordComplete, UserAction.ResetPasswordFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.ResetPasswordFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public contact(emailRequest: SendPredefinedEmailRequestDTO, providerId?: string) {
		/**
		 * Add parameter careProviderId if user is logged in as a provider
		 * Extract it into a service is used multiple times
		 */
		//let providerId;
		//if (this.isRole(UserRoleEnumDTO.INSTITUTIONMANAGER)) {
		//	this.userFacade.select(ProviderSelectors.selectProviderData).pipe(
		//		filter(data => !isNullOrUndefined(data)),
		//		take(1)
		//		)
		//		.subscribe(
		//			(provider: PersonDTO) => {
		//				providerId = provider.id;
		//			}
		//		);
		//}

		if (!!providerId) {
			emailRequest.variables.push({name: 'careProviderId', value: providerId});
		}

		this.userFacade.dispatch(UserAction.Contact({payload: emailRequest}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.ContactComplete, UserAction.ContactFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.ContactFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public renewPasswordNeeded() {
		return this.actionsSubject$.pipe(
			ofType(UserAction.LoginComplete, UserAction.RefreshTokenComplete),
			filter(value => !!value),
			switchMap(() => {
				return this.userFacade.passwordExpirationDate$;
			})
		);
	}


	public refreshToken$(): Observable<boolean> {
		this.userFacade.dispatch(UserAction.RefreshToken());

		return this.actionsSubject$.pipe(
			ofType(UserAction.RefreshTokenComplete, UserAction.RefreshTokenFailed),
			take(1),
			debounceTime(1000),
			switchMap((action) => {
				if (action.type === UserAction.RefreshTokenFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}


	public sendActivationCodeMfa(mfaValidationCodeRequest: MfaValidationCodeRequestDTO, xSmsSimulate?: boolean): Observable<MfaValidationCodeResponseDTO> {
		this.userFacade.dispatch(UserAction.MultiFactorAuthSendActivationCode({
			payload: {
				mfaValidationCodeRequest: mfaValidationCodeRequest,
				xSmsSimulate: xSmsSimulate
			}
		}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.MultiFactorAuthSendActivationCodeComplete, UserAction.MultiFactorAuthSendActivationCodeFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.MultiFactorAuthSendActivationCodeFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public getMfaData(): Observable<MfaDTO> {
		this.userFacade.dispatch(UserAction.GetMfaData());

		return this.actionsSubject$.pipe(
			ofType(UserAction.GetMfaDataComplete, UserAction.GetMfaDataFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.GetMfaDataFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public validatePasswordMfa(password: string): Observable<any> {
		this.userFacade.dispatch(UserAction.ValidatePaswordMfa({payload: {password: password}}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.ValidatePaswordMfaComplete, UserAction.ValidatePaswordMfaFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.ValidatePaswordMfaFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public setMfa(payload: MfaUpdateRequestDTO): Observable<UserDTO> {
		this.userFacade.dispatch(UserAction.MultiFactorAuthUpdate({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.MultiFactorAuthUpdateComplete, UserAction.MultiFactorAuthUpdateFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.MultiFactorAuthUpdateFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public removeSavedDevicesMfa$(): Observable<any> {
		this.userFacade.dispatch(UserAction.RemoveSavedDevices());

		return this.actionsSubject$.pipe(
			ofType(UserAction.RemoveSavedDevicesComplete, UserAction.RemoveSavedDevicesFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.RemoveSavedDevicesFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public logoutAllDevices$(): Observable<any> {
		this.userFacade.dispatch(UserAction.LogoutAllDevices());

		return this.actionsSubject$.pipe(
			ofType(UserAction.LogoutAllDevicesComplete, UserAction.LogoutAllDevicesFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.LogoutAllDevicesFailed.type) {
					return throwError(action.error);
				}
				return of(true);
			})
		);
	}

	public getUserAppSettings$(): Observable<StateSettingsDTO> {
		this.userFacade.dispatch(UserAction.GetUserAppSettings());

		return this.actionsSubject$.pipe(
			ofType(UserAction.GetUserAppSettingsComplete, UserAction.GetUserAppSettingsFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.GetUserAppSettingsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public setUserAppSettings$(payload: StateSettingsDTO): Observable<StateSettingsDTO> {
		this.userFacade.dispatch(UserAction.UpdateUserAppSettings({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.UpdateUserAppSettingsComplete, UserAction.UpdateUserAppSettingsFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.UpdateUserAppSettingsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public readDefaultPid$(): Observable<number> {
		return this.userFacade.defaultPid$;
	}

	public getDefaultPid$(): Observable<number> {
		return this.actionsSubject$.pipe(
			ofType(UserAction.SetDefaultPid),
			switchMap((action) => {
				return of(action.defaultPid);
			})
		);
	}

	public setDefaultPid(id: number): void {
		this.userFacade.defaultPid(id);
	}

	public readPidList$(): Observable<{[key: number]: string}> {
		return this.userFacade.pids$;
	}

	public searchUserFromConnectedInstitutions$(payload: { username: string, personId: number, q: SearchNotAssignedUserRequestDTO }): Observable<Array<UserDTO>> {
		this.userFacade.dispatch(UserAction.SearchUserFromConnectedInstitutions({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.SearchUserFromConnectedInstitutionsComplete, UserAction.SearchUserFromConnectedInstitutionsFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.SearchUserFromConnectedInstitutionsFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public addUserToInstitution$(payload: { username: string, personRoleResourcesDTO: PersonRoleResourcesDTO }): Observable<any> {
		this.userFacade.dispatch(UserAction.AddUserToInstitution({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.AddUserToInstitutionComplete, UserAction.AddUserToInstitutionFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.AddUserToInstitutionFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public error$(): Observable<any> {
		return this.userFacade.error$;
	}

	/** Notification */

	public loadNotificationList(): void {
		this.userFacade.dispatch(UserAction.LoadNotificationList());
	}

	public getNotificationList$(): Observable<Array<NotificationDTO>> {
		return this.actionsSubject$.pipe(
			ofType(UserAction.LoadNotificationListComplete, UserAction.LoadNotificationListFailed),
			switchMap((action) => {
				if (action.type === UserAction.LoadNotificationListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public markNotificationAsRead$(payload: number): Observable<NotificationDTO> {
		this.userFacade.dispatch(UserAction.MarkNotificationAsRead({payload: payload}));

		return this.actionsSubject$.pipe(
			ofType(UserAction.MarkNotificationAsReadComplete, UserAction.MarkNotificationAsReadFailed),
			take(1),
			switchMap((action) => {
				if (action.type === UserAction.MarkNotificationAsReadFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	/** Account */

	public setAccountData(payload: UserDTO) {
		this.accountFacade.data({name: UserUtils.getName(payload), email: payload.email});
	}

	public getAccountName$(): Observable<string> {
		return this.accountFacade.name$;
	}

	public getAccountEmail$(): Observable<string> {
		return this.accountFacade.email$;
	}

	public setPermission(permission: Array<string>) {
		this.accountFacade.permission(permission);
	}

	public setPermissionByRole(role: string) {
		this.accountFacade.permission(InternalUtils.permissionByRole(role));
	}

	public getPermission$(): Observable<Array<string>> {
		return this.accountFacade.permission$;
	}

	public setCurrentPid(id: number): void {
		this.userFacade.pids$.pipe(
			take(1),
			tap(next => {
				this.accountFacade.permission(InternalUtils.permissionByRole(next[id]));
				this.accountFacade.currentPid(id)
			})
		).subscribe();
	}

	public getCurrentPid$(): Observable<number> {
		return this.accountFacade.currentPid$;
	}

	public readManageResource$(): Observable<Array<string>> {
		if (this.isRole(UserRoleEnumDTO.EMPLOYEE)) {
			return this.accountFacade.manageEmployee$;
		}
		return of(undefined);
	}

	public clearState$(): Observable<boolean> {
		this.accountFacade.clear();
		return scheduled([true], asyncScheduler);
	}
}
