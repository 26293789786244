import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '@gp-angular/service/user';
import { PAGE } from '@gp-angular/shared/schema';

@Injectable({
	providedIn: 'root'
})
export class IsEmployeeGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService
	) {
	}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (!!route.params && !!route.params.username && route.params.username !== this.userService.getUser()) {
			return true;
		}

		this.router.navigate([PAGE.PROVIDER_USER_MANAGEMENT.fullpath]).then();
	}

}
