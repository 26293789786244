import { isNullOrUndefined, isString } from './helpers';

export class DateUtils {
	private static _fixSimpleNumber(no): string {
		return no.length < 2 ? (no.length === 0 ? '00' : ((Number(no) < 10 ? '0' : '') + no).substring(0, 2)) : no;
	}

	public static getTime(time: string): string {
		if (isNullOrUndefined(time) || time.trim().length < 1 || time.indexOf(':') < 0) {
			if (time.trim().length < 3 && typeof +time === 'number') {
				return time.trim().length === 1 ? `0${time}:00` : `${time}:00`;
			}
			return undefined;
		}
		const timeArray = time.split(':');
		return this._fixSimpleNumber(timeArray[0]) + ':' + this._fixSimpleNumber(timeArray[1]);
	}

	/**
	 * Provide safe datetime, should not affect (that much) the timezone
	 *
	 * @param date - empty will force as now
	 * Use it wise for birthdates and where time is not mandatory!
	 */
	public static getDateSafe(date?: Date | string): Date {
		const value: Date = !isNullOrUndefined(date) ? (typeof date === 'string') ? new Date(date) : date : new Date(Date.now());
		return new Date(value.getFullYear(), value.getMonth(), value.getDate(), 12, 0, 0, 0);
	}

	public static setHoursForDate(date: Date, time: string): Date {
		const timeArray = time.split(':');
		return new Date(date.setHours(+timeArray[0], +timeArray[1]));
	}

	public static addMinutes(date: Date, minutes: number): Date {
		if (isString(date)) {
			date = new Date(date);
		}
		return new Date(date.getTime() + minutes * 60000);
	}

	public static removeMinutes(date: Date, minutes: number): Date {
		if (isString(date)) {
			date = new Date(date);
		}
		return new Date(date.getTime() - minutes * 60000);
	}

	public static addMonths(date: Date, months: number): Date {
		if (isString(date)) {
			date = new Date(date);
		}
		return new Date(date.setMonth(date.getMonth() + months));
	}

	public static alterYears(date: Date, years: number): Date {
		if (isString(date)) {
			date = new Date(date);
		}

		return new Date(date.setFullYear(date.getFullYear() + years));
	}

	public static alterDate(date: Date, value: number): Date {
		if (value > 0) {
			return new Date(date.setDate(date.getDate() + value));
		}
		return new Date(date.setDate(date.getDate() - (value * -1)));
	}

	public static getInterval(type: string, extra: { startToday?: boolean, weekEnd?: number } = {
		startToday: false, weekEnd: 1
	}): { start: Date, end: Date } {
		const NOW = new Date(Date.now());
		const NEXT_WEEK_START = NOW.getDate() - NOW.getDay() + 8;

		switch (type) {
			case 'WEEK':
				const DATE_START = NOW.getDate() - NOW.getDay() + 1;
				const DATE_END = +DATE_START + 6;
				return {
					start: new Date(NOW.getFullYear(), NOW.getMonth(), extra.startToday ? NOW.getDate() : DATE_START, 12, 0, 0, 0),
					end: new Date(NOW.getFullYear(), NOW.getMonth(), DATE_END, 12, 0, 0, 0)
				};
			case 'NEXT_WEEK':
				const NEXT_WEEK_END = +NEXT_WEEK_START + (7 * +extra.weekEnd - 1);
				return {
					start: new Date(NOW.getFullYear(), NOW.getMonth(), NEXT_WEEK_START, 12, 0, 0, 0),
					end: new Date(NOW.getFullYear(), NOW.getMonth(), NEXT_WEEK_END, 12, 0, 0, 0)
				};
			case 'THIS_MONTH':
				return {
					start: new Date(NOW.getFullYear(), NOW.getMonth(), extra.startToday ? NOW.getDate() : 1),
					end: new Date(new Date(NOW.getFullYear(), NOW.getMonth() + 1, 0))
				}
			case 'NEXT_MONTH':
				return {
					start: new Date(NOW.getFullYear(), NOW.getMonth() + 1, 1),
					end: new Date(new Date(NOW.getFullYear(), NOW.getMonth() + 2, 0))
				}
			default:
				return {start: NOW, end: NOW}
		}

	}
}
