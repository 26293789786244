import { asDate } from '@gp-angular/shared/utils';

export class InternalUtils {
	public static prepareServerData(data: { [key: string]: any }, requestOnly: boolean = false): { [key: string]: any } {
		const DATA = {
			appointmentPatientDetails: {
				telephone: data.telephone,
				firstname: data.firstname,
				lastname: data.lastname,
				gender: data.salutation,
				email: data.email
			},
			insuranceType: data.insuranceType,
			treatments: data.treatments,
			treatmentId: data.treatmentId,
			resourceId: data.resourceId,
			prescriptionDate: data.prescriptionDate,
			emergencyTreatment: data.emergencyTreatment,
			attachments: data.attachments,
			acceptedTos: true,
			allowEmailReminder: data.allowEmailReminder,
			allowEmailConfirmation: true,
			allowSmsReminder: data.allowSmsReminder
		};

		let dataByType;
		if (requestOnly) {
			/** Require appointment */
			dataByType = {
				startDate: asDate(data.startTime).toISOString(),
				endDate: asDate(data.endTime).toISOString()
			};

			if (data.ts && data.te) {
				dataByType = {...dataByType, startTime: data.ts, endTime: data.te}
			}
		} else {
			/** Create appointment */
			dataByType = {
				date: asDate(data.startTime).toISOString(),
				clientStartTime: asDate(data.startTime).toISOString(),
				clientEndTime: asDate(data.endTime).toISOString()
			};
		}
		Object.keys(dataByType).forEach((key) => DATA[key] = dataByType[key]);

		if (!data.email) {
			delete DATA.appointmentPatientDetails.email;
		}

		if (!data.treatmentId) {
			delete DATA.treatmentId;
		}

		if (!data.resourceId) {
			delete DATA.resourceId;
		}

		if (!data.prescriptionDate) {
			delete DATA.prescriptionDate;
		}

		return DATA;
	}
}
