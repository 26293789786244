/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * CMS type enum dto
 */
export type CmsAppTypeEnumDTO = 'ADMIN' | 'PORTAL' | 'BOOKING';

export const CmsAppTypeEnumDTO = {
    ADMIN: 'ADMIN' as CmsAppTypeEnumDTO,
    PORTAL: 'PORTAL' as CmsAppTypeEnumDTO,
    BOOKING: 'BOOKING' as CmsAppTypeEnumDTO
};

