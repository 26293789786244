/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Message Status Enum
 */
export type MessageStatusEnumDTO = 'CREATED' | 'CANCELED';

export const MessageStatusEnumDTO = {
    CREATED: 'CREATED' as MessageStatusEnumDTO,
    CANCELED: 'CANCELED' as MessageStatusEnumDTO
};

