import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RequestOptions } from './request-options';
import { HttpAuthInterceptor } from './http-auth-interceptor';

@Injectable()
export class HttpService extends HttpClient {

	constructor(
		private backend: HttpBackend,
		options: RequestOptions,
		private httpInterceptor: HttpAuthInterceptor,
		private httpHandler?: HttpHandler) {
		super(httpHandler);
	}

	get(url: string, options?: any): Observable<any> {
		if (options && options.params && options.params.ignore) {
			/**
			 * Ignore refreshTokenIfNeeded$ on get for config.json
			 */
			return this.httpInterceptor.interceptResponse(
				super.get(this.httpInterceptor.interceptRequestUrl(url),
					this.httpInterceptor.interceptRequestOptions(options)));
		}
		return this.httpInterceptor.refreshTokenIfNeeded$().pipe(
			switchMap(() => {
				return this.httpInterceptor.interceptResponse(
					super.get(this.httpInterceptor.interceptRequestUrl(url),
						this.httpInterceptor.interceptRequestOptions(options)));
			})
		);
	}

	post(url: string, body: any, options?: any): Observable<any> {
		return this.httpInterceptor.refreshTokenIfNeeded$().pipe(
			switchMap(() => {
				return this.httpInterceptor.interceptResponse(
					super.post(this.httpInterceptor.interceptRequestUrl(url),
						this.httpInterceptor.interceptRequestBody(body),
						this.httpInterceptor.interceptRequestOptions(options)));
			})
		);
	}

	put(url: string, body: any, options?: any): Observable<any> {
		return this.httpInterceptor.refreshTokenIfNeeded$().pipe(
			switchMap(() => {
				return this.httpInterceptor.interceptResponse(
					super.put(this.httpInterceptor.interceptRequestUrl(url),
						this.httpInterceptor.interceptRequestBody(body),
						this.httpInterceptor.interceptRequestOptions(options)));
			})
		);
	}

	patch(url: string, body: any, options?: any): Observable<any> {
		return this.httpInterceptor.refreshTokenIfNeeded$().pipe(
			switchMap(() => {
				return this.httpInterceptor.interceptResponse(
					super.patch(this.httpInterceptor.interceptRequestUrl(url),
						this.httpInterceptor.interceptRequestBody(body),
						this.httpInterceptor.interceptRequestOptions(options)));
			})
		);
	}

	delete(url: string, options?: any): Observable<any> {
		return this.httpInterceptor.refreshTokenIfNeeded$().pipe(
			switchMap(() => {
				return this.httpInterceptor.interceptResponse(
					super.delete(this.httpInterceptor.interceptRequestUrl(url),
						this.httpInterceptor.interceptRequestOptions(options)));
			})
		);
	}
}
