import { Injectable } from '@angular/core';
//import { createEffect, Actions, ofType } from '@ngrx/effects';

//import * as fromApplication from './application.reducer';
//import * as ApplicationActions from './application.actions';

@Injectable()
export class ApplicationEffects {
  constructor() {}
}
