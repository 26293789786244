/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AvailableSlotDTO { 
    /**
     * Care provider ID
     */
    careProviderId: number;
    /**
     * Existing ids
     */
    resourceIds: Array<number>;
    /**
     * Checking date
     */
    appointmentDate: string;
    /**
     * Treatment ID
     */
    treatmentId?: number;
    /**
     * Slot duration
     */
    duration: number;
    /**
     * Excluded appointment ID
     */
    excludedAppointmentId?: number;
    /**
     * Buffer time before appointment
     */
    bufferTimeBefore?: number;
    /**
     * Buffer time after appointment
     */
    bufferTimeAfter?: number;
}

