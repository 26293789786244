export const EnvironmentUpdateEnum = {
	MAJOR: 0 as EnvironmentUpdateEnum,
	MINOR: 1 as EnvironmentUpdateEnum,
	PATH: 2 as EnvironmentUpdateEnum
};

export type EnvironmentUpdateEnum = 0 | 1 | 2;

export interface Environment {
	production: boolean;
	application: string;
	version: string;
	name: string;
	extension?: string;
	env?: string;
	fcKey?: string;
	endpoints: {
		api: string;
		booking?: string;
		images?: string;
		portal?: string;
	};
	websocket?: {
		endpoint: {
			brokerURL: string;
			headers: {};
			heartbeatIncoming: number;
			heartbeatOutgoing: number;
			channelMap: {
				provider: {
					messages: string,
					callbacks: string
				}
			},
			debug: boolean
		}
	};
	analytics: {
		provider: 'matomo' | 'google';
		appId: string;
		url: string;
	}
}
