<ng-container [ngSwitch]="disabled">
    <span class="prefix-push-input"
          [ngClass]="{'disabled' : disabled, 'suppress-menu': countryPrefixList.length < 2}"
          *ngSwitchCase="true">
        {{countryPrefix}}
      <mat-icon *ngIf="countryPrefixList.length > 1">keyboard_arrow_down</mat-icon>
    </span>

  <span class="prefix-push-input" [matMenuTriggerFor]="menuPrefix"
        [ngClass]="{'suppress-menu': countryPrefixList.length < 2}"
        *ngSwitchDefault>
        {{countryPrefix}}
    <mat-icon *ngIf="countryPrefixList.length > 1">keyboard_arrow_down</mat-icon>
    </span>
</ng-container>

<mat-menu #menuPrefix="matMenu">
  <button mat-menu-item type="button" *ngFor="let prefix of countryPrefixList" [value]="prefix" (click)="onPrefixChanges(prefix)">
    {{prefix}}
  </button>
</mat-menu>
