import { Component, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, forwardRef, Output, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'gp-angular-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ColorPickerComponent),
			multi: true
		}
	]
})
export class ColorPickerComponent implements ControlValueAccessor {

	@Output() colorChanged = new EventEmitter();

	@Input() public disabled: boolean;

	@Input() public placeholder: string;

	@Input() public color: string;

	@Input() public toggle: boolean;

	@Input() public calendarColors: string[];

	//eslint-disable-next-line @typescript-eslint/ban-types
	private onChange: Function;

	//eslint-disable-next-line @typescript-eslint/ban-types
	private onTouched: Function;

	constructor() {
		this.onChange = () => {
		};
		this.onTouched = () => {
		};
	}

	public colorUpdate(color: string) {
		this.color = color;
		this.onChange(color);
		this.onTouched();
		this.colorChanged.emit(color);
	}

	registerOnChange(fn: never): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: never): void {
		this.onTouched = fn;
	}

	//setDisabledState(isDisabled: boolean): void {
	//
	//}

	writeValue(obj: never): void {
		this.color = obj;
	}
}
