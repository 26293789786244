import { PageEvent } from '@angular/material/paginator';
import { PageEventPagination } from '@gp-angular/shared/schema';
import { PageableRequestDTO } from '@noventi/gp-platform/care-providers';
import { PageableUsersRequestDTO } from '@noventi/gp-platform/users';

export class AdminUtils {
	public static defaultPageableRequest(type: string): PageableRequestDTO | PageableUsersRequestDTO {
		const result: any = {page: 0, size: 50};
		if (type === 'PageableRequestDTO') {
			result.filter = [{attribute: 'q', value: ''}];
		} else if (type === 'PageableUsersRequestDTO') {
			result.query = '';
			result.roles = [];
		}
		return result;
	}

	public static getPageEvent(selector: PageableRequestDTO | PageableUsersRequestDTO): PageEvent {
		const pageEvent: PageEvent = PageEventPagination;

		if (!!selector.page) {
			pageEvent['pageIndex'] = selector.page;
		}
		if (!!selector.size) {
			pageEvent['pageSize'] = selector.size;
		}
		return pageEvent;
	}
}
