import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'gp-angular-logo',
	styles: [`
        :host {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            fill: #2C4255;
        }
		
		:host svg {
            width: 100%;
            max-width: 100%;
            height: auto;
            padding: 0 16px;
		}

        :host(.light) {
            fill: #FFFFFF;
        }

        :host .o {
            fill: #C7368C;
        }
	`],
	templateUrl: './logo.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.Emulated
})
export class LogoComponent {
}
