/**
 * Gesundheitsportal - Patients REST API
 * Gesundheitsportal Patients Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Patient info DTO
 */
export interface PatientGdprInfoDTO { 
    /**
     * Number of recipe information
     */
    recipeInformation?: number;
    /**
     * Number of recipe photos
     */
    recipePhotos?: number;
    /**
     * Number of appointments
     */
    appointments?: number;
}

