import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export class AsyncStoreObject<T> {
	constructor(
		public readonly value: T,
		public readonly loading: boolean = false,
		public readonly saving: boolean = false
	) { }
}

export class HttpErrorWrapper<T extends Action> {
	constructor(
		public causedBy: T,
		public error: HttpErrorResponse
	) { }
}

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */
const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
	if (typeCache[label as string]) {
		throw new Error(`Action type "${label}" is not unqiue`);
	}

	typeCache[label as string] = true;

	return label as T;
}
