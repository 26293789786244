import { PersonRoleDTO, UserDTO } from '@noventi/gp-platform/users';
import { RegistrationRequestDTO } from '@noventi/gp-platform/care-providers';

export class UserUtils {
	public static getName(user: UserDTO | RegistrationRequestDTO): string {
		return `${user.firstName ? user.firstName : ''} ${user.lastName ? user.lastName : ''}`;
	}

	public static getCurrentPersonRole(user: UserDTO, currentPid: number): PersonRoleDTO {
		return user?.personRoles.find(item => item.personId === currentPid);
	}
}
