import { isNullOrUndefined, padStart } from './helpers';
import { CalendarResourceHourType, CalendarViewType } from '@gp-angular/shared/schema';
import { ResourceHoursItemDTO } from '@noventi/gp-platform/care-providers';

export class CalendarUtils {
	public static setOptions(calendarOptions, accessPath, value) {
		if (typeof (accessPath) === 'string') {
			accessPath = accessPath.split('.');
		}

		if (accessPath.length > 1) {
			this.setOptions(calendarOptions[accessPath.shift()], accessPath, value);
		} else {
			calendarOptions[accessPath[0]] = value;
		}
	}

	public static displayInterval(displayInterval: number): string {
		return '00:' + padStart(displayInterval + '', 2, '0') + ':00';
	}

	public static getDaysInterval(type: CalendarViewType): number {
		switch (type) {
			case CalendarViewType.DAILY:
				return 1;
			case CalendarViewType.WEEKLY:
				return 7;
			case CalendarViewType.MONTHLY:
				return 31;
			default:
				return 1;
		}
	}

	public static getHiddenDays(calendarDaysVisible: Array<number>): Array<number> {
		const weekDays = [0, 1, 2, 3, 4, 5, 6, 7];
		const dayArray = calendarDaysVisible.map((item) => item === 7 ? 0 : item);
		return weekDays.filter(day => !dayArray.includes(day));
	}

	public static getRuleList(resourceId: number, arrayResourceHours: Array<ResourceHoursItemDTO>, calendarSettings): Array<any> {
		const list = [];
		arrayResourceHours.forEach((item) => {
			list.push({
				resourceId: resourceId,
				start: item.itemDate + ' ' + item.startTime,
				end: item.itemDate + ' ' + item.endTime,
				display: 'background',
				editable: false,
				className: this._extractEventClassByType(item),
				backgroundColor: this._extractEventColorByType(item, calendarSettings),
				eventColor: this._extractEventColorByType(item, calendarSettings),
			});
		});
		return list;
	}

	private static _extractEventColorByType(hour: ResourceHoursItemDTO, calendarSettings): string {
		if (!isNullOrUndefined(hour.bookingHours) && hour.bookingHours) {
			return calendarSettings.calendarOpenHoursColor;
		} else if (!isNullOrUndefined(hour.onlineBookingHours) && hour.onlineBookingHours) {
			return calendarSettings.calendarBusinessHoursColor;
		} else if (!isNullOrUndefined(hour.absentHours) && hour.absentHours) {
			return '#B00020';
		}
	}

	private static _extractEventClassByType(hour: ResourceHoursItemDTO): string {
		if (!isNullOrUndefined(hour.bookingHours) && hour.bookingHours) {
			return CalendarResourceHourType.BOOKING;
		} else if (!isNullOrUndefined(hour.onlineBookingHours) && hour.onlineBookingHours) {
			return CalendarResourceHourType.ONLINE_BOOKING;
		} else if (!isNullOrUndefined(hour.absentHours) && hour.absentHours) {
			return CalendarResourceHourType.ABSENT;
		}
	}
}
