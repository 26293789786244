export * from './contentManagementSystem.service';
import { ContentManagementSystemService } from './contentManagementSystem.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './portal.service';
import { PortalService } from './portal.service';
export * from './stateSettings.service';
import { StateSettingsService } from './stateSettings.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userOnboarding.service';
import { UserOnboardingService } from './userOnboarding.service';
export const APIS = [ContentManagementSystemService, NotificationService, PortalService, StateSettingsService, UserService, UserOnboardingService];
