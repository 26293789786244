/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientDetailsDTOAllOf { 
    /**
     * Care provider ID
     */
    careProviderId?: number;
    /**
     * Firstname
     */
    firstname: string;
    /**
     * Lastname
     */
    lastname: string;
    /**
     * Telephone
     */
    telephone: string;
    /**
     * Birthdate
     */
    birthdate?: string;
    /**
     * Postal Code
     */
    postalCode?: string;
    /**
     * City
     */
    city?: string;
    /**
     * Street
     */
    street?: string;
    /**
     * House Number
     */
    houseNumber?: string;
    /**
     * Email
     */
    email?: string;
    /**
     * Gender / Salutation
     */
    gender?: string;
}

