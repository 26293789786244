import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
	imports: [
		CommonModule,

		FormsModule,
		ReactiveFormsModule,

		MatFormFieldModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatInputModule,
		MatSelectModule

	],
	exports: [
		FormsModule,
		ReactiveFormsModule,

		MatFormFieldModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatInputModule,
		MatSelectModule
	],
	providers: []
})
export class SharedMatFormModule {
	static forRoot(): ModuleWithProviders<SharedMatFormModule> {
		return {
			ngModule: SharedMatFormModule
		};
	}
}
