import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, retryWhen, switchMap, takeUntil } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';

import * as AppointmentActions from './appointment.actions';
import { PersonDTO, PersonService, PublicPersonDTO, PublicTreatmentDTO, TreatmentsService } from '@noventi/gp-platform/care-providers';
import {
	ClassicAppointmentService, ExtCalendarAuthDTO, ExternalAuthUrlResponseDTO, ExternalCalendarService,
	FreeSlotsResponseDTO, ImageDTO,
	OnlineAppointmentService, PatientWaitingListService, PublicAppointmentDTO, PublicWaitingListDTO, SMSTokenDTO, TokenTypeResponseDTO,
	TreatmentDTO, TreatmentService
} from '@noventi/gp-platform/online-appointments';
import { PageablePatientsResponseDTO, PatientService } from '@noventi/gp-platform/patients';

@Injectable()
export class AppointmentEffects {

	token$ = createEffect(() => this.actions$.pipe(
		ofType(AppointmentActions.Token),
		switchMap(({payload}) =>
			this.appointmentServiceApi.getTokenType(payload).pipe(
				switchMap((result: TokenTypeResponseDTO) => of(AppointmentActions.TokenCompleted({payload: result}))),
				retryWhen(() => EMPTY),
				catchError((error) => of(AppointmentActions.TokenFailed({error})))
			)
		))
	);

	loadPatient$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.LoadPatient),
			switchMap(({payload}) => {
				return this.personServiceApi.readPerson(payload).pipe(
					takeUntil(this.actions$.pipe(ofType(AppointmentActions.ClearState))),
					switchMap((result: PersonDTO) => of(AppointmentActions.LoadPatientCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.LoadPatientFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);


	loadProviderByToken$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.LoadBookingProviderByToken),
			switchMap(({payload}) => {
				return this.personServiceApi.readPublicPersonByToken(payload).pipe(
					switchMap((result: PublicPersonDTO) => of(AppointmentActions.LoadBookingProviderByTokenCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.LoadBookingProviderByTokenFailed({error})
						])
					)
				)
					;
			})
		),
		{useEffectsErrorHandler: false}
	);

	createAppointment$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CreateAppointment),
			switchMap(({payload: {appointment, widgetToken, xSmsSimulate}}) => {
				return this.appointmentServiceApi.createNewOnlineAppointment(widgetToken, appointment, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CreateAppointmentCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.CreateAppointmentFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);


	createRequest$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CreateRequest),
			switchMap(({payload: {createPublicAppointmentDTO, widgetToken, xSmsSimulate}}) => {
				return this.waitingListServiceApi.createNewWaitingListEntry(widgetToken, createPublicAppointmentDTO, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CreateRequestCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.CreateRequestFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);


	validateAppointment$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ConfirmTokenValidationAppointment),
			switchMap(({payload: {tokenValidationRequest, xSmsSimulate}}) => {
				return this.appointmentServiceApi.checkTokenValidity(tokenValidationRequest, xSmsSimulate).pipe(
					switchMap((result: any) => of(AppointmentActions.ConfirmTokenValidationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ConfirmTokenValidationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	validateRequest$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ConfirmTokenValidationRequest),
			switchMap(({payload: {tokenValidationRequest, xSmsSimulate}}) => {
				return this.waitingListServiceApi.checkWaitingListTokenValidity(tokenValidationRequest, xSmsSimulate).pipe(
					switchMap((result: any) => of(AppointmentActions.ConfirmTokenValidationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ConfirmTokenValidationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	checkTokenAppointmentDuration$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CheckTokenAppointmentDuration),
			switchMap(({payload}) => {
				return this.appointmentServiceApi.checkTokenDuration(payload).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CheckTokenDurationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.CheckTokenDurationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	checkTokenRequestDuration$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CheckTokenRequestDuration),
			switchMap(({payload}) => {
				return this.waitingListServiceApi.checkWaitingListTokenDuration(payload).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CheckTokenDurationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.CheckTokenDurationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	resendTokenAppointment$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ResendTokenAppointment),
			switchMap(({payload: {resendTokenRequest, xSmsSimulate}}) => {
				return this.appointmentServiceApi.resendToken(resendTokenRequest, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.ResendTokenCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ResendTokenFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	resendTokenRequest$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ResendTokenRequest),
			switchMap(({payload: {resendTokenRequest, xSmsSimulate}}) => {
				return this.waitingListServiceApi.resendTokenForWaitingList(resendTokenRequest, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.ResendTokenCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ResendTokenFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	getAppointmentByToken$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.DetailByTokenAppointment),
			switchMap(({payload}) => {
				return this.appointmentServiceApi.getAppointmentBySmsConfirmationToken(payload).pipe(
					switchMap((result: PublicAppointmentDTO) => of(AppointmentActions.DetailByTokenAppointmentCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.DetailByTokenAppointmentFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	getRequestByToken$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.DetailByTokenRequest),
			switchMap(({payload}) => {
				return this.waitingListServiceApi.getWaitingListBySmsConfirmationToken(payload).pipe(
					switchMap((result: PublicWaitingListDTO) => of(AppointmentActions.DetailByTokenRequestCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.DetailByTokenRequestFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	cancelAppointmentByToken$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CancelByTokenAppointment),
			switchMap(({payload: {token, xSmsSimulate}}) => {
				return this.appointmentServiceApi.cancelAppointmentByToken(token, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CancelByTokenCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ResendTokenFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	cancelRequestByToken$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.CancelByTokenRequest),
			switchMap(({payload: {token, xSmsSimulate}}) => {
				return this.waitingListServiceApi.cancelWaitingListByToken(token, xSmsSimulate).pipe(
					switchMap((result: SMSTokenDTO) => of(AppointmentActions.CancelByTokenCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ResendTokenFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	confirmCancellationAppointment$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ConfirmCancellationAppointment),
			switchMap(({payload}) => {
				return this.appointmentServiceApi.confirmCancelAppointmentCode(payload).pipe(
					switchMap((result: any) => of(AppointmentActions.ConfirmCancellationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ConfirmCancellationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	confirmCancellationRequest$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.ConfirmCancellationRequest),
			switchMap(({payload}) => {
				return this.waitingListServiceApi.confirmCancelWaitingListCode(payload).pipe(
					switchMap((result: any) => of(AppointmentActions.ConfirmCancellationCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.ConfirmCancellationFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	uploadPhotos$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.UploadPhotos),
			switchMap(({payload}) => {
				return this.appointmentServiceApi.uploadAttachments(payload).pipe(
					switchMap((result: any) => of(AppointmentActions.UploadPhotosCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.UploadPhotosFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	loadResourceAvailableSlots$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.LoadResourceAvailableSlots),
			switchMap(({payload}) => {
				return this.classicAppointmentServiceApi.getAvailableSlots(payload).pipe(
					switchMap((result: FreeSlotsResponseDTO) => of(AppointmentActions.LoadResourceAvailableSlotsCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.LoadResourceAvailableSlotsFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: true}
	);

	loadAppointmenAttachments$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.LoadAppointmentAttachments),
			switchMap(({payload: {appointmentID, attachments}}) => {
				return this.appointmentServiceApi.getAttachment(appointmentID, attachments).pipe(
					switchMap((result: ImageDTO) => of(AppointmentActions.LoadAppointmentAttachmentsCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.LoadAppointmentAttachmentsFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	searchTreatmentList$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SearchTreatmentList),
			switchMap(({payload}) => {
				return this.appointmentTreatmentServiceApi.searchTreatments(payload).pipe(
					switchMap((result: Array<TreatmentDTO>) => of(AppointmentActions.SearchTreatmentListCompleted({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SearchTreatmentListFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	getPublicTreatment$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.LoadPublicTreatment),
			switchMap(({payload}) => {
				return this.providerTreatmentServiceApi.getPublicTreatement(payload).pipe(
					switchMap((result: PublicTreatmentDTO) => of(AppointmentActions.LoadPublicTreatmentComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.LoadPublicTreatmentFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	searchPatient$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SearchPatient),
			switchMap(({payload}) => {
				return this.patientService.searchPatients(payload).pipe(
					switchMap((result: PageablePatientsResponseDTO) => of(AppointmentActions.SearchPatientComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SearchPatientFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	syncAccounts$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SyncAccounts),
			switchMap(({payload}) => {
				return this.externalCalendarService.getExtCalendarAccounts(payload).pipe(
					switchMap((result: Array<ExtCalendarAuthDTO>) => of(AppointmentActions.SyncAccountsComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SyncAccountsFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	syncGoogleAuth$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SyncGoogleAuth),
			switchMap(() => {
				return this.externalCalendarService.getGoogleAuthUrl().pipe(
					switchMap((result: ExternalAuthUrlResponseDTO) => of(AppointmentActions.SyncGoogleAuthComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SyncGoogleAuthFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	syncGoogleCode$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SyncGoogleCode),
			switchMap(({payload: {username, code}}) => {
				return this.externalCalendarService.syncGoogleAuth(username, code).pipe(
					switchMap((result: ExtCalendarAuthDTO) => of(AppointmentActions.SyncGoogleCodeComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SyncGoogleCodeFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	syncOffice3655Auth$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SyncOffice3655Auth),
			switchMap(() => {
				return this.externalCalendarService.getMicrosoftAuthUrl().pipe(
					switchMap((result: ExternalAuthUrlResponseDTO) => of(AppointmentActions.SyncOffice365AuthComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SyncOffice365AuthFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	syncOffice365Code$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.SyncOffice365Code),
			switchMap(({payload: {username, code}}) => {
				return this.externalCalendarService.syncMicrosoftAuth(username, code).pipe(
					switchMap((result: ExtCalendarAuthDTO) => of(AppointmentActions.SyncOffice365CodeComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.SyncOffice365CodeFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);

	deleteGoogleSync$ = createEffect(() => this.actions$.pipe(
			ofType(AppointmentActions.DeleteCalendarSync),
			switchMap(({payload: {username, id}}) => {
				return this.externalCalendarService.deleteExtCalendarAccount(username, id).pipe(
					switchMap((result: any) => of(AppointmentActions.DeleteCalendarSyncComplete({payload: result}))),
					catchError((error) =>
						from([
							AppointmentActions.Error({error}),
							AppointmentActions.DeleteCalendarSyncFailed({error})
						])
					)
				);
			})
		),
		{useEffectsErrorHandler: false}
	);



	constructor(
		private actions$: Actions,
		private personServiceApi: PersonService,
		private appointmentServiceApi: OnlineAppointmentService,
		private externalCalendarService: ExternalCalendarService,
		private classicAppointmentServiceApi: ClassicAppointmentService,
		private appointmentTreatmentServiceApi: TreatmentService,
		private providerTreatmentServiceApi: TreatmentsService,
		private waitingListServiceApi: PatientWaitingListService,
		private patientService: PatientService
	) {
	}
}
