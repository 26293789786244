/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Care provider filter Enum Data Transfer Object
 */
export type CareProviderFilterEnumDTO = 'UNCONFIRMED_REGISTRATION' | 'ACTIVATED' | 'DEACTIVATED' | 'NEW' | 'VISIBLE' | 'HIDDEN' | 'USER_REGISTRATION' | 'IMPORT' | 'DELETED';

export const CareProviderFilterEnumDTO = {
    UNCONFIRMEDREGISTRATION: 'UNCONFIRMED_REGISTRATION' as CareProviderFilterEnumDTO,
    ACTIVATED: 'ACTIVATED' as CareProviderFilterEnumDTO,
    DEACTIVATED: 'DEACTIVATED' as CareProviderFilterEnumDTO,
    NEW: 'NEW' as CareProviderFilterEnumDTO,
    VISIBLE: 'VISIBLE' as CareProviderFilterEnumDTO,
    HIDDEN: 'HIDDEN' as CareProviderFilterEnumDTO,
    USERREGISTRATION: 'USER_REGISTRATION' as CareProviderFilterEnumDTO,
    IMPORT: 'IMPORT' as CareProviderFilterEnumDTO,
    DELETED: 'DELETED' as CareProviderFilterEnumDTO
};

