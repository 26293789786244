import { createReducer, on, Action } from '@ngrx/store';

import * as ApplicationActions from './application.actions';
import { MediaQuerySizeType } from './application.models';
import { EnvironmentUpdateEnum } from '@gp-angular/shared/environment';

export const APPLICATION_FEATURE_KEY = 'application';

export interface State {
	appLanguage: string;
	mediaQuerySize: MediaQuerySizeType;
	sidenav: string;
	versionUpdate: EnvironmentUpdateEnum;
	version: string;
}

export interface ApplicationPartialState {
	readonly [APPLICATION_FEATURE_KEY]: State;
}

export const initialState: State = {
	appLanguage: undefined,
	mediaQuerySize: undefined,
	sidenav: undefined,
	versionUpdate: undefined,
	version: undefined,
};

const applicationReducer = createReducer(
	initialState,
	on(ApplicationActions.VersionAction, (state, {payload: {version, versionUpdate}}) => ({...state, version, versionUpdate})),
	on(ApplicationActions.MediaQuerySizeChanged, (state, {mediaQuerySize}) => ({...state, mediaQuerySize})),
	on(ApplicationActions.AppLanguage, (state, {language}) => ({...state, language})),
	on(ApplicationActions.AppSidenav, (state, {sidenav}) => ({...state, sidenav})),
	on(ApplicationActions.ClearState, () => ({...initialState}))
);

export function reducer(state: State | undefined, action: Action) {
	return applicationReducer(state, action);
}
