/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * KPI type Enum Data Transfer Object
 */
export type KpiTypeEnumDTO = 'CARE_PROVIDER_CREATED' | 'CARE_PROVIDER_UPDATED' | 'CARE_PROVIDER_DELETED';

export const KpiTypeEnumDTO = {
    CREATED: 'CARE_PROVIDER_CREATED' as KpiTypeEnumDTO,
    UPDATED: 'CARE_PROVIDER_UPDATED' as KpiTypeEnumDTO,
    DELETED: 'CARE_PROVIDER_DELETED' as KpiTypeEnumDTO
};

