import { ModuleWithProviders, NgModule } from '@angular/core';
import { LY_THEME, LY_THEME_NAME, LyCommonModule, LyTheme2, LyThemeModule } from '@alyle/ui';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { MinimaLight } from '@alyle/ui/themes/minima';

@NgModule({
	declarations: [],
	imports: [
		LyCommonModule,
		LyImageCropperModule,
		LyThemeModule.setTheme('minima-light'),
	],
	exports: [
		LyCommonModule,
		LyImageCropperModule
	],
	providers: [
		[LyTheme2],
		{provide: LY_THEME_NAME, useValue: 'minima-light'},
		{provide: LY_THEME, useClass: MinimaLight, multi: true},
	],
})
export class SharedAlyleModule {
	static forRoot(): ModuleWithProviders<SharedAlyleModule> {
		return {
			ngModule: SharedAlyleModule
		};
	}
}
