/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Base Pageable Request Data Transfer Object
 */
export interface PageableResponseDTO { 
    /**
     * Total number of items
     */
    totalCount?: number;
    /**
     * Total number of pages
     */
    totalPages?: number;
    /**
     * Current page
     */
    currentPage?: number;
}

