/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Message template category enum data transfer object
 */
export type MessageTemplateCategoryEnumDTO = 'REGISTER' | 'MFA_CODE_CONFIRMATION' | 'NEW_APPOINTMENT' | 'BOOKING_CODE_CONFIRMATION' | 'BOOKING_CODE_CANCELLATION' | 'BOOKING_CONFIRMATION' | 'BOOKING_CANCELLATION' | 'BOOKING_REMINDER' | 'BOOKING_UPDATE' | 'WAITING_LIST_CONFIRMATION' | 'WAITING_LIST_CANCELLATION';

export const MessageTemplateCategoryEnumDTO = {
    REGISTER: 'REGISTER' as MessageTemplateCategoryEnumDTO,
    MFACODECONFIRMATION: 'MFA_CODE_CONFIRMATION' as MessageTemplateCategoryEnumDTO,
    NEWAPPOINTMENT: 'NEW_APPOINTMENT' as MessageTemplateCategoryEnumDTO,
    BOOKINGCODECONFIRMATION: 'BOOKING_CODE_CONFIRMATION' as MessageTemplateCategoryEnumDTO,
    BOOKINGCODECANCELLATION: 'BOOKING_CODE_CANCELLATION' as MessageTemplateCategoryEnumDTO,
    BOOKINGCONFIRMATION: 'BOOKING_CONFIRMATION' as MessageTemplateCategoryEnumDTO,
    BOOKINGCANCELLATION: 'BOOKING_CANCELLATION' as MessageTemplateCategoryEnumDTO,
    BOOKINGREMINDER: 'BOOKING_REMINDER' as MessageTemplateCategoryEnumDTO,
    BOOKINGUPDATE: 'BOOKING_UPDATE' as MessageTemplateCategoryEnumDTO,
    WAITINGLISTCONFIRMATION: 'WAITING_LIST_CONFIRMATION' as MessageTemplateCategoryEnumDTO,
    WAITINGLISTCANCELLATION: 'WAITING_LIST_CANCELLATION' as MessageTemplateCategoryEnumDTO
};

