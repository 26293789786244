import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PersonDTO, PublicPersonDTO, PublicTreatmentDTO, QuerySearchByExistingIdsDTO } from '@noventi/gp-platform/care-providers';
import {
	AttachmentsResponseDTO, AvailableSlotDTO, CheckTokenDurationDTO, CheckWaitingListTokenDurationDTO, CreatePublicAppointmentDTO,
	CreatePublicWaitingListDTO, ExtCalendarAuthDTO, ExternalAuthUrlResponseDTO, FreeSlotsResponseDTO, ImageDTO,
	PublicAppointmentDTO,
	PublicWaitingListDTO, ResendTokenRequestDTO, SMSTokenDTO, TokenTypeResponseDTO, TokenValidationRequestDTO, TreatmentDTO
} from '@noventi/gp-platform/online-appointments';
import { PageablePatientsResponseDTO, SearchPatiensRequestDTO } from '@noventi/gp-platform/patients';

export const Token = createAction(
	'[Appointment] Token',
	props<{ payload: string }>()
);

export const TokenCompleted = createAction(
	'[Appointment] Token Completed',
	props<{ payload: TokenTypeResponseDTO }>()
);

export const TokenFailed = createAction(
	'[Appointment] Token Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadPatient = createAction(
	'[Appointment] Load Patient',
	props<{ payload: number }>()
);

export const LoadPatientCompleted = createAction(
	'[Appointment] Load Patient Completed',
	props<{ payload: PersonDTO }>()
);

export const LoadPatientFailed = createAction(
	'[Appointment] Load Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadBookingProviderByToken = createAction(
	'[Appointment] Load Booking Provider By Token',
	props<{ payload: string }>()
);

export const LoadBookingProviderByTokenCompleted = createAction(
	'[Appointment] Load Booking Provider By Token Completed',
	props<{ payload: PublicPersonDTO }>()
);

export const LoadBookingProviderByTokenFailed = createAction(
	'[Appointment] Load Booking Provider By Token Failed',
	props<{ error: HttpErrorResponse }>()
);


export const CreateAppointment = createAction(
	'[Appointment] Create Appointment',
	props<{ payload: { appointment: CreatePublicAppointmentDTO, widgetToken: string, xSmsSimulate?: boolean } }>()
);

export const CreateAppointmentCompleted = createAction(
	'[Appointment] Create Appointment Completed',
	props<{ payload: SMSTokenDTO }>()
);

export const CreateAppointmentFailed = createAction(
	'[Appointment] Create Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CreateRequest = createAction(
	'[Appointment] Create Request',
	props<{ payload: { createPublicAppointmentDTO: CreatePublicWaitingListDTO, widgetToken: string, xSmsSimulate?: boolean } }>()
);

export const CreateRequestCompleted = createAction(
	'[Appointment] Create Request Completed',
	props<{ payload: SMSTokenDTO }>()
);

export const CreateRequestFailed = createAction(
	'[Appointment] Create Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ConfirmTokenValidationAppointment = createAction(
	'[Appointment] Confirm Token Validation Appointment',
	props<{ payload: { tokenValidationRequest: TokenValidationRequestDTO, xSmsSimulate: boolean } }>()
);

export const ConfirmTokenValidationRequest = createAction(
	'[Appointment] Confirm Token Validation Request',
	props<{ payload: { tokenValidationRequest: TokenValidationRequestDTO, xSmsSimulate: boolean } }>()
);

export const ConfirmTokenValidationCompleted = createAction(
	'[Appointment] Confirm Token Validation Completed',
	props<{ payload: any }>()
);

export const ConfirmTokenValidationFailed = createAction(
	'[Appointment] Confirm Token Validation Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CheckTokenAppointmentDuration = createAction(
	'[Appointment] Check Token Appointment Duration',
	props<{ payload: CheckTokenDurationDTO }>()
);

export const CheckTokenRequestDuration = createAction(
	'[Appointment] Check Token Request Duration',
	props<{ payload: CheckWaitingListTokenDurationDTO }>()
);

export const CheckTokenDurationCompleted = createAction(
	'[Appointment] Check Token Duration Completed',
	props<{ payload: SMSTokenDTO }>()
);

export const CheckTokenDurationFailed = createAction(
	'[Appointment] Check Token Duration Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ResendTokenAppointment = createAction(
	'[Appointment] Resend Token Appointment',
	props<{ payload: { resendTokenRequest: ResendTokenRequestDTO, xSmsSimulate?: boolean } }>()
);

export const ResendTokenRequest = createAction(
	'[Appointment] Resend Token Request',
	props<{ payload: { resendTokenRequest: ResendTokenRequestDTO, xSmsSimulate?: boolean } }>()
);

export const ResendTokenCompleted = createAction(
	'[Appointment] Resend Token Completed',
	props<{ payload: SMSTokenDTO }>()
);

export const ResendTokenFailed = createAction(
	'[Appointment] Resend Token Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DetailByTokenAppointment = createAction(
	'[Appointment] Detail By Token Appointment',
	props<{ payload: string }>()
);

export const DetailByTokenAppointmentCompleted = createAction(
	'[Appointment] Detail By Token Appointment Completed',
	props<{ payload: PublicAppointmentDTO }>()
);

export const DetailByTokenAppointmentFailed = createAction(
	'[Appointment] Detail By Token Appointment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DetailByTokenRequest = createAction(
	'[Appointment] Detail By Token Request',
	props<{ payload: string }>()
);

export const DetailByTokenRequestCompleted = createAction(
	'[Appointment] Detail By Token Request Completed',
	props<{ payload: PublicWaitingListDTO }>()
);

export const DetailByTokenRequestFailed = createAction(
	'[Appointment] Detail By Token Request Failed',
	props<{ error: HttpErrorResponse }>()
);

export const CancelByTokenAppointment = createAction(
	'[Appointment] Cancel By Token Appointment',
	props<{ payload: { token: string, xSmsSimulate?: boolean } }>()
);

export const CancelByTokenRequest = createAction(
	'[Appointment] Cancel By Token Request',
	props<{ payload: { token: string, xSmsSimulate?: boolean } }>()
);

export const CancelByTokenCompleted = createAction(
	'[Appointment] Cancel By Token Completed',
	props<{ payload: SMSTokenDTO }>()
);

export const CancelByTokenFailed = createAction(
	'[Appointment] Cancel By Token Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ConfirmCancellationAppointment = createAction(
	'[Appointment] Confirm Cancellation Appointment',
	props<{ payload: TokenValidationRequestDTO }>()
);

export const ConfirmCancellationRequest = createAction(
	'[Appointment] Confirm Cancellation Request',
	props<{ payload: TokenValidationRequestDTO }>()
);

export const ConfirmCancellationCompleted = createAction(
	'[Appointment] Confirm Cancellation Completed',
	props<{ payload: any }>()
);

export const ConfirmCancellationFailed = createAction(
	'[Appointment] Confirm Cancellation Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SetTokenError = createAction(
	'[Appointment] Set Token Error',
	props<{ payload: { errorText: string, isCancellation: boolean } }>()
);

export const SearchTreatmentList = createAction(
	'[Appointment] Search Treatment List',
	props<{ payload: QuerySearchByExistingIdsDTO }>()
);

export const SearchTreatmentListCompleted = createAction(
	'[Appointment] Search Treatment List Completed',
	props<{ payload: Array<TreatmentDTO> }>()
);

export const SearchTreatmentListFailed = createAction(
	'[Appointment] Search Treatment List Failed',
	props<{ error: HttpErrorResponse }>()
);

export const UploadPhotos = createAction(
	'[Appointment] Upload Photos',
	props<{ payload: Blob }>()
);

export const UploadPhotosCompleted = createAction(
	'[Appointment] Upload Photos Completed',
	props<{ payload: AttachmentsResponseDTO }>()
);

export const UploadPhotosFailed = createAction(
	'[Appointment] Upload Photos Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadResourceAvailableSlots = createAction(
	'[Appointment] Load Resource Available Slots',
	props<{ payload: AvailableSlotDTO }>()
);

export const LoadResourceAvailableSlotsCompleted = createAction(
	'[Appointment] Load Resource Available Slots Completed',
	props<{ payload: FreeSlotsResponseDTO }>()
);

export const LoadResourceAvailableSlotsFailed = createAction(
	'[Appointment] Load Resource Available Slots Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadAppointmentAttachments = createAction(
	'[Appointment] Load Appointment Upload',
	props<{ payload: { appointmentID: number, attachments: string } }>()
);

export const LoadAppointmentAttachmentsCompleted = createAction(
	'[Appointment] Load Appointment Upload Completed',
	props<{ payload: ImageDTO }>()
);

export const LoadAppointmentAttachmentsFailed = createAction(
	'[Appointment] Load Appointment Upload Failed',
	props<{ error: HttpErrorResponse }>()
);

export const LoadPublicTreatment = createAction(
	'[Appointment] Load Public Treatment',
	props<{ payload: number }>()
);

export const LoadPublicTreatmentComplete = createAction(
	'[Appointment] Load Public Treatment Complete',
	props<{ payload: PublicTreatmentDTO }>()
);

export const LoadPublicTreatmentFailed = createAction(
	'[Appointment] Load Public Treatment Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SearchPatient = createAction(
	'[Appointment] Search Patient',
	props<{ payload: SearchPatiensRequestDTO }>()
);

export const SearchPatientComplete = createAction(
	'[Appointment] Search Patient Complete',
	props<{ payload: PageablePatientsResponseDTO }>()
);

export const SearchPatientFailed = createAction(
	'[Appointment] Search Patient Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SyncAccounts = createAction(
	'[Appointment] Sync Accounts',
	props<{ payload: string }>()
);

export const SyncAccountsComplete = createAction(
	'[Appointment] Sync Accounts Complete',
	props<{ payload: Array<ExtCalendarAuthDTO> }>()
);

export const SyncAccountsFailed = createAction(
	'[Appointment] Sync Accounts Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SyncGoogleAuth = createAction(
	'[Appointment] Sync Google Auth'
);

export const SyncGoogleAuthComplete = createAction(
	'[Appointment] Sync Google Auth Complete',
	props<{ payload: ExternalAuthUrlResponseDTO }>()
);

export const SyncGoogleAuthFailed = createAction(
	'[Appointment] Sync Google Auth Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SyncGoogleCode = createAction(
	'[Appointment] Sync Google Code',
	props<{ payload: { username: string, code: string } }>()
);

export const SyncGoogleCodeComplete = createAction(
	'[Appointment] Sync Google Code Complete',
	props<{ payload: ExtCalendarAuthDTO }>()
);

export const SyncGoogleCodeFailed = createAction(
	'[Appointment] Sync Google Code Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SyncOffice3655Auth = createAction(
	'[Appointment] Sync Office365 Auth'
);

export const SyncOffice365AuthComplete = createAction(
	'[Appointment] Sync Office365 Auth Complete',
	props<{ payload: ExternalAuthUrlResponseDTO }>()
);

export const SyncOffice365AuthFailed = createAction(
	'[Appointment] Sync Office365 Auth Failed',
	props<{ error: HttpErrorResponse }>()
);

export const SyncOffice365Code = createAction(
	'[Appointment] Sync Office365 Code',
	props<{ payload: { username: string, code: string } }>()
);

export const SyncOffice365CodeComplete = createAction(
	'[Appointment] Sync Office365 Code Complete',
	props<{ payload: ExtCalendarAuthDTO }>()
);

export const SyncOffice365CodeFailed = createAction(
	'[Appointment] Sync Office365 Code Failed',
	props<{ error: HttpErrorResponse }>()
);

export const DeleteCalendarSync = createAction(
	'[Appointment] Delete Calendar Sync',
	props<{ payload: { username: string, id: number } }>()
);

export const DeleteCalendarSyncComplete = createAction(
	'[Appointment] Delete Calendar Sync Complete',
	props<{ payload: any }>()
);

export const DeleteCalendarSyncFailed = createAction(
	'[Appointment] Delete Calendar Sync Failed',
	props<{ error: HttpErrorResponse }>()
);

export const ClearState = createAction(
	'[Appointment] Clear State'
);

export const Error = createAction(
	'[Appointment] Error',
	props<{ error: HttpErrorResponse }>()
);
