/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Day Of Week Enum Data Transfer Object
 */
export type DayOfWeekEnumDTO = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const DayOfWeekEnumDTO = {
    MONDAY: 'MONDAY' as DayOfWeekEnumDTO,
    TUESDAY: 'TUESDAY' as DayOfWeekEnumDTO,
    WEDNESDAY: 'WEDNESDAY' as DayOfWeekEnumDTO,
    THURSDAY: 'THURSDAY' as DayOfWeekEnumDTO,
    FRIDAY: 'FRIDAY' as DayOfWeekEnumDTO,
    SATURDAY: 'SATURDAY' as DayOfWeekEnumDTO,
    SUNDAY: 'SUNDAY' as DayOfWeekEnumDTO
};

