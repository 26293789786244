import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'gp-angular-searching',
	styles: [`
        gp-angular-searching {
            display: grid;
            width: 24px;
            height: 24px;
        }
        gp-angular-searching mat-spinner,
        gp-angular-searching svg {
            height: inherit !important;
            width: inherit !important;
        }
        .hidden ~ gp-angular-searching {
            display: block;
            position: relative;
        }
	`],
	templateUrl: './searching.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})

export class SearchingComponent {
	@Input() public searching: boolean;
}
