/**
 * Gesundheitsportal - Users REST API
 * Gesundheitsportal Users Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * External type enum
 */
export type ExternalMappedTypeEnumDTO = 'CARE_PROVIDER_PERSON' | 'CARE_PROVIDER_INSTITUTION';

export const ExternalMappedTypeEnumDTO = {
    PERSON: 'CARE_PROVIDER_PERSON' as ExternalMappedTypeEnumDTO,
    INSTITUTION: 'CARE_PROVIDER_INSTITUTION' as ExternalMappedTypeEnumDTO
};

