import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaService } from './media.service';

@NgModule({
  imports: [CommonModule],
})
export class ServiceMediaModule {
	static forRoot(): ModuleWithProviders<ServiceMediaModule> {
		return {
			ngModule: ServiceMediaModule,
			providers: [MediaService]
		};
	}
}
