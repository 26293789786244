import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PAGE } from '@gp-angular/shared/schema';
import { UserService } from '@gp-angular/service/user';
import { UserRoleEnumDTO } from '@noventi/gp-platform/users';

@Injectable()
export class IsLoggedGuard implements CanActivate {

	constructor(
		private userService: UserService,
		private router: Router
	) {
	}

	canActivate(): boolean {
		const IS_LOGGED_IN = this.userService.isLoggedIn();
		if (IS_LOGGED_IN) {
			const permissions: Array<string> = this.userService.getPermissions();
			switch (permissions[0]) {
				case UserRoleEnumDTO.INSTITUTIONMANAGER:
				case UserRoleEnumDTO.OFFICEMANAGER:
				case UserRoleEnumDTO.EMPLOYEE:
					this.router.navigate([PAGE.PROVIDER.path]).then();
					break;
				case UserRoleEnumDTO.SUPERADMIN:
				case UserRoleEnumDTO.ADMIN:
				case UserRoleEnumDTO.CUSTOMERCARE:
					this.router.navigate([PAGE.ADMIN.path]).then();
					break;
				default:
					break;
			}
		}
		return !IS_LOGGED_IN;
	}
}
