import { AngularEditorConfig } from '@kolkov/angular-editor';

export const editorConfig: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	minHeight: '10em',
	maxHeight: '25em',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	defaultParagraphSeparator: 'p',
	outline: true,
	sanitize: true,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [
		['insertImage', 'insertVideo'],
		['fontSize', 'fontColor', 'fontName', 'textColor', 'backgroundColor'],
		['subscript', 'superscript']
	]
};
