import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class RedirectGuard implements CanActivate {

	constructor(
		@Inject('ENVIRONMENT') private _environment: any
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		window.location.href = `${this._environment.endpoints.portal}/${route.data['externalUrl']}/`;
		return true;
	}
}
