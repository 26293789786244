import { Injectable } from '@angular/core';
import { ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import * as CmsActions from './+state/cms.actions';
import { CmsFacade } from './+state/cms.facade';
import { CmsAppTypeEnumDTO, CmsPageContentDTO, CmsPageTypeEnumDTO } from '@noventi/gp-platform/users';

@Injectable()
export class ContentManagementService {

	constructor(
		private actionsSubject$: ActionsSubject,
		private cmsFacade: CmsFacade
	) {
	}

	public loadList(application: CmsAppTypeEnumDTO) {
		this.cmsFacade.dispatch(CmsActions.LoadList({payload: application}));
	}

	public cancelLoadList() {
		this.cmsFacade.dispatch(CmsActions.CancelLoadList());
	}

	public getList$(): Observable<Array<CmsPageContentDTO>> {
		return this.actionsSubject$.pipe(
			ofType(CmsActions.LoadListComplete, CmsActions.LoadListFailed),
			switchMap((action) => {
				if (action.type === CmsActions.LoadListFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public loadContent(type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO) {
		this.cmsFacade.dispatch(CmsActions.LoadContent({payload: {type: type, pageType: pageType}}));
	}

	public cancelLoadContent() {
		this.cmsFacade.dispatch(CmsActions.CancelLoadContent());
	}

	public getContent$(): Observable<CmsPageContentDTO> {
		return this.actionsSubject$.pipe(
			ofType(CmsActions.LoadContentComplete, CmsActions.LoadContentFailed),
			switchMap((action) => {
				if (action.type === CmsActions.LoadContentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}

	public saveContent$(type: CmsAppTypeEnumDTO, pageType: CmsPageTypeEnumDTO, requestBody: { [key: string]: any; }, newRelease?: boolean): Observable<CmsPageContentDTO> {
		this.cmsFacade.dispatch(CmsActions.SaveContent({
			payload: {type: type, pageType: pageType, requestBody: requestBody, newRelease: newRelease}
		}));

		return this.actionsSubject$.pipe(
			ofType(CmsActions.SaveContentComplete, CmsActions.SaveContentFailed),
			switchMap((action) => {
				if (action.type === CmsActions.SaveContentFailed.type) {
					return throwError(action.error);
				}
				return of(action.payload);
			})
		);
	}
}
