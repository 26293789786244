import { UserRoleEnumDTO } from '@noventi/gp-platform/users';

export type GuestPagesKeys =
	'ERROR' |
	'FRAUD' |
	'GUEST' |
	'LOGIN' |
	'REGISTER' |
	'FEEDBACK' |
	'PASSWORD_FORGOT' |
	'USER_SETTINGS' |
	'LEGAL' |
	'IMPRESSIONS' |
	'PRIVACY' |
	'TERMS_AND_CONDITIONS' |
	'CONTACT' |
	'FAQ' |
	'MULTI_FACTOR_AUTH';

export type AdminPagesKeys =
	'ADMIN' |
	'ADMIN_PROVIDER' |
	'ADMIN_PROVIDER_NEW' |
	'ADMIN_PROVIDER_USER_LIST' |
	'ADMIN_SETTING' |
	'ADMIN_PROVIDER_HISTORY' |
	'ADMIN_USER' |
	'ADMIN_USER_NEW' |
	'ADMIN_CMS' |
	'ADMIN_GENERAL';

export type ProviderPagesKeys =
	'PROVIDER' |
	'PROVIDER_SYNC_GOOGLE' |
	'PROVIDER_SYNC_OFFICE365' |
	'PROVIDER_SETTINGS' |
	'PROVIDER_PROFILE' |
	'PROVIDER_SETTINGS_DATA' |
	'PROVIDER_SETTINGS_PASSWORD' |
	'PROVIDER_SETTINGS_MFA' |
	'PROVIDER_SETTINGS_NOTIFICATION' |
	'PROVIDER_SETTINGS_SYNC' |
	'PROVIDER_CALENDAR' |
	'PROVIDER_CALENDAR_DAY' |
	'PROVIDER_CALENDAR_WEEK' |
	'PROVIDER_CALENDAR_MONTH' |
	'PROVIDER_CALENDAR_SETTINGS' |
	'PROVIDER_INFORMATION' |
	'PROVIDER_EXTRA_INFO' |
	'PROVIDER_EXPERTISE' |
	'PROVIDER_SERVICE' |
	'PROVIDER_SCHEDULE' |
	'PROVIDER_TREATMENT' |
	'PROVIDER_RESOURCE' |
	'PROVIDER_RESOURCE_EMPLOYEE' |
	'PROVIDER_RESOURCE_ROOM' |
	'PROVIDER_RESOURCE_DEVICE' |
	'PROVIDER_TEXT_TEMPLATE' |
	'PROVIDER_TEXT_TEMPLATE_EMAIL' |
	'PROVIDER_TEXT_TEMPLATE_SMS' |
	'PROVIDER_INSTITUTION_SETTINGS' |

	'PROVIDER_MESSAGE' |
	'PROVIDER_CONTACT' |
	'PROVIDER_FAQ' |
	'PROVIDER_IMPRESSUM' |
	'PROVIDER_PRIVACY' |
	'PROVIDER_TERMSANDCONDITIONS' |
	'PROVIDER_NEWS' |
	'PROVIDER_PATIENT' |
	'PROVIDER_PATIENT_MERGE' |
	'PROVIDER_USER_MANAGEMENT' |
	'PROVIDER_PATIENT_WAITING_LIST' |
	'PROVIDER_INSTITUTION';

export type ResourcePagesKeys =
	'RESOURCE_LIST' |
	'RESOURCE_OVERVIEW' |
	'RESOURCE_BUSINESS_HOURS' |
	'RESOURCE_ONLINE_APPOINTMENTS' |
	'RESOURCE_ABSENCES' |
	'RESOURCE_DATA';

export type AppointmentPagesKeys =
	'APPOINTMENT' |
	'APPOINTMENT_REQUEST_PROCESS' |
	'APPOINTMENT_REQUEST_VALIDATE' |
	'APPOINTMENT_REQUEST_RESEND' |
	'APPOINTMENT_REQUEST_RESEND_ERROR' |
	'APPOINTMENT_REQUEST_CANCELLATION' |
	'APPOINTMENT_REQUEST_CANCELLATION_RESEND' |
	'APPOINTMENT_REQUEST_DETAILS';

export interface MenuSchema {
	title?: string;
	tooltip?: string;
	path: string;
	fullpath?: string;
	role?: Array<UserRoleEnumDTO>;
}

export const PAGE: { [key in (GuestPagesKeys | AdminPagesKeys | ProviderPagesKeys | ResourcePagesKeys | AppointmentPagesKeys)]: MenuSchema } = {
	ERROR: {
		path: 'fehler',
		fullpath: '/fehler'
	},
	FRAUD: {
		path: 'betrug',
		fullpath: '/betrug'
	},
	GUEST: {
		path: '',
		fullpath: '/'
	},
	LOGIN: {
		path: 'login',
		fullpath: '/login'
	},
	REGISTER: {
		path: 'registrierung',
		fullpath: '/registrierung'
	},
	FEEDBACK: {
		path: 'feedback',
		fullpath: '/feedback'
	},
	PASSWORD_FORGOT: {
		path: 'passwort-vergessen',
		fullpath: '/passwort-vergessen'
	},
	LEGAL: {
		path: 'rechtliches',
		fullpath: '/rechtliches'
	},
	IMPRESSIONS: {
		path: 'impressum',
		fullpath: '/rechtliches/impressum'
	},
	PRIVACY: {
		path: 'datenschutz',
		fullpath: '/rechtliches/datenschutz'
	},
	TERMS_AND_CONDITIONS: {
		path: 'nutzungsbedingungen',
		fullpath: '/rechtliches/nutzungsbedingungen'
	},
	CONTACT: {
		path: 'kontakt',
		fullpath: '/kontakt'
	},
	FAQ: {
		path: 'faq',
		fullpath: '/rechtliches/faq'
	},
	USER_SETTINGS: {
		title: 'Pages.Provider.User.Settings.Title',
		path: 'benutzereinstellungen',
		fullpath: '/benutzereinstellungen'
	},
	MULTI_FACTOR_AUTH: {
		title: 'Pages.MFA.Title',
		path: 'zwei-faktor-authentifizierung',
		fullpath: '/zwei-faktor-authentifizierung'
	},

	ADMIN: {
		path: 'flamingo-admin',
		fullpath: '/flamingo-admin',
		role: [UserRoleEnumDTO.SUPERADMIN, UserRoleEnumDTO.ADMIN, UserRoleEnumDTO.CUSTOMERCARE]
	},
	ADMIN_PROVIDER: {
		path: 'provider',
		fullpath: '/flamingo-admin/provider'
	},
	ADMIN_PROVIDER_NEW: {
		path: 'neu',
		fullpath: '/flamingo-admin/provider/neu/_',
		role: [UserRoleEnumDTO.SUPERADMIN, UserRoleEnumDTO.ADMIN, UserRoleEnumDTO.CUSTOMERCARE]
	},
	ADMIN_PROVIDER_USER_LIST: {
		path: 'zugang',
		fullpath: '/flamingo-admin/provider/zugang'
	},
	ADMIN_SETTING: {
		path: 'einstellungen',
		fullpath: '/flamingo-admin/einstellungen'
	},
	ADMIN_PROVIDER_HISTORY: {
		path: 'history',
		fullpath: '/flamingo-admin/provider/history'
	},
	ADMIN_USER: {
		path: 'benutzer',
		fullpath: '/flamingo-admin/benutzer',
		role: [UserRoleEnumDTO.SUPERADMIN, UserRoleEnumDTO.ADMIN, UserRoleEnumDTO.CUSTOMERCARE]
	},
	ADMIN_USER_NEW: {
		path: 'neu/_',
		fullpath: '/flamingo-admin/benutzer/neu/_'
	},
	ADMIN_CMS: {
		title: 'Pages.Admin.Cms.Title',
		path: 'cms',
		fullpath: '/flamingo-admin/cms',
		role: [UserRoleEnumDTO.SUPERADMIN, UserRoleEnumDTO.ADMIN]
	},
	ADMIN_GENERAL: {
		title: 'Pages.Admin.GeneralData.Title',
		path: 'allgemeine',
		fullpath: '/flamingo-admin/allgemeine',
		role: [UserRoleEnumDTO.SUPERADMIN, UserRoleEnumDTO.ADMIN]
	},

	PROVIDER: {
		title: 'Pages.Provider.Title',
		path: 'provider',
		fullpath: '/provider',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_SYNC_GOOGLE: {
		path: 'callback/google-auth'
	},
	PROVIDER_SYNC_OFFICE365: {
		path: 'callback/microsoft-auth'
	},
	PROVIDER_SETTINGS: {
		path: 'einstellungen',
		fullpath: '/provider/einstellungen'
	},
	PROVIDER_SETTINGS_DATA: {
		title: 'Pages.Provider.Settings.Data',
		path: 'stammdaten',
		fullpath: '/provider/einstellungen/stammdaten'
	},
	PROVIDER_SETTINGS_PASSWORD: {
		title: 'Pages.Provider.Settings.Password',
		path: 'passwort',
		fullpath: '/provider/einstellungen/passwort'
	},
	PROVIDER_SETTINGS_MFA: {
		title: 'Pages.Provider.Settings.MFA',
		path: 'zwei-faktor-authentifizierung',
		fullpath: '/provider/einstellungen/zwei-faktor-authentifizierung'
	},
	PROVIDER_SETTINGS_NOTIFICATION: {
		title: 'Pages.Provider.Settings.Notification',
		path: 'benachrichtigungen',
		fullpath: '/provider/einstellungen/benachrichtigungen'
	},
	PROVIDER_SETTINGS_SYNC: {
		title: 'Pages.Provider.Settings.Sync',
		path: 'sync',
		fullpath: '/provider/einstellungen/sync'
	},
	PROVIDER_CALENDAR: {
		path: 'kalender',
		fullpath: '/provider/kalender',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_CALENDAR_DAY: {
		path: 'day',
		fullpath: '/provider/kalender/day',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_CALENDAR_WEEK: {
		path: 'week',
		fullpath: '/provider/kalender/week',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_CALENDAR_MONTH: {
		path: 'month',
		fullpath: '/provider/kalender/month',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_CALENDAR_SETTINGS: {
		path: 'einstellungen',
		fullpath: '/provider/kalender/einstellungen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_MESSAGE: {
		path: 'nachrichten',
		fullpath: '/provider/nachrichten',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_PROFILE: {
		path: 'praxis',
		fullpath: '/provider/praxis'
	},
	PROVIDER_INFORMATION: {
		path: 'daten',
		fullpath: '/provider/praxis/daten'
	},
	PROVIDER_EXTRA_INFO: {
		path: 'weiteres',
		fullpath: '/provider/praxis/weiteres'
	},
	PROVIDER_EXPERTISE: {
		path: 'fachgebiete',
		fullpath: '/provider/praxis/fachgebiete'
	},
	PROVIDER_SERVICE: {
		path: 'leistungen',
		fullpath: '/provider/praxis/leistungen'
	},
	PROVIDER_SCHEDULE: {
		path: 'offnungszeiten',
		fullpath: '/provider/praxis/offnungszeiten'
	},
	PROVIDER_TREATMENT: {
		path: 'behandlungen',
		fullpath: '/provider/behandlungen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_RESOURCE: {
		path: 'ressourcen',
		fullpath: '/provider/ressourcen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_RESOURCE_EMPLOYEE: {
		path: 'mitarbeiter',
		fullpath: '/provider/ressourcen/mitarbeiter',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_RESOURCE_ROOM: {
		path: 'raume',
		fullpath: '/provider/ressourcen/raume',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_RESOURCE_DEVICE: {
		path: 'gerat',
		fullpath: '/provider/ressourcen/gerat',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_TEXT_TEMPLATE: {
		path: 'textvorlagen',
		fullpath: '/provider/praxis/textvorlagen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_TEXT_TEMPLATE_EMAIL: {
		path: 'email',
		fullpath: '/provider/praxis/email',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_TEXT_TEMPLATE_SMS: {
		path: 'sms',
		fullpath: '/provider/praxis/sms',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_INSTITUTION_SETTINGS: {
		path: 'einstellungen',
		fullpath: '/provider/praxis/einstellungen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER]
	},

	PROVIDER_CONTACT: {
		path: 'kontakt',
		fullpath: '/provider/kontakt',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER, UserRoleEnumDTO.EMPLOYEE]
	},
	PROVIDER_PATIENT: {
		path: 'patientendaten',
		fullpath: '/provider/patientendaten',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER]
	},
	PROVIDER_PATIENT_MERGE: {
		path: 'verknüpfen',
		fullpath: '/provider/patientendaten/verknüpfen',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER]
	},
	PROVIDER_USER_MANAGEMENT: {
		path: 'nutzerverwaltung',
		fullpath: '/provider/nutzerverwaltung',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER, UserRoleEnumDTO.OFFICEMANAGER]
	},
	PROVIDER_PATIENT_WAITING_LIST: {
		path: 'warteliste',
		fullpath: '/provider/warteliste',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER]
	},
	PROVIDER_INSTITUTION: {
		path: 'standort',
		fullpath: '/provider/standort',
		role: [UserRoleEnumDTO.INSTITUTIONMANAGER]
	},
	PROVIDER_FAQ: {
		path: 'faq',
		fullpath: '/provider/faq'
	},
	PROVIDER_IMPRESSUM: {
		path: 'impressum',
		fullpath: '/provider/impressum'
	},
	PROVIDER_PRIVACY: {
		path: 'datenschutz',
		fullpath: '/provider/datenschutz'
	},
	PROVIDER_TERMSANDCONDITIONS: {
		path: 'nutzungsbedingungen',
		fullpath: '/provider/nutzungsbedingungen'
	},
	PROVIDER_NEWS: {
		path: 'neuigkeiten',
		fullpath: '/provider/neuigkeiten'
	},

	RESOURCE_LIST: {
		path: 'auffuhren'
	},
	RESOURCE_OVERVIEW: {
		path: 'ubersicht'
	},
	RESOURCE_BUSINESS_HOURS: {
		path: 'sprechzeiten'
	},
	RESOURCE_ONLINE_APPOINTMENTS: {
		path: 'online-freigaben'
	},
	RESOURCE_ABSENCES: {
		path: 'abwesenheiten'
	},
	RESOURCE_DATA: {
		path: 'stammdaten'
	},

	APPOINTMENT: {
		path: 'appointment'
	},
	APPOINTMENT_REQUEST_PROCESS: {
		path: 'anfrageprozess'
	},
	APPOINTMENT_REQUEST_VALIDATE: {
		path: 'validieren'
	},
	APPOINTMENT_REQUEST_RESEND: {
		path: 'erneut-senden'
	},
	APPOINTMENT_REQUEST_RESEND_ERROR: {
		path: 'erneut-senden-error'
	},
	APPOINTMENT_REQUEST_CANCELLATION: {
		path: 'stornierung'
	},
	APPOINTMENT_REQUEST_CANCELLATION_RESEND: {
		path: 'stornierung-erneut-senden'
	},
	APPOINTMENT_REQUEST_DETAILS: {
		path: 'einzelheiten'
	}
};
