import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as CmsActions from './cms.actions';
import { CmsPageContentDTO, ContentManagementSystemService } from '@noventi/gp-platform/users';

@Injectable()
export class CmsEffects {

	/**
	 * Effects for: Content management System
	 */
		//region Content management System

	loadContentCMSList = createEffect(() => this.actions$.pipe(
			ofType(CmsActions.LoadList),
			switchMap(({payload}) =>
				this.cmsServiceApi.getPageContents(payload).pipe(
					switchMap((next: Array<CmsPageContentDTO>) => {
							const PAYLOAD = next;
							PAYLOAD.sort((a, b) => a.title.localeCompare(b.title));
							return of(CmsActions.LoadListComplete({payload: PAYLOAD}));
						}
					),
					catchError((error) =>
						from([
							CmsActions.Error({error}),
							CmsActions.LoadListFailed({error: error})
						])
					)
				)
			)),
		{useEffectsErrorHandler: false}
	);

	loadContentCMS = createEffect(() => this.actions$.pipe(
			ofType(CmsActions.LoadContent),
			switchMap(({payload: {type, pageType}}) =>
				this.cmsServiceApi.getPageContent(type, pageType).pipe(
					switchMap((next: CmsPageContentDTO) => of(CmsActions.LoadContentComplete({payload: next}))),
					catchError((error) =>
						from([
							CmsActions.Error({error}),
							CmsActions.LoadContentFailed({error: error})
						])
					)
				)
			)),
		{useEffectsErrorHandler: false}
	);

	saveContentCMS = createEffect(() => this.actions$.pipe(
			ofType(CmsActions.SaveContent),
			switchMap(({payload: {type, pageType, requestBody, newRelease}}) =>
				this.cmsServiceApi.updatePageContent(type, pageType, requestBody, newRelease).pipe(
					switchMap((next: CmsPageContentDTO) => of(CmsActions.SaveContentComplete({payload: next}))),
					catchError((error) =>
						from([
							CmsActions.Error({error}),
							CmsActions.SaveContentFailed({error: error})
						])
					)
				)
			)),
		{useEffectsErrorHandler: false}
	);

	//endregion

	constructor(
		private actions$: Actions,
		private cmsServiceApi: ContentManagementSystemService) {
	}
}
