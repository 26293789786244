import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAdmin from './+state/admin.reducer';
import { AdminEffects } from './+state/admin.effects';
import { AdminFacade } from './+state/admin.facade';
import { AdminService } from './admin.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromAdmin.ADMIN_FEATURE_KEY, fromAdmin.reducer),
		EffectsModule.forFeature([AdminEffects])
	],
	providers: [AdminFacade]
})
export class ServiceAdminModule {
	static forRoot(): ModuleWithProviders<ServiceAdminModule> {
		return {
			ngModule: ServiceAdminModule,
			providers: [AdminService]
		};
	}
}
