/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BookingHoursDTOAllOf { 
    /**
     * Booking Hours name
     */
    name?: string;
    /**
     * Start time
     */
    startTime?: string;
    /**
     * End time
     */
    endTime?: string;
    /**
     * Start date
     */
    startDate?: string;
    /**
     * End date
     */
    endDate?: string;
    /**
     * Flag to indicate whether the Booking Hours instance is indefinite (does not have an end date) or not.
     */
    indefinite?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Monday or not.
     */
    monday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Tuesday or not.
     */
    tuesday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Wednesday or not.
     */
    wednesday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Thursday or not.
     */
    thursday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Friday or not.
     */
    friday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Saturday or not.
     */
    saturday?: boolean;
    /**
     * Flag to indicate whether this Booking Hours instance is applicable on Sunday or not.
     */
    sunday?: boolean;
    /**
     * Id of the resource
     */
    resourceId?: number;
}

