import { isNullOrUndefined } from './helpers';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';

// export class CustomOperators {

export function ignoreResultOnActions(actions$, ...actionTypeList): any {
	return input$ => {

		let stop = false;

		if (!Array.isArray(actionTypeList)) {
			actionTypeList = [actionTypeList];
		}

		const actionSubscription = actions$.subscribe(action => {
			if (actionTypeList.indexOf(action.type) >= 0 || actionTypeList.indexOf(action.constructor) >= 0) {
				stop = true;
				if (!isNullOrUndefined(actionSubscription) && !actionSubscription.closed) {
					actionSubscription.unsubscribe();
				}
			}
		});

		return input$.pipe(
			mergeMap(next => {
				if (!stop) {
					return of(next);
				}
				return EMPTY;
			}),
			catchError(err => {
				if (!stop) {
					return throwError(err);
				}
				return EMPTY;
			}),
			finalize(() => {
				if (!isNullOrUndefined(actionSubscription) && !actionSubscription.closed) {
					actionSubscription.unsubscribe();
				}
			})
		);
	};
}

// }
