/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Predefined email type data transfer object enum
 */
export type PredefinedEmailTypeEnumDTO = 'BLACKLIST' | 'ACTIVATE_ACCOUNT' | 'RESET_PASSWORD' | 'REGISTER_CONFIRM' | 'REGISTER_INTERNAL' | 'REGISTER_INTERNAL_NEW' | 'FEEDBACK' | 'FEEDBACK_PROFILE' | 'CONTACT' | 'APPOINTMENT_CREATED' | 'APPOINTMENT_CANCELLED' | 'APPOINTMENT_REMINDER' | 'GDPR_PATIENTS_DELETE' | 'GDPR_RESOURCE_DELETE' | 'GDPR_CAREPROVIDER_DELETE' | 'APPOINTMENT_FOR_WAITING_LIST_CREATED' | 'WAITING_LIST_CANCELED' | 'APPOINTMENT_CREATED_PATIENT' | 'APPOINTMENT_CANCELLED_PATIENT' | 'APPOINTMENT_REMINDER_PATIENT' | 'WAITING_LIST_CREATED_PATIENT' | 'WAITING_LIST_CANCELLED_PATIENT';

export const PredefinedEmailTypeEnumDTO = {
    BLACKLIST: 'BLACKLIST' as PredefinedEmailTypeEnumDTO,
    ACTIVATEACCOUNT: 'ACTIVATE_ACCOUNT' as PredefinedEmailTypeEnumDTO,
    RESETPASSWORD: 'RESET_PASSWORD' as PredefinedEmailTypeEnumDTO,
    REGISTERCONFIRM: 'REGISTER_CONFIRM' as PredefinedEmailTypeEnumDTO,
    REGISTERINTERNAL: 'REGISTER_INTERNAL' as PredefinedEmailTypeEnumDTO,
    REGISTERINTERNALNEW: 'REGISTER_INTERNAL_NEW' as PredefinedEmailTypeEnumDTO,
    FEEDBACK: 'FEEDBACK' as PredefinedEmailTypeEnumDTO,
    FEEDBACKPROFILE: 'FEEDBACK_PROFILE' as PredefinedEmailTypeEnumDTO,
    CONTACT: 'CONTACT' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTCREATED: 'APPOINTMENT_CREATED' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTCANCELLED: 'APPOINTMENT_CANCELLED' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTREMINDER: 'APPOINTMENT_REMINDER' as PredefinedEmailTypeEnumDTO,
    GDPRPATIENTSDELETE: 'GDPR_PATIENTS_DELETE' as PredefinedEmailTypeEnumDTO,
    GDPRRESOURCEDELETE: 'GDPR_RESOURCE_DELETE' as PredefinedEmailTypeEnumDTO,
    GDPRCAREPROVIDERDELETE: 'GDPR_CAREPROVIDER_DELETE' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTFORWAITINGLISTCREATED: 'APPOINTMENT_FOR_WAITING_LIST_CREATED' as PredefinedEmailTypeEnumDTO,
    WAITINGLISTCANCELED: 'WAITING_LIST_CANCELED' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTCREATEDPATIENT: 'APPOINTMENT_CREATED_PATIENT' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTCANCELLEDPATIENT: 'APPOINTMENT_CANCELLED_PATIENT' as PredefinedEmailTypeEnumDTO,
    APPOINTMENTREMINDERPATIENT: 'APPOINTMENT_REMINDER_PATIENT' as PredefinedEmailTypeEnumDTO,
    WAITINGLISTCREATEDPATIENT: 'WAITING_LIST_CREATED_PATIENT' as PredefinedEmailTypeEnumDTO,
    WAITINGLISTCANCELLEDPATIENT: 'WAITING_LIST_CANCELLED_PATIENT' as PredefinedEmailTypeEnumDTO
};

