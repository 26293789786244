import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromProvider from './+state/provider.reducer';
import { ProviderEffects } from './+state/provider.effects';
import { ProviderFacade } from './+state/provider.facade';
import { ProviderService } from './provider.service';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(fromProvider.PROVIDER_FEATURE_KEY, fromProvider.reducer),
		EffectsModule.forFeature([ProviderEffects])
	],
	providers: [ProviderFacade]
})
export class ServiceProviderModule {
	static forRoot(): ModuleWithProviders<ServiceProviderModule> {
		return {
			ngModule: ServiceProviderModule,
			providers: [ProviderService]
		};
	}
}
