/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RecipientReportDTO { 
    readonly smsId?: string;
    /**
     * Recipient�s mobile phone number.
     */
    readonly dst?: string;
    /**
     * Current process status (see SMS Outbound Status Codes).
     */
    readonly processStatus?: string;
    /**
     * Status (see SMS Outbound Status Codes).
     */
    readonly status?: string;
    /**
     * Recommended max. 64 characters.
     */
    readonly customerRef?: string;
    readonly reason?: string;
    /**
     * Timestamp when the SMS was sent (iso-8601).
     */
    readonly sentTs?: string;
    /**
     * Timestamp which indicates when the SMS received its final status (iso-8601).
     */
    readonly finishedTs?: string;
}

