/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Waiting List Booking Status Enum
 */
export type WaitingListBookingStatusEnumDTO = 'UNDEFINED' | 'BOOKED' | 'BOOKABLE' | 'NOT_BOOKABLE' | 'DENIED';

export const WaitingListBookingStatusEnumDTO = {
    UNDEFINED: 'UNDEFINED' as WaitingListBookingStatusEnumDTO,
    BOOKED: 'BOOKED' as WaitingListBookingStatusEnumDTO,
    BOOKABLE: 'BOOKABLE' as WaitingListBookingStatusEnumDTO,
    NOTBOOKABLE: 'NOT_BOOKABLE' as WaitingListBookingStatusEnumDTO,
    DENIED: 'DENIED' as WaitingListBookingStatusEnumDTO
};

