/**
 * Gesundheitsportal - Online Appointments REST API
 * Gesundheitsportal Online Appointments Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * SMS Token value and it\'s time availability in seconds
 */
export interface SMSTokenDTO { 
    /**
     * SMS Token
     */
    token: string;
    /**
     * Available time in seconds
     */
    seconds: number;
    /**
     * Unique 3 digit code, only present here when x-sms-siumulate header is set to true
     */
    code?: string;
    /**
     * Phone number used to send sms
     */
    phoneNumber?: string;
}

