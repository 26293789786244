/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A public personDTO containing only the needed data for public paths
 */
export interface PublicSettingsDTO { 
    /**
     * Boolean value representing whether the patient waiting list is allowed or not
     */
    allowWaitingList?: boolean;
}

