import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CmsEffects } from './+state/cms.effects';
import { CmsFacade } from './+state/cms.facade';
import { ContentManagementService } from './content-management.service';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([CmsEffects]),
	],
	providers: [CmsFacade]
})
export class ServiceContentManagementModule {
	static forRoot(): ModuleWithProviders<ServiceContentManagementModule> {
		return {
			ngModule: ServiceContentManagementModule,
			providers: [ContentManagementService]
		};
	}
}
