/**
 * Gesundheitsportal - Care Providers REST API
 * Gesundheitsportal Care Providers Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Types of Key value
 */
export type KeyValueTypeEnumDTO = 'SALUTATION' | 'TITLE' | 'ACADEMICAL_DEGREE' | 'EMPLOYMENT_TYPE' | 'MAIN_MEDICAL_FIELD' | 'ADDITIONAL_MEDICAL_FIELD';

export const KeyValueTypeEnumDTO = {
    SALUTATION: 'SALUTATION' as KeyValueTypeEnumDTO,
    TITLE: 'TITLE' as KeyValueTypeEnumDTO,
    ACADEMICALDEGREE: 'ACADEMICAL_DEGREE' as KeyValueTypeEnumDTO,
    EMPLOYMENTTYPE: 'EMPLOYMENT_TYPE' as KeyValueTypeEnumDTO,
    MAINMEDICALFIELD: 'MAIN_MEDICAL_FIELD' as KeyValueTypeEnumDTO,
    ADDITIONALMEDICALFIELD: 'ADDITIONAL_MEDICAL_FIELD' as KeyValueTypeEnumDTO
};

