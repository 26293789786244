export interface Analytics {
	provider?: 'google' | 'matomo';
	appId?: string;
	url?: string;
	token?: string;
	appName?: string;
	appVersion?: string;
}

export class AnalyticsConstants {

	/* ################## CATEGORY #################### */
	public static readonly CATEGORY_ONBOARDING = 'Onboarding';
	public static readonly CATEGORY_PROVIDER = 'Provider';
	public static readonly CATEGORY_ADMIN = 'CareProviderAdminstration';
	public static readonly APPOINTMENT_BOOKING = 'Appointment Booking';


	/* #################### ACTIONS ############## */
	public static readonly ACTION_LOGIN = '[Action] Login';
	public static readonly ACTION_LOGOUT = '[Action] Logout';
	public static readonly ACTION_REGISTER = '[Action] Register';
	public static readonly ACTION_RESET_PASSWORD = '[Action] Reset password';

	public static readonly ACTION_ONBOARDING_TOUR_START = '[Action] Onboarding Tour START';
	public static readonly ACTION_ONBOARDING_TOUR_DROP = '[Action] Onboarding Tour DROPPED';
	public static readonly ACTION_ONBOARDING_TOUR_FINISH = '[Action] Onboarding Tour FINISHED';

	public static readonly ACTION_CLICK_UPLOAD_PICTURE = '[Action] Click Upload Picture';
	public static readonly ACTION_CLICK_SELECT_PICTURE = '[Action] Click Select Picture';
	public static readonly ACTION_DRAG_DROP_PICTURE = '[Action] Drag&Drop Picture';
	public static readonly ACTION_ZOOM_PICTURE = '[Action] Drag Picture Zoom Slider';
	public static readonly ACTION_DRAG_PICTURE = '[Action] Drag Picture';
	public static readonly ACTION_CLICK_CANCEL = '[Action] Click Cancel';
	public static readonly ACTION_CLICK_CONFIRM = '[Action] Click Confirm';

	public static readonly ACTION_CLICK_BUTTON_NEXT_STEP = '[Action] Click Button Next Step';
	public static readonly ACTION_CLICK_ACCEPT_DATA_PRIVACY = '[Action] Click Accept T&C and Data Privacy';
	public static readonly ACTION_CLICK_CANCEL_ABORT = '[Action] Click Cancel / Abort';
	public static readonly ACTION_CLICK_CONSENT_DATA_PRIVACY = '[Action] Click Consent Special Data Privacy';
	public static readonly ACTION_CLICK_CONFIRM_APPOINTMENT = '[Action] Click Confirm Appointment';
	public static readonly ACTION_CLICK_CONFIRM_APPOINTMENT_REQUEST = '[Action] Click Confirm Waiting List';
	public static readonly ACTION_CLICK_REQUEST_NEW_TOKEN = '[Action] Click Request New Token';
	public static readonly ACTION_CLICK_RESEND_TOKEN = '[Action] Click Resend Token';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT = '[Action] Click Cancel Appointment';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT_REQUEST = '[Action] Click Cancel Waiting List';
	public static readonly ACTION_CLICK_CANCEL_APPOINTMENT_FINAL = '[Action] Click Final Cancellation Confirmation';
	public static readonly ACTION_CLICK_ABORT_CANCELLATION = '[Action] Click Abort Appointment Cancellation';
	public static readonly ACTION_CLICK_LINK_PROVIDER_PROFILE = '[Action] Click Link To Care Provider Profile Page';
	public static readonly ACTION_CLICK_CONFIRM_CANCEL_APPOINTMENT = '[Action] Click Confirm Cancellation Appointment';
	public static readonly ACTION_CLICK_CONFIRM_CANCEL_APPOINTMENT_REQUEST = '[Action] Click Confirm Cancellation Waiting List';
	public static readonly ACTION_CLICK_DIAL_PHONE = '[Action] Click Dial Phone Care Provider';
	public static readonly ACTION_CLICK_SEND_EMAIL = '[Action] Click Send Email To Care Provider';

	/* #################### LABEL ############## */
	public static readonly LABEL_VIEW = 'View';
	public static readonly LABEL_SEND = 'Send';
	public static readonly LABEL_SUCCESS = 'Success';
	public static readonly LABEL_FAILED = 'Failed';

	public static readonly LABEL_CANCEL_PICTURE_WIZZARD = 'Cancel Picture Wizzard';
	public static readonly LABEL_CONFIRM_PICTURE_WIZZARD = 'Confirm Picture Wizzard';
	public static readonly LABEL_PERSONAL_DATA = 'Booking Process Personal Data';
	public static readonly LABEL_DATA_PRIVACY = 'Booking Process Data Privacy';
	public static readonly LABEL_TOKEN_VALIDATION = 'Booking Process Token Validation';
	public static readonly LABEL_TOKEN_RESEND = 'Booking Process Token Resend';
	public static readonly LABEL_APPOINTMENT_CONFIRMATION = 'Booking Process Appointment Confirmation';
	public static readonly LABEL_TOKEN_VALIDATION_CANCELATION = 'Booking Process Token Validation Cancellation';
	public static readonly LABEL_APPOINTMENT_CANCELATION = 'Booking Process Appointment Cancellation';
	public static readonly LABEL_APPOINTMENT_CANCELATION_CONFIRMATION = 'Booking Process Appointment Cancellation Confirmation';
}
