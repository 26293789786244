import { InjectionToken, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, HttpHandler, HttpXhrBackend } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { HttpAuthInterceptor } from './http-auth-interceptor';
import { RequestOptions } from './request-options';
import { HttpInterceptor } from './http-interceptor';
import { HttpXsrfInterceptor } from './http-xsrf-interceptor';

export function httpServiceFactory(backend: HttpXhrBackend,
								   interceptor: HttpAuthInterceptor,
								   options: RequestOptions,
								   httpHandler: HttpHandler) {
	return new HttpService(backend, options, interceptor, httpHandler);
}

export const GpHttpClient = new InjectionToken('GP_HTTP_CLIENT');

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		HttpClientXsrfModule
	],
	exports: [
		// TODO do you need this?
		HttpClientModule,
		HttpClientXsrfModule
	],
	providers: [
		RequestOptions,
		HttpInterceptor,
		HttpAuthInterceptor,
		{
			provide: GpHttpClient,
			useFactory: httpServiceFactory,
			deps: [HttpXhrBackend, HttpInterceptor, RequestOptions, HttpHandler]
		},
		{provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true}
	]
})
export class ServiceHttpModule {
}
