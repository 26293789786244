/**
 * Gesundheitsportal - Patients REST API
 * Gesundheitsportal Patients Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Patient Block Request Data Transfer Object
 */
export interface PatientBlockRequestDTO { 
    /**
     * Care Provider Id
     */
    careProviderId: number;
    /**
     * Block
     */
    block: boolean;
}

