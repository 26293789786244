import { ColorResource } from '@gp-angular/shared/schema';
import { isNullOrEmpty } from './helpers';
import { PublicResourceDTO, ResourceCategoryWrapperDTO, ResourceDTO, ResourceTypeEnumDTO } from '@noventi/gp-platform/care-providers';

export class ResourceUtils {
	public static getEmployeeIdList(employeeList: Array<ResourceDTO>): Array<number> {
		if (!employeeList) {
			return undefined;
		}
		return employeeList.map((resource) => resource.id);
	}

	public static getDefaultResource(resourceList: Array<ResourceDTO>): ResourceDTO {
		if (!resourceList) {
			return undefined;
		}
		let resourceFound;
		const RESOURCE = resourceList.find((resource) => resource.defaultResource);
		if (RESOURCE) {
			resourceFound = {
				...RESOURCE,
				title: this.displayName(RESOURCE, true)
			};
		}
		return resourceFound ? resourceFound : resourceList[0]; // Fallback if no defaultResource is defined :(
	}

	public static getResourceColor(resourceList: Array<ResourceDTO>, employeeId: number): string {
		if (!resourceList || !employeeId) {
			return undefined;
		}
		const RESOURCE = resourceList.find((resource) => resource.id === employeeId);
		if (RESOURCE && RESOURCE.type === ResourceTypeEnumDTO.EMPLOYEE) {
			return RESOURCE.color;
		}
		return undefined;//'#0c164c';
	}

	public static getRandomColor(colorListUser: Array<string>): any {
		const colorList = ColorResource;
		const colorListAvailable = Object.values(colorList).filter((item) => typeof item === 'string' && !colorListUser.includes(item));
		const color = colorListAvailable[Math.floor(Math.random() * colorListAvailable.length)];
		return !isNullOrEmpty(colorListAvailable) ? color : '#FF0000';
	}

	public static readResourceColor(resource: ResourceDTO): string {
		if (!resource) {
			return undefined;
		}
		if (resource.type === ResourceTypeEnumDTO.EMPLOYEE) {
			return resource.color;
		}
		return '#0C164C';
	}

	public static getResourceByType(resourceList: Array<ResourceDTO>, resourceIds: Array<number>, type: ResourceTypeEnumDTO): ResourceDTO {
		let resource;
		resourceIds.forEach((id) => {
			const RESOURCE = ResourceUtils.getResourceById(resourceList, id);
			if (!resource && RESOURCE && RESOURCE.type === type) {
				resource =  RESOURCE;
			}
		});
		return resource;
	}

	public static getResourceById(resourceList: Array<ResourceCategoryWrapperDTO> | Array<ResourceDTO>, resourceId: number): any {
		if (!resourceList || !resourceId) {
			return undefined;
		}
		let resourceFound;

		if (!!resourceList[0] && !!resourceList[0]['items']) {
			(resourceList as Array<ResourceCategoryWrapperDTO>).forEach((group) => {
				if (typeof group === 'object' && group.items && group.items.length > 0) {
					const resource = group.items.find((item) => item.id === +resourceId);
					if (resource) {
						resourceFound = {
							...resource,
							title: this.displayName(resource, true)
						};
					}
				}
			});
		} else {
			const resource = (resourceList as Array<ResourceDTO>).find((item) => item.id === +resourceId);
			if (resource) {
				resourceFound = {
					...resource,
					title: this.displayName(resource, true)
				};
			}
		}

		return resourceFound;
	}

	public static displayName(resource: ResourceDTO, short?: boolean): string {
		if (!resource) {
			return undefined;
		}
		if (resource.type === ResourceTypeEnumDTO.EMPLOYEE) {
			if (short) {
				return `${resource.lastName ? resource.lastName : ''} ${resource.firstName ? resource.firstName : ''}`;
			} else {
				return `${resource.academicalDegree ? resource.academicalDegree : ''} ${resource.lastName ? resource.lastName :
					''} ${resource.firstName ? resource.firstName : ''}`;
			}
		}
		return resource.name ? resource.name : '';
	}

	public static displayPublicEmployeeName(resource: PublicResourceDTO): string {
		if (!resource) {
			return undefined;
		}
		return `${resource.lastName ? resource.lastName : ''} ${resource.firstName ? resource.firstName : ''}`;
	}

	public static displayNameById(resourceList: ResourceDTO[], resourceId: number, type?: string): string {
		if (!resourceList || !resourceId) {
			return undefined;
		}
		let result;
		const RESOURCE = resourceList.find(item => item.id === resourceId);
		if (RESOURCE) {
			if (RESOURCE.type === ResourceTypeEnumDTO.EMPLOYEE) {
				result = `${RESOURCE.academicalDegree ? RESOURCE.academicalDegree : ''} ${RESOURCE.lastName ? RESOURCE.lastName : ''}`;
				if (type === 'full') {
					result = `${result} ${RESOURCE.firstName ? RESOURCE.firstName : ''}`;
				}
			} else {
				result = RESOURCE.name;
			}
		}
		return result;
	}

	public static displayFullNameById(resourceList: ResourceDTO[], resourceId: number): string {
		if (!resourceList && !resourceId) {
			return undefined;
		}
		let result = '';
		const RESOURCE = resourceList.find(item => item.id === resourceId);
		if (RESOURCE) {
			if (RESOURCE.type === ResourceTypeEnumDTO.EMPLOYEE) {
				result = `${RESOURCE.academicalDegree ? RESOURCE.academicalDegree : ''} ${RESOURCE.lastName ? RESOURCE.lastName :
					''} ${RESOURCE.firstName ? RESOURCE.firstName : ''}`;
			} else {
				result = RESOURCE.name ? RESOURCE.name : '';
			}
		}
		return result;
	}

	public static calendarResourcesIndexUpdated(calendarResources: Array<ResourceDTO>,
												resourceState: Array<ResourceCategoryWrapperDTO>): Array<ResourceDTO> {
		if (!calendarResources) {
			return undefined;
		}

		const resourceSelected = [...calendarResources];
		resourceSelected.forEach((resource) => {
			const GROUP = resourceState.find((group) =>
				group.type.toLocaleLowerCase() === resource.type.toLocaleLowerCase());
			if (GROUP.items) {
				const ID = GROUP.items.find(item => item.id === resource.id);
				if (ID) {
					return ID
				}
			}
		});

		return resourceSelected;
	}

	public static getResourceColorMap(resourceList: ResourceDTO[]): any {
		const result = new Map();
		resourceList.forEach(resource => {
			result[resource.id] = this.readResourceColor(resource);
		});
		return result;
	}

	public static displayPublicResourceName(resource: PublicResourceDTO, short?: boolean): string {
		if (!resource) {
			return undefined;
		}
		if (short) {
			return `${resource.lastName ? resource.lastName : ''} ${resource.firstName ? resource.firstName : ''}`;
		} else {
			return `${resource.salutation ? resource.salutation : ''} ${resource.lastName ? resource.lastName :
				''} ${resource.firstName ? resource.firstName : ''}`;
		}
	}

	public static getChipResourceArrayById(resourceList: ResourceDTO[], idList: Array<number>): Array<{ name: string, color?: string }> {
		const result = [...idList];
		return result.map((resource) => {
			return {name: this.displayNameById(resourceList, resource), color: this.getResourceColor(resourceList, resource)};
		});
	}

	public static getPublicResourceById(resourceList: PublicResourceDTO[], id: number): PublicResourceDTO {
		if (!resourceList || resourceList.length < -1) {
			return null;
		}
		return resourceList.find(resource => resource.id === id);
	}



	/** New methods */
	public static getListByType(resources: Array<ResourceCategoryWrapperDTO>, type: ResourceTypeEnumDTO | string): Array<ResourceDTO> {
		if (isNullOrEmpty(resources)) {
			return undefined;
		}
		const RESOURCES = resources.filter((group) => group.type === type)[0];
		return RESOURCES && RESOURCES['items'] && RESOURCES['items'].length > 0 ? RESOURCES['items'] : [];
	}

	public static getAllResourceList(resources: Array<ResourceCategoryWrapperDTO>): Array<ResourceDTO> {
		if (isNullOrEmpty(resources)) {
			return undefined;
		}
		let resourceList = [];
		resources.forEach(group => {
			if(group.items && group.items.length > 0) {
				resourceList = resourceList.concat(group.items);
			}
		});
		return resourceList;
	}
}

