import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ADMIN_FEATURE_KEY, State, AdminPartialState } from './admin.reducer';
import { AdminUtils } from '../admin.utils';

export const getAdminState = createFeatureSelector<AdminPartialState, State>(
	ADMIN_FEATURE_KEY
);

export const getProviderListFilter = createSelector(
	getAdminState,
	(state: State) => state.providerListFilter
);

export const getUserListFilter = createSelector(
	getAdminState,
	(state: State) => state.userListFilter
);

export const getPageEventForUserList = createSelector(
	getUserListFilter,
	(userListFilter) => AdminUtils.getPageEvent(userListFilter)
);

export const getAdminLoading = createSelector(
	getAdminState,
	(state: State) => state.loading
);

export const getAdminError = createSelector(
	getAdminState,
	(state: State) => state.error
);
