/**
 * Gesundheitsportal - Emailing REST API
 * Gesundheitsportal Emailing Service REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: valeriu.craciun@kronsoft.ro
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Email config keys
 */
export type EmailConfigEnumDTO = 'FROM' | 'TO' | 'CC' | 'BCC' | 'SUBJECT' | 'PRIORITY_LEVEL';

export const EmailConfigEnumDTO = {
    FROM: 'FROM' as EmailConfigEnumDTO,
    TO: 'TO' as EmailConfigEnumDTO,
    CC: 'CC' as EmailConfigEnumDTO,
    BCC: 'BCC' as EmailConfigEnumDTO,
    SUBJECT: 'SUBJECT' as EmailConfigEnumDTO,
    PRIORITYLEVEL: 'PRIORITY_LEVEL' as EmailConfigEnumDTO
};

