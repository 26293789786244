import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
	catchError, concatMap, debounceTime, exhaustMap, filter, retryWhen, switchMap, takeUntil, withLatestFrom
} from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import * as ProviderActions from './provider.actions';
import { ProviderFacade } from './provider.facade';

import { ignoreResultOnActions, isNullOrEmpty, isNullOrUndefined, ResourceUtils } from '@gp-angular/shared/utils';
import {
	AbsentHourDTO, AbsentHoursService, AttachmentsResponseDTO, BookingHoursDTO, BookingHoursService, CalendarSettingsDTO, ExpertiseDTO,
	ExtraInfoDTO, KeyValueDTO,
	KeyValuePairService, KeyValueTypeEnumDTO, MedicalServiceDTO, MedicalServiceService, MessageTemplateDTO, MessageTemplateService,
	OnlineBookingHourDTO, OnlineBookingHoursService, PeriodicalDeletionService, PersonDTO, PersonListInfoDTO, PersonService,
	PostalCodeCityDTO,
	RegistrationRequestDTO, ResourceDTO, ResourceGroupDTO, ResourceHoursRequestDTO, ResourceHoursResponseDTO, ResourceOverviewService,
	ResourceService, SettingsDTO, SettingsService, TimeSlotsDTO, TreatmentDTO, TreatmentsService
} from '@noventi/gp-platform/care-providers';
import {
	AppointmentDTO, AppointmentHeatmapDTO, BusinessHoursRequestDTO, CalendarEventDTO, ClassicAppointmentService, InsuranceTypeEnumDTO,
	MessageDTO, MessageService, OnlineAppointmentService, PageableAppointmentInfosResponseDTO, PageableMessagesRequestDTO,
	PageableResponseDTO, PageableWaitingListsResponseDTO, PublicAppointmentDTO, ResourceGdprDTO, WaitingListBookingStatusResponseDTO,
	WaitingListService
} from '@noventi/gp-platform/online-appointments';
import {
	ContentManagementSystemService, PageableUsersResponseDTO, PasswordResetResponseDTO, UserDTO, UserService
} from '@noventi/gp-platform/users';
import { InternalUtils } from '../internal.utils';
import {
	GetPatientsResponseDTO, PageablePatientsResponseDTO, PatientDTO, PatientGdprInfoDTO, PatientService
} from '@noventi/gp-platform/patients';

@Injectable()
export class ProviderEffects {

	autocompletePostalCodeCity$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.AutocompletePostalCodeCity),
		switchMap(({payload}) => {
			return this.keyValuePairServiceApi.searchPlzOrt(payload).pipe(
				switchMap((result: Array<PostalCodeCityDTO>) =>
					of(ProviderActions.AutocompletePostalCodeCityComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.AutocompletePostalCodeCityFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadKeySalutation$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProviderKeySalutation),
		exhaustMap(() => {
			return this.keyValuePairServiceApi.getKeysOfType(KeyValueTypeEnumDTO.SALUTATION).pipe(
				switchMap((result: Array<KeyValueDTO>) =>
					of(ProviderActions.LoadProviderKeySalutationComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderKeySalutationFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadKeyTitle$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProviderKeyTitle),
		exhaustMap(() => {
			return this.keyValuePairServiceApi.getKeysOfType(KeyValueTypeEnumDTO.TITLE).pipe(
				switchMap((result: Array<KeyValueDTO>) =>
					of(ProviderActions.LoadProviderKeyTitleComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderKeyTitleFailed({error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	loadKeyAcademicalDegree$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProviderKeyAcademicalDegree),
		exhaustMap(() => {
			return this.keyValuePairServiceApi.getKeysOfType(KeyValueTypeEnumDTO.ACADEMICALDEGREE).pipe(
				switchMap((result: Array<KeyValueDTO>) =>
					of(ProviderActions.LoadProviderKeyAcademicalDegreeComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderKeyAcademicalDegreeFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProvider),
		exhaustMap(({payload}) => {
			return this.personServiceApi.readPerson(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PersonDTO) =>
					of(ProviderActions.LoadProviderComplete({data: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadProviderList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProviderList, ProviderActions.ClearReinitializedProvider),
		switchMap(({payload}) => {
			return this.personServiceApi.readPersonsByUsername(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<PersonListInfoDTO>) =>
					of(ProviderActions.LoadProviderListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderListFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateProvider),
		switchMap(({payload}) => {
			return this.personServiceApi.createPerson(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(result =>
					of(ProviderActions.CreateProviderComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateProviderFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	confirmRegistrationRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ConfirmRegistrationRequest),
		switchMap(({payload: {registerProfileConfirm, token}}) => {
			return this.personServiceApi.confirmRegistrationRequest(token, registerProfileConfirm).pipe(
				concatMap(() =>
					of(ProviderActions.ConfirmRegistrationRequestComplete({payload: registerProfileConfirm}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ConfirmRegistrationRequestFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveProviderInformation),
		switchMap(({payload: {id, body}}) => {
			return this.personServiceApi.patchPerson(id, body).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PersonDTO) =>
					of(ProviderActions.SaveProviderInformationComplete({data: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveProviderInformationFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveProviderImage$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveProviderImage),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			const original$ = this.personServiceApi.uploadProfileImage(provider.id, action.payload['original'], true);
			const thumbnail$ = this.personServiceApi.uploadProfileImage(provider.id, action.payload['thumbnail'], false);
			return original$.pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				concatMap(() => thumbnail$),
				switchMap((thumbnail) =>
					of(ProviderActions.SaveProviderImageComplete({payload: thumbnail}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveProviderImageFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteProviderImage$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteProviderImage),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.deleteProfileImage(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(result =>
					of(ProviderActions.DeleteProviderImageComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteProviderImageFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveProviderSchedule$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveProviderSchedule),
		switchMap(({payload: {id, body}}) => {
			return this.personServiceApi.updateOpenHours(id, body).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.SaveProviderScheduleComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveProviderScheduleFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Services ========================================================== */

	loadProviderService$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadProviderServices),
		switchMap(({payload}) => {
			return this.medicalServicesService.getMedicalServices(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<MedicalServiceDTO>) =>
					of(ProviderActions.LoadProviderServicesComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadProviderServicesFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveProviderService$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveProviderServices),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.medicalServicesService.updateMedicalServices(provider.id, action.payload).pipe(
				debounceTime(2000),
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<MedicalServiceDTO>) =>
					of(ProviderActions.SaveProviderServicesComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveProviderServicesFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Expertise ========================================================== */

	loadExpertise$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadExpertise),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.getExpertise(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ExpertiseDTO) =>
					of(ProviderActions.LoadExpertiseComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadExpertiseFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveExpertise$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveExpertise),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.updateExpertise(provider.id, action.payload).pipe(
				debounceTime(2000),
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ExpertiseDTO) =>
					from([
						ProviderActions.LoadExpertiseComplete({payload: result}),
						ProviderActions.SaveExpertiseComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveExpertiseFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Exrta Info ========================================================== */

	loadExtraInfo$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadExtraInfo),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.getExtraInfo(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ExtraInfoDTO) =>
					of(ProviderActions.LoadExtraInfoComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadExtraInfoFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveExtraInfo$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveExtraInfo),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.updateExtraInfo(provider.id, action.payload).pipe(
				debounceTime(2000),
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ExtraInfoDTO) =>
					from([
						ProviderActions.LoadExtraInfoComplete({payload: result}),
						ProviderActions.SaveExtraInfoComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.SaveExtraInfoFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Messages ========================================================== */

	countUnreadMessage$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CountUnreadMessage),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.messageService.getUnreadMessagesNumber(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((number: number) =>
					of(ProviderActions.CountUnreadMessageComplete({payload: number}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CountUnreadMessageFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadMessageList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadMessageList),
		switchMap(({payload}) => {
			return this.messageService.getMessagesPaginated(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PageableResponseDTO) =>
					of(ProviderActions.LoadMessageListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadMessageListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadLastMessage$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadLastMessage),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			const messagePageableRequest: PageableMessagesRequestDTO = {
				page: action.payload.pageIndex,
				size: 1,
				personId: provider.id
			};
			return this.messageService.getMessagesPaginated(messagePageableRequest).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PageableResponseDTO) =>
					of(ProviderActions.LoadLastMessageComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadLastMessageFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadMessage$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadMessage),
		switchMap((action) => {
			return this.messageService.getMessageById(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((message: MessageDTO) =>
					of(ProviderActions.LoadMessageComplete({payload: message}))
				),
				retryWhen(() => EMPTY),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadMessageFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteProvider),
		switchMap(({payload}) => {
			return this.personServiceApi.deletePerson(payload.providerId, payload.email, payload.download, payload.xSmsSimulate).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.DeleteProviderComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteProviderFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	archiveProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ArchiveProvider),
		switchMap(({payload}) => {
			return this.personServiceApi.archivePerson(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.ArchiveProviderComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ArchiveProviderFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	reactivateProvider$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ReactivateProvider),
		switchMap(({payload}) => {
			return this.personServiceApi.reactivatePerson(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.ReactivateProviderComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ReactivateProviderFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	copyProviderData$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CopyProviderData),
		exhaustMap(({payload}) => {
			return this.personServiceApi.copyPersonData(payload.idFrom, payload.idTo, payload.data).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.CopyProviderDataComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CopyProviderDataFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Appointment ========================================================== */
		//region Appointment

	loadResourceHourList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.loadResourceHourList),
		withLatestFrom(
			this.providerFacade.data$,
			this.providerFacade.calendarResources$
		),
		filter(([action, provider, resources]: [any, PersonDTO, Array<ResourceDTO>]) => !isNullOrUndefined(provider && (resources || action.payload.resourceId))),
		// TODO: Blocking sometimes?!
		//distinctUntilChanged((prev, next) => {
		//	return JSON.stringify(prev[0].payload) === JSON.stringify(next[0].payload) &&
		//		   JSON.stringify(prev[1].id) === JSON.stringify(next[1].id) &&
		//		   JSON.stringify(prev[2]) === JSON.stringify(next[2]);
		//}),
		switchMap(([action, provider, resources]: [any, PersonDTO, Array<ResourceDTO>]) => {
			const payload: ResourceHoursRequestDTO = {
				careProviderId: provider.id,
				resourceIds: !isNullOrUndefined(resources) && isNullOrUndefined(action.payload.resourceId) ?
					resources.map(resource => resource.id) : [action.payload.resourceId],
				interval: action.payload.interval,
				startDate: action.payload.date.toISOString(),
				bookingHours: action.payload.bookingHours,
				onlineBookingHours: action.payload.onlineBookingHours,
				absentHours: action.payload.absentHours
			};
			return this.resourceOverviewService.resourceOverview(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<ResourceHoursResponseDTO>) =>
					of(ProviderActions.loadResourceHourListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.loadResourceHourListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadAppointmentList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadAppointmentList),
		withLatestFrom(
			this.providerFacade.data$,
			this.providerFacade.calendarResources$
		),
		// TODO: Reload when add appointment with same data. Quick fix disabled below
		//distinctUntilChanged((prev, next) => {
		//	return JSON.stringify(prev[0].payload) === JSON.stringify(next[0].payload) &&
		//		   JSON.stringify(prev[1].id) === JSON.stringify(next[1].id) &&
		//		   JSON.stringify(prev[2]) === JSON.stringify(next[2]);
		//}),
		filter(([action, provider, resources]:
					[any, PersonDTO, Array<ResourceDTO>]) => !isNullOrUndefined(provider && resources) && resources.length > 0),
		switchMap(([action, provider, resources]: [any, PersonDTO, Array<ResourceDTO>]) => {
			const resourcesArray = resources.map(resource => resource.id);
			const payload: BusinessHoursRequestDTO = {
				careProviderId: provider.id,
				resourceIds: resourcesArray,
				interval: action.payload.interval,
				startDate: action.payload.date.toISOString()
			};
			return this.onlineAppointmentApi.readAppointments(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<CalendarEventDTO>) =>
					of(ProviderActions.LoadAppointmentListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadAppointmentListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadAppointmentHeatmap$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadAppointmentHeatmap),
		filter((action) => !!action.payload && !isNullOrEmpty(action.payload.resourceIds)),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			const payload: BusinessHoursRequestDTO = {
				careProviderId: provider.id,
				resourceIds: action.payload.resourceIds,
				interval: action.payload.interval,
				startDate: action.payload.date.toISOString()
			};
			return this.onlineAppointmentApi.readAppointmentHeatmap(payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: Array<AppointmentHeatmapDTO>) =>
					of(ProviderActions.LoadAppointmentHeatmapComplete({payload: next}))),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadAppointmentHeatmapFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadAppointment),
		switchMap((action) => {
			return this.classicAppointmentApi.getAppointment(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: AppointmentDTO) =>
					of(ProviderActions.LoadAppointmentComplete({payload: next}))),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadAppointmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	checkAppointmentInterval$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CheckAppointmentInterval),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.classicAppointmentApi.checkSlot(action.overlap, {...action.payload, careProviderId: provider.id}).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: InsuranceTypeEnumDTO[]) =>
					of(ProviderActions.CheckAppointmentIntervalComplete({payload: next}))),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CheckAppointmentIntervalFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadPublicAppointmentList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadPublicAppointmentList),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.classicAppointmentApi.getPublicAppointmentsByIds(action.payload, provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PublicAppointmentDTO[]) =>
					of(ProviderActions.LoadPublicAppointmentListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadPublicAppointmentListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createMultipleAppointments$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateMultipleAppointments),
		exhaustMap(({payload: {appointments, bookingLink}}) => {
			return this.classicAppointmentApi.createMultipleAppointments(bookingLink, appointments).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				exhaustMap((next: Array<AppointmentDTO>) =>
					of(ProviderActions.CreateMultipleAppointmentsComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateMultipleAppointmentsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateAppointment),
		exhaustMap(({payload: {appointment, bookingLink, waitingListId}}) => {
			return this.classicAppointmentApi.createAppointment(bookingLink, appointment, waitingListId).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				exhaustMap((next: AppointmentDTO) =>
					of(ProviderActions.CreateAppointmentComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateAppointmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateAppointment),
		exhaustMap(({payload: {id, sendSms, body}}) => {
			return this.classicAppointmentApi.patchAppointment(id, body, sendSms).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: AppointmentDTO) =>
					of(ProviderActions.UpdateAppointmentComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateAppointmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteAppointment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteAppointment),
		exhaustMap((action) => {
			return this.onlineAppointmentApi.cancelAppointment(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(() =>
					of(ProviderActions.DeleteAppointmentComplete())
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteAppointmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);
	// endregion

	loadSystemTemplates$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadSystemTemplates),
		exhaustMap(() => {
			return this.messageTemplateApi.getMessageTemplates(true).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<MessageTemplateDTO>) =>
					of(ProviderActions.LoadSystemTemplatesComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadSystemTemplatesFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadCustomTemplates$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadCustomTemplates),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.messageTemplateApi.getMessageTemplates(false, provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<MessageTemplateDTO>) =>
					of(ProviderActions.LoadCustomTemplatesComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadCustomTemplatesFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	saveCustomTemplate$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.SaveCustomTemplate),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			const messageTemplate: MessageTemplateDTO = Object.assign({}, action.payload, {person: provider.id});
			if (isNullOrUndefined(messageTemplate.id)) {
				return this.messageTemplateApi.createMessageTemplate(messageTemplate).pipe(
					takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
					switchMap((result: MessageTemplateDTO) =>
						of(ProviderActions.SaveCustomTemplateComplete({payload: result}))
					),
					catchError((error) =>
						from([
							ProviderActions.Error({error}),
							ProviderActions.SaveCustomTemplateFailed({error: error})
						])
					)
				);
			} else {
				return this.messageTemplateApi.updateMessageTemplate(messageTemplate).pipe(
					takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
					switchMap((result: MessageTemplateDTO) =>
						of(ProviderActions.SaveCustomTemplateComplete({payload: result}))
					),
					catchError((error) =>
						from([
							ProviderActions.Error({error}),
							ProviderActions.SaveCustomTemplateFailed({error: error})
						])
					)
				);
			}
		})),
		{useEffectsErrorHandler: false}
	);

	uploadAttachment$ = createEffect(() => this.actions$.pipe(
			ofType(ProviderActions.UploadAttachment),
			exhaustMap(({payload}) => {
				return this.messageTemplateApi.uploadAttachment(payload).pipe(
					takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
					switchMap((result: AttachmentsResponseDTO) =>
						of(ProviderActions.UploadAttachmentComplete({payload: result}))
					),
					catchError((error) =>
						from([
							ProviderActions.Error({error}),
							ProviderActions.UploadAttachmentFailed({error: error})
						])
					)
				);
			})),
		{useEffectsErrorHandler: false}
	);

	getAttachment$ = createEffect(() => this.actions$.pipe(
			ofType(ProviderActions.GetAttachment),
			exhaustMap(({payload: {id, uuid}}) => {
				return this.messageTemplateApi.getAttachment(id, uuid).pipe(
					takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
					switchMap((result: Blob) =>
						of(ProviderActions.GetAttachmentComplete({payload: result}))
					),
					catchError((error) =>
						from([
							ProviderActions.Error({error}),
							ProviderActions.GetAttachmentFailed({error: error})
						])
					)
				);
			})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Resources ========================================================== */

	createResource$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateResource),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.resourceList$),
		switchMap(([action, provider, resources]) => {
			const resource: ResourceDTO = Object.assign({}, action.payload, {personId: provider.id});
			return this.resourceService.createResource(resource).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceDTO) =>
					of(ProviderActions.CreateResourceComplete({
						resources: InternalUtils.getModifiedResourceState(result, resources),
						result: result
					}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateResourceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteResource$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteResource),
		withLatestFrom(this.providerFacade.resourceList$, this.providerFacade.calendarResources$),
		switchMap(([action, resources, calendarResources]) => {
			return this.resourceService.deleteResource(action.payload.resource.id, action.payload.email, action.payload.download).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(blob =>
					of(ProviderActions.DeleteResourceComplete({
							payload: {
								resources: InternalUtils.getModifiedResourceState(action.payload.resource, resources, true),
								calendarResources: !!calendarResources ?
									[...calendarResources.filter(resource => resource.id !== action.payload.resource.id)] : [],
								pdf: blob
							}
						})
					)
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteResourceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateResource$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateResource),
		withLatestFrom(this.providerFacade.resourceList$),
		switchMap(([action, resourceList]) => {
			return this.resourceService.patchResource(action.payload.id, action.payload.body).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceDTO) =>
					of(ProviderActions.UpdateResourceComplete({resources: InternalUtils.getModifiedResourceState(result, resourceList)}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateResourceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	reorderResource$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ReorderResources),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.resourceList$, this.providerFacade.calendarResources$),
		switchMap(([action, provider, resources, calendarResources]) => {
			return this.resourceService.reorderResources(provider.id, action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(() => {
						const RESOURCES = InternalUtils.getReorderedResourceState(action.payload, resources);
						console.error('### reorderResource$:', ResourceUtils.calendarResourcesIndexUpdated(calendarResources, RESOURCES));
						return of(ProviderActions.ReorderResourcesComplete({
							payload: {
								calendarResources: ResourceUtils.calendarResourcesIndexUpdated(calendarResources, RESOURCES),
								resources: RESOURCES
							}
						}))
					}
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ReorderResourcesFailed({error: error})
					])
				)
			);
		}))
	);

	loadResource$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResource),
		switchMap((action: any) => {
			return this.resourceService.readResource(action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceDTO) =>
					of(ProviderActions.LoadResourceComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadResourceGroup$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResourceGroup),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.resourceService.readAllResources(provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceGroupDTO) =>
					of(ProviderActions.LoadResourceGroupComplete({resources: [...result.resources]}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceGroupFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadResourceOfficeHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResourceOfficeHours),
		switchMap(({payload: {resourceId}}) => {
			return this.bookingHoursService.getResourceBookingHours(resourceId).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<BookingHoursDTO>) =>
					of(ProviderActions.LoadResourceOfficeHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceOfficeHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createResourceOfficeHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateResourceOfficeHours),
		switchMap(({payload: {resourceId, bookingHours}}) => {
			return this.bookingHoursService.createBookingHours(resourceId, bookingHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: BookingHoursDTO) =>
					of(ProviderActions.CreateResourceOfficeHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateResourceOfficeHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	patchResourceOfficeHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.PatchResourceOfficeHours),
		switchMap(({payload: {id, bookingHours}}) => {
			return this.bookingHoursService.patchBookingHours(id, bookingHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: BookingHoursDTO) =>
					of(ProviderActions.PatchResourceOfficeHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.PatchResourceOfficeHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteResourceOfficeHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteResourceOfficeHours),
		switchMap(({payload}) => {
			return this.bookingHoursService.deleteBookingHours(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.DeleteResourceOfficeHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteResourceOfficeHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadResourceAbsences$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResourceAbsences),
		switchMap(({payload: {resourceId}}) => {
			return this.absenceHourService.getAbsentHours(resourceId).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<AbsentHourDTO>) =>
					of(ProviderActions.LoadResourceAbsencesComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceAbsencesFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadResourceOnlineHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResourceOnlineHours),
		switchMap(({payload: {resourceId}}) => {
			return this.onlineBookingHoursService.getOnlineBookingHours(resourceId).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<OnlineBookingHourDTO>) =>
					of(ProviderActions.LoadResourceOnlineHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceOnlineHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createResourceAbsence$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateResourceAbsence),
		switchMap(({payload: {resourceId, absenceHours}}) => {
			return this.absenceHourService.createAbsentHour(resourceId, absenceHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: AbsentHourDTO) =>
					of(ProviderActions.CreateResourceAbsenceComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateResourceAbsenceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createResourceOnlineHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateResourceOnlineHours),
		switchMap(({payload: {resourceId, onlineBookingHours}}) => {
			return this.onlineBookingHoursService.createOnlineBookingHour(resourceId, onlineBookingHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: OnlineBookingHourDTO) =>
					of(ProviderActions.CreateResourceOnlineHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateResourceOnlineHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	patchResourceAbsence$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.PatchResourceAbsence),
		switchMap(({payload: {id, absenceHours}}) => {
			return this.absenceHourService.patchAbsentHour(id, absenceHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: AbsentHourDTO) =>
					of(ProviderActions.PatchResourceAbsenceComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.PatchResourceAbsenceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	patchResourceOnlineHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.PatchResourceOnlineHours),
		switchMap(({payload: {id, onlineBookingHours}}) => {
			return this.onlineBookingHoursService.patchOnlineBookingHour(id, onlineBookingHours).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: OnlineBookingHourDTO) =>
					of(ProviderActions.PatchResourceOnlineHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.PatchResourceOnlineHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteResourceAbsence$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteResourceAbsence),
		switchMap(({payload}) => {
			return this.absenceHourService.deleteAbsentHour(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.DeleteResourceAbsenceComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteResourceAbsenceFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteResourceOnlineHours$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteResourceOnlineHours),
		switchMap(({payload}) => {
			return this.onlineBookingHoursService.deleteOnlineBookingHour(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: any) =>
					of(ProviderActions.DeleteResourceOnlineHoursComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteResourceOnlineHoursFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadContentCMS = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadContent),
		switchMap(({payload: {type, pageType}}) => {
			return this.cmsServiceApi.getPageContent(type, pageType).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((response: any) =>
					of(ProviderActions.LoadContentComplete({payload: response}))
				),
				catchError((error) => {
					return from([
						ProviderActions.Error({error}),
						ProviderActions.LoadContentFailed({error: error})
					]);
				})
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getResourceGDPR$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadResourceGdpr),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.classicAppointmentApi.getResourceGdprInfo(provider.id, action.payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceGdprDTO) =>
					of(ProviderActions.LoadResourceGdprComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadResourceGdprFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Calendar Settings ========================================================== */

	loadSettings$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadSettings),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.settingsCalendar$),
		switchMap(([action, provider, settingsCalendar]: [any, PersonDTO, CalendarSettingsDTO]) => {
			return this.settingsService.getPersonSettings(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settingsAppointment: SettingsDTO) => {
					delete settingsAppointment.calendarSettings;
					return of(ProviderActions.LoadSettingsComplete({
						settings: {
							appointment: settingsAppointment,
							calendar: settingsCalendar
						}
					}));
				}),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadSettingsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateSettings$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateSettings),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.settingsCalendar$),
		switchMap(([{payload: {body}}, provider, settingsCalendar]: [any, PersonDTO, CalendarSettingsDTO]) => {
			return this.settingsService.patchPersonSettings(provider.id, body).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settingsAppointment: SettingsDTO) => {
					delete settingsAppointment.calendarSettings;
					return of(ProviderActions.UpdateSettingsComplete({
						settings: {
							appointment: settingsAppointment,
							calendar: settingsCalendar
						}
					}));
				}),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateSettingsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadCalendarSettings$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadCalendarSettings),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.settings$),
		switchMap(([{payload}, provider, settingsAppointment]: [any, PersonDTO, SettingsDTO]) => {
			return this.settingsService.getCalendarSettings(provider.id, payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settingsCalendar: CalendarSettingsDTO) =>
					of(ProviderActions.LoadCalendarSettingsComplete({
						settings: {
							appointment: settingsAppointment,
							calendar: settingsCalendar
						}
					}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadCalendarSettingsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateCalendarSettings$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateCalendarSettings),
		withLatestFrom(this.providerFacade.data$, this.providerFacade.settings$),
		switchMap(([{payload: {body, username}}, provider, settingsAppointment]: [any, PersonDTO, SettingsDTO]) => {
			return this.settingsService.patchCalendarSettings(provider.id, body, username).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settingsCalendar: CalendarSettingsDTO) =>
					of(ProviderActions.UpdateCalendarSettingsComplete({
						settings: {
							appointment: settingsAppointment,
							calendar: settingsCalendar
						}
					}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateCalendarSettingsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadCalendarDefaultSettings$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadCalendarDefaultSettings),
		switchMap(() => {
			return this.settingsService.getDefaultSettings().pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settings: SettingsDTO) =>
					of(ProviderActions.LoadCalendarDefaultSettingsComplete({payload: settings}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadCalendarDefaultSettingsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	downloadPrintableCalendar$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DownloadPrintableCalendar),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			return this.onlineAppointmentApi.calendarPrint({...action.payload, careProviderId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Blob) =>
					of(ProviderActions.DownloadPrintableCalendarComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DownloadPrintableCalendarFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadDefaultTimeSlots$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadDefaultTimeSlots),
		switchMap(() => {
			return this.settingsService.getDefaultTimeSlots().pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((settings: TimeSlotsDTO) =>
					of(ProviderActions.LoadDefaultTimeSlotsComplete({payload: settings}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadDefaultTimeSlotsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	downloadPeriodicalDeletionReport$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DownloadPeriodicalDeletionReport),
		withLatestFrom(this.providerFacade.data$),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			return this.periodicalDeletionService.getPeriodicalDeletionReport(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((blob) =>
					of(ProviderActions.DownloadPeriodicalDeletionReportComplete({payload: blob}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DownloadPeriodicalDeletionReportFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== Treatment ========================================================== */
		//region Treatment

	loadTreatmentList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadTreatmentList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.treatmentService.getPersonTreatments(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: Array<TreatmentDTO>) =>
					of(ProviderActions.LoadTreatmentListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadTreatmentListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createTreatment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateTreatment),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.treatmentService.createTreatment(provider.id, action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: TreatmentDTO) =>
					of(ProviderActions.CreateTreatmentComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateTreatmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateTreatment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateTreatment),
		switchMap(({payload: {id, body}}) => {
			return this.treatmentService.patchTreatment(id, body).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: TreatmentDTO) =>
					of(ProviderActions.UpdateTreatmentComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateTreatmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteTreatment$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteTreatment),
		switchMap((action) => {
			return this.treatmentService.deleteTreatment(action.payload.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(() =>
					of(ProviderActions.DeleteTreatmentComplete({payload: action.payload}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteTreatmentFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getPatientList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.GetPatientList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.searchPatients({...action.payload, careProviderId: provider.id}).pipe(
				switchMap((result: PageablePatientsResponseDTO) =>
					of(ProviderActions.GetPatientListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.GetPatientListFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getPatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadPatientDetails),
		switchMap((action) => {
			return this.patientService.getPatientById(action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PatientDTO) =>
					of(ProviderActions.LoadPatientDetailsComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadPatientDetailsFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getPatientAppointmentList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadPatientAppointmentList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.classicAppointmentApi.getAppointments({...action.payload, careProviderId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PageableAppointmentInfosResponseDTO) =>
					of(ProviderActions.LoadPatientAppointmentListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadPatientAppointmentListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createPatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreatePatient),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.createPatient({...action.payload, careProviderId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PatientDTO) =>
					of(ProviderActions.CreatePatientComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreatePatientFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updatePatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdatePatient),
		switchMap((action) => {
			return this.patientService.updatePatient(action.payload.id, action.payload.data).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: PatientDTO) =>
					from([
						ProviderActions.UpdatePatientComplete({payload: result}),
						ProviderActions.LoadPatientDetailsComplete({payload: result})
					])
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdatePatientFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getPatientGDPR$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.GetPatientGdpr),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.getPatientGdprInfo(provider.id, action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: PatientGdprInfoDTO) =>
					of(ProviderActions.GetPatientGdprComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.GetPatientGdprFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deletePatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeletePatient),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			const payload = action.payload;
			return this.patientService.deletePatients(provider.id, payload.patientList, payload.patientName, payload.email, payload.download).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap(blob =>
					of(ProviderActions.DeletePatientComplete({payload: blob}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeletePatientFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	blockPatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.BlockPatient),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.blockPatient(action.payload.id, {careProviderId: provider.id, block: action.payload.block}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next) =>
					of(ProviderActions.BlockPatientComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.BlockPatientFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	getMergePatientList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.GetMergePatientList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.getMergingPatients(action.payload, provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: GetPatientsResponseDTO) =>
					of(ProviderActions.GetMergePatientListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						//ErrorActions.HttpAction({errorFrom: ProviderActions.GetMergePatientListFailed, error: error}),
						ProviderActions.GetMergePatientListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	mergePatient$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.MergePatient),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !isNullOrUndefined(provider)),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			return this.patientService.mergePatients({...action.payload, careProviderId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: PatientDTO) =>
					of(ProviderActions.MergePatientComplete({payload: next}))
				),
				catchError((error) =>
					from([
						//ErrorActions.HttpAction({errorFrom: ProviderActions.MergePatientFailed, error: error}),
						ProviderActions.MergePatientFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	/** ============================================== User Management ========================================================== */

	userRequestList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadUserRequestList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.findAllRequestsForCareProvider(provider.id).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: RegistrationRequestDTO[]) =>
					of(ProviderActions.LoadUserRequestListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadUserRequestListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	userList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadUserList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider && !!action.payload),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.userServiceApi.searchUsersPaginated({...action.payload, personId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: PageableUsersResponseDTO) =>
					of(ProviderActions.LoadUserListComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadUserListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	createRegistrationRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.CreateRegistrationRequest),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider && !!action.payload),
		exhaustMap(([action, provider]: [any, PersonDTO]) => {
			return this.personServiceApi.createRegistrationRequest(provider.id, action.payload).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next) =>
					of(ProviderActions.CreateRegistrationRequestComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.CreateRegistrationRequestFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	resendVerificationMail$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ResendVerificationMail),
		exhaustMap(({payload}) => {
			return this.personServiceApi.updateRegistrationStatus(payload.id, payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next) =>
					of(ProviderActions.ResendVerificationMailComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ResendVerificationMailFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteRegistrationRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteRegistrationRequest),
		exhaustMap(({payload}) => {
			return this.personServiceApi.deleteRegistrationRequest(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next) =>
					of(ProviderActions.DeleteRegistrationRequestComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteRegistrationRequestFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	deleteUser$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.DeleteUser),
		switchMap(({payload: {username, personId}}) => {
			return this.userServiceApi.deleteUser(username, personId).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next) =>
					of(ProviderActions.DeleteUserComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.DeleteUserFailed({error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	loadUser$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadUser),
		withLatestFrom(this.providerFacade.data$),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.userServiceApi.readUser(action.payload, provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: UserDTO) =>
					of(ProviderActions.LoadUserComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadUserFailed({error: error})
					])
				)
			);
		})
		),
		{useEffectsErrorHandler: false}
	);

	loadNotAssignedResourceList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadNotAssignedResourceList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.resourceService.getNotAssignedResources(provider.id, action.payload.userId).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((result: ResourceDTO[]) =>
					of(ProviderActions.LoadNotAssignedResourceListComplete({payload: result}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadNotAssignedResourceListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	updateUser$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.UpdateUser),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.userServiceApi.patchUser(action.payload.username, action.payload.data, provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: UserDTO) =>
					of(ProviderActions.UpdateUserComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.UpdateUserFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	resetPassword$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.ResetPassword),
		exhaustMap(({payload}) => {
			return this.userServiceApi.requestPasswordReset(payload).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: PasswordResetResponseDTO) =>
					of(ProviderActions.ResetPasswordComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.ResetPasswordFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	pageableWaitingList$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.LoadPageableWaitingList),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider && !!action.payload),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.waitingListService.getWaitingListsPaginated({...action.payload, personId: provider.id}).pipe(
				ignoreResultOnActions(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: PageableWaitingListsResponseDTO) =>
					of(ProviderActions.LoadPageableWaitingListComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.LoadPageableWaitingListFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	waitingListBookingStatus$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.GetWaitingListBookingStatus),
		withLatestFrom(this.providerFacade.data$),
		filter(([action, provider]: [any, PersonDTO]) => !!provider && !!action.payload),
		switchMap(([action, provider]: [any, PersonDTO]) => {
			return this.waitingListService.getWaitingListBookingStatus(action.payload, provider.id).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: Array<WaitingListBookingStatusResponseDTO>) =>
					of(ProviderActions.GetWaitingListBookingStatusComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.GetWaitingListBookingStatusFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	patchWaitingListRequest$ = createEffect(() => this.actions$.pipe(
		ofType(ProviderActions.PatchWaitingListRequest),
		switchMap(({payload: {requestId, data}}) => {
			return this.waitingListService.patchWaitingList(requestId, data).pipe(
				takeUntil(this.actions$.pipe(ofType(ProviderActions.ClearState))),
				switchMap((next: any) =>
					of(ProviderActions.PatchWaitingListRequestComplete({payload: next}))
				),
				catchError((error) =>
					from([
						ProviderActions.Error({error}),
						ProviderActions.PatchWaitingListRequestFailed({error: error})
					])
				)
			);
		})),
		{useEffectsErrorHandler: false}
	);

	constructor(
		private actions$: Actions,
		private providerFacade: ProviderFacade,
		private personServiceApi: PersonService,
		private keyValuePairServiceApi: KeyValuePairService,
		private medicalServicesService: MedicalServiceService,
		private messageService: MessageService,
		private onlineAppointmentApi: OnlineAppointmentService,
		private resourceOverviewService: ResourceOverviewService,
		private classicAppointmentApi: ClassicAppointmentService,
		private messageTemplateApi: MessageTemplateService,
		private resourceService: ResourceService,
		private bookingHoursService: BookingHoursService,
		private absenceHourService: AbsentHoursService,
		private onlineBookingHoursService: OnlineBookingHoursService,
		private cmsServiceApi: ContentManagementSystemService,
		private settingsService: SettingsService,
		private periodicalDeletionService: PeriodicalDeletionService,
		private treatmentService: TreatmentsService,
		private patientService: PatientService,
		private userServiceApi: UserService,
		private waitingListService: WaitingListService
	) {
	}
}
